import boxsvg from '@netcurio/frontend-assets/src/icons/box.svg'
import { Roles, Status } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIconButton,
	NetcurioIcons,
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow
} from '@netcurio/frontend-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { GoodReceipt, GoodReceiptItem as IGoodReceiptItem } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import { GoodReceiptDetailItem } from './GoodReceiptDetailItem'
import { GoodReceiptItem } from './GoodReceiptItem'
import styles from './goodsReceiptDetail.module.scss'

interface GoodsReceiptDetailProps {
	closeGoodReceiveDetail(): void
	userRoles: Roles[]
	newGRModal: boolean
	statusPurchaseOrder: Status
	showEntriesRecord(): void
	dataGoodsReceipts: Array<GoodReceipt>
	positionGRSelected: number
	showGrDetail(position: number, idGoodReceive: string): void
	dataGoodsReceiptsDetail: Array<IGoodReceiptItem>
}
export const GoodsReceiptDetail: FC<GoodsReceiptDetailProps> = ({
	closeGoodReceiveDetail,
	userRoles = [],
	newGRModal,
	statusPurchaseOrder,
	showEntriesRecord,
	dataGoodsReceipts,
	positionGRSelected,
	showGrDetail,
	dataGoodsReceiptsDetail
}) => {
	const { t } = useTranslation()
	const showNewGRButton: boolean = userRoles.includes(Roles.CUSTOMER)
	const disabled: boolean =
		newGRModal ||
		(statusPurchaseOrder &&
			(statusPurchaseOrder.key === Constants.STATUS.NEW ||
				statusPurchaseOrder.key === Constants.STATUS.REJECTED ||
				statusPurchaseOrder.key === Constants.STATUS.DELIVERED))
	const selectedPosition = positionGRSelected - 1

	return (
		<NetcurioGrid width="100%" height="100vh" container className={styles.containerGoodsReceiptDetail}>
			<NetcurioGrid item width="100%" height="50%" maxHeight="50%">
				<div className={styles.headerTable}>
					<NetcurioGrid display="flex">
						<img src={boxsvg} className={styles.grDetailsIcon} alt="" />
						<p className={styles.textHeaderDelivery}>{t('deliverText')}</p>
					</NetcurioGrid>
					<NetcurioIconButton onClick={closeGoodReceiveDetail}>
						<NetcurioIcons.HighlightOff sx={{ color: 'white' }} />
					</NetcurioIconButton>
				</div>
				<NetcurioGrid item width="100%" display="flex" justifyContent="space-around" padding="1rem">
					<NetcurioGrid>
						<p className={styles.centeredTitle}>{t('relatedGoodsReceiptText')}</p>
					</NetcurioGrid>
					{showNewGRButton && (
						<NetcurioGrid className={styles.centeredButton}>
							<NetcurioButton
								startIcon={<NetcurioIcons.Add />}
								color="secondary"
								onClick={showEntriesRecord}
								variant="outlined"
								disabled={disabled}
							>
								{t('newGoodReceipt')}
							</NetcurioButton>
						</NetcurioGrid>
					)}
				</NetcurioGrid>
				<NetcurioTableContainer minHeight="calc(100% - 10.5rem)" maxHeight="calc(100% - 10.5rem)">
					<NetcurioTable size="small" stickyHeader>
						<NetcurioTableHead>
							<NetcurioTableRow isDetailHeader>
								<NetcurioTableCell align="center">
									<span>{t('billText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('GRCustomerReference')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('dateText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('createdByText')}</span>
								</NetcurioTableCell>
							</NetcurioTableRow>
						</NetcurioTableHead>
						<NetcurioTableBody>
							{dataGoodsReceipts.map((gr: GoodReceipt, index: number) => (
								<GoodReceiptItem
									key={index}
									goodReceipt={gr}
									position={index}
									positionGRSelected={positionGRSelected}
									showGrDetail={showGrDetail}
								/>
							))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			</NetcurioGrid>
			<NetcurioGrid
				item
				width="100%"
				height="50%"
				maxHeight="50%"
				className={styles.listOfReceiptsContainer}
			>
				<div className={styles.headerItems}>
					<p className={styles.title}>
						{`${t('deliveredText')}: ${dataGoodsReceipts[selectedPosition + 1]?.id ?? ''}`}
					</p>
				</div>
				<NetcurioTableContainer>
					<NetcurioTable size="small" stickyHeader>
						<NetcurioTableHead>
							<NetcurioTableRow isDetailHeader>
								<NetcurioTableCell align="center">
									<span>{t('positionText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('productText')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('storageLocation')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('deliveredHeaderGR')}</span>
								</NetcurioTableCell>
								<NetcurioTableCell align="center">
									<span>{t('batchText')}</span>
								</NetcurioTableCell>
							</NetcurioTableRow>
						</NetcurioTableHead>
						<NetcurioTableBody>
							{dataGoodsReceiptsDetail.map((grItem: IGoodReceiptItem, index: number) => (
								<GoodReceiptDetailItem key={index} dataGoodsReceiptsDetail={grItem} />
							))}
						</NetcurioTableBody>
					</NetcurioTable>
				</NetcurioTableContainer>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
