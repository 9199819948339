import { useQuery } from '@tanstack/react-query'
import { PURCHASE_REQUISITION_TYPES_CATALOG } from '../api/purchase-requisition-types-catalogue'
import { useClient } from './useClient'

export const usePurchaseRequisitionTypes = (rfc: string) => {
	const client = useClient()
	const fetchPurchaseRequisitionTypes = async () => {
		try {
			const result = await client.query({
				query: PURCHASE_REQUISITION_TYPES_CATALOG,
				variables: {
					companyRfc: rfc
				}
			})

			if (result.data.PurchaseRequisitionTypes) {
				return result.data.PurchaseRequisitionTypes
			}
			return []
		} catch (error) {
			console.error('Error fetching PurchaseRequisitionTypes:', error)
			return []
		}
	}

	const { data: purchaseRequisitionTypes, isSuccess } = useQuery({
		queryKey: ['purchaseRequisitionTypes'],
		queryFn: fetchPurchaseRequisitionTypes,
		staleTime: Infinity
	})

	return { purchaseRequisitionTypes, isSuccess }
}
