import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { SxProps, Theme, ThemeOptions } from '@mui/material/styles'
import {
	ColorBaseWhite,
	MdRefTypefaceFont,
	MdSysLabelMediumSize,
	Netcurio01PrimaryBDark,
	Netcurio01PrimaryCLight,
	Netcurio04ActionCDisabled,
	Netcurio05ErrorAMain
} from '@netcurio/frontend-design-tokens'
import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetcurioTextField, NetcurioTextFieldProps } from '../NetcurioTextField/NetcurioTextField'

const heights = {
	medium: {
		standard: '4.8rem',
		outlined: '5.6rem',
		filled: '5.6rem'
	},
	small: {
		standard: '4.5rem',
		outlined: '4rem',
		filled: '4.8rem'
	},
	smaller: {
		standard: '4rem',
		outlined: '3.2rem',
		filled: '4.2rem'
	}
}

export const NetcurioAutocompleteThemeOptions: ThemeOptions = {
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				clearIndicator: { svg: { fontSize: '2rem' } },
				popupIndicator: { svg: { fontSize: '2.4rem' } },
				option: { fontSize: '1.4rem' }
			}
		},
		MuiInputBase: {
			styleOverrides: {
				formControl: {
					fontSize: MdSysLabelMediumSize,
					backgroundColor: ColorBaseWhite,
					borderColor: Netcurio01PrimaryCLight
				},
				input: {
					fontFamily: MdRefTypefaceFont,
					fontSize: MdSysLabelMediumSize,
					borderColor: Netcurio01PrimaryCLight
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontFamily: MdRefTypefaceFont,
					fontSize: MdSysLabelMediumSize
				}
			}
		}
	}
}

interface NetcurioAutocompleteProps extends Partial<AutocompleteProps<unknown, boolean, boolean, boolean>> {
	label?: NetcurioTextFieldProps['label']
	placeholder?: NetcurioTextFieldProps['placeholder']
	variant: NetcurioTextFieldProps['variant']
	error?: NetcurioTextFieldProps['error']
	minLength?: number
	options: Array<any>
	onSelectValue: (value: any) => void
	getOptionLabel?: (value: any) => string
	isOptionEqualToValue?: (option: any, value: any) => boolean
	inputValue?: string
	onInputValueChange?: (input: string) => void
	loading?: boolean
	warning?: boolean
	disableClearable?: boolean
	size?: 'small' | 'medium'
	height?: 'small' | 'medium' | 'smaller'
}

/**
 * NetcurioAutocomplete
 * @param label <ReactNode>: The label content.
 * @param placeholder <string>: The short hint displayed in the `input` before the user enters a value.
 * @param variant <'standard' | 'filled' | 'outlined'>: The variant to use. @default 'outlined'
 * @param error <boolean>: If `true`, the label is displayed in an error state.
 * @param minLength <number>: Set the minLength to display dropdown options. To use inputValue is required to be controlled
 * @param options <Array<unknown>>: Required array of options for the autocomplete dropdown.
 * @param onSelectValue <(value: unknown) => void>: Function to handle the selection of the value
 * @param inputValue <string>: Value of the TextField
 * @param onInputValueChange <(input: string) => void>: Function to handle the change of the TextField value
 * @param AutocompleteProps <AutocompleteProps>: Component can receive the values of AutocompleteProps, check https://mui.com/material-ui/api/autocomplete/
 * @param size <'small' | 'medium'>: Component height (legacy).
 * @param height <'smaller' | 'small' | 'medium'>: The height of the component.
 @returns component
 */
export const NetcurioAutocomplete = forwardRef<HTMLDivElement, NetcurioAutocompleteProps>(
	({ minLength, onSelectValue, inputValue, onInputValueChange, loading, ...props }, ref) => {
		const {
			label,
			placeholder,
			error,
			warning,
			disableClearable,
			options,
			variant = 'outlined',
			height = 'smaller',
			...autoCompleteProps
		} = props
		const { t } = useTranslation()
		const [canOpen, setCanOpen] = useState(false)
		const [open, setOpen] = useState(false)
		const [sxValue, setSxValue] = useState<SxProps<Theme>>({})

		useEffect(() => {
			if (warning) {
				setSxValue({
					'& .MuiInputBase-root': {
						height: heights[height] ? heights[height][variant] : '',
						borderColor: Netcurio05ErrorAMain
					},
					'& .MuiTextField-root': {
						height: heights[height] ? heights[height][variant] : ''
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						'&:hover fieldset': {
							borderColor: Netcurio01PrimaryBDark,
							borderWidth: '0.2rem !important'
						}
					},
					'& .MuiAutocomplete-input': {
						position: 'absolute',
						width: '80% !important'
					},
					'& .MuiInputBase-input': {
						width: '100%'
					},
					'& .MuiOutlinedInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem',
							borderColor: Netcurio05ErrorAMain
						},
						'&:hover fieldset': {
							borderColor: Netcurio01PrimaryBDark
						},
						'&.Mui-focused fieldset': {
							borderColor: Netcurio05ErrorAMain
						},
						'&.Mui-focused:after': {
							borderBottomColor: Netcurio05ErrorAMain
						},
						'&.Mui-error:after': {
							borderBottomColor: Netcurio05ErrorAMain
						}
					},
					'& .MuiStandardInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem'
						}
					},
					'& .MuiFilledInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						borderBottom: '1px solid transparent',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem'
						}
					},
					/* Labels Section */
					'& .MuiFormLabel-root': {
						fontSize: '1.4rem',
						zIndex: 1,
						position: 'absolute',
						marginTop: '0'
					},
					'& .MuiInputLabel-outlined': {
						top: '-0.3rem'
					},
					'& .MuiInputLabel-standard': {
						top: '0'
					},
					'& .MuiInputLabel-filled': {
						top: '-1.2rem'
					},
					/* Shrinked Animated Label Section */
					'& .MuiInputLabel-shrink': {
						position: 'absolute',
						marginTop: '0.4rem',
						marginLeft: '0.2rem'
					},
					/* Disabled Inputs Section */
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: Netcurio04ActionCDisabled,
						borderColor: Netcurio04ActionCDisabled
					},
					'& .MuiOutlinedInput-root.Mui-disabled': {
						'& fieldset': {
							borderColor: Netcurio04ActionCDisabled
						}
					},
					/* Adornment */
					'& .MuiAutocomplete-endAdornment': {
						zIndex: 1,
						position: 'absolute !important',
						right: 0
					},
					'& .MuiCircularProgress-root': {
						position: 'absolute !important',
						right: '5rem'
					}
				})
			} else {
				setSxValue({
					'& .MuiInputBase-root': {
						height: heights[height] ? heights[height][variant] : '',
						borderColor: Netcurio01PrimaryCLight
					},
					'& .MuiTextField-root': {
						height: heights[height] ? heights[height][variant] : ''
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						'&:hover fieldset': {
							borderColor: Netcurio01PrimaryBDark,
							borderWidth: '0.2rem !important'
						}
					},
					'& .MuiAutocomplete-input': {
						position: 'absolute',
						width: '80% !important'
					},
					'& .MuiInputBase-input': {
						width: '100%'
					},
					'& .MuiOutlinedInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem',
							borderColor: Netcurio01PrimaryCLight
						},
						'&:hover fieldset': {
							borderColor: Netcurio01PrimaryBDark
						}
					},
					'& .MuiStandardInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem'
						}
					},
					'& .MuiFilledInput-root': {
						height: heights[height] ? heights[height][variant] : '',
						borderBottom: '1px solid transparent',
						'& fieldset': {
							paddingLeft: '1.2rem',
							paddingRight: '1.2rem'
						}
					},
					/* Labels Section */
					'& .MuiFormLabel-root': {
						fontSize: '1.4rem',
						zIndex: 1,
						position: 'absolute',
						marginTop: '0'
					},
					'& .MuiInputLabel-outlined': {
						top: '-0.3rem'
					},
					'& .MuiInputLabel-standard': {
						top: '0'
					},
					'& .MuiInputLabel-filled': {
						top: '-1.2rem'
					},
					/* Shrinked Animated Label Section */
					'& .MuiInputLabel-shrink': {
						position: 'absolute',
						marginTop: '0.4rem',
						marginLeft: '0.2rem'
					},
					/* Disabled Inputs Section */
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: Netcurio04ActionCDisabled,
						borderColor: Netcurio04ActionCDisabled
					},
					'& .MuiOutlinedInput-root.Mui-disabled': {
						'& fieldset': {
							borderColor: Netcurio04ActionCDisabled
						}
					},
					/* Adornment */
					'& .MuiAutocomplete-endAdornment': {
						zIndex: 1,
						position: 'absolute !important',
						right: 0
					},
					'& .MuiCircularProgress-root': {
						position: 'absolute !important',
						right: '5rem'
					}
				})
			}
		}, [height, variant, warning])
		let forcePopupIcon = false
		if (inputValue?.length && minLength) {
			forcePopupIcon = inputValue?.length >= minLength
		}
		const onClose = () => {
			setOpen(false)
		}
		const onOpen = () => {
			if (canOpen) {
				setOpen(true)
			}
		}

		const controlledOpen =
			minLength !== undefined && inputValue !== undefined
				? { open, onOpen, onClose, forcePopupIcon }
				: {}
		useEffect(() => {
			if (minLength !== undefined && inputValue !== undefined) {
				setCanOpen(inputValue?.length >= minLength)
				if (inputValue?.length === minLength) {
					setOpen(true)
				}
				if (inputValue?.length < minLength) {
					setOpen(false)
				}
			}
		}, [inputValue, minLength])

		const renderInput = (params: AutocompleteRenderInputParams) => (
			<NetcurioTextField
				{...params}
				ref={ref}
				variant={variant}
				label={label}
				placeholder={placeholder}
				error={error}
				warning={warning}
				value={inputValue}
				onChange={() => null}
				height={height}
				adornmentPosition="end"
				adornment={loading ? <CircularProgress color="inherit" size={20} /> : <></>}
			/>
		)

		const onChange = (_: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
			onSelectValue(newValue)
		}

		const onInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
			onInputValueChange?.(newInputValue)
		}

		return (
			<Autocomplete
				onChange={onChange}
				onInputChange={onInputChange}
				{...autoCompleteProps}
				{...controlledOpen}
				options={options}
				renderInput={renderInput}
				inputValue={inputValue}
				loading={loading}
				clearText={t('clear')}
				openText={t('openText')}
				closeText={t('closeText')}
				loadingText={t('loadingOptions')}
				noOptionsText={t('noAutocompleteOptions')}
				disableClearable={disableClearable}
				sx={sxValue}
			/>
		)
	}
)

NetcurioAutocomplete.displayName = 'NetcurioAutocomplete'
