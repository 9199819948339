import { getCurrency, getCurrentUser } from '@netcurio/frontend-common'
import {
	NetcurioAutocomplete,
	NetcurioDatePicker,
	NetcurioIcons,
	NetcurioTextField,
	NetcurioTooltip,
	PriceField
} from '@netcurio/frontend-components'
import { ColorBaseWhite } from '@netcurio/frontend-design-tokens'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { ChangeEvent, FC, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Queries from '../../../components/queries'
import { Product } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import Formatter from '../../../utilities/formatter'
import { useDebounce } from '../../../utilities/useDebounce'
import { ErrorRFQModal } from '../ModalsRFQ/ErrorRFQModal'
import { ItemQ, SelectedProductItemProps, ValidationItem } from '../types'
import styles from './NewQuotation.module.scss'

interface QuotationRegisterRowProps {
	keyRFQ: number
	itemPosition: number
	itemQuotation: ItemQ
	selectedProductItem(selectedProductItemProps: SelectedProductItemProps): void
	handleItemFieldChange(
		event: ChangeEvent<HTMLInputElement>,
		index: number,
		field: string,
		requestPosition: number
	): void
	deleteItem(keyRFQ: number, itemPosition: number): void
	validationItem?: ValidationItem
}

export const QuotationRegisterRow: FC<QuotationRegisterRowProps> = ({
	itemPosition,
	keyRFQ,
	itemQuotation,
	selectedProductItem,
	handleItemFieldChange,
	deleteItem,
	validationItem
}) => {
	const minDate = dayjs()
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [disabledInputPrice, setDisabledInputPrice] = useState<boolean>(true)
	const [newUnitPriceValue, setNewUnitPriceValue] = useState<boolean>(false)
	const [unitPrice, setUnitPrice] = useState<string | number>('unitPrice')
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const [errorProduct, setErrorProduct] = useState({
		isOpen: false,
		code: ''
	})
	const isMouseOverInput: MutableRefObject<boolean> = useRef<boolean>(true)
	const [date, setDate] = useState<dayjs.Dayjs | null>(null)
	const [searchProduct, setSearchProducts] = useState<string>('')
	const [selectedProduct, setSelectedProduct] = useState<Product | string>({})
	const [currenProductList, setCurrentProductList] = useState<Product[]>([])
	const debouncedProductInput = useDebounce<string>(searchProduct, 500)
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (itemQuotation.product) {
			setSelectedProduct(itemQuotation.product as Product)
			setUnitPrice(itemQuotation.unitPriceString)
		}

		if (!itemQuotation.product) {
			setSelectedProduct(undefined)
			setUnitPrice(undefined)
		}
	}, [itemQuotation.product])

	useEffect(() => {
		const supplierRFC = getCurrentUser().company.rfc
		if (supplierRFC) {
			if (debouncedProductInput.length >= 3) {
				setLoading(true)
				client
					.query({
						query: Queries.PRODUCT_NAME_CODE,
						variables: {
							searchText: debouncedProductInput.toLocaleLowerCase(),
							supplier: supplierRFC
						}
					})
					.then((result: ApolloQueryResult<{ ProductsByNameOrCode: Array<Product> }>) => {
						setCurrentProductList(result.data.ProductsByNameOrCode)
					})
					.catch((error) => {
						console.error(error)
						const errorCode = showErrorComponent(error)
						if (!expiredToken(errorCode)) {
							setErrorProduct({
								isOpen: true,
								code: errorCode
							})
						}
					})
					.finally(() => {
						setLoading(false)
					})
			}
		} else {
			setCurrentProductList([])
		}
	}, [debouncedProductInput])

	useEffect(() => {
		if (itemQuotation.unitPriceString !== 'unitPrice') {
			isMouseOverInput.current = true
		}
		if (itemQuotation.unitPriceString !== 'unitPrice' && unitPrice === 'unitPrice') {
			if (itemQuotation.unitPriceString === 'unitPrice') {
				setDisabledInputPrice(true)
			}
			setUnitPrice(itemQuotation.unitPriceString)
		}
	}, [itemQuotation.unitPriceString])

	const setAmount = (event: ChangeEvent<HTMLInputElement>) => {
		handleItemFieldChange(event, itemPosition, 'amount', keyRFQ)
	}

	const setNewPrice = (
		tempTarget: ChangeEvent<HTMLInputElement>,
		nameField: string,
		valueEvent: number | string
	) => {
		handleItemFieldChange(tempTarget, itemPosition, nameField, keyRFQ)
		setUnitPrice(valueEvent)
	}

	const blurInputPrice = () => {
		setNewUnitPriceValue(itemQuotation.referencePrice !== itemQuotation.unitPrice)
		setDisabledInputPrice(true)
		setIsHovering(false)
		isMouseOverInput.current = true
	}

	const activateInputPrice = () => {
		isMouseOverInput.current = false
		setDisabledInputPrice(false)
		setIsHovering(false)
	}
	const revertUnitPrice = () => {
		setIsHovering(true)
		handleItemFieldChange(
			{
				target: {
					value: itemQuotation.referencePrice.toString()
				}
			} as ChangeEvent<HTMLInputElement>,
			itemPosition,
			'unitPrice',
			keyRFQ
		)
		setNewUnitPriceValue(false)
		setUnitPrice(itemQuotation.referencePrice)
	}

	const deleteItemRow = () => {
		deleteItem(keyRFQ, itemPosition)
		setDate(null)
	}

	const handleOnChangeDate = (date: dayjs.Dayjs) => {
		setDate(date)
		handleItemFieldChange(
			{
				target: {
					value: date.toISOString()
				}
			} as ChangeEvent<HTMLInputElement>,
			itemPosition,
			'requiredDate',
			keyRFQ
		)
	}

	const getSuggestion = (searchText: string) => {
		setSearchProducts(searchText)
	}

	const validateProductValue = (option: Product, value) => option.id === value.id

	const getProductLabel = (product: Product) => `${product.id} - ${product.description}`

	const selectProduct = (product: Product) => {
		setSelectedProduct(product)
		selectedProductItem({
			product: product ? product : undefined,
			itemIndex: itemPosition,
			unitPrice: product ? product.unit_price : 'unitPrice',
			unit: product ? product.unit : 'unitMeasure',
			tax: product ? product.tax : 'taxIVA',
			referencePrice: product ? product.unit_price : '',
			requiredDate: itemQuotation.requiredDate,
			amount: itemQuotation.amount ?? '',
			requestPosition: keyRFQ,
			currency: product ? product.currency : '',
			rejectRow: false
		})
	}

	return (
		<div className={styles.rowRegisterItem}>
			<div className={styles.resizeProductNewQuotation}>
				<NetcurioAutocomplete
					id={'productItem' + keyRFQ + Constants.SYMBOL.HYPHEN + itemPosition}
					label={t('productText')}
					placeholder={t('enrollmentClientPlc')}
					height="smaller"
					size="small"
					variant="outlined"
					options={currenProductList}
					getOptionLabel={getProductLabel}
					isOptionEqualToValue={validateProductValue}
					value={selectedProduct}
					onSelectValue={selectProduct}
					onInputValueChange={getSuggestion}
					loading={loading}
					minLength={3}
					error={validationItem?.productError}
					fullWidth
					freeSolo
				/>
			</div>
			<NetcurioTooltip title={<label>{t('quantity')}</label>} placement="top">
				<div className={styles.amountFieldContainer}>
					<NetcurioTextField
						value={itemQuotation.amount ? itemQuotation.amount.toString() : ''}
						onChange={setAmount}
						error={validationItem?.amountError}
						fullWidth
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('unitMeasure')}</label>} placement="top">
				<div className={styles.ivaUmField}>
					<div className={styles.colorReadModeText}>
						<label>
							{itemQuotation.unit === 'unitMeasure'
								? t(itemQuotation.unit)
								: itemQuotation.unit}
						</label>
					</div>
				</div>
			</NetcurioTooltip>
			<div className={styles.marginUnitPriceRowRegister}>
				{itemQuotation.unitPriceString === 'unitPrice' ? (
					<NetcurioTooltip title={<label>{t('priceTooltip')}</label>} placement="top">
						<div
							id={'unitPriceItem' + keyRFQ + Constants.SYMBOL.HYPHEN + itemPosition}
							className={classNames(
								styles.onlyReadFields,
								styles.resizeCost,
								styles.costNewQuotation,
								styles.colorReadModeText
							)}
						>
							<p className={styles.textAlignResponsive}>{t('priceTooltip')}</p>
						</div>
					</NetcurioTooltip>
				) : (
					<NetcurioTooltip title={<label>{t('priceTooltip')}</label>} placement="top">
						<div
							id={'unitPriceItem' + keyRFQ + Constants.SYMBOL.HYPHEN + itemPosition}
							className={classNames(
								styles.resizeCost,
								styles.padding0Important,
								styles.containerEditPrice
							)}
							onMouseEnter={() => isMouseOverInput.current && setIsHovering(true)}
							onMouseLeave={() => setIsHovering(false)}
						>
							{isHovering ? (
								<div
									id={
										'firstStateUnitPrice' +
										keyRFQ +
										Constants.SYMBOL.HYPHEN +
										itemPosition
									}
									className={classNames(styles.containerButtonsHoverUnitPrice)}
								>
									<div onClick={activateInputPrice} className={styles.width100}>
										<NetcurioIcons.Edit
											sx={{ color: ColorBaseWhite }}
											className={styles.buttonsHoverUnitPrice}
										/>
									</div>
									{newUnitPriceValue && (
										<div
											className={classNames(styles.width100)}
											onClick={revertUnitPrice}
										>
											<NetcurioIcons.Undo
												sx={{ color: ColorBaseWhite }}
												className={styles.buttonsHoverUnitPrice}
											/>
										</div>
									)}
								</div>
							) : (
								<PriceField
									idPriceField={
										'inputPriceItem4444' + keyRFQ + Constants.SYMBOL.HYPHEN + itemPosition
									}
									placeHolder={'unitPriceText'}
									disabledField={disabledInputPrice}
									readValue={
										disabledInputPrice
											? getCurrency(unitPrice as number)
											: unitPrice.toString()
									}
									onChangeText={setNewPrice}
									onBlurPrice={blurInputPrice}
								/>
							)}
						</div>
					</NetcurioTooltip>
				)}
			</div>
			<NetcurioTooltip title={<label>{t('taxIVA')}</label>} placement="top">
				<div className={styles.ivaUmField}>
					{itemQuotation.tax === 'taxIVA' ? (
						<div className={styles.colorReadModeText}>
							<label>{t(itemQuotation.tax)}</label>
						</div>
					) : (
						Formatter.percent.format(itemQuotation.tax)
					)}
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('totalDescriptionStatus')}</label>} placement="top">
				<div className={styles.totalField}>
					{itemQuotation.netPrice === 'netValue' ? (
						<p className={classNames(styles.textCenterResponsive, styles.colorReadModeText)}>
							{t('totalDescriptionStatus')}
						</p>
					) : (
						<p
							className={classNames(
								styles.textCenterResponsive,
								styles.textAlignRight,
								styles.width100
							)}
						>
							{Formatter.currency.format(itemQuotation.netPrice)}
						</p>
					)}
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('proposedDeliveryDate')}</label>} placement="top">
				<div className={styles.calendarQuotation}>
					<NetcurioDatePicker
						height="smaller"
						className={classNames(styles.calendarQuotationInput)}
						label={t('requiredDateText')}
						format="DD/MM/YY"
						value={date}
						onChange={handleOnChangeDate}
						minDate={minDate}
						error={validationItem?.requiredDate}
					/>
				</div>
			</NetcurioTooltip>
			<NetcurioTooltip title={<label>{t('delete')}</label>} placement="top">
				<button
					className={classNames(styles.buttonCancelItemQuotation, styles.backgroundTransparent)}
				>
					<img
						src={itemQuotation.cancelImg}
						alt="cancel"
						className={classNames(styles.cancelIcon)}
						onClick={deleteItemRow}
					/>
				</button>
			</NetcurioTooltip>
			<ErrorRFQModal open={errorProduct.isOpen} errorCode={errorProduct.code} />
		</div>
	)
}
