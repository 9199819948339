import { CSSObject, List, ListItem, Drawer as MuiDrawer, styled, Theme, Tooltip } from '@mui/material'
import { Roles, RouteApp, ROUTES } from '@netcurio/frontend-common'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NetcurioGrid } from '../NetcurioGrid/NetcurioGrid'
import styles from './NavDrawer.module.scss'
import { DrawerFallback } from './components/DrawerFallback/DrawerFallback'
import { DrawerHeader } from './components/DrawerHeader/DrawerHeader'

interface AsideProps {
	activeSection: string
	userRoles: Roles[]
	NavLink: FC<{ to: string; className: string; children: React.ReactNode }>
}

const openedMixin = (theme: Theme): CSSObject => ({
	width: '25rem',
	marginTop: '4rem',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden',
	border: 'none'
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	marginTop: '4rem',
	overflowX: 'hidden',
	width: `5rem`,
	[theme.breakpoints.up('sm')]: {
		width: `5rem`
	},
	border: 'none'
})

const drawerWidth = '5rem'

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	marginTop: '4rem',
	variants: [
		{
			props: ({ open }) => open,
			style: {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme)
			}
		},
		{
			props: ({ open }) => !open,
			style: {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme)
			}
		}
	]
}))

export const NavDrawer: FC<AsideProps> = ({ activeSection, userRoles, NavLink }) => {
	const { t } = useTranslation()
	const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(false)

	useEffect(() => {
		if (isNavDrawerOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}

		return () => {
			document.body.style.overflow = ''
		}
	}, [isNavDrawerOpen])

	return (
		<NetcurioGrid
			container
			width={isNavDrawerOpen ? '100vw' : '5rem'}
			minHeight="calc(100vh - 4rem)"
			height="calc(100vh - 4rem)"
			className={classNames(styles.wrapperMenu)}
			position={isNavDrawerOpen ? 'absolute' : 'relative'}
			display={isNavDrawerOpen ? 'grid' : 'flex'}
			gridTemplateAreas="1fr"
			gridTemplateColumns="20rem 1fr"
		>
			<NetcurioGrid
				container
				width={isNavDrawerOpen ? '20rem' : '100%'}
				className={classNames(styles.containerNavDrawer, {
					[styles.containerMenuOpen]: isNavDrawerOpen
				})}
				top="4rem"
			>
				<NetcurioGrid
					item
					display="flex"
					alignItems="center"
					flexDirection="column"
					position="relative"
					className={styles.drawerContainer}
					xs={12}
				>
					<Drawer variant="permanent" open={isNavDrawerOpen} className={styles.drawer}>
						<DrawerHeader
							isNavDrawerOpen={isNavDrawerOpen}
							setIsNavDrawerOpen={setIsNavDrawerOpen}
						/>
						<List
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: '0.7rem',
								padding: '0'
							}}
						>
							{ROUTES.filter((route: RouteApp) => !route.hide).map((route: RouteApp) => {
								if (!route.roles.some((role) => userRoles.includes(role))) {
									return null
								}

								const link = (
									<NavLink
										to={route.link}
										className={classNames(styles.menuItem, {
											[styles.expandedMenuItem]: isNavDrawerOpen,
											[styles.activeNavLink]: activeSection === route.section
										})}
									>
										<div className={styles.menuIcon}>
											<i className={classNames(styles.icon, styles[route.icon])}></i>
										</div>

										<p
											className={classNames(styles.textOptionNavDrawer, {
												[styles.hideText]: !isNavDrawerOpen
											})}
										>
											{t(route.name)}
										</p>
									</NavLink>
								)

								return (
									<ListItem
										key={route.link}
										sx={{
											padding: '0 0.5rem'
										}}
									>
										{isNavDrawerOpen ? (
											link
										) : (
											<Tooltip
												arrow={false}
												key={route.link}
												title={t(route.dataTip)}
												placement="right"
												slotProps={{
													popper: {
														modifiers: [
															{
																name: 'offset',
																options: {
																	offset: [0, -8]
																}
															}
														]
													},
													tooltip: {
														style: {
															borderRadius: '0 3px 3px 0',
															backgroundColor: 'rgba(0, 0, 0, 0.75)',
															height: '2rem',
															display: 'flex',
															alignItems: 'center',
															padding: '0 0.5rem',
															fontFamily: 'Helvetica Neue',
															fontSize: '12px',
															fontWeight: '400',
															letterSpacing: '0.2px',
															lineHeight: 1.3333333333333333
														}
													}
												}}
											>
												{link}
											</Tooltip>
										)}
									</ListItem>
								)
							})}
						</List>
					</Drawer>
				</NetcurioGrid>
			</NetcurioGrid>

			<DrawerFallback isNavDrawerOpen={isNavDrawerOpen} setIsNavDrawerOpen={setIsNavDrawerOpen} />
		</NetcurioGrid>
	)
}
