import { allowDecimals } from '@netcurio/frontend-common'
import {
	Autocomplete,
	CustomInputLabel,
	CustomMenuItem,
	NetcurioDatePicker,
	NetcurioFormControl,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioSelectChangeEvent,
	NetcurioTooltip,
	OutlinedSelect,
	OutlinedTextField
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { ChangeEvent, FC, Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../../../common/styles/detail.module.scss'
import GlobalQueries from '../../../../components/queries'
import { MeasurementUnit, Product, Supplier } from '../../../../types'
import { useDebounce } from '../../../../utilities/useDebounce'
import { PurchaseRequisitionItemInterface } from '../../interfaces/purchase-requisition-item.interface'
import { FieldValidations } from '../PurchaseRequisitionDetail'

interface ItemProps {
	index: number
	deleteItem: (index: number) => void
	handlePositionFieldChange: (
		value: string | Product | number | ChangeEvent<HTMLInputElement> | Supplier,
		index: number,
		nameField: string
	) => void
	item: PurchaseRequisitionItemInterface
	totalErrors: Array<FieldValidations>
	isFormDisabled: boolean
	measurementUnits: Array<MeasurementUnit>
	headerCurrency?: string
	client: DefaultClient<NormalizedCacheObject>
}

export const PurchaseRequisitionPosition: FC<ItemProps> = ({
	index,
	item,
	handlePositionFieldChange,
	deleteItem,
	totalErrors,
	isFormDisabled,
	measurementUnits,
	headerCurrency,
	client
}) => {
	const { t } = useTranslation()
	const minDate = dayjs()
	/* Supplier */
	const [supplierLoading, setSupplierLoading] = useState<boolean>(false)
	const [supplierSearchString, setSupplierSearchString] = useState<string>('')
	const [supplierInputValue, setSupplierInputValue] = useState<string>('')
	const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null)
	const [suppliersList, setSuppliersList] = useState<Array<Supplier>>([])
	const [supplierPreSavedObject, setSupplierPreSavedObject] = useState<Supplier | undefined>(undefined)
	/* Product */
	const [prodLoading, setProdLoading] = useState<boolean>(false)
	const [productSearchString, setProductSearchString] = useState<string>('')
	const [productInputValue, setProductInputValue] = useState<string>('')
	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
	const [currentProductsList, setCurrentProductsList] = useState<PurchaseRequisitionItemInterface[]>([])
	/* Rest of fields */
	const [requestedAmount, setRequestedAmount] = useState<number>(1)
	const [itemUnitMeasure, setItemUnitMeasure] = useState<string>('')
	const [itemUnitPrice, setItemUnitPrice] = useState<number>(0)
	const [itemNetPrice, setItemNetPrice] = useState<number>(0)
	const [date, setDate] = useState<Dayjs | null>(
		item.requiredDeliveryDate ? dayjs(item.requiredDeliveryDate) : null
	)
	const debouncedProductInput = useDebounce<string>(productSearchString, 500)
	const debouncedSupplierInput = useDebounce<string>(supplierSearchString, 500)
	const errorsInPurchaseRequisition = totalErrors[index]

	useEffect(() => {
		setItemUnitPrice(0)
		setItemNetPrice(0)
	}, [headerCurrency])

	useEffect(() => {
		if (item.supplierRfc) {
			setSelectedSupplier(
				item.supplierRfc
					? ({ rfc: item.supplierRfc, name: item.supplierDescription } as Supplier)
					: null
			)
		}
		if (item.productCode) {
			setSelectedProduct({
				id: item.productCode,
				description: item.productDescription
			} as Product)
		} else if (item.productDescription) {
			setSelectedProduct({
				description: item.productDescription
			} as Product)
		} else setSelectedProduct(null)
		setItemUnitMeasure(item.unit ?? '')
		setItemNetPrice(item.netAmount ?? 0)
		setItemUnitPrice(item.unitPrice ?? 0)
		setRequestedAmount(item.quantity ?? 0)
		setDate(item.requiredDeliveryDate ? dayjs(getDateFormat(item.requiredDeliveryDate)) : null)
	}, [item])

	useEffect(() => {
		setProductInputValue(item.productDescription ?? '')
		if (item.productDescription && item.productDescription?.length > 0 && !item.unitPrice) {
			handlePositionFieldChange(item.productDescription, index, 'product')
		}
	}, [item.productDescription])

	useEffect(() => {
		if (debouncedSupplierInput.length >= 3) {
			setSupplierLoading(true)
			suggestSupplier(debouncedSupplierInput)
		}
	}, [debouncedSupplierInput])

	useEffect(() => {
		if (selectedSupplier?.rfc || (item && item.supplierRfc)) {
			if (debouncedProductInput.length >= 3) {
				setProdLoading(true)
				client
					.query({
						query: GlobalQueries.PRODUCT_NAME_CODE,
						variables: {
							searchText: debouncedProductInput.toLowerCase(),
							supplier: selectedSupplier?.rfc ?? item.supplierRfc
						}
					})
					.then((result) => {
						setCurrentProductsList(result.data.ProductsByNameOrCode)
					})
					.catch((error) => {
						console.error(error)
					})
					.finally(() => setProdLoading(false))
			}
		} else {
			setCurrentProductsList([])
		}
	}, [debouncedProductInput])

	const getDateFormat = (dateToAnalyze: string | Date) => {
		if (dateToAnalyze instanceof Date) return dateToAnalyze.getTime()
		else return parseInt(dateToAnalyze.toString(), 10)
	}

	const getProductSuggestion = (searchText: string) => {
		setProductSearchString(searchText)
		setProductInputValue(searchText)
	}

	const validateProductValue = (option: unknown, value: unknown) => {
		const product = option as Product
		const selection = value as Product
		return product.id === selection.id
	}

	const getProductLabel = (option: any) => {
		const product = option as { id?: string; productCode?: string; description?: string }
		if (typeof product === 'object') {
			if (product.id) {
				return `${product.id} - ${product.description}`
			} else if (product.productCode) {
				return `${product.productCode} - ${product.description}`
			} else if (product.description && !product.id && !product.productCode) {
				return `${product.description}`
			}
		} else {
			return option
		}
	}

	const getSupplierLabel = (option: Supplier) => {
		return `${option.name} - ${option.rfc}`
	}

	const validateSupplierValue = (option: Supplier, value: { rfc: string }) => option?.rfc === value?.rfc

	const handleSupplierSelection = (supplier: Supplier) => {
		if (supplier) {
			const chosenSupplier: Supplier | undefined = suppliersList?.find((supp) => supp === supplier)
			if (chosenSupplier) {
				setSelectedSupplier(chosenSupplier)
				handlePositionFieldChange(chosenSupplier, index, 'supplier')
			}
		} else {
			setSelectedSupplier(null)
			setSelectedProduct(null)
		}
	}
	const getSupplierSuggestion = (searchText: string) => {
		setSupplierSearchString(searchText)
		setSupplierInputValue(searchText)
	}

	const suggestSupplier = (debouncedSupplierInput: string) => {
		client
			.query({
				query: GlobalQueries.SUPPLIERS_BY_NAME_OR_CODE,
				variables: {
					search_text: debouncedSupplierInput
				}
			})
			.then((result: { data: { SuppliersByNameOrCode: any } }) => {
				setSuppliersList(result.data.SuppliersByNameOrCode)
				if (item.supplierRfc && item && !supplierPreSavedObject) {
					setSupplierPreSavedObject(result.data.SuppliersByNameOrCode[0])
					setRequestedAmount(item.quantity ? +item.quantity : 0)
				}
				setSupplierLoading(false)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const selectProduct = (option: Product) => {
		let purchaseRequisitionItem
		let isCurrencyMatchingHeader = false
		if (!option) {
			setProductInputValue('')
			purchaseRequisitionItem = {
				unit: '',
				netAmount: 0,
				unitPrice: 0
			} as PurchaseRequisitionItemInterface
		} else {
			isCurrencyMatchingHeader = option.currency === headerCurrency
			setProductSearchString(option.id + ' ' + option.description)
			setSelectedProduct(option)
			purchaseRequisitionItem = {
				unit: option.unit ? option.unit.trim() : '',
				unitPrice: isCurrencyMatchingHeader ? (option.unit_price ?? 0) : 0,
				netAmount: isCurrencyMatchingHeader
					? option.unit_price
						? option.unit_price * requestedAmount
						: 0
					: 0
			} as PurchaseRequisitionItemInterface
			handlePositionFieldChange(option, index, 'product')
		}
		setItemUnitMeasure(purchaseRequisitionItem.unit ?? '')
		setItemUnitPrice(purchaseRequisitionItem.unitPrice ?? 0)
		setItemNetPrice(purchaseRequisitionItem.netAmount ?? 0)
	}

	const setQuantity = (event: ChangeEvent<HTMLInputElement>) => {
		if (allowDecimals(event.target.value)) {
			handlePositionFieldChange(event.target.value, index, 'quantity')
		}
	}
	const setUnitPrice = (event: ChangeEvent<HTMLInputElement>) => {
		if (allowDecimals(event.target.value)) {
			handlePositionFieldChange(event.target.value, index, 'unitPrice')
		}
	}

	const handleSetUnitMeasure = (event: NetcurioSelectChangeEvent) => {
		handlePositionFieldChange(event.target.value, index, 'unit')
		setItemUnitMeasure(event.target.value)
		setItemUnitPrice(0)
		handlePositionFieldChange(0, index, 'unitPrice')
		setItemNetPrice(0)
	}

	const handleOnChangeDate = (date: Dayjs) => {
		setDate(date)
		handlePositionFieldChange(date?.toISOString(), index, 'requiredDate')
	}

	const displayWarningMsg = () => {
		if (
			errorsInPurchaseRequisition?.product_description ||
			errorsInPurchaseRequisition?.quantity ||
			errorsInPurchaseRequisition?.unit_measure ||
			errorsInPurchaseRequisition?.unit_cost ||
			errorsInPurchaseRequisition?.required_date
		) {
			return <NetcurioGrid className={styles.errorText}>{t('fieldNoFillText')}</NetcurioGrid>
		} else {
			return null
		}
	}

	return (
		<NetcurioGrid className={styles.itemModule}>
			<NetcurioGrid className={styles.rightModule}>
				<NetcurioGrid className={styles.alignModules}>
					<NetcurioTooltip title={t('positionText')}>
						<div
							className={classNames(
								styles.readOnlyField,
								styles.resizePosition,
								styles.positionHeight
							)}
						>
							{index + 1}
						</div>
					</NetcurioTooltip>
					<NetcurioGrid style={{ width: '100%' }}>
						<NetcurioGrid className={styles.containerSize}>
							<NetcurioGrid className={styles.flexContainer}>
								<NetcurioGrid className={styles.supplierContainer}>
									<Autocomplete
										label={t('supplierText')}
										placeholder={t('selectSupplier')}
										size="small"
										variant="outlined"
										options={suppliersList}
										getOptionLabel={getSupplierLabel}
										isOptionEqualToValue={validateSupplierValue}
										value={selectedSupplier}
										onSelectValue={handleSupplierSelection}
										inputValue={supplierInputValue}
										onInputValueChange={getSupplierSuggestion}
										loading={supplierLoading}
										minLength={3}
										freeSolo
										fullWidth
										disabled={isFormDisabled}
										getOptionKey={(option: any) => option?.rfc ?? ''}
										key={
											item && item.supplierRfc
												? (supplierPreSavedObject as unknown as Key)
												: undefined
										}
										defaultValue={
											item && item.supplierRfc
												? (supplierPreSavedObject as unknown as Key)
												: null
										}
										clearOnBlur={false}
									/>
								</NetcurioGrid>
								<NetcurioGrid className={styles.resizeProduct}>
									<Autocomplete
										label={t('purchaseRequisitions.detail.codeOrDesc')}
										placeholder={t('selectProduct')}
										size="small"
										variant="outlined"
										options={currentProductsList}
										getOptionLabel={getProductLabel}
										isOptionEqualToValue={validateProductValue}
										value={selectedProduct}
										onSelectValue={selectProduct}
										inputValue={productInputValue}
										onInputValueChange={getProductSuggestion}
										loading={prodLoading}
										minLength={3}
										freeSolo
										fullWidth
										autoSelect
										disabled={isFormDisabled}
										error={
											errorsInPurchaseRequisition
												? errorsInPurchaseRequisition.product_description
												: false
										}
										getOptionKey={(option: Product) => option?.id ?? ''}
										key={
											item.productDescription
												? (selectedProduct?.id as unknown as Key)
												: item.productCode
										}
										defaultValue={
											item.productDescription
												? (item.productDescription as unknown as Key)
												: productInputValue
										}
										clearOnBlur={false}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid
								className={classNames(styles.flexContainer, styles.marginRowPosition)}
							>
								<NetcurioTooltip title={t('quantity')}>
									<NetcurioGrid className={styles.resizeUm}>
										<OutlinedTextField
											label={t('quantity')}
											value={requestedAmount}
											onChange={setQuantity}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.quantity
													: false
											}
											fullWidth
											disabled={isFormDisabled}
											type={'number'}
											inputProps={{
												min: '0',
												step: '0.01'
											}}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioGrid className={styles.resizeUm}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="unitMeasure"
											label={t('purchaseRequisitions.detail.unit')}
											size="small"
											disabled={isFormDisabled}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.unit_measure
													: false
											}
										/>
										<OutlinedSelect
											labelId={'unitMeasure'}
											label={t('unitMeasureText')}
											value={itemUnitMeasure}
											onChange={(e) => handleSetUnitMeasure(e)}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.unit_measure
													: false
											}
											disabled={isFormDisabled}
										>
											{measurementUnits?.map((measurementUnit) => {
												return (
													<CustomMenuItem
														value={measurementUnit.code}
														key={measurementUnit.code}
													>
														{measurementUnit.description}
													</CustomMenuItem>
												)
											})}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioTooltip title={t('purchaseRequisitions.detail.unitPrice')}>
									<NetcurioGrid className={styles.productSpace}>
										<OutlinedTextField
											label={t('purchaseRequisitions.detail.unitPrice')}
											value={itemUnitPrice}
											onChange={setUnitPrice}
											adornment={
												itemUnitPrice ? <NetcurioIcons.AttachMoney /> : undefined
											}
											fullWidth
											adornmentPosition={'start'}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.unit_cost
													: false
											}
											disabled={isFormDisabled}
											type={'number'}
											inputProps={{
												min: '0',
												step: '0.01'
											}}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioTooltip title={t('netValue')}>
									<NetcurioGrid className={styles.productSpace}>
										<OutlinedTextField
											label={t('netValue')}
											value={itemNetPrice}
											adornment={<NetcurioIcons.AttachMoney />}
											adornmentPosition="start"
											disabled
											fullWidth
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioTooltip title={t('purchaseRequisitions.detail.currency')}>
									<NetcurioGrid className={styles.productSpace}>
										<OutlinedTextField
											label={t('purchaseRequisitions.detail.currency')}
											value={headerCurrency}
											disabled
											fullWidth
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioTooltip title={t('purchaseRequisitions.detail.requiredDate')}>
									<NetcurioGrid>
										<NetcurioDatePicker
											height="smaller"
											label={t('requiredDateText')}
											format="DD/MM/YY"
											value={date ?? null}
											onChange={(date: Dayjs) => handleOnChangeDate(date)}
											minDate={minDate}
											error={errorsInPurchaseRequisition?.required_date ?? false}
											fullWidth
											disabled={isFormDisabled}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
							</NetcurioGrid>
							{displayWarningMsg()}
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid>
						<NetcurioTooltip title={t('delete')}>
							<NetcurioIcons.Close
								style={{ display: isFormDisabled ? 'none' : 'inherit' }}
								className={styles.deletePositionIcon}
								onClick={() => deleteItem(index)}
							/>
						</NetcurioTooltip>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
