import { NetcurioButton, NetcurioIcons, NetcurioTooltip } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import buttonStyles from '../../common/styles/button.module.scss'

interface ExportButtonProps {
	onClick: () => void
	translationKey?: string
}

export const ExportButton = ({ onClick, translationKey = 'exportTextButton' }: ExportButtonProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioTooltip title={t('buttonExportTooltip')}>
			<NetcurioButton
				variant="outlined"
				color="primary"
				className={classNames(buttonStyles.button, buttonStyles.fill)}
				endIcon={<NetcurioIcons.Download />}
				onClick={onClick}
				size="small"
			>
				<span>{t(translationKey)}</span>
			</NetcurioButton>
		</NetcurioTooltip>
	)
}
