import { getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import { HomeWidget, HomeWidgetProps } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'
import { ConditionalRender } from '../../components/ConditionalRender/ConditionalRender'
import { useCompanySettings } from '../../hooks/useCompanySettings'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import styles from './home.module.scss'

export const HomePage = (): ReactElement => {
	const currentUser = getCurrentUser()
	const { companySettings } = useCompanySettings({ rfc: currentUser?.company?.rfc ?? null })
	const userRoles: Roles[] = getUserRolesForCompany()
	const HomeWidgetArray: HomeWidgetProps[] = [
		{
			title: 'newPO',
			link: URLS.PO_NEW,
			userRole: [Roles.CUSTOMER]
		},
		{
			title: 'newInvoice',
			link: URLS.NEW_INVOICE,
			userRole: [Roles.CUSTOMER_STANDALONE, Roles.SUPPLIER]
		},
		{
			title: 'newEntryGR',
			link: URLS.GR_NEW,
			userRole: [Roles.CUSTOMER]
		},
		{
			title: 'newCMText',
			link: URLS.NEW_CREDIT_MEMO,
			userRole: [Roles.SUPPLIER]
		},
		{
			title: 'reportsText',
			link: URLS.REPORT_LIST,
			userRole: [Roles.CUSTOMER, Roles.SUPPLIER]
		}
	]
	const checkIfCompanyHasStandAloneEnabled = () => {
		return companySettings?.standalone_invoice_workflow_enabled
	}

	return (
		<div className={styles.cardsContainer}>
			{HomeWidgetArray.map((widgetInformation, i) => (
				<ConditionalRender
					key={widgetInformation.title + i}
					condition={widgetInformation.userRole.some(
						(role: string) =>
							userRoles.includes(role) ||
							(role === Roles.CUSTOMER_STANDALONE &&
								checkIfCompanyHasStandAloneEnabled())
					)}
				>
					<HomeWidget widgetInformationArray={widgetInformation} />
				</ConditionalRender>
			))}
		</div>
	)
}
