import { Roles } from '@netcurio/frontend-common'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './consumptionList.module.scss'

export function objectHeaderArray(userRoles: Roles[]): Array<tableFillHeader> {
	const idOrderColumn: tableFillHeader = new tableFillHeader(
		'idConsignmentHeader',
		styles.headerIdContainer,
		'',
		'id',
		'text'
	)
	const customerReferenceOrderColumn: tableFillHeader = new tableFillHeader(
		'customerReferenceConsignmentHeader',
		styles.headerCustomerReferenceContainer,
		'',
		'customer_reference',
		'text'
	)
	const branchOrderColumn: tableFillHeader = new tableFillHeader(
		'branchOfficeConsignmentHeader',
		styles.headerBranchContainer,
		'',
		'branch_office',
		'text'
	)
	const createdByOrderColumn: tableFillHeader = new tableFillHeader(
		'createdByConsignmentHeader',
		styles.headerCreatedByContainer,
		'',
		'created_by',
		'text'
	)
	const createDateOrderColumn: tableFillHeader = new tableFillHeader(
		'creationDateConsignmentHeader',
		styles.headerCreatedAtContainer,
		'',
		'created_at',
		'date'
	)

	const customerOrderColumn: tableFillHeader = new tableFillHeader(
		'customerConsignmentHeader',
		styles.headerCustomerContainer,
		'',
		'customer',
		'text'
	)

	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		idOrderColumn,
		customerReferenceOrderColumn,
		branchOrderColumn,
		createdByOrderColumn,
		createDateOrderColumn
	]

	const objectHeaderArraySupplier: Array<tableFillHeader> = [
		idOrderColumn,
		customerReferenceOrderColumn,
		customerOrderColumn,
		branchOrderColumn,
		createDateOrderColumn
	]

	if (userRoles.includes(Roles.CUSTOMER)) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}
