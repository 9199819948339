import { Filter, Modal, TableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	NumericInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { HeaderTable } from '../../../../../components/HeaderTable/HeaderTable'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import listHelper from '../../../../../utilities/listHelper'
import styles from '../../../adminConsole.module.scss'
import { CatchedErroModalProductList } from './Modals/CatchedErroModalProductList'
import { modalValues, styleMainTableValues, userListValues } from './defaultValuesProductList'
import * as queries from './queries'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import {
	DataProduct,
	FilterToApply,
	Product,
	ProductListDataTable,
	ProductListProps,
	ResultQueryProducts,
	StyleMainTable,
	UpdateFilter
} from './types'

const headerButtons = objectHeaderArray()
export const ProductList: FC<ProductListProps> = ({ setDataFilterArray }) => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [productList, setProductList] = useState<ProductListDataTable>(userListValues)
	const [styleMainTable, setStyleMainTable] = useState<StyleMainTable>(styleMainTableValues)
	const [modal, setModal] = useState<Modal>(modalValues)
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const heightRow = 40
	let isMounted = true

	useEffect(() => {
		if (isMounted) {
			showLoadingSpinner()
			const finalRowsHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.ROWS_INFO_HEIGHT
			const numberRows: number = Math.round(finalRowsHeight / 44) + 1
			setProductList((prevState) => ({
				...prevState,
				limitRows: numberRows,
				rowsActual: numberRows
			}))
			const finalHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.TABLE_HEIGHT
			const mainHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.CONTAINER_HEIGHT
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
		}
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		if (activePagination && stopPagination && isMounted) {
			setActivePagination(false)
			setStopPagination(false)
			filteringProducts(productList.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	useEffect(() => {
		if (productList.totalRows && productList.rowsActual) {
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)
		}

		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [productList.totalRows, productList.rowsActual])

	useEffect(() => {
		if (productList.limitRows !== undefined && isMounted) {
			filteringProducts(0, productList.initialSort, '', productList.initialSort)
		}
	}, [modal.errorCode, productList.limitRows])

	useEffect(() => {
		if (productList.deleteRange && productList.actualField === 'unit_price') {
			setProductList((prevState) => ({
				...prevState,
				deleteRange: false
			}))
		}
		if (productList.dataStatusFilter === 'return') {
			if (productList.fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setProductList((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
			}
			if (productList.filterValue !== undefined) {
				setProductList((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		}
		if (productList.filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!productList.filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}

		setResultNotFound(productList.totalRows < 1)
	}, [
		productList.deleteRange,
		productList.dataStatusFilter,
		productList.filterContainerBar,
		productList.totalRows,
		productList.statusFilter,
		productList.filterValue,
		styleMainTable.mainTableHeight,
		styleMainTable.mainContainerHeight
	])

	const handlePagination = (): void => {
		listHelper.handlePagination(
			'tableInformation',
			productList.totalRows,
			productList.rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		if (columnFilterActual === 'is_active') {
			valueFilterActual = valueFilterActual === Constants.PRODUCT_STATUS.ACTIVE ? 'true' : 'false'
		}
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			productList.dataFilters,
			0,
			[]
		) as FilterToApply

		const objUpdate: UpdateFilter = resultFiltersToApply.objectForStateUpdate
		setProductList((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))

		if (objUpdate.dataStatusFilter) {
			setProductList((prevState) => ({
				...prevState,
				dataStatusFilter: objUpdate.dataStatusFilter
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const filteringProducts = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove = ''
	): void => {
		let fieldListData: string = productList.fieldList
		let orderListData: string = productList.orderList
		let columnFilterActual: string = productList.actualFilterData
		let currentList: DataProduct = { ...productList.dataProducts }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setProductList((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setProductList((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setProductList((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'id':
			case 'description':
			case 'currency':
			case 'unit':
				typeFilterActual = 'wildcard'
				break
			case 'is_active':
				typeFilterActual = 'boolean'
				break
			case 'unit_price':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}

		if (skip > 0) {
			const rowsActual: number = productList.rowsActual + productList.limitRows
			setProductList((prevState) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setProductList((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataCreditMemo: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)

		setDataFilterArray(filterToApply, {
			sortField: fieldListData,
			sortOrder: orderListData
		})
		getProducts(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const getProducts = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataProduct
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.PRODUCTS,
				variables: {
					limit: productList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result: ApolloQueryResult<ResultQueryProducts>) => {
				let interCount: number = skip
				const data: DataProduct = { ...currentList }
				const obj: Array<Product> = result.data.Products.list || []
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				if (isMounted) {
					setProductList((prevState) => ({
						...prevState,
						totalRows: result.data.Products.total,
						dataProducts: data,
						dataStatusFilter: ''
					}))
				}
				setStopPagination(true)
				hideLoadingSpinner()
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setProductList((prevState) => ({
				...prevState,
				dataCreditMemo: {},
				dataStatusFilter: ''
			}))
			setStopPagination(true)
			setModal({
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
		setShowBarLoader(false)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string
		switch (filterActual) {
			case 'numericFilter':
				classActualField = 'product-' + fieldStyle + '-filter'
				activeFilter = productList.numericFilter
				break
			case 'statusFilter':
				classActualField = 'product-' + fieldStyle + '-filter'
				activeFilter = productList.statusFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = productList.textFilter
				break
		}

		if (activeFilter === 'display-none') {
			if (styleMainTable.mainTableHeight > window.innerHeight - 222) {
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))
			}

			setProductList((prevState) => ({
				...prevState,
				minAmount: undefined,
				maxAmount: undefined,
				filterValue: '',
				minRange: undefined,
				maxRange: undefined,
				numericFilter: 'display-none',
				statusFilter: 'display-none',
				textFilter: 'display-none',
				[filterActual]: classActualField,
				actualField: field,
				textHeader: styles.textHeaderListAdmin
			}))
		} else if (productList.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
			setProductList((prevState) => ({
				...prevState,
				filterValue: '',
				[filterActual]: 'display-none',
				textHeader: styles.headerTableDefault,
				initRange: undefined,
				finalRange: undefined
			}))
		} else {
			setProductList((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
	}

	const callDataList = (sortField: string, order: string, fieldFilter: string, filterValue: string) => {
		showLoadingSpinner()
		let initRange: number | Dayjs
		let finalRange: number | Dayjs
		const minRange: number | Dayjs = productList.minRange
		const maxRange: number | Dayjs = productList.maxRange

		if (minRange) initRange = minRange
		if (maxRange) finalRange = maxRange

		if (sortField !== '') {
			filteringProducts(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringProducts(0, sortField, order, fieldFilter, filterValue, initRange, finalRange, undefined)
		}
		setProductList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const selectionFilter = (status: string) => {
		callDataList('', '', productList.actualField, status)
		const booleanOptions: Array<TableFillSelectionFilter> = objectStatusFilterArray().filter(
			(option) => option.getSelectedParameter() !== status
		)
		setProductList((prevState) => ({
			...prevState,
			fillStatusInputFilter: booleanOptions
		}))
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
		setProductList((prevState) => ({
			...prevState,
			numericFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.headerTableDefault,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
	}

	const handleTextFilterChangeQty = (evt: ChangeEvent<HTMLInputElement>, order: string) => {
		evt.preventDefault()
		const newFilterValue: number = evt.target.value
		const value: string = evt.target.value
		if (order === 'initial') {
			setProductList((prevState) => ({
				...prevState,
				minRange: newFilterValue,
				minAmount: value
			}))
		} else {
			setProductList((prevState) => ({
				...prevState,
				maxRange: newFilterValue,
				maxAmount: value
			}))
		}
	}

	const emptyFilterField = () => {
		setProductList((prevState) => ({
			...prevState,
			filterValue: '',
			minAmount: '',
			maxAmount: ''
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			productList.actualField,
			productList.filterValue,
			productList.minRange,
			productList.maxRange
		)
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value = evt.target.value
		setProductList((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringProducts, productList.initialSort)
		setProductList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			productList.dataFilters,
			closeFilterContainerBar,
			filteringProducts
		)
		setProductList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	return (
		<div className="tableInformationWhiteStyle margins-table">
			<div className={styles.headerMainTableUsersList}>
				{Object.keys(headerButtons).map((key) => (
					<HeaderTable
						key={key}
						dataMainHeaderTable={headerButtons[key]}
						textHeader={productList.textHeader}
						showFilters={showFilters}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={productList.statusFilter}
				actualField={productList.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={productList.fillStatusInputFilter}
			/>
			<NumericInputFilter
				showNumFilter={productList.numericFilter}
				actualField={productList.actualField}
				minAmount={productList.minAmount}
				maxAmount={productList.maxAmount}
				handleTextFilterChangeQty={handleTextFilterChangeQty}
				handleFilterKeyDown={handleFilterKeyDown}
				callDataList={callDataList}
				closeFilter={closeFilter}
			/>
			<TextInputFilter
				showTextFilter={productList.textFilter}
				actualField={productList.actualField}
				valueFilter={productList.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{productList.filterContainerBar ? (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={productList.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.PRODUCTS}
					booleanNames={{
						true: Constants.PRODUCT_STATUS.ACTIVE,
						false: Constants.PRODUCT_STATUS.INACTIVE
					}}
				/>
			) : null}
			<div
				className="tableInformationWhiteStyle"
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className="tableInformation" style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(productList.dataProducts).map((key: string) => (
						<TableRow key={`product-${key}`} dataProduct={productList.dataProducts[key]} />
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
			<CatchedErroModalProductList open={!!modal.errorCode} errorCode={modal.errorCode} />
		</div>
	)
}
