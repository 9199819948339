import { getCurrentUser } from '@netcurio/frontend-common'
import { useMemo } from 'react'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'

const user = getCurrentUser()

interface Props {
	purchaseRequisition: PurchaseRequisitionInterface
}

export const useCurrentRequester = ({ purchaseRequisition }: Props) => {
	const { approvals = [] } = purchaseRequisition
	const { isRequester } = useUserRoles()

	const isUserCurrentRequester = useMemo(() => {
		const currentRequester: boolean = user?.email === purchaseRequisition.createdBy?.email

		return isRequester && currentRequester
	}, [approvals, isRequester, purchaseRequisition.createdBy?.email])

	const isInApprovalProcess =
		approvals?.some((approval) => {
			return approval.approvedAt !== null || approval.rejectedAt !== null
		}) ?? false

	const isUserOwner = useMemo(() => {
		return user?.email === purchaseRequisition.createdBy?.email
	}, [purchaseRequisition.createdBy?.email])

	return { isUserCurrentRequester, isUserOwner, isInApprovalProcess }
}
