import { InputLabel } from '@mui/material'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import {
	Netcurio03TextADarkAHighEmphasisTextPrimary,
	Netcurio03TextADarkBMediumEmphasisTextSecondary,
	Netcurio03TextADarkCLowEmphasisDisabled,
	Netcurio04BodyBody2ADarkAHighEmphasisText,
	Netcurio04BodyBody2ADarkCLowEmphasisText,
	Netcurio04BodyBody3ADarkBMediumEmphasisColor,
	Netcurio04BodyBody3ADarkBMediumEmphasisText,
	Netcurio05ErrorAMain,
	Netcurio06WarningCLight
} from '@netcurio/frontend-design-tokens'
import { forwardRef, Ref } from 'react'

interface InputLabelProps extends Partial<AutocompleteRenderInputParams> {
	disabled?: boolean
	disableAnimation?: boolean
	shrink?: boolean
	label?: string
	error?: boolean
	warning?: boolean
	required?: boolean
	htmlFor?: string
	id?: string
	isReadOnly?: boolean
}

/**
 * NetcurioInputField
 * @param disabled <boolean>: If `true`, the component is disabled.
 * @param disableAnimation <boolean>: If `true`, the component's animation is disabled.
 * @param shrink <boolean>: If `true`, the component will be smaller than usual.
 * @param label <string>: The label to be disaplayed.
 * @param error <boolean>: If `true`, display the label as an error label.
 * @param warning <boolean>: If `true`, display the label as a warning label.
 * @param required <boolean>: If `true`, the component's linked input is required (in a form).
 * @param htmlFor <string>: The ID of the input that will be linked to the label.
 * @param id <string>: The ID of the TextField.
 */
export const CustomInputLabel = forwardRef<HTMLDivElement, InputLabelProps>((props, ref) => {
	const {
		disabled,
		disableAnimation,
		shrink,
		label,
		error,
		warning,
		required,
		htmlFor,
		size,
		id,
		isReadOnly = false
	} = props

	const getColor = () => {
		if (isReadOnly) {
			return Netcurio04BodyBody3ADarkBMediumEmphasisColor
		}
		if (disabled) {
			return Netcurio03TextADarkCLowEmphasisDisabled
		}
		if (error) {
			return Netcurio05ErrorAMain
		}
		if (warning) {
			return Netcurio06WarningCLight
		}
		return Netcurio03TextADarkAHighEmphasisTextPrimary
	}

	const getShrunkColor = () => {
		if (isReadOnly) {
			return Netcurio04BodyBody3ADarkBMediumEmphasisColor
		}
		if (disabled) {
			return Netcurio03TextADarkBMediumEmphasisTextSecondary
		}
		if (error) {
			return Netcurio05ErrorAMain
		}
		if (warning) {
			return Netcurio06WarningCLight
		}
		return Netcurio04BodyBody3ADarkBMediumEmphasisColor
	}

	const styles = {
		'&.MuiFormLabel-root': {
			color: getColor(),
			font: disabled
				? Netcurio04BodyBody2ADarkCLowEmphasisText
				: Netcurio04BodyBody2ADarkAHighEmphasisText,
			marginTop: '-0.2rem'
		},
		'&.MuiInputLabel-shrink': {
			paddingTop: '0.6rem',
			paddingBottom: '0.5rem',
			font: Netcurio04BodyBody3ADarkBMediumEmphasisText,
			color: getShrunkColor()
		},
		'&.Mui-focused': {
			font: Netcurio04BodyBody3ADarkBMediumEmphasisText,
			color: getColor()
		}
	}

	return (
		<InputLabel
			ref={ref as Ref<HTMLLabelElement>}
			disabled={disabled}
			disableAnimation={disableAnimation}
			shrink={shrink}
			error={error}
			required={required}
			htmlFor={htmlFor}
			sx={styles}
			size={size}
			variant="outlined"
			id={id}
		>
			{label}
		</InputLabel>
	)
})

CustomInputLabel.displayName = 'CustomInputLabel'
