import { Roles } from '@netcurio/frontend-common'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { StatusFilter } from '../../types/StatusFilter'
import Constants from '../../utilities/constants'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import ReportView from './reportDetail/reportView'
import { ReportList } from './reportList/reportList'
import {
	statusFilterCreditMemo,
	statusFilterInvoice,
	statusFilterPaymentComplement,
	statusFilterPO
} from './statusOption'
interface Report {
	route: string
	title: string
	description: string
	reportType: string
	dateFilterText?: string
	statusFilterText?: string
	statusFilter?: StatusFilter[]
	hideFilter?: boolean
}

export function ReportsRouter() {
	const userRoles = getUserRolesForCompany()
	const { path } = useRouteMatch()
	const Reports: Array<Report> = [
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_CONSIGNMENT_STOCK,
			title: 'consignmentStockTitle',
			description: userRoles.includes(Roles.SUPPLIER)
				? 'consignmentStockDescriptionSupplier'
				: 'consignmentStockDescriptionCustomer',
			reportType: 'stock-in-consignment'
		},
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES,
			title: 'differenceBetweenProductQuantitiesTitle',
			description: 'differenceBetweenProductQuantitiesDescription',
			reportType: 'product-quantity-differences',
			dateFilterText: 'reportDateFilterProductQuantities'
		},
		{
			route: Constants.REPORTS.REPORT_URLS.HISTORICAL_INVOICES,
			title: 'historicalInvoiceTitle',
			description: userRoles.includes(Roles.SUPPLIER)
				? 'historicalInvoiceDescriptionSupplier'
				: 'historicalInvoiceDescriptionCustomer',
			reportType: 'invoice-history',
			dateFilterText: 'reportDateFilterInvoices',
			statusFilterText: 'reportStatusFilterInvoice',
			statusFilter: statusFilterInvoice
		},
		{
			route: Constants.REPORTS.REPORT_URLS.HISTORICAL_PAYMENT_COMPLEMENT,
			title: 'paymentComplementHistoryTitle',
			description: userRoles.includes(Roles.SUPPLIER)
				? 'historicalPaymentComplementDescriptionSupplier'
				: 'historicalPaymentComplementDescriptionCustomer',
			reportType: 'payment-complement-history',
			dateFilterText: 'reportDateFilterPaymentComplement',
			statusFilterText: 'reportStatusFilterPC',
			statusFilter: statusFilterPaymentComplement
		},
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_PRODUCT_MISSING_INVOICE,
			title: userRoles.includes(Roles.CUSTOMER) ? 'missingInvoicesTitle' : 'productMissingInvoiceTitle',
			description: userRoles.includes(Roles.CUSTOMER)
				? 'productMissingInvoiceCustomerDescription'
				: 'productMissingInvoiceDescription',
			reportType: 'product-missing-invoice',
			dateFilterText: 'reportDateFilterInvoices'
		},
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_PRODUCTS_CATALOG,
			title: userRoles.includes(Roles.CUSTOMER)
				? 'productsCatalogTitle'
				: 'productsCatalogTitleSupplier',
			description: userRoles.includes(Roles.CUSTOMER)
				? 'productsCatalogDescription'
				: 'productsCatalogDescriptionSupplier',
			reportType: 'product-catalog',
			hideFilter: userRoles.includes(Roles.CUSTOMER)
		},
		{
			route: Constants.REPORTS.REPORT_URLS.HISTORICAL_PURCHASE_ORDERS,
			title: 'historicalPurchaseOrdersTitle',
			description: userRoles.includes(Roles.CUSTOMER)
				? 'historicalPurchaseOrdersCustomerDescription'
				: 'historicalPurchaseOrdersSupplierDescription',
			reportType: 'purchase-order-history',
			dateFilterText: 'reportDateFilterPO',
			statusFilterText: 'reportStatusFilterPO',
			statusFilter: statusFilterPO
		},
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_RESPONSE_TIME,
			title: userRoles.includes(Roles.CUSTOMER) ? 'deliveryTimeTitle' : 'responseTimeTitle',
			description: 'responseTimeDescription',
			reportType: 'response-time',
			dateFilterText: 'reportDateFilterPO'
		},
		{
			route: Constants.REPORTS.REPORT_URLS.HISTORICAL_CREDIT_MEMOS,
			title: 'historicalCreditMemosTitle',
			description: userRoles.includes(Roles.CUSTOMER)
				? 'historicalCreditMemosDescriptionCustomer'
				: 'historicalCreditMemosDescriptionSupplier',
			reportType: 'credit-memo-history',
			dateFilterText: 'reportDateFilterCreditMemos',
			statusFilterText: 'reportStatusFilterCreditMemos',
			statusFilter: statusFilterCreditMemo
		},
		{
			route: Constants.REPORTS.REPORT_URLS.REPORT_HISTORICAL_TICKETS,
			title: 'historicalTickets',
			description: userRoles.includes(Roles.CUSTOMER)
				? 'historicalTicketsDescriptionCustomer'
				: 'historicalTicketsDescriptionSupplier',
			reportType: 'ticket-history',
			dateFilterText: 'reportDateFilterTickets'
		}
	]

	return (
		<Switch>
			<Route exact path={path}>
				<ReportList />
			</Route>
			{Reports.map((report) => (
				<Route exact path={`${path}${report.route}`} key={report.route}>
					<ReportView
						title={report.title}
						description={report.description}
						reportType={report.reportType}
						hideFilter={report.hideFilter}
						dateFilterText={report.dateFilterText}
						statusFilterText={report.statusFilterText}
						statusFilter={report.statusFilter}
					/>
				</Route>
			))}
			<Redirect from={path + '*'} to={path} />
		</Switch>
	)
}
