import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextFieldRenderInputParams from '@mui/material/TextField'
import {
	Netcurio01PrimaryBDark,
	Netcurio01PrimaryCLight,
	Netcurio04ActionCDisabled,
	Netcurio04BodyBody2ADarkAHighEmphasisColor,
	Netcurio04BodyBody2ADarkAHighEmphasisText,
	Netcurio04BodyBody2ADarkBMediumEmphasisColor,
	Netcurio04BodyBody2ADarkCLowEmphasisColor,
	Netcurio05ErrorAMain,
	Netcurio06WarningCLight,
	Netcurio11Grey40
} from '@netcurio/frontend-design-tokens'
import React, { forwardRef, ReactNode } from 'react'
import { CustomInputLabel } from '../CustomInputLabel/CustomInputLabel'

export interface OutlinedTextFieldProps extends Partial<typeof TextFieldRenderInputParams> {
	label: string
	variant?: 'standard' | 'filled' | 'outlined'
	size?: 'small' | undefined
	value?: unknown
	disabled?: boolean
	error?: boolean
	type?: 'email' | 'password'
	adornment?: ReactNode
	adornmentPosition?: 'end' | 'start'
	fullWidth?: boolean
	helperText?: ReactNode
	defaultValue?: string
	warning?: boolean
	maxLength?: number
	minLength?: number
	autoFocus?: boolean
	placeholder?: string
	hiddenLabel?: boolean
	id?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
	isReadOnly?: boolean
}

/**
 * OutlinedTextField
 * @param label <ReactNode>: The label content.
 * @param variant: <'standard' | 'filled' | 'outlined'>: The variant to use. @default 'outlined'
 * @param size: <'small' | undefined>: The size to use. @default 'small' (3.2rem)
 * @param value <unknown>: The value of the `input` element, required for a controlled component
 * @param disabled <boolean>: If `true`, the component is disabled
 * @param error <boolean>: If `true`, the label is displayed in an error state
 * @param type <undefined | 'password'>: Set to 'password' to use a password field
 * @param adornment <ReactNode> Expects a MUI Icon to use as adornment
 * @param adornmentPosition <'end'|'start'> Position of the adornment inside the input
 * @param fullWidth <boolean>: If `true`, the input will take up the full width of its container
 * @param helperText <ReactNode>: The helper text content. Also, useful in validation
 * @param onChange <(event: React.ChangeEvent<HTMLInputElement>) => void>: Callback fired when the value is changed
 * @param id <string>: The ID of the TextField.
 * @param isReadOnly <boolean>: Identifies this field as read Only
 * @returns component
 */
export const OutlinedTextField = forwardRef<HTMLDivElement, OutlinedTextFieldProps>((props, ref) => {
	const {
		label,
		variant = 'outlined',
		size = 'small',
		value,
		disabled,
		error,
		adornment,
		adornmentPosition,
		warning,
		maxLength,
		onChange,
		onBlur,
		onFocus,
		onKeyDown,
		id,
		type,
		isReadOnly = false,
		...rest
	} = props

	const borderColor = () => {
		if (isReadOnly) {
			return 'none'
		}
		if (disabled) {
			return Netcurio04ActionCDisabled
		}
		if (error) {
			return Netcurio05ErrorAMain
		}
		if (warning) {
			return Netcurio06WarningCLight
		}
		return Netcurio01PrimaryCLight
	}

	const hoverBorderColor = () => {
		if (isReadOnly) {
			return 'none'
		}
		if (disabled) {
			return Netcurio04ActionCDisabled
		}
		if (error) {
			return Netcurio05ErrorAMain
		}
		if (warning) {
			return Netcurio06WarningCLight
		}
		return Netcurio01PrimaryBDark
	}

	const getColor = () => {
		if (isReadOnly) {
			return Netcurio04BodyBody2ADarkBMediumEmphasisColor
		}
		if (disabled) {
			return Netcurio04BodyBody2ADarkCLowEmphasisColor
		}
		return Netcurio04BodyBody2ADarkAHighEmphasisColor
	}

	const styles = {
		'& .MuiInputBase-root': {
			height: '3.2rem',
			...(isReadOnly ? { backgroundColor: Netcurio11Grey40 } : {})
		},
		'& .MuiInputBase-input': {
			padding: adornment ? '0.6rem 1rem 0.6rem 0' : '0.6rem 1rem 0.6rem 1rem',
			position: 'relative',
			font: Netcurio04BodyBody2ADarkAHighEmphasisText,
			color: getColor()
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: borderColor()
		},
		'& .MuiOutlinedInput-root ': {
			paddingLeft: 0,
			paddingRight: 0,
			fontSize: '1.2rem',
			letterSpacing: '0.01rem',
			'& fieldset': {
				border: isReadOnly ? 'none' : '0.1rem solid',
				borderColor: borderColor()
			},
			'&:hover fieldset': {
				border: isReadOnly ? 'none' : '0.1rem solid',
				borderColor: hoverBorderColor()
			},

			'& input:focus + fieldset': {
				border: isReadOnly ? 'none' : '0.2rem solid',
				borderColor: hoverBorderColor()
			}
		},
		// Focused effect for adornment-end case
		'& .MuiInputBase-adornedEnd': {
			paddingLeft: '1.2rem',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: isReadOnly ? 'none' : '0.2rem solid',
				borderColor: hoverBorderColor()
			}
		},
		// HELPER TEXT
		'& .MuiFormHelperText-root': {
			fontSize: '1rem'
		},
		// ADORNMENT
		'& .MuiInputAdornment-root': {
			marginRight: 0,
			marginLeft: 0,
			padding: '0 0.5rem'
		}
	}
	const TextFieldInputLabel = React.forwardRef(() => {
		return (
			<CustomInputLabel
				label={label}
				id={id}
				size={'small'}
				error={error}
				warning={warning}
				disabled={disabled}
				isReadOnly={isReadOnly}
			/>
		)
	})
	return (
		<TextField
			{...rest}
			id={id}
			ref={ref}
			label={label}
			variant={variant}
			size={size}
			value={value}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
				if (!isReadOnly && onChange) {
					onChange(event)
				}
			}}
			onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
				if (!isReadOnly && onBlur) {
					onBlur(event)
				}
			}}
			onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
				if (!isReadOnly && onFocus) {
					onFocus(event)
				}
			}}
			onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
				if (!isReadOnly && onKeyDown) {
					onKeyDown(event)
				}
			}}
			disabled={disabled}
			error={error}
			type={type}
			slotProps={{
				htmlInput: { maxLength: maxLength, readOnly: isReadOnly },
				inputLabel: {
					component: TextFieldInputLabel
				}
			}}
			{...(adornment
				? {
						InputProps: {
							...(adornmentPosition === 'start'
								? {
										startAdornment: (
											<InputAdornment position="start">{adornment}</InputAdornment>
										)
									}
								: {
										endAdornment: (
											<InputAdornment position="end">{adornment}</InputAdornment>
										)
									})
						}
					}
				: {})}
			sx={styles}
		/>
	)
})

OutlinedTextField.displayName = 'OutlinedTextField'
