import { Modal } from '@netcurio/frontend-common'
import Constants from '../../../../../utilities/constants'
import styles from '../../../adminConsole.module.scss'
import { objectStatusFilterArray, objectTypeFilterArray } from './fillFieldsHeaderTable'

export const userListDefaultValues = {
	textHeader: styles.headerTableDefault,
	dataUsers: {},
	dataFilters: {},
	filterContainerBar: false,
	dataFiltersArray: [],
	filtersOfTypeStatus: {
		status: {
			list: objectStatusFilterArray(),
			numSelectOptions: 0,
			dataFilter: ''
		}
	},
	fillStatusInputFilter: objectStatusFilterArray(),
	fillTypeInputFilter: objectTypeFilterArray(),
	actualField: Constants.LIST_PROPERTIES.SORT_TYPES.CREATED_AT,
	numericFilter: 'display-none',
	textFilter: 'display-none',
	statusFilter: 'display-none',
	dateFilter: 'display-none',
	typeFilter: 'display-none',
	filterValue: undefined,
	deleteRange: false,
	initDate: undefined,
	finalDate: undefined,
	initPropDate: undefined,
	finalPropDate: undefined,
	minAmount: '',
	initRange: '',
	finalRange: '',
	maxAmount: '',
	initDateErrorText: '',
	finalDateErrorText: '',
	dataStatusFilter: '',
	limitRows: undefined,
	rowsActual: undefined,
	totalRows: undefined,
	orderList: Constants.ORDER.DESC,
	fieldList: Constants.LIST_PROPERTIES.SORT_TYPES.CREATED_AT,
	initialSort: Constants.LIST_PROPERTIES.SORT_TYPES.CREATED_AT,
	actualFilterData: Constants.LIST_PROPERTIES.SORT_TYPES.CREATED_AT
}

export const styleMainTableDefaultValues = {
	mainContainerHeight: undefined,
	mainTableHeight: undefined,
	activeHeight: true
}

export const modalDefaultValues: Modal = {
	errorCode: undefined
}
