import checkConfirmedsvg from '@netcurio/frontend-assets/src/icons/check-solid.svg'
import checksvg from '@netcurio/frontend-assets/src/icons/check.svg'
import { NetcurioTooltip } from '@netcurio/frontend-components'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../../style.scss'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import * as Queries from './queries'
import './styles.scss'
import { TableRow as RowTablePurchaseOrderEdit } from './tableRow'

export const MainTable = ({
	showMessageMissingInfoProps,
	confirmedAllItems,
	informationTable,
	fillDataConfirmAllItems,
	changeRequiredDate,
	changeConfirmAmount,
	setReasonItem,
	setRowSelected,
	fillConsecutiveDate,
	dateCopyAllItems,
	selectedRow,
	purchaseOrderType
}): ReactElement => {
	const client = connection()
	const { t } = useTranslation()
	const [allReasons, setAllReasons] = useState()
	const [showMessageMissingInfo, setShowMessageMissingInfo] = useState(false)
	const [dataTip, setDataTip] = useState('confirmAll')
	const [imgCheckAll, setImgCheckAll] = useState(checksvg)
	useEffect(() => {
		client
			.query({
				query: Queries.PO_REASONS_REJECT,
				variable: {
					language: ''
				}
			})
			.then((result) => {
				setAllReasons(result.data.PurchaseOrderRejectedReasons)
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				expiredToken(errorCode)
			})
	}, [])

	useEffect(() => {
		if (showMessageMissingInfoProps && !showMessageMissingInfo) {
			setShowMessageMissingInfo(true)
			setDataTip('confirmAll')
		}
		if (confirmedAllItems && imgCheckAll !== checkConfirmedsvg) {
			setImgCheckAll(checkConfirmedsvg)
			setDataTip('allConfirmed')
		} else if (imgCheckAll !== checksvg) {
			setImgCheckAll(checksvg)
			setDataTip('confirmAll')
		}
	}, [confirmedAllItems, informationTable, showMessageMissingInfoProps])
	return (
		<div className={'mainTableOrderEdit'}>
			<div className={'headerTableEditTitlesContainer'}>
				<div className={'gray-color-text-general-info header-main-table-orderDetails table-title'}>
					<NetcurioTooltip title={t('positionTooltip')}>
						<div className={'textHeaderMainTableOrderEdit positionEditPO'}>
							{t('positionText')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('productTooltip')}>
						<div className={'textHeaderMainTableOrderEdit product-header-purchase-orderEdit'}>
							{t('descriptionProductHeader')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('storageLocationTooltip')}>
						<div className={'textHeaderMainTableOrderEdit storageLocationEditPO'}>
							{t('storageLocation')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('requestedAmountTooltip')}>
						<div className={'textHeaderMainTableOrderEdit requestedAmountEditPO'}>
							{t('requestedAmount')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('confirmedAmountTooltip')}>
						<div className={'textHeaderMainTableOrderEdit confirmed-amount-edit-po'}>
							{t('confirmedAmount')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('requiredDateTooltip')}>
						<div className={'textHeaderMainTableOrderEdit requiredDateEditPO'}>
							{t('requiredDate')}
						</div>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('proposalDateTooltip')}>
						<div className={'textHeaderMainTableOrderEdit proposal-date-edit-po'}>
							{t('dateConfirmation')}
						</div>
					</NetcurioTooltip>
					<div className={'containerCheckColumnPurchaseOrderEdit'}>
						<NetcurioTooltip title={t(dataTip)}>
							<img
								id="imgCheckItems"
								src={imgCheckAll}
								className={'imgCheckColumnPurchaseOrderEdit filterImageColor'}
								alt=""
								onClick={() => fillDataConfirmAllItems()}
							/>
						</NetcurioTooltip>
					</div>
				</div>
			</div>
			{Object.keys(informationTable).map((key) => (
				<RowTablePurchaseOrderEdit
					key={key}
					positionKey={key}
					informationTable={informationTable[key]}
					confirmedAllItemsProps={confirmedAllItems}
					changeRequiredDateProps={changeRequiredDate}
					changeConfirmAmount={changeConfirmAmount}
					setReasonItem={setReasonItem}
					setRowSelected={setRowSelected}
					fillConsecutiveDate={fillConsecutiveDate}
					dateCopyAllItemsProps={dateCopyAllItems}
					selectedRow={selectedRow}
					purchaseOrderType={purchaseOrderType}
					allReasons={allReasons}
				/>
			))}
		</div>
	)
}
