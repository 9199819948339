import { HorizontalScrollMenu, ReportButton } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import '../../../style.scss'
import headerStyles from '../headerStyles.module.scss'
import styles from './reportList.module.scss'
import { objectHistoricalArray, objectReportArray } from './reportsInformationArray'

export const ReportListTable = (): ReactElement => {
	const history = useHistory()
	const { t } = useTranslation()
	const reportsButtons = () => {
		const reports = objectReportArray(history)
		const buttonsReports = reports.map((item) => {
			return <ReportButton key={item.text} {...item} />
		})
		return <HorizontalScrollMenu>{buttonsReports}</HorizontalScrollMenu>
	}

	const historicalButtons = () => {
		const historical = objectHistoricalArray(history)
		const buttonsHistorical = historical.map((item) => {
			return <ReportButton key={item.text} {...item} />
		})
		return <HorizontalScrollMenu>{buttonsHistorical}</HorizontalScrollMenu>
	}

	return (
		<div className={styles.tableReports}>
			<div className={styles.differentReports}>
				<div className={headerStyles.reportsTitle}>{t('reportsText')}</div>
				<div className={styles.reportsSectionTitle}>{t('selectReport')}</div>
				<div className={styles.reportsSection}>{reportsButtons()}</div>
				<div className={styles.reportsSectionTitle}>{t('selectHistorical')}</div>
				<div className={styles.reportsSection}>{historicalButtons()}</div>
			</div>
		</div>
	)
}
