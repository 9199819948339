import { ThemeOptions, Tooltip } from '@mui/material'
import React, { FC, ReactElement, ReactNode } from 'react'

export const NetcurioTooltipThemeOptions: ThemeOptions = {
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '1.2rem'
				}
			}
		}
	}
}

interface NetcurioTooltipProps {
	enterDelay?: number
	enterNextDelay?: number
	enterTouchDelay?: number
	followCursor?: boolean
	leaveDelay?: number
	leaveTouchDelay?: number
	onClose?: (event: React.SyntheticEvent | Event) => void
	onOpen?: (event: React.SyntheticEvent) => void
	open?: boolean
	placement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top'
	title: ReactNode
	disableInteractive?: boolean

	children: ReactElement<any, any>
}

/**
 * NetcurioTooltip
 * @param enterDelay <number>: The number of milliseconds to wait before showing the tooltip when one was
	* already recently opened. @default 100
 * @param enterNextDelay <number>: The number of milliseconds to wait before showing the tooltip
	* when one was already recently opened. @default 0
 * @param enterTouchDelay <number>: The number of milliseconds a user must touch the element before
	* showing the tooltip. @default 700
 * @param followCursor <boolean>: If `true`, the tooltip follow the cursor over the wrapped element.
   * @default false
 * @param leaveDelay <number>: The number of milliseconds to wait before hiding the tooltip.
   * This prop won't impact the leave touch delay (`leaveTouchDelay`). @default 0
 * @param leaveTouchDelay <number>: The number of milliseconds after the user stops touching an element
	* before hiding the tooltip. @default 1500
 * @param onClose <(event: React.SyntheticEvent | Event) => void>: Callback fired when the component requests to be closed.
 * @param onOpen <(event: React.SyntheticEvent) => void>: Callback fired when the component requests to be opened.
 * @param open <boolean>: If `true`, the component is shown.
 * @param placement <'bottom-end'
	| 'bottom-start'
	| 'bottom'
	| 'left-end'
	| 'left-start'
	| 'left'
	| 'right-end'
	| 'right-start'
	| 'right'
	| 'top-end'
	| 'top-start'
	| 'top'>: Tooltip placement. * @default 'bottom'
 * @param title <ReactNode>: Tooltip title. Zero-length titles string, undefined, null and false are never displayed.
 * @param children: <ReactElement>: Tooltip reference element.
 * @returns component
 */
export const NetcurioTooltip: FC<NetcurioTooltipProps> = ({ children, ...props }) => {
	return (
		<Tooltip hidden={false} arrow={true} {...props}>
			{children}
		</Tooltip>
	)
}
