import { Filter, Modal, Roles } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { HeaderTable } from '../../../../../components/HeaderTable/HeaderTable'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import { getUserRolesForCompany } from '../../../../../utilities/getUserRolesForCompany'
import listHelper from '../../../../../utilities/listHelper'
import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import styles from '../../../adminConsole.module.scss'
import { styleMainTableDefaultValues } from '../../users/usersListTab/defaultValuesUserList'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import { modalDefaultValues, relatedCompaniesListDefaultValues } from './defaultValuesRelatedCompaies'
import * as queries from './queries'
import { objectHeaderArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import {
	DataRelatedCompanies,
	FilterToApply,
	List,
	RelatedCompaniesData,
	RelatedCompaniesListProps,
	ResultQuery,
	StyleMainTable,
	UpdateFilter
} from './types'

export const RelatedCompaniesList: FC<RelatedCompaniesListProps> = ({ setDataFilterArray }) => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [userRoles, setUserRoles] = useState<Roles[]>()
	const [relatedCompanies, setRelatedCompanies] = useState<RelatedCompaniesData>(
		relatedCompaniesListDefaultValues
	)
	const [headerButtons, setHeaderButtons] = useState<Array<tableFillHeader>>([])
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [modal, setModal] = useState<Modal>(modalDefaultValues)
	const [styleMainTable, setStyleMainTable] = useState<StyleMainTable>(styleMainTableDefaultValues)
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const heightRow = 42
	let isMounted = true

	useEffect(() => {
		if (isMounted) {
			showLoadingSpinner()
			setShowBarLoader(true)
			const finalRowsHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.ROWS_INFO_HEIGHT
			const numberRows: number = Math.round(finalRowsHeight / 44) + 1
			setRelatedCompanies((prevState) => ({
				...prevState,
				limitRows: numberRows,
				rowsActual: numberRows
			}))
			const finalHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.TABLE_HEIGHT
			const mainHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.CONTAINER_HEIGHT
			setUserRoles(getUserRolesForCompany())
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
		}
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		setHeaderButtons(objectHeaderArray(userRoles ?? []))
		const initField: Roles = userRoles?.includes(Roles.CUSTOMER) ? Roles.SUPPLIER : Roles.CUSTOMER
		setRelatedCompanies((prevState) => ({
			...prevState,
			actualField: initField,
			fieldList: initField,
			initialSort: initField
		}))
	}, [userRoles])

	useEffect(() => {
		if (relatedCompanies.limitRows !== undefined && isMounted) {
			filteringCompanies(0, relatedCompanies.initialSort, '', relatedCompanies.initialSort, '')
		}
	}, [modal.errorCode, relatedCompanies.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination && isMounted) {
			setActivePagination(false)
			setStopPagination(false)
			filteringCompanies(
				relatedCompanies.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, stopPagination])

	useEffect(() => {
		if (relatedCompanies.totalRows && relatedCompanies.rowsActual) {
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)
		}
		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [relatedCompanies.totalRows, relatedCompanies.rowsActual])

	useEffect(() => {
		if (relatedCompanies.deleteRange && relatedCompanies.actualField === 'created_at') {
			setRelatedCompanies((prevState) => ({
				...prevState,
				deleteRange: false
			}))
		}
		if (relatedCompanies.dataStatusFilter === 'return') {
			if (relatedCompanies.filterValue !== undefined) {
				setRelatedCompanies((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		}
		if (relatedCompanies.filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!relatedCompanies.filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}
		setResultNotFound(relatedCompanies.totalRows < 1)
	}, [
		relatedCompanies.deleteRange,
		relatedCompanies.dataStatusFilter,
		relatedCompanies.filterContainerBar,
		relatedCompanies.totalRows
	])

	const handlePagination = () => {
		listHelper.handlePagination(
			'tableInformation',
			relatedCompanies.totalRows,
			relatedCompanies.rowsActual,
			filteringCompanies,
			setActivePagination,
			setShowBarLoader
		)
	}

	const filteringCompanies = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove = ''
	): void => {
		let fieldListData: string = relatedCompanies.fieldList
		let orderListData: string = relatedCompanies.orderList
		let columnFilterActual: string = relatedCompanies.actualFilterData
		let currentList: DataRelatedCompanies = { ...relatedCompanies.dataCustomerSupplier }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setRelatedCompanies((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setRelatedCompanies((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setRelatedCompanies((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}

		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'customer':
			case 'supplier':
			case 'supplier_rfc':
			case 'customer_rfc':
			case 'customer_reference':
				typeFilterActual = 'wildcard'
				break
			case 'payment_days':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}

		if (skip > 0) {
			setRelatedCompanies((prevState) => ({
				...prevState,
				rowsActual: relatedCompanies.rowsActual + relatedCompanies.limitRows
			}))
		} else {
			setRelatedCompanies((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataCustomerSupplier: {}
			}))
			currentList = {}
		}

		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)

		setDataFilterArray(filterToApply, {
			sortField: fieldListData,
			sortOrder: orderListData
		})
		getRelatedCompanies(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		if (columnFilterActual === 'is_admin') {
			valueFilterActual = valueFilterActual === Constants.USER_TYPES.ADMINISTRATOR ? 'true' : 'false'
		}
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			relatedCompanies.dataFilters,
			0
		) as FilterToApply

		const objUpdate: UpdateFilter = resultFiltersToApply.objectForStateUpdate
		setRelatedCompanies((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))

		if (objUpdate.filtersOfTypeStatus) {
			setRelatedCompanies((prevState) => ({
				...prevState,
				filtersOfTypeStatus: objUpdate.filtersOfTypeStatus
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const getRelatedCompanies = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataRelatedCompanies
	) => {
		const copyFilterToApply: Array<Filter> = [...filterToApply]
		client
			.query({
				query: queries.CustomerSupplier,
				variables: {
					limit: relatedCompanies.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result: ApolloQueryResult<ResultQuery>) => {
				let interCount: number = skip
				const data: DataRelatedCompanies = { ...currentList }
				const obj: Array<List> = result.data.CustomersSuppliers.list || []
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}

				if (isMounted) {
					setRelatedCompanies((prevState) => ({
						...prevState,
						totalRows: result.data.CustomersSuppliers.total,
						dataCustomerSupplier: data,
						dataStatusFilter: ''
					}))
				}
				setStopPagination(true)
				hideLoadingSpinner()
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setRelatedCompanies((prevState) => ({
				...prevState,
				dataCustomerSupplier: {},
				filtersOfTypeStatus: {
					status: {
						dataFilter: ''
					}
				},
				dataStatusFilter: ''
			}))
			setStopPagination(true)
			setModal({
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
		setShowBarLoader(false)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string
		switch (filterActual) {
			case 'numericFilter':
				classActualField = 'company-' + fieldStyle + '-filter'
				activeFilter = relatedCompanies.numericFilter
				break
			case 'dateFilter':
				classActualField = 'company-' + fieldStyle + '-filter'
				activeFilter = relatedCompanies.dateFilter
				break
			case 'statusFilter':
				classActualField = 'company-' + fieldStyle + '-filter'
				activeFilter = relatedCompanies.statusFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = relatedCompanies.textFilter
				break
		}

		if (activeFilter === 'display-none') {
			if (styleMainTable.mainTableHeight > window.innerHeight - 222) {
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))
			}
			deleteDateRange()
			setRelatedCompanies((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				dateFilter: 'display-none',
				statusFilter: 'display-none',
				textFilter: 'display-none',
				typeFilter: 'display-none',
				[filterActual]: classActualField,
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textHeaderListAdmin
			}))
		} else if (relatedCompanies.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
			deleteDateRange()
			setRelatedCompanies((prevState) => ({
				...prevState,
				filterValue: '',
				[filterActual]: 'display-none',
				textHeader: styles.headerTableDefault
			}))
		} else {
			setRelatedCompanies((prevState) => ({ ...prevState, actualField: field, filterValue: '' }))
		}
	}

	const deleteDateRange = () => {
		setRelatedCompanies((prevState) => ({
			...prevState,
			initRange: undefined,
			finalRange: undefined
		}))
	}
	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringCompanies, relatedCompanies.initialSort)
		deleteDateRange()
		setRelatedCompanies((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		if (indexObject === 'created_at') {
			deleteDateRange()
		}
		listHelper.deleteFilterActive(
			indexObject,
			relatedCompanies.dataFilters,
			closeFilterContainerBar,
			filteringCompanies
		)
		setRelatedCompanies((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: Dayjs }
	) => {
		showLoadingSpinner()
		let initRange: number | Dayjs = relatedCompanies.initRange
		let finalRange: number | Dayjs = relatedCompanies.finalRange
		const minRange: number | Dayjs = relatedCompanies.minRange
		const maxRange: number | Dayjs = relatedCompanies.maxRange

		if (objectRange) {
			if (objectRange.initRange) initRange = objectRange.initRange
			if (objectRange.finalRange) finalRange = objectRange.finalRange
		}
		if (minRange) initRange = minRange
		if (maxRange) finalRange = maxRange

		if (sortField !== '') {
			filteringCompanies(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringCompanies(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setRelatedCompanies((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
		deleteDateRange()
		setRelatedCompanies((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			typeFilter: 'display-none',
			textHeader: styles.headerTableDefault,
			filterValue: undefined
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual = field + 'Date'
		const rangeActual = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', relatedCompanies.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setRelatedCompanies((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value = evt.target.value
		setRelatedCompanies((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}
	const emptyFilterField = () => {
		setRelatedCompanies((prevState) => ({
			...prevState,
			filterValue: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			relatedCompanies.actualField,
			relatedCompanies.filterValue
		)
	}

	return (
		<div className="tableInformationWhiteStyle margins-table">
			<div className={styles.headerMainTableUsersList}>
				{Object.keys(headerButtons).map((key) => (
					<HeaderTable
						key={key}
						dataMainHeaderTable={headerButtons[key]}
						textHeader={relatedCompanies.textHeader}
						showFilters={showFilters}
					/>
				))}
			</div>
			<TextInputFilter
				showTextFilter={relatedCompanies.textFilter}
				actualField={relatedCompanies.actualField}
				valueFilter={relatedCompanies.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			<DateInputFilter
				showDateFilter={relatedCompanies.dateFilter}
				actualField={relatedCompanies.actualField}
				initDate={relatedCompanies.initDate}
				finalDate={relatedCompanies.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			{relatedCompanies.filterContainerBar ? (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={relatedCompanies.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.COMPANIES}
					booleanNames={{
						true: Constants.PRODUCT_STATUS.ACTIVE,
						false: Constants.PRODUCT_STATUS.INACTIVE
					}}
				/>
			) : null}
			<div
				className="tableInformationWhiteStyle"
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className="tableInformation" style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(relatedCompanies.dataCustomerSupplier).map((key: string) => (
						<TableRow
							key={key}
							rfc={relatedCompanies.dataCustomerSupplier[key]['company']['rfc']}
							payment_days={relatedCompanies.dataCustomerSupplier[key]['payment_days']}
							created_at={relatedCompanies.dataCustomerSupplier[key]['company']['created_at']}
							name={relatedCompanies.dataCustomerSupplier[key]['company']['name']}
							clickable={userRoles?.includes(Roles.CUSTOMER)}
							customer_reference={
								relatedCompanies.dataCustomerSupplier[key]['customer_reference']
							}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
			<ErrorModal open={!!modal?.errorCode} errorCode={modal?.errorCode} />
		</div>
	)
}
