import { TypesDataTable, TableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	NumericInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import { HeaderTable } from '../../../components/HeaderTable/HeaderTable'
import Constants from '../../../utilities/constants'
import listHelper from '../../../utilities/listHelper'
import { tableFillHeader } from '../../../utilities/tableFillHeader'
import styles from './purchaseOrderList.module.scss'
import { objectHeaderArray, objectStatusFilterArray, objectTypeFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { MainTableProps, StyleMainTable } from './types'

export const PurchaseOrderTable = ({
	dataPurchase,
	filteringPurchaseOrder,
	closeFilterContainerBar,
	dataFiltersArray,
	deleteFilterActive,
	userRoles = [],
	deleteRange,
	filtersOfTypeStatus,
	filterContainerBar,
	totalRows,
	rowsActual,
	setActivePagination,
	showBarLoader,
	setShowBarLoader
}: MainTableProps): ReactElement => {
	const { showLoadingSpinner } = useNetcurioLoader()
	const [styleMainTable, setStyleMainTable] = useState<StyleMainTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		activeHeight: true
	})
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		textHeader: styles.textHeaderListPO,
		statusFilter: 'display-none',
		dateFilter: 'display-none',
		numericFilter: 'display-none',
		textFilter: 'display-none',
		actualField: 'date',
		fillStatusInputFilter: [],
		fillTypeInputFilter: objectTypeFilterArray(),
		initDate: undefined,
		finalDate: undefined,
		initDateErrorText: '',
		finalDateErrorText: '',
		minAmount: undefined,
		maxAmount: undefined,
		filterValue: '',
		initRange: undefined,
		finalRange: undefined,
		minRange: undefined,
		maxRange: undefined,
		deleteRange: false,
		typeFilter: 'display-none'
	})
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const initReferenceText = 'init'
	const finReferenceText = 'final'
	const heightRow = 40

	useEffect(() => {
		if (setShowBarLoader) setShowBarLoader(false)
		setFillFields(objectHeaderArray(userRoles))
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT,
			mainContainerHeight: window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		}))
		if (setShowBarLoader) setShowBarLoader(false)
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRoles))
	}, [userRoles])

	useEffect(() => {
		if (totalRows && rowsActual) {
			window.document
				.getElementsByClassName(styles.tableInformation)[0]
				.addEventListener('scroll', handlePagination)
		}
		return () => {
			const element: Element = window.document.getElementsByClassName(styles.tableInformation)[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [rowsActual, totalRows])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'created_at') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initRange: undefined,
					finalRange: undefined
				}))
			} else if (dataTable.actualField === 'total') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					minRange: undefined,
					maxRange: undefined
				}))
			}
		}
		if (filtersOfTypeStatus.status?.dataFilter === 'return') {
			if (dataTable.fillStatusInputFilter?.length !== objectStatusFilterArray().length) {
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
			}
			if (dataTable.filterValue !== undefined) {
				setDataTable((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		} else if (
			filtersOfTypeStatus?.status?.dataFilter !== '' &&
			filtersOfTypeStatus?.status?.dataFilter &&
			!dataTable?.fillStatusInputFilter?.some(
				(selectionFilter) =>
					selectionFilter.getSelectedParameter() === filtersOfTypeStatus.status?.dataFilter
			)
		) {
			setDataTable((prevState) => ({
				...prevState,
				fillStatusInputFilter: [
					...(prevState.fillStatusInputFilter ? prevState.fillStatusInputFilter : []),
					new TableFillSelectionFilter(filtersOfTypeStatus.status?.dataFilter)
				]
			}))
		}

		if (filtersOfTypeStatus.type?.dataFilter === 'return') {
			if (dataTable.fillTypeInputFilter?.length !== objectTypeFilterArray().length) {
				setDataTable((prevState) => ({
					...prevState,
					fillTypeInputFilter: objectTypeFilterArray()
				}))
			}
			if (dataTable.filterValue !== undefined) {
				setDataTable((prevState) => ({
					...prevState,
					filterValue: undefined
				}))
			}
		} else if (
			filtersOfTypeStatus?.type?.dataFilter !== '' &&
			filtersOfTypeStatus?.type?.dataFilter &&
			!dataTable?.fillTypeInputFilter?.some(
				(selectionFilter) =>
					selectionFilter.getSelectedParameter() === filtersOfTypeStatus?.type?.dataFilter
			)
		) {
			setDataTable((prevState) => ({
				...prevState,
				fillTypeInputFilter: [
					...(prevState.fillTypeInputFilter ? prevState.fillTypeInputFilter : []),
					new TableFillSelectionFilter(filtersOfTypeStatus?.type?.dataFilter)
				]
			}))
		}

		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}

		setResultNotFound(totalRows < 1)
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [
		totalRows,
		deleteRange,
		filtersOfTypeStatus,
		filterContainerBar,
		dataTable.textHeader,
		dataTable.deleteRange,
		dataTable.actualField,
		styleMainTable.activeHeight,
		filtersOfTypeStatus?.status?.dataFilter,
		filtersOfTypeStatus?.type?.dataFilter
	])

	const handlePagination = () => {
		listHelper.handlePagination(
			styles.tableInformation,
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		showLoadingSpinner()
		let initRange: number | Dayjs | undefined = dataTable.initRange
		let finalRange: number | Dayjs | undefined = dataTable.finalRange
		const minRange: number | Dayjs | undefined = dataTable.minRange
		const maxRange: number | Dayjs | undefined = dataTable.maxRange

		if (objectRange) {
			if (objectRange.initRange) initRange = objectRange.initRange
			if (objectRange.finalRange) finalRange = objectRange.finalRange
		}

		if (minRange) initRange = minRange
		if (maxRange) finalRange = maxRange

		if (sortField !== '') {
			filteringPurchaseOrder(
				0,
				sortField,
				order,
				fieldFilter,
				undefined,
				undefined,
				undefined,
				undefined
			)
		} else {
			filteringPurchaseOrder(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string | undefined
		switch (filterActual) {
			case 'dateFilter':
				classActualField = 'purchase-order-' + fieldStyle + '-filter'
				activeFilter = dataTable.dateFilter
				break
			case 'numericFilter':
				classActualField = 'purchase-order-' + fieldStyle + '-filter'
				activeFilter = dataTable.numericFilter
				break
			case 'statusFilter':
				classActualField = 'purchase-order-' + fieldStyle + '-filter'
				activeFilter = dataTable.statusFilter
				break
			case 'typeFilter':
				classActualField = 'purchase-order-' + fieldStyle + '-filter'
				activeFilter = dataTable.typeFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}
		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer) {
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - heightRow,
					mainContainerHeight: prevState.mainContainerHeight - heightRow
				}))
			}
			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				initRange: undefined,
				finalRange: undefined,
				minAmount: '',
				maxAmount: '',
				minRange: undefined,
				maxRange: undefined,
				statusFilter: 'display-none',
				dateFilter: 'display-none',
				numericFilter: 'display-none',
				textFilter: 'display-none',
				typeFilter: 'display-none',
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textHeaderListOnClick,
				[filterActual]: classActualField
			}))
		} else if (dataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + heightRow,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				[filterActual]: 'display-none',
				textHeader: styles.textHeaderListPO,
				initRange: undefined,
				finalRange: undefined
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const closeFilter = () => {
		setDataTable((prevState) => ({
			...prevState,
			numericFilter: 'display-none',
			dateFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.tableHeaderClicked,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined,
			typeFilter: 'display-none'
		}))
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChangeQty = (evt: ChangeEvent<HTMLInputElement>, order: string) => {
		evt.preventDefault()
		const newFilterValue = evt.target.value
		const value = evt.target.value
		if (order === 'initial') {
			setDataTable((prevState) => ({
				...prevState,
				minRange: newFilterValue,
				minAmount: value
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				maxRange: newFilterValue,
				maxAmount: value
			}))
		}
	}

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			dataTable.minRange,
			dataTable.maxRange
		)
	}

	const selectionFilter = (status: string, position: number) => {
		if (dataTable.actualField === 'status' && filtersOfTypeStatus.status.numSelectOptions < 6) {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillStatusInputFilter.splice(position, 1)
		} else if (dataTable.actualField === 'type') {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillTypeInputFilter.splice(position, 1)
		}
	}

	const emptyFilterField = () => {
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			minAmount: '',
			maxAmount: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}
	return (
		<div className={'margins-table'}>
			<div className={styles.headerMainTablePO}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={
					dataTable.actualField === 'type' ? dataTable.typeFilter : dataTable.statusFilter
				}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={
					dataTable.actualField === 'type'
						? dataTable.fillTypeInputFilter
						: dataTable.fillStatusInputFilter
				}
			/>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText={initReferenceText}
				finReferenceText={finReferenceText}
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<NumericInputFilter
				showNumFilter={dataTable.numericFilter}
				actualField={dataTable.actualField}
				minAmount={dataTable.minAmount}
				maxAmount={dataTable.maxAmount}
				handleTextFilterChangeQty={handleTextFilterChangeQty}
				handleFilterKeyDown={handleFilterKeyDown}
				callDataList={callDataList}
				closeFilter={closeFilter}
			/>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{filterContainerBar ? (
				<ActiveFiltersBar
					objectType={Constants.LISTS.PO}
					dataFiltersArray={dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={deleteFilterActive}
				/>
			) : null}
			<div
				className={styles.tableInformationBackground}
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className={styles.tableInformation} style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataPurchase).map((key) => (
						<TableRow
							key={key}
							dataPurchase={dataPurchase[parseInt(key)]}
							userRoles={userRoles}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
