import { Comment, getCurrentUser } from '@netcurio/frontend-common'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { CommentsInterface } from '../../../common/interfaces/comments.inteface'
import { useClient } from '../../../hooks/useClient'
import { useUpdateEffect } from '../../../hooks/useUpdateEffect'
import { CREATE_PURCHASE_REQUISITION_COMMENT } from '../graphql-ops/mutations'
import { GET_PURCHASE_REQUISITION_COMMENTS } from '../graphql-ops/queries'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

interface Props {
	initialComments?: CommentsInterface[]
	purchaseRequisitionId: number | undefined
	customerRfc: string
	onCommentAdded?: () => void
}

const transformComments = (comment: any): Comment => ({
	...comment,
	created_by: comment.createdBy
})

const user = getCurrentUser()

export const usePurchaseRequisitionComments = ({
	initialComments,
	purchaseRequisitionId: id,
	customerRfc,
	onCommentAdded
}: Props) => {
	const client = useClient()
	const [comments, setComments] = useState<Comment[]>(initialComments?.map(transformComments) ?? [])

	const handleAddComment = (comment: string) => {
		if (!id) {
			setComments((prev) => [
				...prev,
				{
					uuid: new Date().toISOString(),
					text: comment,
					created_by: user!,
					created_at: new Date()
				}
			])
		} else {
			addComment(comment)
		}
	}

	const { mutate: addComment } = useMutation({
		mutationFn: async (comment: string) => {
			const { data } = await client.mutate({
				mutation: CREATE_PURCHASE_REQUISITION_COMMENT,
				variables: {
					id: id,
					customerRfc: customerRfc,
					comment: comment
				}
			})
			return data
		},
		onSuccess: () => {
			refetchComments()
			onCommentAdded?.()
		},
		onError: (error) => {
			console.error('Error adding comment:', error)
		}
	})

	const addPendingComments = async ({
		comments,
		purchaseRequisitionId,
		customerRfc
	}: {
		comments: Comment[]
		purchaseRequisitionId: number
		customerRfc: string
	}) => {
		for (const comment of comments) {
			const { text } = comment
			await client.mutate({
				mutation: CREATE_PURCHASE_REQUISITION_COMMENT,
				variables: {
					id: purchaseRequisitionId,
					customerRfc,
					comment: String(text)
				}
			})
			await delay(1000)
		}
	}

	const { refetch: refetchComments } = useQuery({
		queryKey: ['fetchComments', id, customerRfc],
		queryFn: async () => {
			if (id) {
				const { data } = await client.query({
					query: GET_PURCHASE_REQUISITION_COMMENTS,
					variables: {
						id: id,
						customerRfc: customerRfc
					},
					fetchPolicy: 'no-cache'
				})
				setComments(data.PurchaseRequisition?.comments?.map(transformComments))
			}
			return []
		}
	})

	useUpdateEffect(() => {
		if (id) {
			setComments(initialComments?.map(transformComments) ?? [])
		}
	}, [initialComments])

	return {
		comments,
		addComment: handleAddComment,
		refetchComments,
		addPendingComments
	}
}
