import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PRODUCT_NAME_CODE: DocumentNode = gql`
	query ProductsByNameOrCode($searchText: String!, $supplier: ID!) {
		ProductsByNameOrCode(searchText: $searchText, supplier: $supplier) {
			id
			supplier {
				rfc
			}
			description
			unit_price
			unit
			tax
			ean
			currency
		}
	}
`
