import * as React from 'react'
import './initI18n'
import './instrument'
// TODO: Update the components to no longer require next line:
import '@netcurio/frontend-assets/src/styles/base.scss'
import '@netcurio/frontend-components/dist/style.css'
import { createRoot } from 'react-dom/client'
import { Bootstrap } from './bootstrap'
;(window as any).global = window

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<Bootstrap />)
