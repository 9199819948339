import inactive from '@netcurio/frontend-assets/src/icons/cancel.svg'
import check from '@netcurio/frontend-assets/src/icons/check-white.svg'
import block from '@netcurio/frontend-assets/src/icons/padlock-white.svg'
import {
	dateFormatter,
	getCurrentUser,
	parseTimestampToTimeZone,
	Role,
	Roles,
	User
} from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioCheckbox,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTooltip,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import { getUserRolesForCompany } from '../../../../../utilities/getUserRolesForCompany'
import { createInvitationHelper } from '../createInvitationHelper'
import { ErrorModal } from '../Modals/ErrorModal/ErrorModal'
import { BlockUserModal, ChangeUserRoleModal, UnblockUserModal, UnsubscribeUserModal } from './Modals'
import * as queries from './queries'
import { TypesProps } from './types'
import styles from './userDetail.module.scss'

export default function UserDetailTab({ companyName }: TypesProps) {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showChangeStatusButtons, setShowChangeStatusButtons] = useState<boolean>(false)
	const [showChangeButton, setShowChangeButton] = useState<boolean>(true)
	const [showInviteButton, setShowInviteButton] = useState<boolean>(false)
	const [dataUser, setDataUser] = useState<User | Record<string, any>>({})
	const [hideWhenLoading, setHideWhenLoading] = useState<boolean>(true)
	const [textStatusInvitation, setTextStatusInvitation] = useState<string>('')
	const [fillModalUnsubscribe, setFillModalUnsubscribe] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>('')
	const [changeUserTypeButton, setChangeUserTypeButton] = useState<boolean>(true)
	const [saveOrCancelUserTypeButton, setSaveOrCancelUserTypeButton] = useState<boolean>(false)
	const [showModalSaveUserType, setShowModalSaveUserType] = useState<boolean>(false)
	const [statusClass, setStatusClass] = useState<string>('')
	const [blockOrUnblockText, setBlockOrUnblockText] = useState<string>('')
	const [statusIcon, setStatusIcon] = useState<string>()
	const [sendInvitation, setSendInvitation] = useState<boolean>(false)
	const [errorSendInvitation, setErrorSendInvitation] = useState<boolean>(false)
	const [showBlockModal, setShowBlockModal] = useState<boolean>(false)
	const [showUnBlockModal, setShowUnBlockModal] = useState<boolean>(false)
	const userRoles = useMemo(() => getUserRolesForCompany(), [])
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const currentUserEmail = getCurrentUser()?.email
	let isMounted = true
	let { email } = useParams<{ email: string }>()
	email = email.replace('%40', '@').split('&').join('.')

	useEffect(() => {
		const textStatus = sessionStorage.getItem('statusInvitation')
		if (textStatus) setTextStatusInvitation(textStatus)
		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		if (sendInvitation) {
			sendInvitationAgain()
		}
	}, [sendInvitation])

	useEffect(() => {
		getUser()
	}, [email])

	useEffect(() => {
		let newStatusClass,
			newBlockOrUnblockText,
			newShowInviteButton = false,
			newStatusIcon,
			newShowChangeButton = true
		if (
			dataUser?.status?.key === Constants.USER_STATUS.ACTIVE ||
			dataUser?.status?.key === Constants.USER_STATUS.INACTIVE
		) {
			newBlockOrUnblockText = 'block'
			if (dataUser?.status?.key === Constants.USER_STATUS.ACTIVE) {
				newStatusClass = styles.statusActive
				newStatusIcon = check
			} else {
				newStatusClass = styles.statusInactive
				newStatusIcon = inactive
				newShowInviteButton = true
			}
		} else if (dataUser?.status?.key === Constants.USER_STATUS.BLOCKED) {
			newBlockOrUnblockText = 'unblock'
			newStatusClass = styles.statusBlock
			newStatusIcon = block
		} else {
			newBlockOrUnblockText = ''
			newStatusClass = styles.statusUnsubscribed
			newStatusIcon = check
			newShowChangeButton = false
			setShowChangeStatusButtons(false)
		}
		setShowChangeButton(newShowChangeButton)
		setStatusClass(newStatusClass)
		setBlockOrUnblockText(newBlockOrUnblockText)
		setShowInviteButton(newShowInviteButton)
		setStatusIcon(newStatusIcon)
	}, [dataUser])

	useEffect(() => {
		if (textStatusInvitation && isMounted) {
			setTimeout(function () {
				if (isMounted) {
					setTextStatusInvitation('')
					sessionStorage.removeItem('statusInvitation')
				}
			}, 3500)
		}
	}, [textStatusInvitation])

	function sendInvitationAgain() {
		showLoadingSpinner()
		createInvitationHelper({
			emailInvite: dataUser.email,
			roleInvite: dataUser.roles[0].id ?? '',
			isAdminInvite: dataUser.is_admin,
			enrollmentRequestApprover: dataUser.enrollment_request_approver
		})
			.then(() => {
				setErrorSendInvitation(false)
				setTextStatusInvitation(Constants.INVITATION_STATUS.INVITATION_RESENT)
			})
			.catch(handleError)
			.finally(() => {
				setSendInvitation(false)
				hideLoadingSpinner()
			})
	}

	function getUser() {
		showLoadingSpinner()
		client
			.query({
				query: queries.USER,
				variables: {
					email
				},
				fetchPolicy: 'no-cache'
			})
			.then(async (result) => {
				if (isMounted) {
					if (result.data.User) {
						setDataUser(result.data.User)
						setHideWhenLoading(false)
						if (currentUserEmail === result.data.User.email) {
							setShowChangeButton(false)
							setChangeUserTypeButton(false)
						}
						hideLoadingSpinner()
					} else {
						hideLoadingSpinner()
						setErrorCode('USER_NOT_FOUND')
					}
				}
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		if (isMounted) {
			console.error(error)
			const newErrorCode = showErrorComponent(error)
			expiredToken(newErrorCode)
			if (!expiredToken(newErrorCode)) {
				setErrorCode(newErrorCode)
			}
			hideLoadingSpinner()
		}
	}

	function setTimezone() {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		let timezone = dataUser.time_zone
		if (!timezone) {
			if (!dataUser.name) {
				timezone = t('pendingText')
			} else {
				timezone = userTimezone
			}
		}
		return timezone
	}

	function showBlockOrUnblockModal() {
		if (
			dataUser?.status?.key === Constants.USER_STATUS.ACTIVE ||
			dataUser?.status?.key === Constants.USER_STATUS.INACTIVE
		) {
			setShowBlockModal(true)
		} else if (dataUser?.status?.key === Constants.USER_STATUS.BLOCKED) {
			setShowUnBlockModal(true)
		}
	}

	function acceptActionModal(action: string) {
		if (action === 'unsubscribeUser') {
			changeUserStatus(Constants.ACTIONS_USER_STATUS.UNSUBSCRIBE)
		} else if (action === 'changeUserType') {
			saveUserType()
		}
	}

	function hideModal(action: string) {
		if (action === 'unsubscribeUser') {
			setFillModalUnsubscribe(false)
		} else if (action === 'changeUserType') {
			setShowModalSaveUserType(false)
		}
	}

	function changeUserStatus(status: string) {
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.CHANGE_USER_STATUS,
				variables: {
					email,
					action: status
				}
			})
			.then(() => {
				let action
				if (status === Constants.ACTIONS_USER_STATUS.BLOCK) {
					action = 'blockUser'
				} else if (status === Constants.ACTIONS_USER_STATUS.UNBLOCK) {
					action = 'unblockUser'
				} else if (status === Constants.ACTIONS_USER_STATUS.UNSUBSCRIBE) {
					action = 'unsubscribeUser'
				}
				if (action) hideModal(action)
				getUser()
				setShowChangeButton(true)
				setShowChangeStatusButtons(false)
				setHideWhenLoading(true)
			})
			.catch(handleError)
	}

	function showChangeStatusButton() {
		if (showChangeButton) {
			return (
				<div className={styles.buttonsSpacing}>
					<NetcurioButton
						size={'small'}
						variant={'outlined'}
						onClick={() => {
							setShowChangeButton(false)
							setShowChangeStatusButtons(true)
						}}
					>
						{t('change')}
					</NetcurioButton>
				</div>
			)
		}
		return null
	}

	function showUnsubscribedAndBlockButtons() {
		if (showChangeStatusButtons) {
			return (
				<div className={styles.displayFlex}>
					<NetcurioTooltip title={t(blockOrUnblockText + 'Tooltip')} placement={'top'}>
						<NetcurioButton size={'small'} variant={'outlined'} onClick={showBlockOrUnblockModal}>
							{t(blockOrUnblockText)}
						</NetcurioButton>
					</NetcurioTooltip>
					<NetcurioTooltip title={t('tooltipUnsubscribeUser')} placement={'top'}>
						<NetcurioButton
							size={'small'}
							variant={'outlined'}
							onClick={() => setFillModalUnsubscribe(true)}
						>
							{t('unsubscribeUser')}
						</NetcurioButton>
					</NetcurioTooltip>
					<NetcurioButton
						size={'small'}
						color={'secondary'}
						variant={'text'}
						onClick={() => {
							setShowChangeButton(true)
							setShowChangeStatusButtons(false)
						}}
					>
						{t('cancelButton')}
					</NetcurioButton>
				</div>
			)
		}
		return null
	}

	function saveUserType() {
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.UPDATE_USER_TYPE,
				variables: {
					email,
					is_admin: dataUser.is_admin,
					enrollment_request_approver: dataUser.enrollment_request_approver
				}
			})
			.then(() => {
				setChangeUserTypeButton(true)
				setSaveOrCancelUserTypeButton(false)
				setHideWhenLoading(true)
				setShowModalSaveUserType(false)
				getUser()
				hideLoadingSpinner()
			})
			.catch(handleError)
	}

	function showUserTypeChangeButton() {
		if (dataUser.status && changeUserTypeButton) {
			if (
				dataUser?.status?.key === Constants.USER_STATUS.ACTIVE ||
				dataUser?.status?.key === Constants.USER_STATUS.INACTIVE
			) {
				return (
					<NetcurioButton
						size={'small'}
						variant={'outlined'}
						onClick={() => {
							setChangeUserTypeButton(false)
							setSaveOrCancelUserTypeButton(true)
						}}
					>
						{t('change')}
					</NetcurioButton>
				)
			}
		}
		return null
	}

	function showSaveAndCancelButtons() {
		if (saveOrCancelUserTypeButton) {
			return (
				<div className="cancel-save-user-detail-buttons displayFlex">
					<div className={styles.marginRightMedium}>
						<NetcurioButton
							size={'small'}
							variant={'outlined'}
							onClick={() => setShowModalSaveUserType(true)}
						>
							{t('saveButton')}
						</NetcurioButton>
					</div>
					<div>
						<NetcurioButton
							size={'small'}
							variant={'text'}
							color={'secondary'}
							onClick={() => {
								setChangeUserTypeButton(true)
								setSaveOrCancelUserTypeButton(false)
							}}
						>
							{t('cancelButton')}
						</NetcurioButton>
					</div>
				</div>
			)
		}
		return null
	}

	function dropdownUserType() {
		if (saveOrCancelUserTypeButton) {
			const options = [
				{ label: t(Constants.USER_TYPES.STANDARD), value: false },
				{ label: t(Constants.USER_TYPES.ADMINISTRATOR), value: true }
			]
			return (
				<NetcurioSelect
					fullWidth
					value={t(dataUser.is_admin ? 'administrator' : 'standard')}
					onChange={(e) => {
						setDataUser({
							...dataUser,
							is_admin: e.target.value === 'Administrador',
							enrollment_request_approver: false
						})
					}}
				>
					{options.map((item, i) => (
						<NetcurioMenuItem key={i} value={item.label}>
							<span>{item.label}</span>
						</NetcurioMenuItem>
					))}
				</NetcurioSelect>
			)
		} else {
			const userType = dataUser.is_admin
				? Constants.USER_TYPES.ADMINISTRATOR
				: Constants.USER_TYPES.STANDARD
			return <p className={styles.readOnlyField}>{t(userType)}</p>
		}
	}
	const blockUser = () => {
		setShowBlockModal(false)
		changeUserStatus(Constants.ACTIONS_USER_STATUS.BLOCK)
	}
	const unblockUser = () => {
		setShowUnBlockModal(false)
		changeUserStatus(Constants.ACTIONS_USER_STATUS.UNBLOCK)
	}

	return (
		!hideWhenLoading && (
			<div className={styles.tableInformationWhiteStyle}>
				<div className={styles.userDetailInfoText}>
					<div className={classNames(styles.listUsersText, styles.detailUsersName)}>
						<p className={styles.widthContainerName}>
							{t(
								dataUser.name && dataUser.lastname
									? `${dataUser.name} ${dataUser.lastname}`
									: 'pendingText'
							)}
						</p>
						{textStatusInvitation && (
							<div className={styles.containerInvitationStatus}>
								<div
									className={classNames(
										styles.containerTextInvitationStatus,
										{
											[styles.containerTextInvitationStatusValid]: !errorSendInvitation
										},
										{
											[styles.containerTextInvitationStatusError]: errorSendInvitation
										}
									)}
								>
									<p className={styles.textInvitationStatus}>{t(textStatusInvitation)}</p>
								</div>
							</div>
						)}
						<div className={styles.inviteAndStatusButtons}>
							{showInviteButton && (
								<NetcurioButton
									size={'small'}
									variant={'outlined'}
									sx={{ marginRight: '2rem' }}
									onClick={() => setSendInvitation(true)}
								>
									{t('inviteAgain')}
								</NetcurioButton>
							)}
							<div className={classNames(styles.headerButtonUserDetailTab, statusClass)}>
								<img
									src={statusIcon}
									alt="status icon"
									className={styles.checkSizeUserDetailTab}
								/>
								<p className={styles.marginHorizontal1rem}>{dataUser.status?.value || ''}</p>
							</div>
						</div>
					</div>
					<div className={styles.userDetailTabFieldsContainer}>
						<div className={styles.userDetailTabFieldSizeContainer}>
							<div className={styles.containerTwoFieldsWidth}>
								<p className="title-fields">{t('user')}</p>
								<p className={styles.readOnlyField}>{email}</p>
							</div>
							<div className={styles.containerTwoFieldsWidth}>
								<p className="title-fields">{t('company')}</p>
								<p className={styles.readOnlyField}>{companyName}</p>
							</div>
						</div>
						<div className={styles.userDetailTabFieldSizeContainer}>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('phoneNumber')}</p>
								<p className={styles.readOnlyField}>
									{dataUser.phone_number || t('pendingText')}
								</p>
							</div>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('created_at_PO')}</p>
								<p className={styles.readOnlyField}>
									{dateFormatter.format(parseTimestampToTimeZone(dataUser.created_at))}
								</p>
							</div>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('timezoneText')}</p>
								<p className={styles.readOnlyField}>{setTimezone()}</p>
							</div>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('createdByText')}</p>
								<NetcurioTooltip title={dataUser.created_by?.email || ''} placement={'top'}>
									<p className={styles.readOnlyField}>
										{dataUser.created_by
											? `${dataUser.created_by.name} ${dataUser.created_by.lastname}`
											: ''}
									</p>
								</NetcurioTooltip>
							</div>
						</div>
						<div className={styles.userDetailTabFieldSizeContainer}>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('lastEditDate')}</p>
								<p className={styles.readOnlyField}>
									{dateFormatter.format(parseTimestampToTimeZone(dataUser.updated_at))}
								</p>
							</div>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('editBy')}</p>
								<NetcurioTooltip title={dataUser.modified_by?.email || ''} placement={'top'}>
									<p className={styles.readOnlyField}>
										{dataUser.modified_by
											? `${dataUser.modified_by.name} ${dataUser.modified_by.lastname}`
											: ''}
									</p>
								</NetcurioTooltip>
							</div>
							<div className={styles.containerFourFieldsWidth}>
								<p className="title-fields">{t('role')}</p>
								<p className={styles.readOnlyField}>
									{t(
										dataUser?.roles?.some((role: Role) => role.id === Roles.SUPPLIER)
											? 'supplierFilterName'
											: 'customerFilterName'
									)}
								</p>
							</div>
							<div className={styles.containerFourFieldsWidth} />
						</div>
					</div>
					<p className={styles.userDetailTabStatus}>{t('status_text')}</p>
					<div className={styles.changeStatusUserDetailTab}>
						<div className={classNames(styles.containerStatusUserDetailTab, styles.marginRight2)}>
							<p className="title-fields">{t('changeStatus')}</p>
							<p className={styles.readOnlyField}>{dataUser.status?.value || ''}</p>
						</div>
						{showChangeStatusButton()}
						{showUnsubscribedAndBlockButtons()}
					</div>
					<p className={styles.userDetailTabUserType}>{t('userType')}</p>
					<div className={styles.changeUserTypeUserDetailTab}>
						<div
							className={classNames(styles.containerUserTypeUserDetailTab, styles.marginRight2)}
						>
							<p className="title-fields">{t('selectUserType')}</p>
							{dropdownUserType()}
						</div>
						{showUserTypeChangeButton()}
						{showSaveAndCancelButtons()}
					</div>
					{dataUser.is_admin && userRoles.includes(Roles.CUSTOMER) && (
						<div className={styles.changeUserTypeUserDetailTab}>
							<div
								className={classNames(
									styles.containerUserTypeUserDetailTab,
									styles.marginRight2
								)}
							>
								<div className={styles.checkbox}>
									<NetcurioCheckbox
										checked={dataUser.enrollment_request_approver}
										disabled={!saveOrCancelUserTypeButton}
										onChange={(e) =>
											setDataUser({
												...dataUser,
												enrollment_request_approver: e.target.checked
											})
										}
									/>
									<NetcurioTooltip title={t('enrollmentManagerTooltip')} placement={'top'}>
										<div className="title-checkbox text-12">{t('enrollmentManager')}</div>
									</NetcurioTooltip>
								</div>
							</div>
						</div>
					)}
				</div>
				<BlockUserModal
					open={showBlockModal}
					close={() => setShowBlockModal(false)}
					block={() => blockUser()}
					email={email}
				/>
				<UnblockUserModal
					open={showUnBlockModal}
					close={() => setShowUnBlockModal(false)}
					unblock={() => unblockUser()}
					email={email}
				/>
				<ChangeUserRoleModal
					open={showModalSaveUserType}
					isChangeToAdmin={dataUser.is_admin || false}
					close={() => hideModal('changeUserType')}
					accept={() => acceptActionModal('changeUserType')}
				/>
				<UnsubscribeUserModal
					open={fillModalUnsubscribe}
					accept={() => acceptActionModal('unsubscribeUser')}
					close={() => hideModal('unsubscribeUser')}
					email={email}
				/>
				<ErrorModal open={!!errorCode} errorCode={errorCode} />
			</div>
		)
	)
}
