import { Roles } from '@netcurio/frontend-common'
import { getUserRolesForCompany } from '../utilities/getUserRolesForCompany'

export const useUserRoles = () => {
	const userRoles = getUserRolesForCompany()

	return {
		userRoles,
		isUserSupplier: userRoles.includes(Roles.SUPPLIER),
		isUserCustomer: userRoles.includes(Roles.CUSTOMER),
		isUserCustomerNoAdmins: userRoles.includes(Roles.CUSTOMER_NO_ADMINS),
		isRequester: userRoles.includes(Roles.REQUESTER),
		isApprover: userRoles.includes(Roles.APPROVER)
	}
}
