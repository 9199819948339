import logo from '@netcurio/frontend-assets/src/images/secondaryBlueNavyLogo.svg'
import landingImage from '@netcurio/frontend-assets/src/imagesNewDesign/landingPassword.webp'
import { Company, URLS } from '@netcurio/frontend-common'
import {
	HorizontalScrollMenu,
	NetcurioButton,
	NetcurioCheckbox,
	NetcurioFormCheckboxLabel,
	NetcurioGrid,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import { t } from 'i18next'
import moment from 'moment-timezone'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { connection } from '../../../utilities/connection'
import constants from '../../../utilities/constants'
import { loadRecaptchaScript } from '../../../utilities/load-re-captcha-script'
import { TermsAndConditionsModal } from '../../termsAndConditionsModal/TermsAndConditionsModal'
import { ActivateUserObject, TimeZone } from '../utilities/localTypes'
import { ACTIVATE_USER } from '../utilities/queries'
import styles from './registerUserToCompany.module.scss'

interface RegisterUserToCompanyProps {
	email: string
	companies: Company[]
	id: string
}

const formatTimezone = (name: string) => {
	return moment.tz(name).format('Z')
}

export const RegisterUserToCompany = ({ email, companies }: RegisterUserToCompanyProps): ReactElement => {
	const client = connection()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const history = useHistory()
	const [isFormIncomplete, setIsFormIncomplete] = useState<boolean>(true)
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [selectValue, setSelectValue] = useState('America/Mexico_City')
	const [isChecked, setIsChecked] = useState<boolean>(false)
	const [formValues, setFormValues] = useState<Record<string, string>>({
		name: '',
		lastname: '',
		phone_number: '',
		password: '',
		confirmPassword: ''
	})
	const [error, setError] = useState({
		errors: new Set(),
		errorMessages: new Set()
	})

	const { environment, isSuccess } = useEnvironment()

	useEffect(() => {
		const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
		setFormValues((state) => ({ ...state, time_zone }))
		if (environment?.RECAPTCHA_KEY) loadRecaptchaScript(environment.RECAPTCHA_KEY)
	}, [isSuccess])

	const isAnyValueEmpty = Object.values(formValues).some((value) => value.trim() === '')

	const activateUser = (variables: ActivateUserObject) => {
		return client
			.mutate({ mutation: ACTIVATE_USER, variables })
			.then(() => {
				sessionStorage.setItem('correctMessage', 'userRegistered')
				history.push(URLS.LOGIN)
			})
			.catch((error) => console.log(error))
	}

	const handleSubmit = async (): Promise<void> => {
		showLoadingSpinner()

		try {
			const { name, lastname, phone_number, time_zone } = formValues
			const payload = { email, name, lastname, phone_number, time_zone }

			await Auth.signUp({ username: email, password: formValues.password })
			await activateUser(payload)
		} catch (err: any) {
			if (err.code && err.code === 'UsernameExistsException') {
				const { name, lastname, phone_number, time_zone } = formValues
				const payload = { email, name, lastname, phone_number, time_zone }
				await activateUser(payload)
			}
		} finally {
			hideLoadingSpinner()
		}
	}

	const possibleErrors = new Map<string, string>([
		['phone', t('phoneNumbersText')],
		['password', t('validatePasswordFailText')],
		['confirmPassword', t('validatePasswordFailText')],
		['noMatch', t('validatePasswordFailConfirmText')],
		['termsAndConditions', t('acceptTermsFailText')],
		['isEmpty', t('fieldNoFillText')]
	])

	const handleIsAnyValueEmpty = () => {
		setIsFormIncomplete(isAnyValueEmpty)
		if (isAnyValueEmpty) {
			error.errors.add('isEmpty')
			error.errorMessages.add(possibleErrors.get('isEmpty'))
		} else {
			error.errors.delete('isEmpty')
			error.errorMessages.delete(possibleErrors.get('isEmpty'))
		}
	}

	const handlePhoneValidation = (value: string): void => {
		const phoneRegex = /^\d*$/
		if (phoneRegex.test(value) && value.length <= 10) {
			setFormValues((previousValue) => ({
				...previousValue,
				phone_number: value
			}))
		}
		if (value.length < 10) {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors, 'phone']),
				errorMessages: new Set([...prevError.errorMessages, possibleErrors.get('phone')])
			}))
		} else {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors].filter((error) => error !== 'phone')),
				errorMessages: new Set(
					[...prevError.errorMessages].filter((message) => message !== possibleErrors.get('phone'))
				)
			}))
		}
	}

	const handleIsTermsAndConditionsSelected = () => {
		if (!isChecked) {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors, 'termsAndConditions']),
				errorMessages: new Set([...prevError.errorMessages, possibleErrors.get('termsAndConditions')])
			}))
		} else {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors].filter((error) => error !== 'termsAndConditions')),
				errorMessages: new Set(
					[...prevError.errorMessages].filter(
						(message) => message !== possibleErrors.get('termsAndConditions')
					)
				)
			}))
		}
	}

	const handlePasswordsMatch = () => {
		if (formValues.password !== formValues.confirmPassword) {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors, 'noMatch']),
				errorMessages: new Set([...prevError.errorMessages, possibleErrors.get('noMatch')])
			}))
		} else {
			setError((prevError) => ({
				...prevError,
				errors: new Set([...prevError.errors].filter((error) => error !== 'noMatch')),
				errorMessages: new Set(
					[...prevError.errorMessages].filter(
						(message) => message !== possibleErrors.get('noMatch')
					)
				)
			}))
		}
	}

	useEffect(() => {
		handleIsAnyValueEmpty()
		handlePasswordsMatch()
		handleIsTermsAndConditionsSelected()
	}, [formValues, isChecked])

	const handlePasswordValidation = (key: string, value: string): void => {
		setFormValues((previousValue) => ({
			...previousValue,
			[key]: value
		}))
		const isValidPassword =
			/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/.test(
				value
			)

		if (!isValidPassword) {
			error.errors.add(key)
			error.errorMessages.add(possibleErrors.get(key))
		} else {
			error.errors.delete(key)
			error.errorMessages.delete(possibleErrors.get(key))
		}
	}

	const handleSetFormValues = (key: string, value: string): void => {
		setFormValues((previousValue) => ({
			...previousValue,
			[key]: value
		}))
	}

	const timezones: Array<TimeZone> = useMemo(() => {
		const timezonesMap = moment.tz.names().map((tz) => {
			return { name: tz, offset: moment.tz(tz).utcOffset() }
		})
		return timezonesMap.sort((a, b) => a.offset - b.offset)
	}, [])

	const handleIsModalOpen = (): void => {
		setIsModalOpen(true)
	}

	const handleIsChecked = (): void => {
		setIsChecked(!isChecked)
	}

	const handleIsModalClosed = (): void => {
		setIsModalOpen(false)
	}

	const handleSelectValue = (value: string) => {
		setSelectValue(value)
	}

	const subscribedCompanies = companies.map((company, index) => {
		return (
			<div key={index} id={company.name} className={styles.listCompanies}>
				<NetcurioTextField
					label={t('enterprise')}
					value={company.name}
					fullWidth
					size="small"
					variant="outlined"
					disabled
				/>
				<NetcurioTextField
					label={t('companyRFC')}
					value={company.rfc}
					size="small"
					fullWidth
					variant="outlined"
					disabled
				/>
			</div>
		)
	})

	const validationSumbit = isFormIncomplete || !isChecked
	return (
		<>
			<NetcurioGrid container className={styles.body}>
				<NetcurioGrid item xs={4} className={styles.columnLeft}>
					<div className={styles.container}>
						<div className={styles.centeredColumn}>
							<img src={logo} className={styles.logo} />
							<span className={styles.completeRegisterLabel}>
								{t('fillYourInformationText')}
							</span>
							<NetcurioTextField
								fullWidth
								size="small"
								variant="outlined"
								value={email}
								disabled
							/>
							<span className={styles.relatedCompaniesLabel}>{t('relatedCompanies')}</span>
							<div className={styles.sizeContainerScroll}>
								<HorizontalScrollMenu>{subscribedCompanies}</HorizontalScrollMenu>
							</div>
							<span className={styles.relatedCompaniesLabel}>{t('fillRequiredDataText')}</span>
							<NetcurioTextField
								fullWidth
								size="small"
								variant="outlined"
								value={formValues.name}
								onChange={(e) => handleSetFormValues('name', e.target.value)}
								label={t('pluralName')}
							/>
							<NetcurioTextField
								fullWidth
								size="small"
								variant="outlined"
								value={formValues.lastname}
								onChange={(e) => handleSetFormValues('lastname', e.target.value)}
								label={t('placeHolderLastName')}
							/>
							<NetcurioTextField
								fullWidth
								error={error.errors.has('phone')}
								size="small"
								variant="outlined"
								value={formValues.phone_number}
								onChange={(e) => handlePhoneValidation(e.target.value)}
								label={t('placeHolderPhone')}
							/>
							<NetcurioTextField
								fullWidth
								size="small"
								variant="outlined"
								type="password"
								value={formValues.password}
								onChange={(e) => handlePasswordValidation('password', e.target.value)}
								error={error.errors.has('password') || error.errors.has('noMatch')}
								label={t('placeHolderPassword')}
							/>
							<span className={styles.validatePasswordSpan}>
								{t('validatePasswordFailText')}
							</span>
							<NetcurioTextField
								fullWidth
								size="small"
								variant="outlined"
								type="password"
								value={formValues.confirmPassword}
								onChange={(e) => handlePasswordValidation('confirmPassword', e.target.value)}
								error={error.errors.has('confirmPassword') || error.errors.has('noMatch')}
								label={t('placeHolderPasswordConfirm')}
							/>
							<div className={styles.selectContainer}>
								<NetcurioSelect
									variant="standard"
									displayEmpty
									fullWidth
									size="medium"
									value={selectValue}
									onChange={(event) => handleSelectValue(event.target.value)}
								>
									{timezones.map((tz, i) => (
										<NetcurioMenuItem key={i} value={tz.name}>
											{`(UTC${formatTimezone(tz.name)}) ${tz.name}`}
										</NetcurioMenuItem>
									))}
								</NetcurioSelect>
							</div>

							<div className={styles.termsContainer}>
								<NetcurioFormCheckboxLabel
									required
									checked={isChecked}
									onChange={handleIsChecked}
									control={<NetcurioCheckbox />}
									componentsProps={{
										typography: {
											style: {
												fontSize: '1.2rem'
											}
										}
									}}
									label={
										<Trans
											i18nKey="termsAndPrivacyPolicy"
											components={{
												t: (
													<span
														className={styles.termsAndConditionsLinks}
														onClick={() => handleIsModalOpen()}
													/>
												),
												p: (
													<a
														href={constants.LINKS.LINK_PRIVACY_POLICY}
														className={styles.termsAndConditionsLinks}
													/>
												)
											}}
										/>
									}
								/>
							</div>
							{error.errors.size !== 0 && (
								<div className={styles.topMessageContainer}>
									<div className={styles.msgerrorP}>
										{Array.from(error.errorMessages).map((errorMessage, index) => (
											<p className={styles.errorMessageSpan} key={index}>
												{errorMessage as string}
											</p>
										))}
									</div>
								</div>
							)}

							<NetcurioButton
								className={styles.registerButton}
								variant="contained"
								fullWidth
								onClick={handleSubmit}
								disabled={validationSumbit}
							>
								{t('textActionRegister')}
							</NetcurioButton>
							<NetcurioButton
								onClick={() => history.push(URLS.LOGIN)}
								className={styles.cancelSpan}
							>
								{t('cancelButton')}
							</NetcurioButton>
						</div>
					</div>
				</NetcurioGrid>
				<NetcurioGrid item alignItems="center" xs={8} className={styles.columnRight}>
					<picture className={styles.landingImage}>
						<source type="image/webp" srcSet={landingImage} />
						<source type="image/png" srcSet={landingImage} />
						<img src={landingImage} />
					</picture>
				</NetcurioGrid>
			</NetcurioGrid>
			<TermsAndConditionsModal showModal={isModalOpen} closeModal={handleIsModalClosed} />
		</>
	)
}
