import { NetcurioButton, NetcurioIcons, useNetcurioLoader } from '@netcurio/frontend-components'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks/useClient'
import { useUserRoles } from '../../../../hooks/useUserRoles'
import { PurchaseRequisitionStatusEnum } from '../../enums/purchase-requisition-status.enum'
import { APPROVE_PURCHASE_REQUISITION } from '../../graphql-ops/mutations'
import { useCurrentApprover } from '../../hooks/useCurrentApprover'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

interface Props {
	purchaseRequisition: PurchaseRequisitionInterface
	onApprove?: () => void
	validateBeforeApprove: () => boolean
}

export const Approve = ({ purchaseRequisition, onApprove, validateBeforeApprove }: Props) => {
	const { isApprover } = useUserRoles()
	const client = useClient()
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const { id, requester, customerRfc } = purchaseRequisition

	const { isUserCurrentApprover: canApprove } = useCurrentApprover({ purchaseRequisition })

	const { mutate: approve } = useMutation({
		mutationFn: async () => {
			showLoadingSpinner()
			const { data } = await client.mutate({
				mutation: APPROVE_PURCHASE_REQUISITION,
				variables: {
					id,
					requester: requester.email,
					customerRfc: customerRfc
				}
			})
			return data
		},
		onSuccess: () => {
			onApprove?.()
			hideLoadingSpinner()
		},
		onError: (error) => {
			console.error('Error approving purchase requisition:', error)
			hideLoadingSpinner()
		}
	})

	if (!isApprover || PurchaseRequisitionStatusEnum.PENDING !== purchaseRequisition.status || !canApprove) {
		return null
	}

	return (
		<NetcurioButton
			color="primary"
			variant="contained"
			onClick={() => {
				if (validateBeforeApprove()) {
					approve()
				}
			}}
			size="small"
			endIcon={<NetcurioIcons.Check />}
		>
			<span> {t('purchaseRequisitions.actions.approve')} </span>
		</NetcurioButton>
	)
}
