import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const AREAS_CATALOGUE: DocumentNode = gql`
	query Areas($companyRfc: String) {
		Areas(companyRfc: $companyRfc) {
			id
			description
			companyRfc
		}
	}
`
