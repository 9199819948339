import { Filter, FilterInput, getCurrentUser } from '@netcurio/frontend-common/src'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { useClient } from '../../../../hooks/useClient'
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll'
import { downloadExcelFile } from '../../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../../utilities/file-handling/file-descriptor'
import { PURCHASE_REQUISITIONS } from '../../graphql-ops/queries'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

export const usePurchaseRequisitions = () => {
	const client = useClient()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const [isFirstRender, setIsFirstRender] = useState(true)

	const fetchPurchaseRequisitions = async ({
		skip,
		limit,
		filters,
		sortField,
		sortOrder
	}: {
		skip: number
		limit: number
		filters: Record<string, any>
		sortField: string
		sortOrder: string
	}): Promise<{ total: number; items: PurchaseRequisitionInterface[] }> => {
		try {
			if (isFirstRender) {
				showLoadingSpinner()
			}
			const response = await client.query({
				query: PURCHASE_REQUISITIONS,
				variables: { skip, limit, filter: filters, sortField, sortOrder },
				fetchPolicy: 'no-cache'
			})

			return {
				total: response.data.PurchaseRequisitions.total,
				items: response.data.PurchaseRequisitions.list
			}
		} catch (error) {
			console.error('Error fetch data:', error)
			return {
				total: 0,
				items: []
			}
		} finally {
			if (isFirstRender) {
				hideLoadingSpinner()
				setIsFirstRender(false)
			}
		}
	}

	const {
		items: purchaseRequisitions,
		hasMore,
		loading,
		loadMore,
		reload,
		setFilters,
		setSortParams,
		filters
	} = useInfiniteScroll<PurchaseRequisitionInterface>({
		fetchFn: fetchPurchaseRequisitions,
		initialLimit: 22,
		initialSkip: 0,
		defaultSortField: 'createdAt',
		defaultSortOrder: 'DESC'
	})

	const downloadExcelReport = async () => {
		if (Object.keys(purchaseRequisitions).length > 0) {
			showLoadingSpinner()
			const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filters))
			const body: string = JSON.stringify({
				filters: copyFilterToApply,
				sortField: 'created_at',
				sortOrder: 'desc'
			})
			const currentUser = getCurrentUser()
			const token = await Auth.currentAuthenticatedUser()
				.then((user) => {
					return user.signInUserSession.idToken.jwtToken
				})
				.catch((err) => {
					console.error(err)
				})

			if (currentUser) {
				const requestHeaders: HeadersInit = new Headers()
				requestHeaders.set('Content-Type', 'application/json; charset=UTF-8')
				requestHeaders.set('Accept', 'application/json')
				requestHeaders.set('authorization', 'Bearer ' + token)
				requestHeaders.set('current-company', currentUser.company?.rfc ?? '')
				// TODO: check how to get the expected language here
				requestHeaders.set('accept-language', 'es')
				return new Promise(() => {
					fetch(`/api/reporting/purchase-requisitions`, {
						method: 'POST',
						headers: requestHeaders,
						body: body
					})
						.then(async (result) => {
							if (result.ok) {
								result.json().then((responseJson: FileDescriptor) => {
									downloadExcelFile(responseJson)
								})
							} else {
								const error: Error = await result.json()
								console.error(error)
							}
						})
						.catch((error) => {
							console.error(error)
						})
						.finally(() => {
							hideLoadingSpinner()
						})
				})
			} else {
				hideLoadingSpinner()
				return
			}
		} else {
			hideLoadingSpinner()
			return
		}
	}

	const [selectedPurchaseRequisitions, setSelectedPurchaseRequisitions] = useState<
		PurchaseRequisitionInterface[]
	>([])

	const handleOnFiltersChange = (filters: FilterInput[]) => {
		setFilters(
			filters.map((filter) =>
				filter.type === 'date'
					? {
							...filter,
							initDate: filter?.initDate?.toDate().getTime(),
							finalDate: filter?.finalDate?.toDate().getTime()
						}
					: filter.type === 'numeric'
						? {
								...filter,
								initRange: filter?.initRange ? String(filter?.initRange) : undefined,
								finalRange: filter?.finalRange ? String(filter?.finalRange) : undefined
							}
						: filter
			)
		)
	}

	const handleOnChangeSort = (field: string, order: string) => {
		setSortParams({ field, order })
	}

	return {
		purchaseRequisitions,
		selectedPurchaseRequisitions,
		setSelectedPurchaseRequisitions,
		hasMore,
		loading,
		reload,
		loadMore,
		handleOnFiltersChange,
		handleOnChangeSort,
		downloadExcelReport
	}
}
