import { useQuery } from '@tanstack/react-query'
import { AREAS_CATALOGUE } from '../api/areas-catalogue'
import { useClient } from './useClient'

export const useAreas = (rfc: string) => {
	const client = useClient()
	const fetchAreas = async () => {
		try {
			const result = await client.query({
				query: AREAS_CATALOGUE,
				variables: {
					companyRfc: rfc
				}
			})

			if (result.data.Areas) {
				return result.data.Areas
			}
			return []
		} catch (error) {
			console.error('Error fetching areas:', error)
			return []
		}
	}

	const { data: areas, isSuccess } = useQuery({
		queryKey: ['areas'],
		queryFn: fetchAreas,
		staleTime: Infinity
	})

	return { areas, isSuccess }
}
