import { PURCHASE, Roles, TableFillSelectionFilter } from '@netcurio/frontend-common'
import { t } from 'i18next'
import Constants from '../../../utilities/constants'
import { tableFillHeader } from '../../../utilities/tableFillHeader'
import styles from './purchaseOrderList.module.scss'

export function objectHeaderArray(userRoles: Roles[]): Array<tableFillHeader> {
	const purchaseOrderColumn: tableFillHeader = new tableFillHeader(
		t('internalIdentifier'),
		styles.headerPurchaseContainerList,
		'',
		'id',
		'text'
	)
	const typeOrderColumn: tableFillHeader = new tableFillHeader(
		t('type_PO'),
		styles.headerTypeContainerList,
		'',
		'type',
		'type'
	)
	const statusOrderColumn: tableFillHeader = new tableFillHeader(
		t('actualState'),
		styles.headerStatusContainerList,
		'',
		'status',
		'status'
	)
	const branchOrderColumn: tableFillHeader = new tableFillHeader(
		t('placeOfDelivery'),
		styles.headerOfficeContainerList,
		'',
		'branch_office',
		'text'
	)
	const supplierOrderColumn: tableFillHeader = new tableFillHeader(
		t('seller'),
		styles.headerSupplierContainerList,
		'',
		'supplier',
		'text'
	)
	const customerOrderColumn: tableFillHeader = new tableFillHeader(
		t('buyer'),
		styles.headerCustomerContainerList,
		'',
		'customer',
		'text'
	)
	const dateOrderColumn: tableFillHeader = new tableFillHeader(
		t('creationDate'),
		styles.headerDateContainerList,
		'',
		'created_at',
		'date'
	)
	const totalOrderColumnCustomer: tableFillHeader = new tableFillHeader(
		t('subtotalToPay'),
		styles.headerTotalContainerListCustomer,
		'',
		'total',
		'numeric'
	)
	const totalOrderColumnProvider: tableFillHeader = new tableFillHeader(
		t('subtotalToPay'),
		styles.headerTotalContainerListProvider,
		'',
		'total',
		'numeric'
	)
	const customerReferenceOrderColumn: tableFillHeader = new tableFillHeader(
		t('externalIdentifier'),
		styles.headerCustomerReferenceContainerList,
		'',
		'customer_reference',
		'text'
	)
	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		purchaseOrderColumn,
		customerReferenceOrderColumn,
		typeOrderColumn,
		statusOrderColumn,
		branchOrderColumn,
		supplierOrderColumn,
		dateOrderColumn,
		totalOrderColumnCustomer
	]
	const objectHeaderArraySupplier: Array<tableFillHeader> = [
		purchaseOrderColumn,
		customerReferenceOrderColumn,
		typeOrderColumn,
		statusOrderColumn,
		branchOrderColumn,
		customerOrderColumn,
		dateOrderColumn,
		totalOrderColumnProvider
	]

	if (userRoles.includes(Roles.CUSTOMER)) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}

export function objectStatusFilterArray(): Array<TableFillSelectionFilter> {
	const newStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		Constants.STATUS.NEW,
		styles.newStatusButton
	)
	const confirmedStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		Constants.STATUS.CONFIRMED,
		styles.confirmedStatusButton
	)
	const halfConfirmedStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		PURCHASE.HALF_CONFIRMED,
		styles.halfConfirmedStatusButton
	)
	const deliveredStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		Constants.STATUS.DELIVERED,
		styles.deliveredStatusButton
	)
	const halfDeliveredStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		PURCHASE.HALF_DELIVERED,
		styles.halfDeliveredStatusButton
	)
	const rejectedStatusOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		Constants.STATUS.REJECTED,
		styles.rejectStatusButton
	)

	return [
		newStatusOption,
		confirmedStatusOption,
		halfConfirmedStatusOption,
		deliveredStatusOption,
		halfDeliveredStatusOption,
		rejectedStatusOption
	]
}

export function objectTypeFilterArray(): Array<TableFillSelectionFilter> {
	const typeStandardOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.STANDARD,
		styles.standardTypeButton
	)
	const typeConsignmentOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT,
		styles.consignmentTypeButton
	)
	const typeExpressOption: TableFillSelectionFilter = new TableFillSelectionFilter(
		PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS,
		styles.expressTypeButton
	)

	return [typeStandardOption, typeConsignmentOption, typeExpressOption]
}
