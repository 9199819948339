export enum InvoiceStatusEnum {
	IN_PROCESS = 'in_process',
	IN_APPROVAL = 'in_approval',
	PAYMENT_SCHEDULED = 'payment_scheduled',
	PARTIALLY_PAID = 'partially_paid',
	PAID = 'paid',
	CANCELLED = 'cancelled',
	ERROR = 'error',
	MISSING_LINK = 'missing_link',
	VOIDED = 'voided'
}
