import gql from 'graphql-tag'

export const CREDIT_MEMO_DETAIL = gql`
	query CreditMemo($uuid: ID!) {
		CreditMemo(uuid: $uuid) {
			uuid
			status {
				key
				value
			}
			sender {
				name
				rfc
			}
			receiver {
				name
				rfc
			}
			uploaded_by
			serie
			folio
			payment_method
			currency
			subtotal
			iva
			total
			invoice {
				serie
				folio
				uuid
				purchase_order {
					id
					customer {
						rfc
					}
					customer_reference
				}
				consumption {
					id
					customer_reference
					customer {
						rfc
					}
				}
			}
			date
			created_at
			updated_at
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			type
			error
			modified_by_customer
			modified_by_supplier
			date_voided
			date_accounting
			invoice_reference
			related_invoices {
				source {
					uuid
				}
				destination {
					uuid
				}
				type
			}
			items {
				position
				concept
				quantity
				net_amount
				tax
				unit_price
				reference_position
				unit
				error
				code
				request_for_credit_memo_item {
					concept_description
					net_amount
					tax
				}
			}
			request_for_credit_memo {
				id
				customer {
					rfc
				}
				customer_reference
				items {
					position
					concept_description
					net_amount
				}
			}
			ieps
			isr
			sat_validation_message
		}
	}
`

export const CREATE_CREDIT_MEMO_COMMENT = gql`
	mutation createCreditMemoComment($creditMemo: ID!, $text: String!) {
		createCreditMemoComment(creditMemo: $creditMemo, text: $text) {
			code
			message
		}
	}
`

export const CREDIT_MEMO_COMMENTS = gql`
	query CreditMemo($uuid: ID!) {
		CreditMemo(uuid: $uuid) {
			uuid
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREDIT_MEMO_ASSOCIATION = gql`
	mutation associateCreditMemo($uuid: ID!, $RFCMId: ID!, $items: [CreditMemoAssociationItem!]!) {
		associateCreditMemo(uuid: $uuid, RFCMId: $RFCMId, items: $items) {
			code
			message
		}
	}
`

export const SEND_CREDIT_MEMO_RELATED_TO_ADVANCE = gql`
	mutation sendCreditMemoRelatedToAdvance($uuid: ID!) {
		sendCreditMemoRelatedToAdvance(uuid: $uuid) {
			code
			message
		}
	}
`

export const VOID_CREDIT_MEMO = gql`
	mutation voidCreditMemo($uuid: ID!) {
		voidCreditMemo(uuid: $uuid) {
			code
			message
		}
	}
`

export const REQUEST_FOR_CREDIT_MEMOS_FOR_CM = gql`
	query RequestForCreditMemosForCM(
		$limit: Int
		$sort_field: String
		$sort_order: String
		$search_text: String!
		$customer: ID!
	) {
		RequestForCreditMemosForCM(
			limit: $limit
			sort_field: $sort_field
			sort_order: $sort_order
			search_text: $search_text
			customer: $customer
		) {
			id
			customer_reference
			customer {
				rfc
			}
			items {
				position
				concept_description
				net_amount
			}
		}
	}
`

export const REMOVE_ASSOCIATION = gql`
	mutation removeCreditMemoAssociation($uuid: ID!) {
		removeCreditMemoAssociation(uuid: $uuid) {
			code
			message
		}
	}
`

export const CANCEL_CREDIT_MEMO = gql`
	mutation cancelCreditMemo($uuid: ID!) {
		cancelCreditMemo(uuid: $uuid) {
			code
			message
		}
	}
`
