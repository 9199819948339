import { NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import buttonStyles from '../../common/styles/button.module.scss'

export const AddButton = ({
	onClick,
	translationKey,
	disabled = false
}: {
	onClick: () => void
	translationKey: string
	disabled?: boolean
}) => {
	const { t } = useTranslation()

	return (
		<NetcurioButton
			variant="outlined"
			className={buttonStyles.button}
			color="primary"
			size="small"
			endIcon={<NetcurioIcons.Add />}
			onClick={onClick}
			disabled={disabled}
		>
			<span>{t(translationKey)}</span>
		</NetcurioButton>
	)
}
