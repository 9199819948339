import Button, { ButtonProps } from '@mui/material/Button'
import { ThemeOptions } from '@mui/material/styles'
import {
	ColorBaseWhite,
	MdRefTypefaceFont,
	MdSysLabelMediumLineHeight,
	MdSysLabelMediumSize,
	Netcurio01PrimaryAMain,
	Netcurio02ButtonCSmallCenteredCPrimaryText
} from '@netcurio/frontend-design-tokens'
import { forwardRef } from 'react'

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		gradient: true
		borderless: true
	}
}

export const NetcurioButtonThemeOptions: ThemeOptions = {
	typography: {
		button: {
			textTransform: 'none',
			fontFamily: MdRefTypefaceFont,
			lineHeight: MdSysLabelMediumLineHeight
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					fontSize: Netcurio02ButtonCSmallCenteredCPrimaryText.fontSize,
					fontWeight: Netcurio02ButtonCSmallCenteredCPrimaryText.fontWeight,
					letterSpacing: Netcurio02ButtonCSmallCenteredCPrimaryText.letterSpacing,
					maxHeight: '3rem'
				},
				text: {
					fontSize: Netcurio02ButtonCSmallCenteredCPrimaryText.fontSize,
					fontWeight: Netcurio02ButtonCSmallCenteredCPrimaryText.fontWeight,
					letterSpacing: Netcurio02ButtonCSmallCenteredCPrimaryText.letterSpacing,
					maxHeight: '3rem'
				},
				outlined: {
					fontSize: Netcurio02ButtonCSmallCenteredCPrimaryText.fontSize,
					fontWeight: Netcurio02ButtonCSmallCenteredCPrimaryText.fontWeight,
					letterSpacing: Netcurio02ButtonCSmallCenteredCPrimaryText.letterSpacing,
					maxHeight: '3rem'
				}
			},
			variants: [
				{
					props: { variant: 'gradient' },
					style: {
						background: 'linear-gradient(to right, #7C80EF, #494FE9)', //Does not evaluate as a design token
						color: ColorBaseWhite,
						fontSize: MdSysLabelMediumSize
					}
				},
				{
					props: { variant: 'borderless' },
					style: {
						background: 'transparent',
						color: Netcurio01PrimaryAMain,
						border: 'none',
						borderColor: 'transparent',
						fontSize: '1.3rem',
						fontWeight: 500
					}
				}
			]
		}
	}
}

export const NetcurioButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const { children, variant, ...rest } = props

	return (
		<Button ref={ref} variant={variant} {...rest}>
			{children}
		</Button>
	)
})

NetcurioButton.displayName = 'NetcurioButton'
