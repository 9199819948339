import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles'
import {
	MdRefTypefaceFont,
	Netcurio01PrimaryAMain,
	Netcurio01PrimaryBDark,
	Netcurio01PrimaryCLight,
	Netcurio01PrimaryDContrast,
	Netcurio02SecondaryAMain,
	Netcurio02SecondaryBDark,
	Netcurio02SecondaryCLight,
	Netcurio02SecondaryDContrast,
	Netcurio03TextADarkAHighEmphasisTextPrimary,
	Netcurio03TextADarkBMediumEmphasisTextSecondary,
	Netcurio03TextADarkCLowEmphasisDisabled,
	Netcurio04ActionAActive,
	Netcurio04ActionBHover,
	Netcurio04ActionCDisabled,
	Netcurio04ActionDSelected,
	Netcurio04ActionEFocus,
	Netcurio04ActionFDisabledBackground,
	Netcurio05ErrorAMain,
	Netcurio05ErrorBDark,
	Netcurio05ErrorCLight,
	Netcurio05ErrorEContrast,
	Netcurio06WarningAMain,
	Netcurio06WarningBDark,
	Netcurio06WarningCLight,
	Netcurio06WarningEContrast,
	Netcurio07InfoAMain,
	Netcurio07InfoBDark,
	Netcurio07InfoCLight,
	Netcurio07InfoEContrast,
	Netcurio08SuccessAMain,
	Netcurio08SuccessBDark,
	Netcurio08SuccessCLight,
	Netcurio08SuccessEContrast,
	Netcurio10BackgroundDefault,
	Netcurio10BackgroundPaper,
	Netcurio11Grey300,
	Netcurio11Grey40,
	Netcurio11Grey400,
	Netcurio11Grey600
} from '@netcurio/frontend-design-tokens'
import { ReactNode } from 'react'
import { NetcurioAlertThemeOptions } from '../NetcurioAlert/NetcurioAlert'
import { NetcurioAutocompleteThemeOptions } from '../NetcurioAutocomplete/NetcurioAutocomplete'
import { NetcurioButtonThemeOptions } from '../NetcurioButton/NetcurioButton'
import { NetcurioCheckboxThemeOptions } from '../NetcurioCheckbox/NetcurioCheckbox'
import { NetcurioChipThemeOptions } from '../NetcurioChip/NetcurioChip'
import { NetcurioDatePickerThemeOptions } from '../NetcurioDatePicker/NetcurioDatePicker'
import { NetcurioGridThemeOptions } from '../NetcurioGrid/NetcurioGrid'
import { NetcurioIconsThemeOptions } from '../NetcurioIcons/NetcurioIcons'
import { NetcurioNumericFieldThemeOptions } from '../NetcurioNumericField/NetcurioNumericField'
import { NetcurioSelectThemeOptions } from '../NetcurioSelect/NetcurioSelect'
import { NetcurioStepperThemeOptions } from '../NetcurioStepper/NetcurioStepper'
import { NetcurioTableThemeOptions } from '../NetcurioTable/NetcurioTable'
import { NetcurioTextFieldThemeOptions } from '../NetcurioTextField/NetcurioTextField'
import { NetcurioTooltipThemeOptions } from '../NetcurioTooltip/NetcurioTooltip'

export const PaletteThemeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: Netcurio01PrimaryAMain,
			light: Netcurio01PrimaryCLight,
			dark: Netcurio01PrimaryBDark,
			contrastText: Netcurio01PrimaryDContrast
		},
		secondary: {
			main: Netcurio02SecondaryAMain,
			light: Netcurio02SecondaryCLight,
			dark: Netcurio02SecondaryBDark,
			contrastText: Netcurio02SecondaryDContrast
		},
		text: {
			primary: Netcurio03TextADarkAHighEmphasisTextPrimary,
			secondary: Netcurio03TextADarkBMediumEmphasisTextSecondary,
			disabled: Netcurio03TextADarkCLowEmphasisDisabled
		},
		action: {
			active: Netcurio04ActionAActive,
			hover: Netcurio04ActionBHover,
			disabled: Netcurio04ActionCDisabled,
			selected: Netcurio04ActionDSelected,
			focus: Netcurio04ActionEFocus,
			disabledBackground: Netcurio04ActionFDisabledBackground
		},
		error: {
			main: Netcurio05ErrorAMain,
			light: Netcurio05ErrorCLight,
			dark: Netcurio05ErrorBDark,
			contrastText: Netcurio05ErrorEContrast
		},
		success: {
			main: Netcurio08SuccessAMain,
			light: Netcurio08SuccessCLight,
			dark: Netcurio08SuccessBDark,
			contrastText: Netcurio08SuccessEContrast
		},
		warning: {
			main: Netcurio06WarningAMain,
			light: Netcurio06WarningCLight,
			dark: Netcurio06WarningBDark,
			contrastText: Netcurio06WarningEContrast
		},
		info: {
			main: Netcurio07InfoAMain,
			dark: Netcurio07InfoBDark,
			light: Netcurio07InfoCLight,
			contrastText: Netcurio07InfoEContrast
		},
		background: {
			default: Netcurio10BackgroundDefault,
			paper: Netcurio10BackgroundPaper
		},
		grey: {
			50: Netcurio11Grey40,
			300: Netcurio11Grey300,
			400: Netcurio11Grey400,
			600: Netcurio11Grey600
		}
	},
	typography: {
		fontSize: 10,
		fontFamily: MdRefTypefaceFont
	},
	spacing: (factor: number) => `${0.25 * factor}rem` // (Bootstrap strategy)
}

const theme = createTheme(
	PaletteThemeOptions,
	NetcurioChipThemeOptions,
	NetcurioGridThemeOptions,
	NetcurioSelectThemeOptions,
	NetcurioButtonThemeOptions,
	NetcurioTextFieldThemeOptions,
	NetcurioAutocompleteThemeOptions,
	NetcurioNumericFieldThemeOptions,
	NetcurioTooltipThemeOptions,
	NetcurioStepperThemeOptions,
	NetcurioTableThemeOptions,
	NetcurioCheckboxThemeOptions,
	NetcurioAlertThemeOptions,
	NetcurioDatePickerThemeOptions,
	NetcurioIconsThemeOptions
)

interface NetcurioThemeProps {
	children: ReactNode
}

export const NetcurioTheme = ({ children }: NetcurioThemeProps) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
