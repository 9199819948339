import { URLS } from '@netcurio/frontend-common'
import { WorkflowSelector } from '@netcurio/frontend-components'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ErrorModal } from '../../../components/totalContainerBar/Modals/ErrorModal/ErrorModal'
import { RemoveAssociation } from '../../../components/totalContainerBar/Modals/RemoveAssociation/RemoveAssociation'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { CreditMemo } from '../../../types'
import constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import { WorkflowChangeModal } from '../Modals/WorkflowChangeModal'
import { AutoCompleteOption, WorkflowValueEnum } from '../types'
import { useDocumentAssociation } from './useDocumentAssociation'
import styles from './WorkflowSection.module.scss'

interface WorkflowSectionProps {
	onAssociateDocument: (rfcmId: string | undefined, items: unknown) => void
	onRefreshDocumentData: () => void
	workflow: WorkflowValueEnum
	setWorkflow: (workFlowValue: WorkflowValueEnum) => void
	creditMemo: CreditMemo | null
	rfcmReference: string | undefined
}

const LABEL_ID = 'credit-memo-workflow-select-label'

export const WorkflowSection = ({
	onAssociateDocument,
	onRefreshDocumentData,
	workflow,
	setWorkflow,
	creditMemo,
	rfcmReference
}: WorkflowSectionProps): React.ReactElement => {
	const { t } = useTranslation()
	const history = useHistory()
	const { isUserCustomer } = useUserRoles()
	const [showChangeWorkflowModal, setShowChangeWorkflowModal] = useState(false)
	const isMissingLinkStatus = constants.CREDIT_MEMO_STATUS.MISSING_LINK === (creditMemo?.status?.key ?? '')
	const disableWorkflow = isMissingLinkStatus && isUserCustomer
	const {
		setSearchTerm,
		loading,
		documentSuggestions,
		currentAssociation,
		handleSelectOption,
		isRemoveAssociationModalOpen,
		handleCloseRemoveAssociationModal,
		handleRemoveAssociation,
		isErrorModalOpen,
		errorCode,
		setIsRemoveAssociationModalOpen,
		workFlowOptions,
		searchTerm
	} = useDocumentAssociation({
		creditMemo,
		onAssociateDocument,
		onRefreshDocumentData
	})

	useEffect(() => {
		if (!creditMemo) return
		const hasRequestId = !!creditMemo?.request_for_credit_memo?.id
		const hasInvoiceRef = !!creditMemo?.invoice_reference
		const isErrorStatus = creditMemo?.status.key === constants.CREDIT_MEMO_STATUS.ERROR
		if (hasRequestId) {
			setWorkflow(WorkflowValueEnum.OtherCreditMemo)
		} else if (!isMissingLinkStatus && !isErrorStatus) {
			setWorkflow(WorkflowValueEnum.AdvanceDiscount)
		} else if (isMissingLinkStatus && !isErrorStatus && hasInvoiceRef) {
			setWorkflow(WorkflowValueEnum.OtherCreditMemo)
		} else if (!isMissingLinkStatus && isErrorStatus && workflow !== WorkflowValueEnum.OtherCreditMemo) {
			setWorkflow(WorkflowValueEnum.AdvanceDiscount)
		}
	}, [creditMemo, isMissingLinkStatus])

	const handleCreditMemoWorkFlowChange = (workflowValue: WorkflowValueEnum) => {
		if (
			rfcmReference &&
			workflow === WorkflowValueEnum.OtherCreditMemo &&
			workflowValue === WorkflowValueEnum.AdvanceDiscount
		) {
			setShowChangeWorkflowModal(true)
		} else {
			setWorkflow(workflowValue)
		}
	}

	return (
		<div className={styles.container}>
			<WorkflowSelector
				readonly={
					isUserCustomer ||
					[constants.CREDIT_MEMO_STATUS.ACCOUNTED, constants.CREDIT_MEMO_STATUS.CANCELED].includes(
						creditMemo?.status?.key ?? ''
					) ||
					(constants.CREDIT_MEMO_STATUS.VOIDED.includes(creditMemo?.status?.key ?? '') &&
						!currentAssociation)
				}
				disabled={disableWorkflow}
				title={t('workFlow')}
				selectProps={{
					label: t('creditMemos.documentType'),
					placeholder: t('creditMemos.selectDocumentFlow'),
					id: LABEL_ID,
					onChange: (selected: WorkflowValueEnum) => {
						handleCreditMemoWorkFlowChange(selected)
					},
					value: workflow,
					options: workFlowOptions
				}}
				showAutocomplete={workflow === WorkflowValueEnum.OtherCreditMemo}
				autoCompleteProps={{
					label: t('creditMemos.creditNoteRequest'),
					placeholder: t('creditMemos.associateCreditNoteRequest'),
					value: currentAssociation ?? undefined,
					inputValue: searchTerm,
					workFlowOptions: workFlowOptions,
					loading: loading,
					suggestions: documentSuggestions,
					onInputValueChange: (event: string) => {
						if (event === '' && (currentAssociation || creditMemo?.request_for_credit_memo?.id)) {
							setIsRemoveAssociationModalOpen(true)
						}
						setSearchTerm(event)
					},
					onSelectValue: (selected) => handleSelectOption(selected as AutoCompleteOption),
					getOptionLabel: (option: AutoCompleteOption) =>
						`${Formatter.id(option.id)} - ${t('NAtext')}`,
					isOptionEqualToValue: (option: AutoCompleteOption, value: AutoCompleteOption) =>
						option.id === value.id
				}}
				linkedDocument={{
					label: currentAssociation
						? currentAssociation.id
						: creditMemo?.request_for_credit_memo?.id,
					onClick: () => {
						history.push(
							`${URLS.RFCM_DETAIL}?requestforcreditmemo=${creditMemo?.request_for_credit_memo?.id}&customer=${creditMemo?.receiver?.rfc}`
						)
					}
				}}
			/>
			<RemoveAssociation
				open={isRemoveAssociationModalOpen}
				onClose={handleCloseRemoveAssociationModal}
				onAccept={handleRemoveAssociation}
			/>
			<WorkflowChangeModal
				open={showChangeWorkflowModal}
				onClose={() => setShowChangeWorkflowModal(false)}
				onAccept={() => {
					handleRemoveAssociation()
					setWorkflow(WorkflowValueEnum.AdvanceDiscount)
					setShowChangeWorkflowModal(false)
				}}
			/>
			<ErrorModal open={isErrorModalOpen} errorCode={errorCode} />
		</div>
	)
}
