import { Status } from '@netcurio/frontend-common'
import { NetcurioAlert, NetcurioButton, NetcurioGrid, Severity } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { useUserRoles } from '../../../../hooks/useUserRoles'
import { Invoice } from '../../../../types'
import { Validations } from '../../../../types/Validations'
import constants from '../../../../utilities/constants'
import { getErrorDetail } from '../../helpers/get-status-detail'
import { themeButtonReview } from '../utilities/defaultValues'
import styles from './InvoiceAlert.module.scss'

interface InvoiceAlertProps {
	invoice: Invoice
	isStandaloneDocument: boolean
	executeValidation: () => void
	validations: Validations
	isAdmin: boolean
	isRelatedToAdvance?: boolean
}

export const InvoiceAlert = ({
	invoice,
	isStandaloneDocument,
	executeValidation,
	validations,
	isAdmin,
	isRelatedToAdvance
}: InvoiceAlertProps) => {
	const notAllowedStatusForManualAuthorization = ['EXTERNAL_ERROR', 'POSTING_ERROR']
	const isMissingLinkStatus = invoice.status.key === constants.STATUS.MISSING_LINK
	const isInApprovalStatus = invoice.status.key === constants.STATUS.IN_APPROVAL
	const isCanceledStatus = invoice.status.key === constants.STATUS.CANCELED
	const isErrorStatus = invoice.status.key === constants.STATUS.ERROR
	const isExceptionStatus = invoice.status.key === constants.STATUS.EXCEPTION

	const { isUserSupplier } = useUserRoles()

	const getReviewMessage = () => {
		if (isStandaloneDocument) {
			return t('authorizeManualInvoice.reviewErrors')
		}
		if (
			[
				constants.STATUS.PAYMENT_SCHEDULED,
				constants.STATUS.PARTIALLY_PAID,
				constants.STATUS.PAID
			].includes(invoice.status.key)
		) {
			return t('authorizeManualInvoice.reviewErrors')
		}
		if (validations?.amountValidation?.value === 'NOT_OK' && (invoice.advance || isRelatedToAdvance)) {
			return invoice.advance ? t('invoices.advanceAmountError') : t('invoices.discountError')
		}
		if (validations?.relationValidation?.value === 'NOT_OK') {
			const message = invoice?.relation_validation_message
            if (message) {
                return message
            } else {
                return t('authorizeManualInvoice.reviewErrors')
            }
		}
		return t('authorizeManualInvoice.reviewErrors')
	}

    const errorKey = invoice?.error?.key ?? ''

	const isAllowedToReview =
		Boolean(invoice.reference_type) &&
		!isUserSupplier &&
		isAdmin &&
		!notAllowedStatusForManualAuthorization.includes(errorKey)

	if (isMissingLinkStatus) {
		if (!isUserSupplier) return null

		return (
			<NetcurioGrid className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Info}>{t('showMessagePendingAssociate')}</NetcurioAlert>
			</NetcurioGrid>
		)
	}

	if (isInApprovalStatus) {
		const message = isUserSupplier
			? t('invoices.awaitingClientApproval')
			: t('invoices.authorizeOrCancelInvoice')

		return (
			<NetcurioGrid className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Info}>{message}</NetcurioAlert>
			</NetcurioGrid>
		)
	}

	if (isCanceledStatus) {
		return (
			<NetcurioGrid className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Warning}>{invoice.cancellation_reason}</NetcurioAlert>
			</NetcurioGrid>
		)
	}

	if (isErrorStatus || isExceptionStatus) {
		const severity = isExceptionStatus ? Severity.Warning : Severity.Error

		if (isAllowedToReview) {
			return (
				<NetcurioGrid className={styles.msgContainer}>
					<NetcurioAlert severity={severity} messagePadding="0">
						<div className={styles.alertWithActionButtonContainer}>
							<div>{getReviewMessage()}</div>
							<NetcurioButton onClick={() => executeValidation()} sx={themeButtonReview}>
								{t('review')}
							</NetcurioButton>
						</div>
					</NetcurioAlert>
				</NetcurioGrid>
			)
		}

		const errorDetail = getErrorDetail(
			(invoice.error as Status)?.key,
			invoice?.sat_validation_message ?? ''
		)

		if (errorDetail) {
			return (
				<NetcurioGrid className={styles.msgContainer}>
					<NetcurioAlert severity={severity}>
						{errorDetail.title && <b>{errorDetail.title}</b>}
						<div>
							<Trans>{errorDetail.description}</Trans>
						</div>
					</NetcurioAlert>
				</NetcurioGrid>
			)
		}
	}

	return null
}
