import { dateFormatter, getCurrentUser, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { NetcurioListHeader } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAreas } from '../../../../hooks/useAreas'
import { useCurrencies } from '../../../../hooks/useCurrencies'
import { usePurchaseRequisitionTypes } from '../../../../hooks/usePurchaseRequisitionTypes'
import { Area, PurchaseRequisitionType } from '../../../../types'
import symbols from '../../../../utilities/constants/symbols'
import formatter from '../../../../utilities/formatter'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'
import { PurchaseRequisitionStatusChip } from '../components/PurchaseRequisitionStatusChip'

const user = getCurrentUser()

export const usePurchaseRequisitionHeaders = (): NetcurioListHeader<PurchaseRequisitionInterface>[] => {
	const { t } = useTranslation()
	const { currencies } = useCurrencies()
	const { areas } = useAreas(user?.company?.rfc ?? '')
	const { purchaseRequisitionTypes } = usePurchaseRequisitionTypes(user?.company?.rfc ?? '')

	return [
		{
			title: t('purchaseRequisitions.headers.id'),
			tooltip: t('purchaseRequisitions.headers.id'),
			key: 'id',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.type'),
			tooltip: t('purchaseRequisitions.headers.type'),
			key: 'typeId',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				purchaseRequisition?.type ? purchaseRequisition.type.description : symbols.HYPHEN,
			filter: {
				type: 'options',
				options: purchaseRequisitionTypes?.map((type: PurchaseRequisitionType) => ({
					label: type.description,
					value: type.id
				}))
			}
		},
		{
			title: t('purchaseRequisitions.headers.status'),
			tooltip: t('purchaseRequisitions.headers.status'),
			key: 'status',
			render: (purchaseRequisition: PurchaseRequisitionInterface) => (
				<PurchaseRequisitionStatusChip purchaseRequisition={purchaseRequisition} />
			),
			filter: {
				type: 'options',
				options: [
					{
						label: t('purchaseRequisitions.status.NEW'),
						value: 'NEW'
					},
					{
						label: t('purchaseRequisitions.status.CANCELED'),
						value: 'CANCELED'
					},
					{
						label: t('purchaseRequisitions.status.APPROVED'),
						value: 'APPROVED'
					},
					{
						label: t('purchaseRequisitions.status.REJECTED'),
						value: 'REJECTED'
					},
					{
						label: t('purchaseRequisitions.status.PENDING'),
						value: 'PENDING'
					},
					{
						label: t('purchaseRequisitions.status.UNDER_REVIEW'),
						value: 'UNDER_REVIEW'
					}
				]
			}
		},
		{
			title: t('purchaseRequisitions.headers.description'),
			tooltip: t('purchaseRequisitions.headers.description'),
			key: 'description',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.requester'),
			tooltip: t('purchaseRequisitions.headers.requester'),
			key: 'requester',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				purchaseRequisition?.requester
					? `${purchaseRequisition.requester?.name} ${purchaseRequisition.requester?.lastname}`
					: symbols.HYPHEN,
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.area'),
			tooltip: t('purchaseRequisitions.headers.area'),
			key: 'areaId',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				purchaseRequisition?.area ? purchaseRequisition.area.description : symbols.HYPHEN,
			filter: {
				type: 'options',
				options: areas?.map((area: Area) => ({
					label: area.description,
					value: area.id
				}))
			}
		},
		{
			title: t('purchaseRequisitions.headers.currentAuthorizer'),
			tooltip: t('purchaseRequisitions.headers.currentAuthorizer'),
			key: 'currentApprover',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				purchaseRequisition?.currentApprover
					? `${purchaseRequisition.currentApprover?.name} ${purchaseRequisition.currentApprover?.lastname}`
					: symbols.HYPHEN
		},
		{
			title: t('purchaseRequisitions.headers.createdAt'),
			tooltip: t('purchaseRequisitions.headers.createdAt'),
			key: 'createdAt',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				purchaseRequisition.createdAt
					? dateFormatter.format(parseTimestampToTimeZone(purchaseRequisition.createdAt))
					: symbols.HYPHEN,
			filter: {
				type: 'dateRange'
			}
		},
		{
			title: t('purchaseRequisitions.headers.total'),
			tooltip: t('purchaseRequisitions.headers.total'),
			key: 'total',
			formatter: (purchaseRequisition: PurchaseRequisitionInterface) =>
				formatter.currency.format(Number(purchaseRequisition?.total ?? 0)),
			contentAlign: 'right'
		},
		{
			title: t('purchaseRequisitions.headers.currency'),
			tooltip: t('purchaseRequisitions.headers.currency'),
			key: 'currency',
			filter: {
				type: 'options',
				options: currencies.map((currency) => ({
					label: currency.code,
					value: currency.code
				}))
			}
		}
	]
}
