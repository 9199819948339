import { PurchaseOrder } from '../../../types'

export const defaultValuesPurchaseOrder: PurchaseOrder = {
	attachment: '',
	items: [],
	type: { key: '', value: '' },
	customer: { rfc: '', name: '' },
	status: { key: '', value: '' },
	id: '',
	created_at: new Date(),
	updated_at: new Date(),
	customer_reference: '',
	user_reference: '',
	delivery_address_postal_code: '',
	delivery_address_country: '',
	delivery_address_state: '',
	delivery_address_line_1: '',
	delivery_address_line_2: '',
	branch_office: '',
	supplier: {},
	comments: [],
	billed: false,
	branch_office_id: '',
	created_by: '',
	currency: '',
	date_confirmed: undefined,
	date_last_delivery: undefined,
	date_rejected: undefined,
	delivery_address_country_code: '',
	delivery_address_state_code: '',
	modified_by: '',
	quotation: '',
	rejected_reason: '',
	release_date: undefined,
	total: 0,
	goodsReceipts: [],
	invoices: []
}
