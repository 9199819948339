import {
	IBranchOffices,
	ISupplier,
	ItemRFQ,
	MeasurementUnits,
	ModalRFQ,
	NewRFQType,
	ProductRFQ,
	Roles,
	URLS,
	ValidateItems,
	ValuesRFQ,
	getCurrentUser
} from '@netcurio/frontend-common'
import {
	CommentSectionVariant,
	CommentsSection,
	NetcurioButton,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import dayjs, { Dayjs } from 'dayjs'
import { t as i18nT } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ErrorModal } from '../../components/dialogModal/errorModal'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import GlobalQueries from '../../components/queries'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import { redirectByRoles } from '../../utilities/validateUrlRoles'
import HeaderInformation from './headerInformation/headerInformationTs'
import ItemsList from './itemList/itemsList'
import { CreateNewRfqConfirmationModal } from './modals/CreateNewRfqConfirmationModal'
import styles from './newRFQ.module.scss'
import { CancelNewRFQModal } from './newRFQModal/CancelNewRFQModal'
import { CREATE_RFQ } from './queries'

export const NewRFQ = (): JSX.Element => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

	const currentUser = getCurrentUser()
	const [newRFQ, setNewRFQ] = useState<NewRFQType>({
		itemDataList: [],
		dataSupplier: {
			supplier: '',
			supplier_rfc: ''
		},
		branch_office_id: '',
		branch_office: '',
		delivery_address_line_1: '',
		delivery_address_line_2: '',
		delivery_address_state_code: '',
		delivery_address_state: '',
		delivery_address_postal_code: '',
		delivery_address_country_code: '',
		delivery_address_country: '',
		expiredDate: undefined
	})
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [validateItemsDataList, setValidateItemsDataList] = useState<ValidateItems[]>([])
	const [changeSupplier, setChangeSupplier] = useState<boolean>(true)
	const [notFilledMessage, setNotFilledMessage] = useState<boolean>(false)
	const [saveAvailable, setSaveAvailable] = useState<boolean>(false)
	const [fillSupplier, setFillSupplier] = useState<boolean>(false)
	const [stylesErrorInputBranchOffice, setStylesErrorInputBranchOffice] = useState(false)
	const [stylesErrorInputSupplier, setStylesErrorInputSupplier] = useState(false)
	const [allMeasurementUnits, setAllMeasurementUnits] = useState<MeasurementUnits[]>([])
	const [fillDataBranch, setFillDataBranch] = useState<boolean>()
	const [commentSend, setCommentSend] = useState<{ text: string | undefined }>({
		text: undefined
	})

	const [isConfirmationSendModalOpen, setIsConfirmationSendModalOpen] = useState(false)
	const [isChangeSupplierModalOpen, setIsChangeSupplierModalOpen] = useState(false)

	const [stateModal, setStateModal] = useState<ModalRFQ>({
		errorCode: undefined
	})
	const [isCancelNewRFQModalOpen, setIsCancelNewRFQModalOpen] = useState(false)
	const history = useHistory()

	redirectByRoles(URLS.RFQ, Roles.SUPPLIER)
	beforeUnloadListener('add')

	useEffect(() => {
		getAllMeasurementUnits()
	}, [])

	useEffect(() => {
		showModal()
	}, [stateModal])

	function showModal() {
		let element = <div></div>
		if (stateModal.errorCode) {
			element = (
				<ErrorModal
					fillModal={errorModal(stateModal.errorCode, '')}
					hideModal={() => {
						setStateModal({
							errorCode: undefined,
							fillModalSaveData: false,
							fillModalChangeSupplier: false
						})
						setModal(undefined)
					}}
					errorCode={`${stateModal.errorCode}`}
				/>
			)
		}
		setModal(element)
	}

	function acceptActionModal(action: string) {
		if (action === 'createNewRFQ') {
			const sendItems = []
			showLoadingSpinner()
			for (const key in newRFQ.itemDataList) {
				const requiredDate: Dayjs | undefined = newRFQ.itemDataList[key].requiredDate
				const unitCode = newRFQ.itemDataList[key].unit?.code
				const objectItems = {
					position: parseInt(key) + 1,
					product_description: newRFQ.itemDataList[key].product?.description,
					product_code: newRFQ.itemDataList?.[key]?.product?.id,
					quantity: parseFloat(newRFQ.itemDataList[key].amount),
					unit: unitCode,
					required_delivery_date: requiredDate?.format('YYYY-MM-DD')
				}
				sendItems.push(objectItems)
			}
			client
				.mutate({
					mutation: CREATE_RFQ,
					variables: {
						branch_office_id: newRFQ.branch_office_id,
						branch_office: newRFQ.branch_office,
						supplier: newRFQ.dataSupplier.supplier_rfc,
						delivery_address_line_1: newRFQ.delivery_address_line_1,
						delivery_address_line_2: newRFQ.delivery_address_line_2,
						delivery_address_state_code: newRFQ.delivery_address_state_code,
						delivery_address_state: newRFQ.delivery_address_state,
						delivery_address_postal_code: newRFQ.delivery_address_postal_code,
						delivery_address_country_code: newRFQ.delivery_address_country_code,
						delivery_address_country: newRFQ.delivery_address_country,
						expiration_date: newRFQ.expiredDate
							? dayjs(newRFQ.expiredDate).format('YYYY-MM-DD')
							: '',
						items: sendItems,
						comment: commentSend.text ? commentSend : undefined
					}
				})
				.then((result) => {
					const rfqId = result.data.createRequestForQuotation
					location.href =
						URLS.RFQ_DETAIL + '?rfq=' + rfqId + '&customer=' + currentUser?.company?.rfc
					hideLoadingSpinner()
					setModal(undefined)
				})
				.catch(handleError)
		} else if (action === 'cleanList') {
			const newRFQtmp = { ...newRFQ }
			newRFQtmp.itemDataList = [
				{
					product: undefined,
					unit: undefined,
					amount: '',
					requiredDate: undefined
				}
			]

			setNotFilledMessage(false)
			setNewRFQ(newRFQtmp)
			setIsChangeSupplierModalOpen(false)
			setModal(undefined)
			setSaveAvailable(false)
		}
	}

	function closeCancelNewRFQModal() {
		setIsCancelNewRFQModalOpen(false)
	}

	function cancelNewRFQ() {
		setIsCancelNewRFQModalOpen(false)
		history.push(URLS.RFQ)
	}

	function getAllMeasurementUnits() {
		showLoadingSpinner()
		client
			.query({
				query: GlobalQueries.MEASUREMENT_UNITS
			})
			.then((result) => {
				const allMeasurementUnits = []
				const items = result.data.MeasurementUnits
				for (const item of items) {
					const itemTemp = {
						description: i18nT(item.code),
						code: item.code
					}
					allMeasurementUnits.push(itemTemp)
				}
				setAllMeasurementUnits(allMeasurementUnits)
				hideLoadingSpinner()
			})
			.catch(handleError)
	}

	function handleError(error: Error) {
		console.error(error)
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setStateModal({
				...stateModal,
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
	}
	const setBranchOffice = useCallback((selectedBranchOffice: IBranchOffices | null) => {
		setNewRFQ((state) => {
			if (selectedBranchOffice !== null) {
				state.branch_office_id = selectedBranchOffice.id
				state.branch_office = selectedBranchOffice.description
				state.delivery_address_line_1 = selectedBranchOffice.address_line_1
				state.delivery_address_line_2 = selectedBranchOffice.address_line_2
				state.delivery_address_state_code = selectedBranchOffice.state_code
				state.delivery_address_state = selectedBranchOffice.state_description
				state.delivery_address_country_code = selectedBranchOffice.country_code
				state.delivery_address_country = selectedBranchOffice.country_description
				state.delivery_address_postal_code = selectedBranchOffice.postal_code
			} else {
				state.branch_office_id = undefined
				state.branch_office = undefined
				state.delivery_address_line_1 = undefined
				state.delivery_address_line_2 = undefined
				state.delivery_address_state_code = undefined
				state.delivery_address_state = undefined
				state.delivery_address_postal_code = undefined
				state.delivery_address_country_code = undefined
				state.delivery_address_country = undefined
			}
			return { ...state }
		})
		setFillDataBranch(false)
		setStylesErrorInputBranchOffice(false)
	}, [])

	const cleanItemList = useCallback(() => {
		if (
			newRFQ.itemDataList.length > 1 ||
			(newRFQ.itemDataList.length === 1 && newRFQ.itemDataList[0].product !== undefined) ||
			newRFQ.itemDataList[0].unit !== undefined
		) {
			setIsChangeSupplierModalOpen(true)
		}
	}, [newRFQ.itemDataList])

	function resetChangeSupplier() {
		setChangeSupplier(true)
	}

	const setSupplier = useCallback(
		(selectedSupplier: ISupplier | null) => {
			setNewRFQ((state) => {
				if (selectedSupplier !== null) {
					if (state.dataSupplier.supplier !== selectedSupplier.name) {
						state.dataSupplier.supplier = selectedSupplier.name
						state.dataSupplier.supplier_rfc = selectedSupplier.rfc
						if (changeSupplier) {
							cleanItemList()
						} else resetChangeSupplier()
					}
					setFillSupplier(false)
					setStylesErrorInputSupplier(false)
				} else {
					state.dataSupplier.supplier = undefined
					state.dataSupplier.supplier_rfc = undefined
					cleanItemList()
				}
				return { ...state }
			})
		},
		[changeSupplier, cleanItemList]
	)

	function setExpiredDate(date: Dayjs) {
		const newRFQtmp = { ...newRFQ }
		if (date === undefined) newRFQtmp.expiredDate = undefined
		else newRFQtmp.expiredDate = date
		setNewRFQ(newRFQtmp)
	}

	function addItem() {
		const newRFQtmp = { ...newRFQ }
		const items = newRFQ.itemDataList
		const item: ItemRFQ = {
			amount: '',
			product: undefined,
			requiredDate: undefined,
			unit: undefined
		}
		const validateItems = {
			validateProduct: '',
			validateAmount: false,
			validateUnit: '',
			validateRequiredDate: ''
		}
		if (items.length < 1 || checkMissingFields()) {
			items.push(item)
			validateItemsDataList.push(validateItems)
		}
		newRFQtmp.itemDataList = items
		setNewRFQ(newRFQtmp)
	}

	function addMessage() {
		setNotFilledMessage(true)
	}

	function checkMissingFields(): boolean {
		const items = newRFQ.itemDataList
		let missingFiled = false
		const validateItems = validateItemsDataList
		items.forEach(
			(
				item: {
					product: ProductRFQ
					amount: string
					unit: MeasurementUnits
					requiredDate: Dayjs
				},
				index: number
			) => {
				if (item.product === undefined) {
					validateItems[index] = {
						...validateItems[index],
						validateProduct: 'setRequiredData'
					}
					if (!missingFiled) {
						missingFiled = true
					}
				} else {
					validateItems[index] = {
						...validateItems[index],
						validateProduct: ''
					}
				}
				if (item.amount === '' || parseFloat(item.amount) === 0) {
					validateItems[index] = {
						...validateItems[index],
						validateAmount: true
					}

					if (!missingFiled) {
						missingFiled = true
					}
				} else {
					validateItems[index] = {
						...validateItems[index],
						validateAmount: false
					}
				}

				if (item.unit === undefined || '') {
					validateItems[index] = {
						...validateItems[index],
						validateUnit: 'setRequiredData'
					}
					if (!missingFiled) {
						missingFiled = true
					}
				} else {
					validateItems[index] = {
						...validateItems[index],
						validateUnit: ''
					}
				}

				if (item.requiredDate === undefined) {
					validateItems[index] = {
						...validateItems[index],
						validateRequiredDate: 'setRequiredData'
					}
					if (!missingFiled) {
						missingFiled = true
					}
				} else {
					validateItems[index] = {
						...validateItems[index],
						validateRequiredDate: ''
					}
				}
			}
		)
		if (missingFiled) {
			addMessage()
			return false
		} else {
			setNotFilledMessage(false)
			return true
		}
	}

	function selectedProductItem(product: ProductRFQ, itemIndex: string, unit: MeasurementUnits | string) {
		const newRFQtmp = { ...newRFQ }
		const itemDataList = newRFQ.itemDataList
		const amount = newRFQtmp.itemDataList[parseInt(itemIndex)].amount
		const date = newRFQtmp.itemDataList[parseInt(itemIndex)].requiredDate
		const validateItems = validateItemsDataList
		const objectItem = {
			product: product,
			unit: typeof unit === 'string' ? { code: unit, description: '' } : unit,
			amount: amount,
			requiredDate: date
		}
		itemDataList.splice(parseInt(itemIndex), 1, objectItem)
		if (!product) {
			if (itemDataList.length < 2) {
				setSaveAvailable(false)
			}
		}
		if (unit) {
			validateItems[parseInt(itemIndex)] = {
				...validateItems[parseInt(itemIndex)],
				validateUnit: ''
			}
		}
		newRFQtmp.itemDataList = itemDataList
		setNewRFQ(newRFQtmp)
		setValidateItemsDataList(validateItems)
	}

	function handleItemFieldChange(values: ValuesRFQ, index: string, field: string) {
		const newRFQtmp = { ...newRFQ }
		const itemData = newRFQtmp.itemDataList
		let amount = itemData[parseInt(index)].amount
		let requiredDate: Dayjs | undefined = itemData[parseInt(index)].requiredDate
		let product: ProductRFQ | undefined = itemData[parseInt(index)].product
		const validateItems = validateItemsDataList

		switch (field) {
			case 'amount':
				amount = values.amount ? values.amount : ''
				validateItems[parseInt(index)] = {
					...validateItems[parseInt(index)],
					validateAmount: false
				}
				break
			case 'requiredDate':
				requiredDate = values.requiredDate
				validateItems[parseInt(index)] = {
					...validateItems[parseInt(index)],
					validateRequiredDate: ''
				}
				break
			case 'product':
				product = values.product
				validateItems[parseInt(index)] = {
					...validateItems[parseInt(index)],
					validateProduct: ''
				}
				break
			case 'unit':
				if (typeof values.unit === 'object' && typeof itemData[parseInt(index)].unit === 'object') {
					if (values.unit.code !== null && itemData[parseInt(index)].unit.code) {
						itemData[parseInt(index)].unit.code = values.unit.code
						itemData[parseInt(index)].unit.description = values.unit.description
						validateItems[parseInt(index)] = {
							...validateItems[parseInt(index)],
							validateUnit: ''
						}
					}
				} else if (typeof values.unit === 'string' && values.unit !== '') {
					itemData[parseInt(index)].unit.code = values.unit
					itemData[parseInt(index)].unit.description = values.unit
					validateItems[parseInt(index)] = {
						...validateItems[parseInt(index)],
						validateUnit: ''
					}
				}
				break
		}

		const objectItem = {
			product,
			unit: itemData[parseInt(index)].unit,
			amount: amount,
			requiredDate: requiredDate
		}
		itemData.splice(parseInt(index), 1, objectItem)
		if (
			itemData[parseInt(index)].product !== undefined &&
			itemData[parseInt(index)].unit !== undefined &&
			itemData[parseInt(index)].amount !== '' &&
			itemData[parseInt(index)].requiredDate !== undefined
		) {
			if (itemData.length < 2) {
				setNotFilledMessage(false)
				if (!saveAvailable) {
					setSaveAvailable(true)
				}
			}
		} else {
			if (itemData.length < 2) {
				if (saveAvailable) {
					setSaveAvailable(false)
				}
			}
		}
		newRFQtmp.itemDataList = itemData
		setNewRFQ(newRFQtmp)
		setValidateItemsDataList(validateItems)
	}

	function deleteItem(itemIndex: number) {
		const newRFQtmp = { ...newRFQ }
		const items = newRFQ.itemDataList
		const validateItem = validateItemsDataList
		items.splice(itemIndex, 1)
		newRFQtmp.itemDataList = items
		if (items.length > 0) {
			setNotFilledMessage(false)
		} else {
			setValidateItemsDataList(validateItem)
			addItem()
			setSaveAvailable(false)
		}
		setNewRFQ(newRFQtmp)
	}

	function clearUnit(id: string) {
		const newRFQtmp = { ...newRFQ }
		newRFQtmp.itemDataList[parseInt(id)].unit = undefined
		setNewRFQ(newRFQtmp)
	}

	function addNewComment(newComment: string) {
		setCommentSend({ text: newComment })
	}

	function saveDataNewRFQ() {
		if (saveAvailable) {
			let getUnfillItems = false
			const validateItems = validateItemsDataList
			newRFQ.itemDataList.forEach(
				(
					itemData: {
						product: ProductRFQ
						amount: string
						unit: MeasurementUnits
						requiredDate: Dayjs
					},
					index: number
				) => {
					if (
						itemData.product === undefined ||
						itemData.amount === '' ||
						parseFloat(itemData.amount) === 0 ||
						itemData.unit === undefined ||
						itemData.requiredDate === undefined
					) {
						if (itemData.product === undefined) {
							validateItems[index] = {
								...validateItems[index],
								validateProduct: 'true'
							}
						}
						if (itemData.amount === '' || parseFloat(itemData.amount) === 0) {
							validateItems[index] = {
								...validateItems[index],
								validateAmount: true
							}
						}
						if (itemData.unit === undefined || '') {
							validateItems[index] = {
								...validateItems[index],
								validateUnit: 'setRequiredData'
							}
						}
						if (itemData.requiredDate === undefined) {
							validateItems[index] = {
								...validateItems[index],
								validateRequiredDate: 'setRequiredData'
							}
						}
						getUnfillItems = true
					}
				}
			)
			setValidateItemsDataList(validateItems)
			if (!newRFQ.branch_office || !newRFQ.dataSupplier.supplier_rfc || getUnfillItems) {
				if (!newRFQ.branch_office) {
					setFillDataBranch(true)
					setStylesErrorInputBranchOffice(true)
				}
				if (!newRFQ.dataSupplier.supplier_rfc) {
					setStylesErrorInputSupplier(true)
					setFillSupplier(true)
				}
				if (getUnfillItems) {
					setNotFilledMessage(true)
				}
				return false
			} else if (!getUnfillItems) {
				setNotFilledMessage(false)
			}

			setIsConfirmationSendModalOpen(true)

			setStylesErrorInputSupplier(false)
			setStylesErrorInputBranchOffice(false)
		}
	}

	return (
		<div className={styles.containerNewRfq}>
			{modal ?? modal}
			<AuthenticatedHeader>
				<div>
					<CancelButton onClick={() => setIsCancelNewRFQModalOpen(true)} />
				</div>
				<div></div>
			</AuthenticatedHeader>
			<div className={styles.containerTotal}>
				<div className={styles.mainContainer}>
					<HeaderInformation
						setBranchOffice={setBranchOffice}
						setSupplier={setSupplier}
						changeSupplier={changeSupplier}
						resetChange={resetChangeSupplier}
						newRFQ={newRFQ}
						setExpiredDate={setExpiredDate}
						fillDataBranch={fillDataBranch}
						fillSupplier={fillSupplier}
						errorInputBranchOffice={stylesErrorInputBranchOffice}
						errorInputSupplier={stylesErrorInputSupplier}
						setIsChangeSupplierModalOpen={setIsChangeSupplierModalOpen}
						isChangeSupplierModalOpen={isChangeSupplierModalOpen}
						cleanList={() => acceptActionModal('cleanList')}
					/>
					<div className={styles.mainModule}>
						<div className={styles.itemCreationSizeCont}>
							<div className={styles.itemCreationSize}>
								<ItemsList
									itemDataList={newRFQ.itemDataList}
									addItem={addItem}
									validateItems={validateItemsDataList}
									selectedProductItem={selectedProductItem}
									handleItemFieldChange={handleItemFieldChange}
									notFilledMessage={notFilledMessage}
									selectedSupplier={newRFQ.dataSupplier}
									deleteItem={deleteItem}
									measurementUnits={allMeasurementUnits}
									clearUnit={clearUnit}
								/>
							</div>
						</div>

						<div className={styles.widthContainerLateral}>
							<div>
								<CommentsSection
									variant={CommentSectionVariant.Simple}
									onChange={addNewComment}
								/>
							</div>
							<div className={styles.buttonContainer}>
								<NetcurioButton
									variant="outlined"
									color="primary"
									disabled={!saveAvailable}
									onClick={saveDataNewRFQ}
								>
									{t('requestQuotation')}
								</NetcurioButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CancelNewRFQModal
				open={isCancelNewRFQModalOpen}
				close={() => closeCancelNewRFQModal()}
				accept={() => cancelNewRFQ()}
			/>
			<CreateNewRfqConfirmationModal
				isOpen={isConfirmationSendModalOpen}
				onCancel={() => setIsConfirmationSendModalOpen(false)}
				onConfirm={() => acceptActionModal('createNewRFQ')}
			/>
		</div>
	)
}
