import { IBranchOffices } from '@netcurio/frontend-common'
import { useCallback, useEffect, useState } from 'react'
import ComponentQueries from '../components/queries'
import { useClient } from './useClient'

export const useBranches = (rfc: string | undefined) => {
	const [branches, setBranches] = useState<IBranchOffices[]>([])
	const client = useClient()

	const fetchBranches = useCallback(() => {
		client
			.query({
				query: ComponentQueries.BRANCH_OFFICES,
				variables: {
					company: rfc
				}
			})
			.then((result) => {
				if (result.data.BranchOffices) {
					setBranches(result.data.BranchOffices)
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
	}, [client])

	useEffect(() => {
		fetchBranches()
	}, [fetchBranches])

	return { branches }
}
