import cancelRejectsvg from '@netcurio/frontend-assets/src/icons/cancel-black.svg'
import confirmedsvg from '@netcurio/frontend-assets/src/icons/confirmed.svg'
import flag from '@netcurio/frontend-assets/src/icons/flag.svg'
import partial from '@netcurio/frontend-assets/src/icons/partial.svg'
import halfDelivered from '@netcurio/frontend-assets/src/icons/sent-partial.svg'
import delivered from '@netcurio/frontend-assets/src/icons/sent.svg'
import { PURCHASE } from '@netcurio/frontend-common'
import { StatusInformation, StatusSquareVariant } from '@netcurio/frontend-components/'
import Constants from '../../../../utilities/constants'

//#region PurchaseOrder
const newPurchaseOrder = (subLabel?: string): StatusInformation => {
	return {
		label: 'NEW',
		subLabel: subLabel || '',
		icon: flag,
		invertIconColor: true,
		isLast: false
	}
}
const deliveredPurchaseOrder = (subLabel?: string, isLast?: boolean): StatusInformation => {
	return {
		label: 'DELIVERED',
		subLabel: subLabel || '',
		icon: delivered,
		invertIconColor: true,
		isLast: isLast
	}
}
const rejectedPurchaseOrder = (subLabel?: string): StatusInformation => {
	return {
		label: 'REJECTED',
		subLabel: subLabel || '',
		icon: cancelRejectsvg,
		invertIconColor: true,
		variant: StatusSquareVariant.ERROR,
		isLast: true
	}
}
const confirmedPurchaseOrder = (subLabel?: string, isLast?: boolean): StatusInformation => {
	return {
		label: 'CONFIRMED',
		subLabel: subLabel || '',
		icon: confirmedsvg,
		invertIconColor: true,
		isLast: isLast
	}
}
const halfConfirmedPurchaseOrder = (subLabel?: string, isLast?: boolean): StatusInformation => {
	return {
		label: 'HALF_CONFIRMED',
		subLabel: subLabel || '',
		icon: partial,
		invertIconColor: true,
		isLast: isLast
	}
}
const halfDeliveredPurchaseOrder = (subLabel?: string): StatusInformation => {
	return {
		label: 'HALF_DELIVERED',
		subLabel: subLabel || '',
		icon: halfDelivered,
		invertIconColor: true,
		isLast: true
	}
}
//#endregion PurchaseOrder

const statuses = (informationForStatusSquare: object, confirmationSkipped: string) => {
	const statusFunctions = {
		[Constants.PurchaseOrderStatus.NEW]: [newPurchaseOrder()],
		[Constants.PurchaseOrderStatus.CONFIRMED]: [
			newPurchaseOrder(informationForStatusSquare['createdDate']),
			confirmedPurchaseOrder(informationForStatusSquare['confirmedDate'], true)
		],
		[Constants.PurchaseOrderStatus.HALF_CONFIRMED]: [
			newPurchaseOrder(informationForStatusSquare['createdDate']),
			halfConfirmedPurchaseOrder(informationForStatusSquare['confirmedDate'], true)
		],
		[Constants.PurchaseOrderStatus.DELIVERED]:
			informationForStatusSquare['purchaseOrderType'] === PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS
				? [deliveredPurchaseOrder(informationForStatusSquare['createdDate'])]
				: confirmationSkipped === Constants.SYMBOL.NA
					? [
							newPurchaseOrder(informationForStatusSquare['createdDate']),
							deliveredPurchaseOrder(informationForStatusSquare['lastDeliveredDate'], true)
						]
					: [
							newPurchaseOrder(informationForStatusSquare['createdDate']),
							confirmationSkipped === Constants.STATUS.CONFIRMED
								? confirmedPurchaseOrder(informationForStatusSquare['confirmedDate'], false)
								: halfConfirmedPurchaseOrder(
										informationForStatusSquare['confirmedDate'],
										false
									),
							deliveredPurchaseOrder(informationForStatusSquare['lastDeliveredDate'], true)
						],
		[Constants.PurchaseOrderStatus.HALF_DELIVERED]:
			confirmationSkipped === Constants.SYMBOL.NA
				? [
						newPurchaseOrder(informationForStatusSquare['createdDate']),
						halfDeliveredPurchaseOrder(informationForStatusSquare['lastDeliveredDate'])
					]
				: [
						newPurchaseOrder(informationForStatusSquare['createdDate']),
						confirmationSkipped === Constants.STATUS.CONFIRMED
							? confirmedPurchaseOrder(informationForStatusSquare['confirmedDate'], false)
							: halfConfirmedPurchaseOrder(informationForStatusSquare['confirmedDate'], false),
						halfDeliveredPurchaseOrder(informationForStatusSquare['lastDeliveredDate'])
					],
		[Constants.PurchaseOrderStatus.REJECTED]: [
			newPurchaseOrder(informationForStatusSquare['createdDate']),
			rejectedPurchaseOrder(informationForStatusSquare['rejectedDate'])
		]
	}
	return statusFunctions[informationForStatusSquare['currentStatus']] || []
}

export const getVisualStatusPoDetail = (informationForStatusSquare: object, confirmationSkipped: string) => {
	return statuses(informationForStatusSquare, confirmationSkipped)
}
