import * as Sentry from '@sentry/react'

const getEnvironment = () => {
	const url = window.location.href
	if (url.includes('stg')) return 'staging'
	if (url.includes('app')) return 'production'
	if (url.includes('demo')) return 'demo'
	if (url.includes('test')) return 'test'
	return 'local'
}

Sentry.init({
	dsn: 'https://8198b9b50977c64a314af62bd070728a@o4508173092192256.ingest.us.sentry.io/4509011480281088',
	integrations: [Sentry.browserTracingIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: getEnvironment() === 'production' ? 0.5 : 1.0,
	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:/ / yourserver.io / api / ],
	environment: getEnvironment(),
	enabled: getEnvironment() !== 'local'
})
