import { User } from '@netcurio/frontend-common'
import { useCallback, useEffect, useState } from 'react'
import { ACTIVE_USERS_BY_COMPANY } from '../views/purchase-requisitions/graphql-ops/queries'
import { useClient } from './useClient'

export const useUsers = () => {
	const [users, setUsers] = useState<User[]>([])
	const client = useClient()

	const fetchUsers = useCallback(() => {
		client
			.query({
				query: ACTIVE_USERS_BY_COMPANY,
				variables: {
					filter: []
				}
			})
			.then((result) => {
				if (result.data.ActiveUserByCompany) {
					setUsers(result.data.ActiveUserByCompany.list)
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
	}, [client])

	useEffect(() => {
		fetchUsers()
	}, [fetchUsers])

	return { users }
}
