import { Filter, Roles, TableFillSelectionFilter, URLS } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { showModalExpiredSession } from '../../components/expiredSessionMessage'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import * as queries from './queries'
import { objectHeaderArray, objectStatusFilterArray, objectTypeFilterArray } from './tableParameterArray'
import TableRow from './tableRow'
import styles from './ticketList.module.scss'
import { IFilterData, IHeight, IListData, Tickets } from './types'

interface ResultFiltersToApplyObj {
	filterContainerBar: boolean
	dataFilters: { [name: string]: Filter }
	dataFiltersArray: Array<Filter>
	dataStatusFilter: string
	filtersOfTypeStatus: TypeStatusFilters
	deleteRange: boolean
}

interface TypeStatusFilters {
	status: {
		list: { selectedParameter: string; statusClass?: string }[]
		numSelectOptions: number
		dataFilter?: string
	}
}

export const TicketList = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [initRange, setInitRange] = useState<string>('')
	const [finalRange, setFinalRange] = useState<string>('')
	const [minRange, setMinRange] = useState<string>('')
	const [maxRange, setMaxRange] = useState<string>('')
	const [initDate, setInitDate] = useState<Dayjs>()
	const [finalDate, setFinalDate] = useState<Dayjs>()
	const [textHeader, setTextHeader] = useState<string>(styles.textHeaderTicketList)
	const [paginationActive, setPaginationActive] = useState<boolean>(false)
	const [userRoles, setUserRoles] = useState<Roles[]>([])
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [listData, setListData] = useState<IListData>({
		orderList: Constants.ORDER.DESC,
		fieldList: '',
		resultNotFound: false,
		totalRows: 1,
		limitRows: 0,
		rowsActual: 0,
		dataTickets: {}
	})
	const [filterData, setFilterData] = useState<IFilterData>({
		textFilter: 'display-none',
		statusFilter: 'display-none',
		dateFilter: 'display-none',
		typeFilter: 'display-none',
		filterValue: undefined,
		actualFilterData: '',
		actualField: '',
		dataFiltersArray: [],
		dataFilters: {},
		filterContainerBar: false,
		dataStatusFilter: ''
	})
	const [stateHeight, setStateHeight] = useState<IHeight>({
		activeHeight: true,
		mainTableHeight: 0,
		mainContainerHeight: 0
	})
	const [fillStatusInputFilter, setFillStatusInputFilter] =
		useState<TableFillSelectionFilter[]>(objectStatusFilterArray())
	const [fillTypeInputFilter, setFillTypeInputFilter] =
		useState<TableFillSelectionFilter[]>(objectTypeFilterArray())
	const [filtersOfTypeStatus, setFiltersOfTypeStatus] = useState<TypeStatusFilters>({
		status: {
			list: objectStatusFilterArray(),
			numSelectOptions: 0,
			dataFilter: ''
		}
	})
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const containerRef = useRef<HTMLDivElement>(null)
	const history = useHistory()
	let isMounted = true

	useEffect(() => {
		showModalExpiredSession()
	})

	const downloadReportExcel = () => {
		if (Object.keys(listData.dataTickets).length > 0) {
			showLoadingSpinner()
			const copyfilterToApply = JSON.parse(JSON.stringify(filterData.dataFiltersArray))
			copyfilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body = JSON.stringify({
				filters: copyfilterToApply,
				sort_order: listData.orderList,
				sort_field: listData.fieldList
			})
			listHelper
				.generateReportList(body, 'tickets')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						hideLoadingSpinner()
					} else {
						const error = await res.json()
						handleError(error)
						hideLoadingSpinner()
					}
				})
				.catch(handleError)
		}
	}

	useEffect(() => {
		const finalRowsHeight = window.innerHeight - 95
		const numberRows = Math.round(finalRowsHeight / 45) + 2
		setListData((prev) => ({
			...prev,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
		if (window.innerWidth < 2000) {
			containerRef.current.parentElement.style.marginLeft = '50px'
		}
		document
			.getElementsByClassName('tableInformation')[0]
			.addEventListener('scroll', () => setPaginationActive(true))

		const finalHeight = window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT
		const mainHeight = window.innerHeight - Constants.SIZE.HEADERS_HEIGHT

		const barSpinner = document.getElementById('barSpinner')
		if (barSpinner) {
			barSpinner.style.display = 'none'
		}
		setUserRoles(getUserRolesForCompany())
		setStateHeight((prev) => ({
			...prev,
			mainTableHeight: finalHeight,
			mainContainerHeight: mainHeight
		}))
		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			if (element) element.removeEventListener('scroll', () => setPaginationActive(true))
			isMounted = false
		}
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRoles))
	}, [userRoles])

	useEffect(() => {
		if (listData.limitRows) {
			filteringTickets(0, 'created_at', '', 'created_at', undefined, undefined, undefined, undefined)
		}
	}, [listData.limitRows])

	useEffect(() => {
		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setFillStatusInputFilter(objectStatusFilterArray())
			}
			if (filterData.filterValue !== undefined) {
				setFilterData((prev) => ({
					...prev,
					filterValue: undefined
				}))
			}
		} else if (filtersOfTypeStatus.status.dataFilter !== '' && filtersOfTypeStatus.status.dataFilter) {
			fillStatusInputFilter.push(new TableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter))
		}
		if (filterData.dataStatusFilter === 'return') {
			if (fillTypeInputFilter.length !== objectTypeFilterArray().length) {
				setFillTypeInputFilter(objectTypeFilterArray())
			}
			if (filterData.filterValue !== undefined) {
				setFilterData((prev) => ({
					...prev,
					filterValue: undefined
				}))
			}
		}
	}, [filtersOfTypeStatus.status.dataFilter, fillTypeInputFilter])

	useEffect(() => {
		setListData((prev) => ({
			...prev,
			resultNotFound: listData.totalRows < 1
		}))
	}, [listData.totalRows])

	useEffect(() => {
		if (filterData.filterContainerBar && stateHeight.activeHeight) {
			const finalHeight = stateHeight.mainTableHeight - 40
			const mainHeight = stateHeight.mainContainerHeight - 40
			setStateHeight((prev) => ({
				...prev,
				activeHeight: false,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
		} else if (filterData.filterContainerBar === false && stateHeight.activeHeight === false) {
			const finalHeight = stateHeight.mainTableHeight + 40
			const mainHeight = stateHeight.mainContainerHeight + 40
			setStateHeight((prev) => ({
				...prev,
				activeHeight: true,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
		}
	}, [filterData.filterContainerBar, stateHeight.activeHeight])

	useEffect(() => {
		if (paginationActive) {
			listHelper.handlePagination(
				'tableInformation',
				listData.totalRows,
				listData.rowsActual,
				filteringTickets
			)
			setPaginationActive(false)
		}
	}, [paginationActive])

	useEffect(() => {
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClickedTicket)
	})

	function activeFilterToApply(
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: string,
		finalRangeActual: string,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	) {
		if (columnFilterActual === 'company_is_sender') {
			valueFilterActual = valueFilterActual === Constants.TICKET_REVIEW.MY_COMPANY ? 'true' : 'false'
		}
		if (columnFilterActual === 'status' && valueFilterActual == 'CLOSED_TICKET') {
			valueFilterActual = 'CLOSED'
		}
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			filterData.dataFilters,
			filtersOfTypeStatus
		)
		const obj = resultFiltersToApply.objectForStateUpdate as ResultFiltersToApplyObj
		setFilterData((prev) => ({
			...prev,
			filterContainerBar: obj['filterContainerBar'],
			dataFilters: obj['dataFilters'],
			dataFiltersArray: obj['dataFiltersArray'],
			dataStatusFilter: obj['dataStatusFilter']
		}))
		if (obj['filtersOfTypeStatus']) setFiltersOfTypeStatus(obj['filtersOfTypeStatus'])
		return resultFiltersToApply.filterToApply
	}

	function getTickets(
		fieldListData: string,
		orderListData: string,
		filterToApply: Filter[],
		skip: number,
		currentList: Tickets
	) {
		const copyfilterToApply = JSON.parse(JSON.stringify(filterToApply))
		copyfilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.Tickets,
				variables: {
					limit: listData.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyfilterToApply
				}
			})
			.then((result) => {
				if (isMounted) {
					hideLoadingSpinner()
					document.getElementById('barSpinner').style.display = 'none'
					let interCount = skip
					const queryTotal = result.data.Tickets.total
					const data: Tickets = { ...currentList }
					const obj = result.data.Tickets.list
					for (const keyName in obj) {
						interCount++
						data[interCount] = obj[keyName]
					}

					setFilterData((prev) => ({
						...prev,
						dataStatusFilter: ''
					}))
					setListData((prev) => ({
						...prev,
						totalRows: queryTotal,
						dataTickets: data
					}))
					setFiltersOfTypeStatus({
						status: {
							...filtersOfTypeStatus.status,
							dataFilter: ''
						}
					})
					hideLoadingSpinner()
					document.getElementById('barSpinner').style.display = 'none'
				}
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		if (isMounted) {
			hideLoadingSpinner()
			document.getElementById('barSpinner').style.display = 'none'
			const errorCode = showErrorComponent(error)
			expiredToken(errorCode)
			setListData((prev) => ({ ...prev, dataTickets: {} }))
			setFilterData((prev) => ({
				...prev,
				dataStatusFilter: ''
			}))
			setFiltersOfTypeStatus({
				status: {
					...filtersOfTypeStatus.status,
					dataFilter: ''
				}
			})
		}
	}

	function deleteDateRange() {
		setInitRange(undefined)
		setFinalRange(undefined)
	}

	function deleteFilterActive(indexObject: string) {
		if (indexObject === 'created_at') {
			deleteDateRange()
		}
		listHelper.deleteFilterActive(
			indexObject,
			filterData.dataFilters,
			closeFilterContainerBar,
			filteringTickets
		)
	}

	const closeFilterContainerBar = () => {
		deleteDateRange()
		listHelper.closeFilterContainerBar(filteringTickets, 'created_at')
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValueParam: string,
		objectRange: { initRange?: string; finalRange?: string }
	) => {
		showLoadingSpinner()
		let initRangeL = initRange
		let finalRangeL = finalRange
		const minRangeL = minRange
		const maxRangeL = maxRange
		if (minRangeL) initRangeL = minRangeL
		if (maxRangeL) finalRangeL = maxRangeL
		if (objectRange) {
			if (objectRange.initRange) initRangeL = objectRange.initRange
			if (objectRange.finalRange) finalRangeL = objectRange.finalRange
		}
		if (sortField !== '') {
			filteringTickets(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringTickets(
				0,
				sortField,
				order,
				fieldFilter,
				filterValueParam,
				initRangeL,
				finalRangeL,
				undefined
			)
		}
	}

	function filteringTickets(
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter: string,
		initRangeP: string,
		finalRange: string,
		filterRemove: string
	) {
		let fieldListData = listData.fieldList
		let orderListData = listData.orderList
		let columnFilterActual = filterData.actualFilterData

		if (sortOrder !== '') {
			orderListData = sortOrder
			setListData((prev) => ({ ...prev, orderList: sortOrder }))
		}
		if (sortField) {
			setListData((prev) => ({ ...prev, fieldList: sortField }))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setFilterData((prev) => ({
				...prev,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual
		let initRangeActual
		let finalRangeActual
		const valueFilterActual = valueFilter

		switch (columnFilterActual) {
			case 'id':
			case 'my_counterpart':
			case 'category':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRangeP
				finalRangeActual = finalRange
				break
			case 'company_is_sender':
				typeFilterActual = 'boolean'
				break
		}
		let currentList = { ...listData.dataTickets }
		if (skip > 0) {
			setListData((prev) => ({
				...prev,
				rowsActual: listData.rowsActual + listData.limitRows
			}))
		} else {
			setListData((prev) => ({
				...prev,
				rowsActual: listData.limitRows,
				dataTickets: {}
			}))
			currentList = {}
		}
		const filterToApply = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		getTickets(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	function showFilters(field: string, fieldStyle: string) {
		const filterActual = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		switch (filterActual) {
			case 'dateFilter':
			case 'numericFilter':
			case 'statusFilter':
			case 'typeFilter':
				classActualField = 'ticket-' + fieldStyle + '-filter'
				break
			case 'textFilter':
				classActualField = 'display-block'
				break
		}

		const activeFilter =
			filterActual === 'textFilter'
				? filterData.textFilter
				: filterActual === 'statusFilter'
					? filterData.statusFilter
					: filterActual === 'typeFilter'
						? filterData.typeFilter
						: filterData.dateFilter
		if (activeFilter === 'display-none') {
			let finalHeight: number
			let mainHeight: number
			if (stateHeight.mainTableHeight > window.innerHeight - 155) {
				finalHeight = stateHeight.mainTableHeight - 40
				mainHeight = stateHeight.mainContainerHeight - 40
			} else {
				finalHeight = stateHeight.mainTableHeight
				mainHeight = stateHeight.mainContainerHeight
			}
			setTextHeader(styles.textheaderOnClick)
			setFilterData((prev) => ({
				...prev,
				textFilter: 'display-none',
				statusFilter: 'display-none',
				typeFilter: 'display-none',
				dateFilter: 'display-none'
			}))

			switch (filterActual) {
				case 'dateFilter':
					setFilterData((prev) => ({
						...prev,
						dateFilter: classActualField
					}))
					break
				case 'statusFilter':
					setFilterData((prev) => ({
						...prev,
						statusFilter: classActualField
					}))
					break
				case 'typeFilter':
					setFilterData((prev) => ({
						...prev,
						typeFilter: classActualField
					}))
					break
				case 'textFilter':
					setFilterData((prev) => ({
						...prev,
						textFilter: classActualField
					}))
					break
			}
			setFilterData((prev) => ({
				...prev,
				filterValue: '',
				actualField: field
			}))
			setStateHeight((prev) => ({
				...prev,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
			setInitDate(undefined)
			setFinalDate(undefined)
			setMaxRange(undefined)
			setMinRange(undefined)
		} else if (filterData.actualField === field) {
			const finalHeight = stateHeight.mainTableHeight + 40
			const mainHeight = stateHeight.mainContainerHeight + 40
			setTextHeader(styles.textHeaderTicketList)
			setFilterData((prev) => ({
				...prev,
				filterValue: ''
			}))
			setStateHeight((prev) => ({
				...prev,
				mainTableHeight: finalHeight,
				mainContainerHeight: mainHeight
			}))
			switch (filterActual) {
				case 'dateFilter':
					setFilterData((prev) => ({
						...prev,
						dateFilter: 'display-none'
					}))
					break
				case 'statusFilter':
					setFilterData((prev) => ({
						...prev,
						statusFilter: 'display-none'
					}))
					break
				case 'typeFilter':
					setFilterData((prev) => ({
						...prev,
						typeFilter: 'display-none'
					}))
					break
				case 'textFilter':
					setFilterData((prev) => ({
						...prev,
						textFilter: 'display-none'
					}))
					break
			}
			deleteDateRange()
		} else {
			setFilterData((prev) => ({
				...prev,
				filterValue: '',
				actualField: field
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClickedTicket)
	}

	function closeFilter() {
		const finalHeight = stateHeight.mainTableHeight + 40
		const mainHeight = stateHeight.mainContainerHeight + 40
		setTextHeader(styles.tableHeaderClickedTicket)
		setFilterData((prev) => ({
			...prev,
			textFilter: 'display-none',
			statusFilter: 'display-none',
			typeFilter: 'display-none',
			dateFilter: 'display-none',
			filterValue: undefined
		}))
		setStateHeight((prev) => ({
			...prev,
			mainTableHeight: finalHeight,
			mainContainerHeight: mainHeight
		}))
		deleteDateRange()
	}

	function handleDateRangeFilterChange(evt: Dayjs, field: string) {
		const evtValue: Dayjs = evt
		const rangeActual = field + 'Range'
		const dateFunc = field === 'init' ? setInitDate : setFinalDate
		const rangeFunc = field === 'init' ? setInitRange : setFinalRange
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', filterData.actualField, undefined, {
					[rangeActual]: evtValue
				})
				dateFunc(evtValue)
				rangeFunc(evtValue.toString)
				emptyFilterField()
			}
		}
	}

	function showFilterContainerBar() {
		if (filterData.filterContainerBar) {
			const booleanNames = {
				true: Constants.TICKET_REVIEW.MY_COMPANY,
				false: Constants.TICKET_REVIEW.MY_COUNTERPART
			}
			return (
				<ActiveFiltersBar
					objectType={Constants.LISTS.TICKETS}
					dataFiltersArray={filterData.dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={deleteFilterActive}
					booleanNames={booleanNames}
				/>
			)
		} else {
			return null
		}
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value = evt.target.value
		setFilterData((prev) => ({
			...prev,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (evt: React.ChangeEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			evt,
			callDataList,
			emptyFilterField,
			filterData.actualField,
			filterData.filterValue,
			'',
			''
		)
	}

	const selectionFilter = (status: string, position: any) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 5) {
			if (filterData.actualField === 'company_is_sender') {
				callDataList('', '', filterData.actualField, status, {})
				const booleanOptions = objectTypeFilterArray().filter(
					(option) => option.getSelectedParameter() !== status
				)
				setFillTypeInputFilter(booleanOptions)
			} else {
				callDataList('', '', filterData.actualField, status, {})
				fillStatusInputFilter.splice(position, 1)
			}
		}
	}

	const emptyFilterField = () => {
		setFilterData((prev) => ({
			...prev,
			filterValue: ''
		}))
		setInitDate(undefined)
		setFinalDate(undefined)
	}

	return (
		<div ref={containerRef}>
			<AuthenticatedHeader>
				<div>
					<AddButton translationKey="newTicket" onClick={() => history.push(URLS.NEW_TICKET)} />
				</div>
				<div>
					<ExportButton onClick={downloadReportExcel} />
				</div>
			</AuthenticatedHeader>
			<div className={styles.tableTicketsWidth}>
				<div className={classNames(styles.headerTableTicketList)}>
					{Object.keys(fillFields).map((key: string) => (
						<HeaderTable
							key={key}
							textHeader={textHeader}
							showFilters={showFilters}
							dataMainHeaderTable={fillFields[parseInt(key)]}
						/>
					))}
				</div>
				<TagsFilter
					showTagsFilter={
						filterData.actualField === 'company_is_sender'
							? filterData.typeFilter
							: filterData.statusFilter
					}
					fillInputFilter={
						filterData.actualField === 'company_is_sender'
							? fillTypeInputFilter
							: fillStatusInputFilter
					}
					actualField={filterData.actualField}
					callDataList={callDataList}
					selectionFilter={selectionFilter}
					closeFilter={closeFilter}
				/>
				<DateInputFilter
					showDateFilter={filterData.dateFilter}
					actualField={filterData.actualField}
					initDate={initDate}
					finalDate={finalDate}
					callDataList={callDataList}
					initReferenceText="init"
					finReferenceText="final"
					handleDateRangeFilterChange={handleDateRangeFilterChange}
					closeFilter={closeFilter}
				/>
				<TextInputFilter
					showTextFilter={filterData.textFilter}
					actualField={filterData.actualField}
					valueFilter={filterData.filterValue}
					handleTextFilterChange={handleTextFilterChange}
					handleFilterKeyDown={handleFilterKeyDown}
					callDataList={callDataList}
					closeFilter={closeFilter}
				/>
				{showFilterContainerBar()}
				<div
					className={'tableInformationWhiteStyle'}
					style={{ height: stateHeight.mainContainerHeight }}
				>
					<div className={'tableInformation'} style={{ height: stateHeight.mainTableHeight }}>
						<ResultNotFound showNotFound={listData.resultNotFound} />
						{Object.keys(listData.dataTickets).map((key: string) => (
							<TableRow
								key={key}
								id={listData.dataTickets[key]['id']}
								status={listData.dataTickets[key]['status']['value']}
								created_at={listData.dataTickets[key]['created_at']}
								sender={listData.dataTickets[key]['sender']}
								my_counterpart={listData.dataTickets[key]['my_counterpart']}
								company_is_sender={listData.dataTickets[key]['company_is_sender']}
								category={listData.dataTickets[key]['category']['description']}
							/>
						))}
					</div>
					<BarLoader idBarLoader={'barSpinner'} />
				</div>
			</div>
		</div>
	)
}
