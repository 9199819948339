import { getCurrentUser, Roles, Status } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioFormControl,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	NetcurioTextField,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import { t } from 'i18next'
import moment from 'moment-timezone'
import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import { getUserRolesForCompany } from '../../../../../utilities/getUserRolesForCompany'
import UserInformation from '../../../../../utilities/userInformation'
import { CatchedErrorModalUserProfileTab } from '../../../Modals/CatchedErrorModalUserProfileTab/CatchedErrorModalUserProfileTab'
import { themeButtons } from '../themeButtons'
import * as queries from './queries'
import styles from './userInformation.module.scss'

interface UserInfo {
	email: string
	phoneNumber: string
	name: string
	lastName: string
	passwords: {
		currentPassword: string
		newPassword: string
		confirmPassword: string
	}
}

interface FormErrors {
	phoneNumberError?: boolean
	nameError?: boolean
	lastNameError?: boolean
	currentPasswordError?: boolean
	newPasswordError?: boolean
	confirmPasswordError?: boolean
}

const formatTimezone = (name: string) => {
	return moment.tz(name).format('Z')
}

export const UserProfileTab = (): ReactElement => {
	const userRoles = getUserRolesForCompany()
	const user = getCurrentUser()
	const client = connection()
	const [errorCode, setErrorCode] = useState('')
	const [timeZone, setTimeZone] = useState<string>(user?.time_zone ?? '')
	const [changeTimeZone, setChangeTimeZone] = useState<boolean>(false)
	const [actualCompany, setActualCompany] = useState('')
	const [actualMainScreen, setActualMainScreen] = useState('')
	const [changeCompany, setChangeCompany] = useState<boolean>(false)
	const [changeMainScreen, setChangeMainScreen] = useState<boolean>(false)
	const [showSavedInformationMsg, setShowSavedInformationMsg] = useState<string>('')
	const [missingUserInfo, setMissingUserInfoText] = useState<boolean>(false)
	const [wrongPasswordText, setWrongPasswordText] = useState<string>('')
	const [userInfo, setUserInfo] = useState<UserInfo>({
		email: user?.email ?? '',
		phoneNumber: user?.phone_number ?? '',
		name: user?.name ?? '',
		lastName: user?.lastname ?? '',
		passwords: {
			currentPassword: '••••••••••••••••••••••••••',
			newPassword: '',
			confirmPassword: ''
		}
	})
	const [errorsForm, setErrorsForm] = useState<FormErrors>({
		phoneNumberError: false,
		nameError: false,
		lastNameError: false,
		currentPasswordError: false,
		newPasswordError: false,
		confirmPasswordError: false
	})
	const [editUserInformation, setEditUserInformation] = useState<boolean>(false)
	const [showActualPassword, setShowActualPassword] = useState<boolean>(true)
	const [showPasswordInputs, setShowPasswordInputs] = useState<boolean>(false)
	const homepageList = () => {
		const commonMainScreens = [
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.PURCHASE_ORDERS,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.PURCHASE_ORDERS)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.INVOICES,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.INVOICES)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.GOOD_RECEIPTS,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.GOOD_RECEIPTS)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.PAYMENT_COMPLEMENTS,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.PAYMENT_COMPLEMENTS)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.REPORTS,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.REPORTS)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.REQUEST_FOR_QUOTATION,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.REQUEST_FOR_QUOTATION)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.CREDIT_MEMO,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.CREDIT_MEMO)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.FOR_CREDIT_MEMO,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.FOR_CREDIT_MEMO)
			},
			{
				mainScreen: Constants.KEY_MAIN_SCREENS.CONSUMPTION,
				nameScreen: t(Constants.TEXT_MAIN_SCREENS.CONSUMPTION)
			}
		]

		if (userRoles.includes(Roles.SUPPLIER)) {
			commonMainScreens.splice(
				commonMainScreens.findIndex(
					(screen) => screen.mainScreen === Constants.KEY_MAIN_SCREENS.GOOD_RECEIPTS
				),
				1
			)
		}

		return commonMainScreens
			.sort((screenA, screenB) => screenA.nameScreen.localeCompare(screenB.nameScreen))
			.filter((screenList) => screenList.nameScreen !== '')
	}

	useEffect(() => {
		getActualCompany()
		getActualMainScreen()
	}, [])

	const getActualCompany = () => {
		let useCompany
		if (user?.companies) {
			useCompany = UserInformation.getCompanySelected(user?.companies, user?.default_company ?? '')?.rfc
		}
		if (user?.default_company !== null && useCompany) {
			setActualCompany(useCompany)
		} else {
			setActualCompany('notSelectedYet')
		}
	}
	const getActualMainScreen = () => {
		if (user?.company?.homepage !== null) {
			setActualMainScreen(user?.company?.homepage as string)
		} else {
			setActualMainScreen(Constants.KEY_MAIN_SCREENS.PURCHASE_ORDERS)
		}
	}

	useEffect(() => {
		if (showSavedInformationMsg) {
			displaySavedInformationMessage()
		}
	}, [showSavedInformationMsg])

	const displaySavedInformationMessage = () => {
		setTimeout(() => {
			setShowSavedInformationMsg('')
		}, 3500)
	}

	const onChangeUserName = (evt: ChangeEvent<HTMLInputElement>) => {
		setUserInfo({ ...userInfo, name: evt.target.value })
	}

	const onChangeUserLastName = (evt: ChangeEvent<HTMLInputElement>) => {
		setUserInfo({ ...userInfo, lastName: evt.target.value })
	}

	const onChangeUserPhoneNumber = (evt: ChangeEvent<HTMLInputElement>) => {
		const onlyNumbersRegex = /^[0-9]*$/
		if (onlyNumbersRegex.test(evt.target.value)) {
			setUserInfo({ ...userInfo, phoneNumber: evt.target.value })
		}
	}

	const cancelEditUserInfo = () => {
		setEditUserInformation(false)
		setUserInfo({
			...userInfo,
			name: user?.name ?? '',
			lastName: user?.lastname ?? '',
			phoneNumber: user?.phone_number ?? ''
		})
		if (missingUserInfo) setMissingUserInfoText(false)
		setErrorsForm({})
	}

	const hasUserInformationChanged = () => {
		return (
			userInfo['name'] !== user?.name ||
			userInfo['lastName'] !== user?.lastname ||
			userInfo['phoneNumber'] !== user?.phone_number
		)
	}

	const changeUserInformation = () => {
		client
			.mutate({
				mutation: queries.UPDATE_USER,
				variables: {
					name: userInfo['name'],
					lastname: userInfo['lastName'],
					phone_number: userInfo['phoneNumber']
				}
			})
			.then(async () => {
				await UserInformation.updateUserInfo()
				setEditUserInformation(false)
				setMissingUserInfoText(false)
				setShowSavedInformationMsg('dataUserSaved')
			})
			.catch(handleError)
	}

	const changeUserPassword = () => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				Auth.changePassword(
					user,
					userInfo['passwords']['currentPassword'],
					userInfo['passwords']['newPassword']
				)
					.then(() => {
						setShowPasswordInputs(true)
						setShowActualPassword(false)
						setUserInfo({
							...userInfo,
							passwords: {
								...userInfo.passwords,
								currentPassword: '••••••••••••••••••••••••••',
								newPassword: '',
								confirmPassword: ''
							}
						})
						setShowPasswordInputs(false)
						setShowActualPassword(true)
						setShowSavedInformationMsg('passwordUpdated')
						setWrongPasswordText('')
					})
					.catch(handleError)
			})
			.then(() => {
				setShowPasswordInputs(true)
				setShowActualPassword(false)
				setUserInfo({
					...userInfo,
					passwords: {
						...userInfo.passwords,
						currentPassword: '••••••••••••••••••••••••••',
						newPassword: '',
						confirmPassword: ''
					}
				})
				setShowPasswordInputs(false)
				setShowActualPassword(true)
				setShowSavedInformationMsg('passwordUpdated')
				setWrongPasswordText('')
			})
			.catch(handleError)
	}

	const validateUserInformation = () => {
		const newUserErrors: FormErrors = {
			nameError: false,
			lastNameError: false,
			phoneNumberError: false
		}
		let validUserInformation = true

		if (!userInfo['name']) {
			validUserInformation = false
			newUserErrors.nameError = true
			setMissingUserInfoText(true)
		}
		if (!userInfo['lastName']) {
			validUserInformation = false
			setMissingUserInfoText(true)
			newUserErrors.lastNameError = true
		}
		if (!userInfo['phoneNumber']) {
			validUserInformation = false
			newUserErrors.phoneNumberError = true
			setMissingUserInfoText(true)
		}

		setErrorsForm(newUserErrors)

		if (validUserInformation && hasUserInformationChanged()) {
			changeUserInformation()
		}
	}

	const validatePassword = () => {
		const newErrors: FormErrors = {
			currentPasswordError: false,
			newPasswordError: false,
			confirmPasswordError: false
		}
		let validPassowrd = true
		if (!userInfo['passwords']['currentPassword']) {
			validPassowrd = false
			newErrors.currentPasswordError = true
			setWrongPasswordText('missingFields')
		} else if (!userInfo['passwords']['currentPassword'].match(Constants.REGEX.PASSWORD)) {
			validPassowrd = false
			newErrors.currentPasswordError = true
			setWrongPasswordText('incorrectPassword')
		}
		if (!userInfo['passwords']['newPassword']) {
			validPassowrd = false
			newErrors.newPasswordError = true
			setWrongPasswordText('missingFields')
		} else if (!userInfo['passwords']['newPassword'].match(Constants.REGEX.PASSWORD)) {
			validPassowrd = false
			newErrors.newPasswordError = true
			setWrongPasswordText('validatePasswordFailText')
		}
		if (!userInfo['passwords']['confirmPassword']) {
			validPassowrd = false
			newErrors.confirmPasswordError = true
			setWrongPasswordText('missingFields')
		}
		if (userInfo['passwords']['newPassword'] !== userInfo['passwords']['confirmPassword']) {
			validPassowrd = false
			newErrors.newPasswordError = true
			newErrors.confirmPasswordError = true
			setWrongPasswordText('validatePasswordFailConfirmText')
		}

		setErrorsForm(newErrors)
		if (validPassowrd) {
			changeUserPassword()
		}
	}

	const displayEditUserButtons = () => {
		return editUserInformation ? (
			<div className={styles.displayFlex}>
				<NetcurioButton
					variant="text"
					size={'small'}
					color="secondary"
					sx={themeButtons}
					className={styles.margin0}
					onClick={() => cancelEditUserInfo()}
				>
					<span> {t('cancelButton')} </span>
				</NetcurioButton>
				<NetcurioButton
					size={'small'}
					variant="outlined"
					onClick={() => validateUserInformation()}
					sx={themeButtons}
				>
					<span> {t('saveButton')} </span>
				</NetcurioButton>
			</div>
		) : (
			<NetcurioButton
				variant="outlined"
				className={styles.margin0}
				size={'small'}
				onClick={() => {
					setEditUserInformation(true)
				}}
				sx={themeButtons}
			>
				<span> {t('change')} </span>
			</NetcurioButton>
		)
	}

	const cancelPasswordChange = () => {
		setUserInfo({
			...userInfo,
			passwords: {
				...userInfo.passwords,
				currentPassword: '••••••••••••••••••••••••••',
				newPassword: '',
				confirmPassword: ''
			}
		})
		setShowPasswordInputs(false)
		setShowActualPassword(true)
		setErrorsForm({})
		setWrongPasswordText('')
	}

	const showChangePasswordInputs = () => {
		return (
			showPasswordInputs && (
				<div className={styles.changePassword}>
					<div className={styles.newPasswordContainerUserSettings}>
						<NetcurioTooltip
							title={
								<div className={styles.specialCharacters}>
									<div>{t('specialCharacterAllowed')}</div>
									<div>{t('validSpecialCharacters')}</div>
								</div>
							}
							placement={'bottom'}
						>
							<NetcurioTextField
								fullWidth
								label={t('newPassword')}
								value={userInfo.passwords['newPassword']}
								type="password"
								onChange={(evt) =>
									setUserInfo({
										...userInfo,
										passwords: {
											...userInfo.passwords,
											newPassword: evt.target.value
										}
									})
								}
								error={errorsForm['newPasswordError']}
							/>
						</NetcurioTooltip>
					</div>
					<div className={styles.currentPasswordContainerUserSettings}>
						<NetcurioTextField
							fullWidth
							label={t('confirmPassword')}
							value={userInfo.passwords['confirmPassword']}
							type="password"
							onChange={(evt) =>
								setUserInfo({
									...userInfo,
									passwords: {
										...userInfo.passwords,
										confirmPassword: evt.target.value
									}
								})
							}
							error={errorsForm['confirmPasswordError']}
						/>
					</div>
					<div className={styles.buttonsPasswordSection}>
						<NetcurioButton
							variant="text"
							size={'small'}
							color="secondary"
							sx={themeButtons}
							onClick={() => {
								cancelPasswordChange()
							}}
						>
							<span> {t('cancelButton')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							size={'small'}
							onClick={() => validatePassword()}
							sx={themeButtons}
						>
							<span> {t('saveButton')} </span>
						</NetcurioButton>
					</div>
				</div>
			)
		)
	}

	const timezones = useMemo(() => {
		const timezonesMap = moment.tz.names().map((tz) => {
			return { name: tz, offset: moment.tz(tz).utcOffset() }
		})
		return timezonesMap.sort((a, b) => a.offset - b.offset)
	}, [])

	const handleSelectTimeZone = (event: NetcurioSelectChangeEvent) => {
		setTimeZone(event.target.value)
	}

	const handleSelectCompany = (event: NetcurioSelectChangeEvent) => {
		setActualCompany(event.target.value)
	}

	const handleSelectMainScreen = (event: NetcurioSelectChangeEvent) => {
		setActualMainScreen(event.target.value)
	}

	const companies = useMemo(() => {
		return user?.companies.map((item) => ({
			label: t(item.name ?? ''),
			value: item.rfc,
			isBlock: (item.status as Status)?.key === Constants.USER_STATUS.BLOCKED
		}))
	}, [])

	const saveTimeZone = () => {
		if (user?.time_zone !== timeZone) {
			client
				.mutate({
					mutation: queries.UPDATE_USER,
					variables: {
						time_zone: timeZone
					}
				})
				.then(async () => {
					await UserInformation.updateUserInfo()
					setShowSavedInformationMsg('timezoneUserSaved')
					setChangeTimeZone(false)
				})
				.catch(handleError)
		}
	}

	const saveCompany = () => {
		if (UserInformation.getDefaultCompany() !== actualCompany) {
			client
				.mutate({
					mutation: queries.UPDATE_USER,
					variables: {
						default_company: actualCompany
					}
				})
				.then(async () => {
					await UserInformation.updateUserInfo()
					setShowSavedInformationMsg('defaultCompanyUserSaved')
					setChangeCompany(false)
				})
				.catch(handleError)
		}
	}

	const saveNewMainScreen = () => {
		client
			.mutate({
				mutation: queries.UPDATE_USER,
				variables: {
					homepage: actualMainScreen
				}
			})
			.then(async () => {
				await UserInformation.updateUserInfo()
				setShowSavedInformationMsg('mainScreenUserSaved')
				setChangeMainScreen(false)
			})
			.catch(handleError)
	}

	const cancelChangeTimeZone = () => {
		setChangeTimeZone(false)
		setTimeZone(user?.time_zone as string)
	}

	const cancelChangeCompany = () => {
		setChangeCompany(false)
		getActualCompany()
	}

	const cancelChangeMainScreen = () => {
		setChangeMainScreen(false)
		setActualMainScreen(user?.company?.homepage as string)
	}

	const displayEditTimeZoneButtons = () => {
		return (
			<div
				className={classNames(styles.buttonsTimeZoneSection, {
					[styles.marginLeft20]: !changeTimeZone
				})}
			>
				{changeTimeZone ? (
					<>
						<NetcurioButton
							size={'small'}
							variant="text"
							color="secondary"
							sx={themeButtons}
							onClick={() => cancelChangeTimeZone()}
						>
							<span> {t('cancelButton')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							size={'small'}
							sx={themeButtons}
							onClick={() => saveTimeZone()}
						>
							<span> {t('saveButton')} </span>
						</NetcurioButton>
					</>
				) : (
					<NetcurioButton
						variant="outlined"
						size={'small'}
						className={styles.margin0}
						onClick={() => setChangeTimeZone(true)}
						sx={themeButtons}
					>
						<span> {t('change')} </span>
					</NetcurioButton>
				)}
			</div>
		)
	}

	const displayEditCompanyButtons = () => {
		return changeCompany ? (
			<div className={classNames(styles.displayFlex, styles.buttonsCompanySection)}>
				<NetcurioButton
					variant="text"
					size={'small'}
					color="secondary"
					sx={themeButtons}
					onClick={() => cancelChangeCompany()}
				>
					<span> {t('cancelButton')} </span>
				</NetcurioButton>
				<NetcurioButton
					variant="outlined"
					size={'small'}
					sx={themeButtons}
					onClick={() => saveCompany()}
				>
					<span> {t('saveButton')} </span>
				</NetcurioButton>
			</div>
		) : (
			<div className={styles.buttonsCompanySection}>
				<NetcurioButton
					size={'small'}
					variant="outlined"
					onClick={() => setChangeCompany(true)}
					sx={themeButtons}
				>
					<span> {t('change')} </span>
				</NetcurioButton>
			</div>
		)
	}

	const displayEditMainScreenButtons = () => {
		return changeMainScreen ? (
			<>
				<NetcurioButton
					size={'small'}
					variant="text"
					color="secondary"
					sx={themeButtons}
					onClick={() => cancelChangeMainScreen()}
				>
					<span> {t('cancelButton')} </span>
				</NetcurioButton>
				<NetcurioButton
					variant="outlined"
					size={'small'}
					sx={themeButtons}
					onClick={() => saveNewMainScreen()}
				>
					<span> {t('saveButton')} </span>
				</NetcurioButton>
			</>
		) : (
			<NetcurioButton
				size={'small'}
				variant="outlined"
				onClick={() => setChangeMainScreen(true)}
				sx={themeButtons}
			>
				<span> {t('change')} </span>
			</NetcurioButton>
		)
	}

	const handleError = (error: Error) => {
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setErrorCode(newErrorCode)
		}
	}

	return (
		<div className={styles.profileTabContainer}>
			<div className={styles.headerProfileTitle}>
				<div className={styles.personalInfoTitle}>{t('personalInfo')}</div>
				{!!showSavedInformationMsg && (
					<div className={styles.messageDataSavedContainer}>
						<div className={styles.messageDataSavedText}>{t(showSavedInformationMsg)}</div>
					</div>
				)}
			</div>
			<div className={styles.sectionChangeGeneralInfo}>
				<div className={styles.userProfileEmail}>
					<NetcurioTextField
						fullWidth
						label={t('userEmail')}
						disabled
						value={userInfo.email}
						type="email"
					/>
				</div>
				<div className={styles.userProfileName}>
					<NetcurioTextField
						fullWidth
						label={t('userName')}
						disabled={!editUserInformation}
						value={userInfo['name']}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeUserName(e)}
						error={errorsForm['nameError']}
					/>
				</div>
				<div className={styles.userProfileLastname}>
					<NetcurioTextField
						fullWidth
						label={t('userLastName')}
						disabled={!editUserInformation}
						value={userInfo['lastName']}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeUserLastName(e)}
						error={errorsForm['lastNameError']}
					/>
				</div>
				<div className={styles.userProfilePhoneNumber}>
					<NetcurioTextField
						fullWidth
						label={t('userPhoneNumber')}
						disabled={!editUserInformation}
						value={userInfo['phoneNumber']}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeUserPhoneNumber(e)}
						maxLength={10}
						error={errorsForm['phoneNumberError']}
					/>
				</div>
				<div className={styles.buttonsProfileSection}>{displayEditUserButtons()}</div>
			</div>
			{missingUserInfo && (
				<div className={styles.changeGeneralInfoErrorMessage}>
					<p>{t('missingFields')}</p>
				</div>
			)}
			<div className={styles.headerProfilePassword}>
				<div className={styles.changePasswordLabel}>{t('changePassword')}</div>
			</div>
			<div className={styles.sectionChangePassword}>
				<div className={styles.currentPasswordSection}>
					<div className={styles.passwordInputSection}>
						<NetcurioTextField
							fullWidth
							label={t('currentPassword')}
							value={userInfo.passwords['currentPassword']}
							type="password"
							onChange={(evt) =>
								setUserInfo({
									...userInfo,
									passwords: { ...userInfo.passwords, currentPassword: evt.target.value }
								})
							}
							error={errorsForm['currentPasswordError']}
							disabled={showActualPassword}
						/>
					</div>
					{showChangePasswordInputs()}
					{showActualPassword && (
						<div className={styles.buttonsPasswordSection}>
							<NetcurioButton
								variant="outlined"
								onClick={() => {
									setShowPasswordInputs(true)
									setShowActualPassword(false)
									setUserInfo({
										...userInfo,
										passwords: { ...userInfo.passwords, currentPassword: '' }
									})
								}}
								sx={themeButtons}
								size={'small'}
							>
								<span> {t('change')} </span>
							</NetcurioButton>
						</div>
					)}
				</div>
			</div>
			{!!wrongPasswordText && (
				<div className={styles.passwordChangeErrorMessage}>
					<p>{t(wrongPasswordText)}</p>
				</div>
			)}
			<div className={styles.sectionMainScreenMulticompany}>
				<div className={styles.headerProfileMainScreen}>
					<div className={styles.mainScreenLabel}>{t('mainScreen')}</div>
				</div>
				<div className={styles.sectionMainScreenCompany}>
					<div className={styles.currentScreenSection}>
						<NetcurioFormControl fullWidth>
							<NetcurioInputLabel id="currentScreen">{t('currentScreen')}</NetcurioInputLabel>
							<NetcurioSelect
								labelId="currentScreen"
								variant="outlined"
								label={t('currentScreen')}
								displayEmpty
								fullWidth
								size="medium"
								value={actualMainScreen}
								onChange={(event) => handleSelectMainScreen(event)}
								height="smaller"
								disabled={!changeMainScreen}
							>
								{homepageList().map((screen, i) => (
									<NetcurioMenuItem key={i} value={screen.mainScreen}>
										{screen.nameScreen}
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</NetcurioFormControl>
					</div>
					<div className={styles.buttonsMainScreenSection}>{displayEditMainScreenButtons()}</div>
				</div>
			</div>
			<div className={styles.multiCompany}>
				<div className={styles.multiCompanyHeader}>
					<div className={styles.mainScreenLabel}>{t('currentCompany')}</div>
				</div>
				<div className={styles.currentCompanySection}>
					<div className={classNames(styles.currentCompany)}>
						<NetcurioFormControl fullWidth>
							<NetcurioInputLabel id="actualCurrentCompany">
								{t('actualCurrentCompany')}
							</NetcurioInputLabel>
							<NetcurioSelect
								labelId="actualCurrentCompany"
								variant="outlined"
								label={t('actualCurrentCompany')}
								displayEmpty
								fullWidth
								size="medium"
								value={actualCompany}
								onChange={(event) => handleSelectCompany(event)}
								height="smaller"
								disabled={!changeCompany}
							>
								{actualCompany === 'notSelectedYet' && (
									<NetcurioMenuItem value={actualCompany}>
										<em>{t(actualCompany)}</em>
									</NetcurioMenuItem>
								)}

								{companies &&
									companies.map((company, i) => (
										<NetcurioMenuItem
											key={i}
											value={company.value}
											disabled={company.isBlock}
										>
											{company.label}
										</NetcurioMenuItem>
									))}
							</NetcurioSelect>
						</NetcurioFormControl>
					</div>
					{displayEditCompanyButtons()}
				</div>
			</div>
			<div className={styles.timeZone}>
				<div className={classNames(styles.headerProfileTimeZone)}>
					<div className={styles.timeZoneHeader}>{t('timeZone')}</div>
				</div>
				<div className={classNames(styles.sectionTimeZone, styles.displayFlex)}>
					<div className={classNames(styles.currentTimeZone)}>
						<NetcurioFormControl fullWidth>
							<NetcurioInputLabel id="currentTimeZone">
								{t('currentTimeZone')}
							</NetcurioInputLabel>
							<NetcurioSelect
								labelId="currentTimeZone"
								variant="outlined"
								label={t('currentTimeZone')}
								displayEmpty
								fullWidth
								size="medium"
								value={timeZone}
								onChange={(event) => handleSelectTimeZone(event)}
								height="smaller"
								disabled={!changeTimeZone}
							>
								{timezones.map((tz, i) => (
									<NetcurioMenuItem key={i} value={tz.name}>
										{`(UTC${formatTimezone(tz.name)}) ${tz.name}`}
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</NetcurioFormControl>
					</div>
					{displayEditTimeZoneButtons()}
				</div>
			</div>
			<CatchedErrorModalUserProfileTab open={!!errorCode} errorCode={errorCode} />
		</div>
	)
}
