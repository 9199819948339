import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PURCHASE_REQUISITION_TYPES_CATALOG: DocumentNode = gql`
	query PurchaseRequisitionTypes($companyRfc: String) {
		PurchaseRequisitionTypes(companyRfc: $companyRfc) {
			id
			description
			companyRfc
		}
	}
`
