import { getCurrentUser, Roles } from '@netcurio/frontend-common'
import { getUserRolesForCompany } from './getUserRolesForCompany'
import { getRelationRolesAndStatus } from './relationRolesAndStatus'

export function redirectByRoles(urlToGo: string, rolUser: Roles) {
	if (getCurrentUser()?.company && getUserRolesForCompany().includes(rolUser)) location.href = urlToGo
}

export function redirectByStatus(objectType: string, status: string, paramsToUrl: string) {
	const userRoles = getUserRolesForCompany()
	const objectRelationRolesAndStatus = getRelationRolesAndStatus()
	const filteredRole = userRoles.find((role) => {
		return role === Roles.SUPPLIER || role === Roles.CUSTOMER
	})
	const urlToGo = filteredRole ? objectRelationRolesAndStatus[filteredRole][objectType][status] : ''
	if (window.location.pathname !== urlToGo) location.href = urlToGo + paramsToUrl
}
