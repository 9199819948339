import { useCallback, useEffect, useState } from 'react'

interface FetchResponse<T> {
	total: number
	items: T[]
}

type FetchFunction<T> = (params: {
	skip: number
	limit: number
	filters: Record<string, any>
	sortField: string
	sortOrder: string
}) => Promise<FetchResponse<T>>

interface UseInfiniteScrollOptions<T> {
	fetchFn: FetchFunction<T>
	initialSkip?: number
	initialLimit?: number
	defaultSortOrder?: string
	defaultSortField?: string
}

export function useInfiniteScroll<T>({
	fetchFn,
	initialSkip = 0,
	initialLimit = 10,
	defaultSortOrder = 'DESC',
	defaultSortField = 'createdAt'
}: UseInfiniteScrollOptions<T>) {
	const [items, setItems] = useState<T[]>([])
	const [skip, setSkip] = useState<number>(initialSkip)
	const limit = initialLimit
	const [loading, setLoading] = useState<boolean>(false)
	const [total, setTotal] = useState<number>(0)

	const hasMore = items.length < total

	const [filters, updateFilters] = useState<Record<string, any>[]>([])

	const [sortParams, updateSortParams] = useState({
		field: defaultSortField,
		order: defaultSortOrder
	})

	const loadMore = useCallback(() => {
		if (loading || !hasMore) return
		setSkip((prevSkip) => prevSkip + limit)
	}, [loading, hasMore, limit])

	const reload = useCallback(() => {
		setItems([])
		setSkip(0)
		setTotal(0)
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)
				const { total: newTotal, items: newItems } = await fetchFn({
					skip,
					limit,
					filters,
					sortField: sortParams.field,
					sortOrder: sortParams.order
				})
				setTotal(newTotal)

				if (skip === 0) {
					setItems(newItems)
				} else {
					setItems((prev) => [...prev, ...newItems])
				}
			} catch (error) {
				console.error('Error fetch data:', error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skip, filters, sortParams])

	const setSortParams = ({ field, order }: { field: string; order: string }) => {
		setSkip(0)
		updateSortParams({
			field,
			order
		})
	}

	const setFilters = (newFilters: Record<string, any>[]) => {
		setSkip(0)
		updateFilters(newFilters)
	}

	return {
		items,
		hasMore,
		loading,
		loadMore,
		reload,
		setSkip,
		setFilters,
		sortParams,
		filters,
		setSortParams
	}
}
