import { NetcurioAlert, Severity } from '@netcurio/frontend-components'
import React from 'react'

interface InvoiceAlertProps {
	alert: {
		severity: Severity
		message: string
	} | null
}

export const RequisitionAlert = ({ alert }: InvoiceAlertProps) => {
	if (!alert) return null

	return <NetcurioAlert severity={alert.severity}>{alert.message}</NetcurioAlert>
}
