import delivery from '@netcurio/frontend-assets/src/icons/delivery-reports.svg'
import differences from '@netcurio/frontend-assets/src/icons/differences-reports.svg'
import historicalCM from '@netcurio/frontend-assets/src/icons/historical-cm.svg'
import historicalComplement from '@netcurio/frontend-assets/src/icons/historical-complement.svg'
import historicalInv from '@netcurio/frontend-assets/src/icons/historical-invoices.svg'
import historicalPO from '@netcurio/frontend-assets/src/icons/historical-po.svg'
import historicalTK from '@netcurio/frontend-assets/src/icons/historical-tickets.svg'
import missing from '@netcurio/frontend-assets/src/icons/missing-items.svg'
import products from '@netcurio/frontend-assets/src/icons/product.svg'
import stock from '@netcurio/frontend-assets/src/icons/shelf.svg'
import { Roles, URLS } from '@netcurio/frontend-common'
import { ReportButtonProps } from '@netcurio/frontend-components'
import Constants from '../../../utilities/constants'
import { getUserRolesForCompany } from '../../../utilities/getUserRolesForCompany'
import styles from './btnStyles.module.scss'

export function objectReportArray(history): Array<ReportButtonProps> {
	const userRoles = getUserRolesForCompany()

	const deliveryPresent = {
		image: delivery,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_RESPONSE_TIME}`)
	}

	const missingPresent = {
		image: missing,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_PRODUCT_MISSING_INVOICE}`)
	}

	const deliveryTime: ReportButtonProps = {
		...deliveryPresent,
		text: 'deliveryTimeTitle'
	}
	const deliveryAnswer: ReportButtonProps = {
		...deliveryPresent,
		text: 'responseTimeTitle'
	}

	const missingItems: ReportButtonProps = {
		...missingPresent,
		text: 'missingItemsReports'
	}
	const missingInvoices: ReportButtonProps = {
		...missingPresent,
		text: 'missingInvoicesTitle'
	}

	const itemsDifferences: ReportButtonProps = {
		text: 'differenceBetweenProductQuantitiesTitle',
		image: differences,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(
				`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_DIFFERENCE_BETWEEN_PRODUCT_QUANTITIES}`
			)
	}

	const productsCatalog: ReportButtonProps = {
		text: 'productsCatalogTitle',
		image: products,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_PRODUCTS_CATALOG}`)
	}

	const consignmentStock: ReportButtonProps = {
		text: 'consignmentStockTitle',
		image: stock,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_CONSIGNMENT_STOCK}`)
	}

	const roleButtons = {
		[Roles.SUPPLIER]: [deliveryAnswer, itemsDifferences, missingItems, productsCatalog, consignmentStock],
		[Roles.CUSTOMER]: [
			deliveryTime,
			itemsDifferences,
			missingInvoices,
			productsCatalog,
			consignmentStock
		],
		[Roles.CUSTOMER_NO_ADMINS]: [
			deliveryTime,
			itemsDifferences,
			missingInvoices,
			productsCatalog,
			consignmentStock
		]
	}
	return roleButtons[
		userRoles.includes(Roles.SUPPLIER)
			? Roles.SUPPLIER
			: userRoles.includes(Roles.CUSTOMER_NO_ADMINS)
				? Roles.CUSTOMER_NO_ADMINS
				: Roles.CUSTOMER
	]
}

export function objectHistoricalArray(history): Array<ReportButtonProps> {
	const historicalPurchaseOrders: ReportButtonProps = {
		text: 'historicalPurchaseOrdersTitle',
		image: historicalPO,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.HISTORICAL_PURCHASE_ORDERS}`)
	}
	const historicalInvoices: ReportButtonProps = {
		text: 'historicalInvoiceTitle',
		image: historicalInv,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.HISTORICAL_INVOICES}`)
	}
	const paymentComplementHistory: ReportButtonProps = {
		text: 'paymentComplementHistoryTitle',
		image: historicalComplement,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.HISTORICAL_PAYMENT_COMPLEMENT}`)
	}
	const historicalCreditMemos: ReportButtonProps = {
		text: 'historicalCreditMemosTitle',
		image: historicalCM,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.HISTORICAL_CREDIT_MEMOS}`)
	}
	const historicalTickets: ReportButtonProps = {
		text: 'historicalTickets',
		image: historicalTK,
		className: styles.rBtnDefault,
		goToLink: () =>
			history.push(`${URLS.REPORT_LIST}${Constants.REPORTS.REPORT_URLS.REPORT_HISTORICAL_TICKETS}`)
	}

	return [
		historicalPurchaseOrders,
		historicalInvoices,
		paymentComplementHistory,
		historicalCreditMemos,
		historicalTickets
	]
}
