import { Roles } from '@netcurio/frontend-common'
import React from 'react'

import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'

interface HeaderButtonsProps {
	onExport: () => void
	onNewInvoice: () => void
	userRoles: Roles[] | undefined
}

export const HeaderButtons = ({ onExport, userRoles = [], onNewInvoice }: HeaderButtonsProps) => {
	return (
		<AuthenticatedHeader>
			<div>
				{userRoles.includes(Roles.CUSTOMER) && (
					<AddButton onClick={onNewInvoice} translationKey="newRequestForCreditMemo" />
				)}
			</div>
			<div>
				<ExportButton onClick={onExport} />
			</div>
		</AuthenticatedHeader>
	)
}
