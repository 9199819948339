import { Autocomplete as Ac, InputAdornment, TextField } from '@mui/material'
import { AutocompleteProps as ACProps } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import {
	ColorBaseWhite,
	Netcurio01PrimaryAMain,
	Netcurio01PrimaryBDark,
	Netcurio01PrimaryCLight,
	Netcurio04ActionCDisabled,
	Netcurio04BodyBody1CPrimaryColor,
	Netcurio04BodyBody2ADarkAHighEmphasisText,
	Netcurio05ErrorBDark
} from '@netcurio/frontend-design-tokens'
import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AutocompleteProps extends Partial<ACProps<unknown, boolean, boolean, boolean>> {
	label?: React.ReactNode
	placeholder?: string
	variant: 'filled' | 'outlined' | 'standard'
	error?: boolean
	errorMessage?: string
	minLength?: number
	options: Array<any>
	onSelectValue: (value: any) => void
	getOptionLabel: (value: any) => string
	isOptionEqualToValue?: (option: any, value: any) => boolean
	inputValue?: string
	onInputValueChange?: (input: string) => void
	loading?: boolean
	warning?: boolean
	disableClearable?: boolean
	disabled?: boolean
	size?: 'small' | 'medium'
	endAdornment?: React.ReactNode
	startAdornment?: React.ReactNode
	onOpen?: () => void
	onClose?: () => void
	autoCapitalizeLabel?: boolean
	getOptionKey: (option: any) => string
	autoSelect?: boolean
}

export const Autocomplete = forwardRef<HTMLDivElement, AutocompleteProps>(
	(
		{
			minLength,
			onSelectValue,
			inputValue,
			onInputValueChange,
			loading,
			size,
			getOptionLabel,
			getOptionKey,
			autoSelect,
			...props
		},
		ref
	) => {
		const {
			label,
			error,
			errorMessage,
			disableClearable,
			disabled,
			options,
			variant = 'outlined',
			endAdornment,
			startAdornment,
			...autoCompleteProps
		} = props
		const { t } = useTranslation()
		const [canOpen, setCanOpen] = useState(true)
		const [open, setOpen] = useState(false)
		const FORCED_OPACITY = '20'

		let forcePopupIcon = false
		if (inputValue?.length && minLength) {
			forcePopupIcon = inputValue?.length >= minLength
		}
		const handleClose = () => {
			setOpen(false)
		}
		const handleOpen = () => {
			if (canOpen) {
				setOpen(true)
			}
		}

		const controlledOpen =
			minLength !== undefined && inputValue !== undefined
				? { open, onOpen: handleOpen, onClose: handleClose, forcePopupIcon }
				: {}

		useEffect(() => {
			if (minLength !== undefined && inputValue !== undefined) {
				setCanOpen(inputValue?.length >= minLength)
				if (inputValue?.length === minLength) {
					setOpen(true)
				}
				if (inputValue?.length < minLength) {
					setOpen(false)
				}
			}
		}, [inputValue, minLength])

		const onChange = (_: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
			onSelectValue(newValue)
		}

		const onInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
			onInputValueChange?.(newInputValue)
		}

		const styles = {
			'& .MuiInputBase-root': {
				height: '3.2rem',
				borderColor: Netcurio01PrimaryCLight
			},
			'& .MuiTextField-root': {
				height: '3.2rem'
			},
			'& .MuiInputLabel-shrink': {
				paddingTop: '0.5rem',
				paddingBottom: '0.5rem',
				fontSize: '1.2rem'
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				'&:hover fieldset': {
					borderColor: Netcurio01PrimaryBDark,
					borderWidth: '0.2rem !important'
				}
			},
			'& .MuiAutocomplete-input': {
				textOverflow: 'ellipsis'
			},
			'& .MuiOutlinedInput-root': {
				height: '3.2rem',
				fontSize: '1.2rem',
				letterSpacing: '0.01rem',
				'& fieldset': {
					paddingRight: 0,
					borderColor: Netcurio01PrimaryCLight
				},
				'&:hover fieldset': {
					borderColor: Netcurio01PrimaryBDark
				}
			},
			'& .MuiStandardInput-root': {
				height: '3.2rem',
				'& fieldset': {
					paddingLeft: '1.2rem'
				}
			},
			'& .MuiFilledInput-root': {
				height: '3.2rem',
				borderBottom: '1px solid transparent'
			},
			'& .MuiInputLabel-root': {
				'&.Mui-focused': {
					color: error ? Netcurio05ErrorBDark : Netcurio01PrimaryBDark
				}
			},
			'& .MuiInputBase-input': {
				font: Netcurio04BodyBody2ADarkAHighEmphasisText
			},
			'& .MuiInputBase-input.Mui-disabled': {
				WebkitTextFillColor: Netcurio04ActionCDisabled,
				borderColor: Netcurio04ActionCDisabled,
				backgroundColor: disabled ? 'transparent' : ColorBaseWhite
			},
			'& .MuiOutlinedInput-root.Mui-focused': {
				'& fieldset': {
					borderColor: Netcurio01PrimaryBDark
				}
			},
			'& .MuiAutocomplete-clearIndicator': {
				color: Netcurio05ErrorBDark
			},
			'& .MuiAutocomplete-popupIndicator': {
				color: Netcurio01PrimaryAMain
			},
			'& .MuiOutlinedInput-root.Mui-error': {
				height: '3.2rem',
				'& fieldset': {
					borderColor: Netcurio05ErrorBDark
				},
				'& .MuiSvgIcon-root': {
					color: Netcurio05ErrorBDark
				},
				'&.Mui-error': {
					'&:hover': {
						borderColor: Netcurio05ErrorBDark
					}
				}
			},
			'& .MuiOutlinedInput-root.Mui-disabled': {
				'& fieldset': {
					borderColor: Netcurio04ActionCDisabled
				}
			},
			'& .MuiCircularProgress-root': {
				color: Netcurio01PrimaryBDark,
				marginRight: '0.5rem',
				size: 16
			},
			'& .MuiAutocomplete-inputRoot': {
				paddingRight: 0
			},
			'& .MuiAutocomplete-endIcon': {
				right: '5'
			},
			size: size ?? 'small'
		}

		return (
			<Ac
				ref={ref}
				options={options}
				slotProps={{
					listbox: {
						sx: {
							font: Netcurio04BodyBody2ADarkAHighEmphasisText
						}
					}
				}}
				renderOption={(props, option) => (
					<li
						{...props}
						key={option.text}
						style={{
							backgroundColor: props['aria-selected']
								? Netcurio04BodyBody1CPrimaryColor + FORCED_OPACITY
								: props?.style?.backgroundColor
						}}
					>
						{getOptionLabel(option)}
					</li>
				)}
				onChange={onChange}
				onOpen={handleOpen}
				onClose={handleClose}
				{...autoCompleteProps}
				{...controlledOpen}
				getOptionKey={getOptionKey}
				getOptionLabel={getOptionLabel}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						variant={variant}
						label={label}
						disabled={disabled}
						value={inputValue}
						error={error}
						fullWidth
						helperText={error ? errorMessage : ''}
						onChange={(event) => onInputChange(event, event.target.value)}
						slotProps={{
							input: {
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										{loading ? <CircularProgress color="inherit" size={12} /> : null}
										{endAdornment || params.InputProps.endAdornment}
									</InputAdornment>
								),
								startAdornment: startAdornment ? (
									<InputAdornment position="start">{startAdornment}</InputAdornment>
								) : (
									<React.Fragment />
								)
							}
						}}
					/>
				)}
				loading={loading}
				clearText={t('clear')}
				openText={t('openText')}
				closeText={t('closeText')}
				loadingText={t('loadingOptions')}
				noOptionsText={t('noAutocompleteOptions')}
				disableClearable={disableClearable}
				disabled={disabled}
				sx={styles}
				autoSelect={autoSelect}
			/>
		)
	}
)
