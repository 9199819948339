import { PurchaseRequisitionItemInterface } from '../interfaces/purchase-requisition-item.interface'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'

export const getPurchaseRequisitionDtoHelper = (
	purchaseRequisition: PurchaseRequisitionInterface,
	items: PurchaseRequisitionItemInterface[],
	id?: number
) => {
	return {
		...(purchaseRequisition.id ? { id: id } : {}),
		customerRfc: purchaseRequisition.customerRfc,
		currency: purchaseRequisition.currency,
		requesterEmail: purchaseRequisition.requesterEmail,
		typeId: purchaseRequisition.typeId || null,
		description: purchaseRequisition.description,
		branchOfficeId: purchaseRequisition.branchOfficeId,
		branchOffice: purchaseRequisition.branchOffice,
		deliveryAddressLine1: purchaseRequisition.deliveryAddressLine1,
		deliveryAddressLine2: purchaseRequisition.deliveryAddressLine2,
		deliveryAddressPostalCode: purchaseRequisition.deliveryAddressPostalCode,
		deliveryAddressStateCode: purchaseRequisition.deliveryAddressStateCode,
		deliveryAddressCountry: purchaseRequisition.deliveryAddressCountry,
		deliveryAddressCountryCode: purchaseRequisition.deliveryAddressCountryCode,
		areaId: purchaseRequisition.areaId,
		items: items
	}
}
