import { Roles } from '../enums'
import { URLS } from './urls'

export interface RouteApp {
	link: string
	icon: string
	name: string
	section?: string
	dataTip: string
	roles: Array<Roles>
	hide?: boolean
	isAdmin?: boolean
}
export const ROUTES: Array<RouteApp> = [
	{
		link: URLS.HOME,
		section: 'Home',
		icon: 'homeIcon',
		dataTip: 'menu.homeTooltip',
		name: 'menu.homeText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.PURCHASE_REQUISITION_LIST,
		section: 'PurchaseRequisitions',
		icon: 'purchaseRequisitionIcon',
		dataTip: 'menu.purchaseRequisitionTooltip',
		name: 'menu.purchaseRequisitionText',
		roles: [Roles.APPROVER, Roles.REQUESTER]
	},
	{
		link: URLS.RFQ,
		section: 'RFQ',
		icon: 'RFQIcon',
		dataTip: 'menu.rfqTooltip',
		name: 'menu.rfqText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.PO_LIST,
		section: 'Purchase',
		icon: 'POIcon',
		dataTip: 'menu.purchaseOrderTooltip',
		name: 'menu.purchaseOrderText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.GR_LIST,
		section: 'GoodsReceipts',
		icon: 'GRIcon',
		dataTip: 'menu.goodsReceiptTooltip',
		name: 'menu.goodsReceiptText',
		roles: [Roles.CUSTOMER]
	},
	{
		link: URLS.CONSUMPTION_LIST,
		section: 'Consumption',
		icon: 'consumptionIcon',
		dataTip: 'menu.consignmentTooltip',
		name: 'menu.consignmentText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.INVOICE_LIST,
		section: 'Invoice',
		icon: 'invoiceIcon',
		dataTip: 'menu.invoiceTooltip',
		name: 'menu.invoiceText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.REQUEST_FOR_CREDIT_MEMO_LIST,
		section: 'RequestForCreditMemo',
		icon: 'RFCMemo',
		dataTip: 'menu.requestForCreditMemoTooltip',
		name: 'menu.requestForCreditMemoText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.CREDIT_MEMO_LIST,
		section: 'CreditMemo',
		icon: 'CM',
		dataTip: 'menu.creditMemoTooltip',
		name: 'menu.creditMemoText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	{
		link: URLS.COMPLEMENT_LIST,
		section: 'Complement',
		icon: 'complement',
		dataTip: 'menu.paymentComplementTooltip',
		name: 'menu.paymentComplementText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	},
	/* {
		link: URLS.TICKET_LIST,
		section: 'Ticket',
		icon: 'ticket',
		dataTip: 'ticketText',
		name: 'ticketText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER],
		hide: true
	}, */
	{
		link: URLS.REPORT_LIST,
		section: 'Reports',
		icon: 'report',
		dataTip: 'reportsText',
		name: 'reportsText',
		roles: [Roles.SUPPLIER, Roles.CUSTOMER]
	}
]

export interface RouteMenu {
	link?: string
	name: string
	isAdmin: boolean
	command?: boolean
}
export const routesMenu: Array<RouteMenu> = [
	{
		link: URLS.USER_SETTINGS,
		name: 'profilePreferences',
		isAdmin: false
	},
	{
		link: URLS.ADMINISTRATION,
		name: 'administrationText',
		isAdmin: true
	},
	{
		name: 'LogoutText',
		isAdmin: false,
		command: true
	}
]
