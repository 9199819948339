import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { CANCEL_PURCHASE_REQUISITION } from '../graphql-ops/mutations'

export const cancelPurchaseRequisition = async (
	client: DefaultClient<NormalizedCacheObject>,
	id: number
): Promise<string> => {
	let result: string = ''
	try {
		const response = await client.mutate({
			mutation: CANCEL_PURCHASE_REQUISITION,
			variables: {
				id: id
			}
		})
		if (response.data.cancelPurchaseRequisition.message.includes('Success')) result = 'Success'
	} catch (e) {
		console.error(e)
		result = 'Error'
	}
	return result
}
