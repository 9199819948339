import '@netcurio/frontend-assets/src/images/netcurio-logo.png'
import { getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import {
	NavDrawer,
	NetcurioAppBar,
	NetcurioGrid,
	NetcurioGridThemeOptions,
	useMediaQuery
} from '@netcurio/frontend-components'
import React, { FC, useMemo } from 'react'
import { BrowserRouter, NavLink, Route, Switch } from 'react-router-dom'
import '../style.scss'
import UserInformation from '../utilities/userInformation'
// Routes
import { Auth } from 'aws-amplify'
import { getUserRolesForCompany } from '../utilities/getUserRolesForCompany'
import { AdministratorConsole } from './administratorConsole/administratorConsole'
import { ConsumptionDetail } from './consumption/consumptionDetail/consumptionDetail'
import { ConsumptionList } from './consumptiontList/consumptionList'
import { CreditMemoDetail } from './creditMemoDetail/creditMemoDetail'
import { CreditMemoList } from './creditMemoList/creditMemoList'
import { Dashboard } from './dashboard/dashboard'
import { GoodsReceiptDetail } from './goodsReceiptDetail/goodsReceiptDetail'
import { GoodsReceiptList } from './goodsReceiptList/goodsReceiptList'
import { HomePage } from './home/home'
import { InvoiceDetail } from './invoices/InvoiceDetail/InvoiceDetail'
import { InvoiceEdit } from './invoices/InvoiceEdit/InvoiceEdit'
import { InvoicesList } from './invoices/invoicesList/InvoicesList'
import { ArchivedInvoicesList } from './invoices/invoicesList/archivedInvoicesList/ArchivedInvoicesList'
import { NewConsumption } from './newConsumption/newConsumption'
import { NewEnrollment } from './newEnrollment/newEnrollment'
import { NewGoodsReceipt } from './newGoodsReceipt/NewGoodsReceipt'
import { NewProduct } from './newProduct/newProduct'
import { NewRFQ } from './newRFQ/newRFQ'
import { NewRequestForCreditMemo } from './newRequestForCreditMemo/newRequestForCreditMemo'
import { NewTicket } from './newTicket/newTicket'
import { PaymentComplementDetail } from './paymentComplementDetail/paymentComplementDetail'
import { PaymentComplementList } from './paymentComplementList/paymentComplementList'
import { NewPurchaseOrder } from './purcharse-orders/newPurchaseOrder/newPurchaseOrder'
import { PurchaseOrderDetail } from './purcharse-orders/purchaseOrderDetail/PurchaseOrderDetail'
import { PurchaseOrderEdit } from './purcharse-orders/purchaseOrderEdit/purchaseOrderEdit'
import { PurchaseOrdersList } from './purcharse-orders/purchaseOrderList/purchaseOrderList'
import { PurchaseRequisitionDetail } from './purchase-requisitions/purchaseRequisitionDetail/PurchaseRequisitionDetail'
import { PurchaseRequisitionList } from './purchase-requisitions/purchaseRequisitionList/PurchaseRequisitionList'
import { ReportsRouter } from './reports/reportsRouter'
import { RequestForCreditMemoDetail } from './requestForCreditMemoDetail/requestForCreditMemoDetail'
import { RequestForCreditMemoList } from './requestForCreditMemoList/requestForCreditMemoList'
import { RequestForQuotationDetail } from './requestForQuotationDetail/RequestForQuotationDetail'
import { RequestForQuotationList } from './requestForQuotationList/requestForQuotationList'
import { TicketDetailView } from './ticketDetail/ticketDetail'
import { TicketList } from './ticketList/ticketList'
import { UserSettings } from './userSettings/userSettings'

interface Component {
	path: string
	component: FC
	bar?: string
	type?: {
		mainClass: string
		subClass: string
	}
	hide?: boolean
	exact?: boolean
}

const logout = () => {
	Auth.signOut()
		.then(() => {
			window.localStorage.clear()
			setTimeout(() => {
				location.href = URLS.LOGIN
			}, 100)
		})
		.catch((err) => console.error(err))
}

export default function HomeView() {
	const currentUser = getCurrentUser()
	const roles = getUserRolesForCompany()
	const isCustomer: boolean = roles.includes(Roles.CUSTOMER)
	const isAdmin = !!getCurrentUser() && UserInformation.hasAdminRole()
	const isXXL: boolean = useMediaQuery((theme: typeof NetcurioGridThemeOptions) =>
		theme?.breakpoints.up('xxl')
	)
	const userRoles: Roles[] = useMemo((): Roles[] => getUserRolesForCompany(), [])
	const types = {
		detail: {
			mainClass: 'container-mainView-Detail',
			subClass: 'container-view-detail'
		},
		list: {
			mainClass: 'container-mainView-List',
			subClass: 'container-view-list'
		},
		new: {
			mainClass: 'container-mainView-New',
			subClass: 'container-view-new'
		}
	}

	const sections = {
		HOME: 'Home',
		PO: 'Purchase',
		INVOICE: 'Invoice',
		GR: 'GoodsReceipts',
		COMP: 'Complement',
		REPORT: 'Reports',
		DASH: 'Dashboard',
		RFQ: 'RFQ',
		ADMIN: 'admin',
		CONS: 'Consumption',
		RFCM: 'RequestForCreditMemo',
		CM: 'CreditMemo',
		TICKET: 'Ticket',
		PURCHASE_REQUISITION: 'PurchaseRequisitions',
		ARCHIVED_INVOICES: 'ArchivedInvoices'
	}

	const components: Array<Component> = [
		{
			path: URLS.HOME,
			component: HomePage,
			bar: sections.HOME
		},
		{
			path: URLS.PO_DETAIL,
			component: PurchaseOrderDetail,
			bar: sections.PO,
			type: types.detail
		},
		{
			path: URLS.PO_NEW,
			component: NewPurchaseOrder,
			bar: sections.PO,
			type: types.new
		},
		{
			path: URLS.PO_LIST,
			component: PurchaseOrdersList,
			bar: sections.PO,
			type: types.list
		},
		{
			path: URLS.PO_EDIT,
			component: PurchaseOrderEdit,
			bar: sections.PO,
			type: types.detail
		},
		{
			path: URLS.INVOICE_LIST_ARCHIVED,
			component: ArchivedInvoicesList,
			bar: sections.ARCHIVED_INVOICES,
			type: types.list
		},
		{
			path: URLS.INVOICE_LIST,
			component: InvoicesList,
			bar: sections.INVOICE,
			type: types.list
		},
		{
			path: URLS.INVOICE_DETAIL,
			component: InvoiceDetail,
			bar: sections.INVOICE,
			type: types.detail
		},
		{
			path: URLS.INVOICE_EDIT,
			component: InvoiceEdit,
			bar: sections.INVOICE,
			type: types.detail
		},
		{
			path: URLS.NEW_INVOICE,
			component: InvoicesList,
			bar: sections.INVOICE,
			type: types.new
		},
		{
			path: URLS.GR_LIST,
			component: GoodsReceiptList,
			bar: sections.GR,
			type: types.list,
			hide: !isCustomer
		},
		{
			path: URLS.GR_NEW,
			component: NewGoodsReceipt,
			bar: sections.GR,
			type: types.detail
		},
		{
			path: URLS.GR_DETAIL,
			component: GoodsReceiptDetail,
			bar: sections.GR,
			type: types.detail
		},
		{
			path: URLS.COMPLEMENT_LIST,
			component: PaymentComplementList,
			bar: sections.COMP,
			type: types.list
		},
		{
			path: URLS.COMPLEMENT_DETAIL,
			component: PaymentComplementDetail,
			bar: sections.COMP,
			type: types.detail
		},
		{
			path: URLS.REPORT_LIST,
			component: ReportsRouter,
			bar: sections.REPORT,
			type: types.detail
		},
		{
			path: URLS.DASHBOARDS,
			component: Dashboard,
			bar: sections.DASH,
			type: types.detail,
			hide: true
		},
		{
			path: URLS.RFQ,
			component: RequestForQuotationList,
			bar: sections.RFQ,
			type: types.list
		},
		{
			path: URLS.RFQ_NEW,
			component: NewRFQ,
			bar: sections.RFQ,
			type: types.new
		},
		{
			path: URLS.RFQ_DETAIL,
			component: RequestForQuotationDetail,
			bar: sections.RFQ,
			type: types.detail
		},
		{
			path: URLS.ADMINISTRATION,
			component: AdministratorConsole,
			bar: sections.ADMIN,
			type: types.list,
			hide: !isAdmin
		},
		{
			path: URLS.USER_SETTINGS,
			component: UserSettings,
			bar: sections.ADMIN,
			type: types.detail
		},
		{
			path: URLS.NEW_PRODUCT,
			component: NewProduct,
			bar: sections.ADMIN,
			type: types.new
		},
		{
			path: URLS.NEW_ENROLLMENT,
			component: NewEnrollment,
			bar: sections.ADMIN,
			type: types.new
		},
		{
			path: URLS.CONSUMPTION_LIST,
			component: ConsumptionList,
			bar: sections.CONS,
			type: types.list
		},
		{
			path: URLS.NEW_CONSUMPTION,
			component: NewConsumption,
			bar: sections.CONS,
			type: types.detail
		},
		{
			path: URLS.CONSUMPTION_DETAIL,
			component: ConsumptionDetail,
			bar: sections.CONS,
			type: types.detail
		},
		{
			path: URLS.REQUEST_FOR_CREDIT_MEMO_LIST,
			component: RequestForCreditMemoList,
			bar: sections.RFCM,
			type: types.list
		},
		{
			path: URLS.RFCM_DETAIL,
			component: RequestForCreditMemoDetail,
			bar: sections.RFCM,
			type: types.detail
		},
		{
			path: URLS.NEW_REQUEST_FOR_CREDIT_MEMO,
			component: NewRequestForCreditMemo,
			bar: sections.RFCM,
			type: types.detail
		},
		{
			path: URLS.CREDIT_MEMO_LIST,
			component: CreditMemoList,
			bar: sections.CM,
			type: types.list
		},
		{
			path: URLS.NEW_CREDIT_MEMO,
			component: CreditMemoList,
			bar: sections.CM,
			type: types.new
		},
		{
			path: URLS.TICKET_LIST,
			component: TicketList,
			bar: sections.TICKET,
			type: types.list,
			hide: true
		},
		{
			path: URLS.CREDIT_MEMO_DETAIL,
			component: CreditMemoDetail,
			bar: sections.CM,
			type: types.detail
		},
		{
			path: URLS.NEW_TICKET,
			component: NewTicket,
			bar: sections.TICKET,
			type: types.new,
			hide: true
		},
		{
			path: URLS.TICKET_DETAIL,
			component: TicketDetailView,
			bar: sections.TICKET,
			type: types.detail,
			hide: true
		},
		{
			path: URLS.PURCHASE_REQUISITION_LIST,
			component: PurchaseRequisitionList,
			bar: sections.PURCHASE_REQUISITION,
			type: types.list
		},
		{
			path: URLS.PURCHASE_REQUISITION_DETAIL,
			component: PurchaseRequisitionDetail,
			bar: sections.PURCHASE_REQUISITION,
			type: types.detail
		}
	]

	const getOverflowY = (componentPath: string) => {
		if (componentPath.includes('list') || componentPath.includes('requisitions')) {
			return 'hidden'
		}
		return 'visible'
	}

	return (
		<div className={'backgroundMenu'}>
			<div id="parentModalExpiredSession" />
			<div id="parentModalUploadFiles" />
			<div id="parentModal" />
			<div id="parentSecondaryModal" />
			<div id="parentModalErrorInvitation" />
			<div id="parentModalError" />
			<BrowserRouter>
				<Switch>
					{components
						.filter((component: Component) => !component.hide)
						.map((component: Component) => (
							<Route path={component.path} key={component.path} exact={component.exact}>
								<NetcurioAppBar user={currentUser!} userRoles={userRoles} logout={logout} />
								<NetcurioGrid
									container
									minHeight="calc(100vh - 4rem)"
									height="calc(100vh - 4rem)"
									className="mainViewHome"
								>
									<NetcurioGrid
										item
										xs={12}
										xl={12}
										xxl={1}
										position={isXXL ? 'relative' : 'fixed'}
										zIndex={5}
									>
										{component.bar && (
											<NavDrawer
												activeSection={component.bar}
												userRoles={userRoles}
												NavLink={NavLink}
											/>
										)}
									</NetcurioGrid>
									<NetcurioGrid
										item
										xs={12}
										xl={12}
										xxl={11}
										marginLeft={isXXL ? '0' : ' 5rem'}
										height="100%"
									>
										<main
											style={{
												margin: '0 1rem',
												maxHeight: 'calc(100vh - 4rem)',
												overflowY: getOverflowY(component.path)
											}}
										>
											<component.component />
										</main>
									</NetcurioGrid>
								</NetcurioGrid>
							</Route>
						))}
				</Switch>
			</BrowserRouter>
		</div>
	)
}
