export default {
	PURCHASE_ORDER: 'PURCHASE_ORDER',
	CUSTOMER_PURCHASE_ORDER: 'CUSTOMER_PURCHASE_ORDER',
	INVOICE: 'INVOICE',
	PAYMENT_COMPLEMENT: 'PAYMENT_COMPLEMENT',
	REQUEST_FOR_QUOTATION: 'REQUEST_FOR_QUOTATION',
	CONSUMPTION: 'CONSUMPTION',
	REQUEST_FOR_CREDIT_MEMO: 'REQUEST_FOR_CREDIT_MEMO',
	CREDIT_MEMO: 'CREDIT_MEMO',
	TICKET: 'TICKET',
	STANDALONE: 'STANDALONE',
	PURCHASE_REQUISITION: 'PURCHASE_REQUISITION'
}
