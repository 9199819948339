import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PURCHASE_REQUISITIONS: DocumentNode = gql`
	query PurchaseRequisitions(
		$limit: Int
		$skip: Int
		$sortField: String
		$sortOrder: String
		$filter: [Filter]
		$archived: Boolean
	) {
		PurchaseRequisitions(
			limit: $limit
			skip: $skip
			sortField: $sortField
			sortOrder: $sortOrder
			filter: $filter
			archived: $archived
		) {
			total
			list {
				id
				customerRfc
				status
				area {
					id
					description
				}
				type {
					id
					description
				}
				description
				total
				currency
				requester {
					lastname
					name
					email
				}
				total
				currency
				createdAt
				currentApprover {
					lastname
					name
				}
			}
		}
	}
`

export const GET_PURCHASE_REQUISITION_DETAIL: DocumentNode = gql`
	query PurchaseRequisition($id: ID!, $customerRfc: String) {
		PurchaseRequisition(id: $id, customerRfc: $customerRfc) {
			id
			customerRfc
			requester {
				name
				lastname
				email
			}
			requesterEmail
			typeId
			type {
				id
				description
			}
			status
			description
			branchOfficeId
			branchOffice
			deliveryAddressLine1
			deliveryAddressLine2
			deliveryAddressPostalCode
			deliveryAddressStateCode
			deliveryAddressState
			deliveryAddressCountryCode
			deliveryAddressCountry
			folderUuid
			currency
			createdAt
			createdBy {
				name
				lastname
				email
			}
			updatedAt
			total
			areaId
			area {
				id
				description
			}
			items {
				position
				customerRfc
				supplierRfc
				supplierDescription
				productCode
				productDescription
				quantity
				unitPrice
				unit
				netAmount
				requiredDeliveryDate
			}
			approvals {
				level
				notifiedAt
				approvedAt
				rejectedAt
				approver {
					email
					name
					lastname
				}
			}
			comments {
				uuid
				text
				uuid
				createdBy {
					name
					lastname
				}
			}
		}
	}
`

export const GET_PURCHASE_REQUISITION_COMMENTS: DocumentNode = gql`
	query PurchaseRequisition($id: ID!, $customerRfc: String) {
		PurchaseRequisition(id: $id, customerRfc: $customerRfc) {
			comments {
				text
				uuid
				createdBy {
					name
					lastname
				}
			}
			createdBy {
				name
				lastname
				email
			}
		}
	}
`

export const ACTIVE_USERS_BY_COMPANY: DocumentNode = gql`
	query ActiveUserByCompany($filter: [FilterInput]) {
		ActiveUserByCompany(filter: $filter) {
			total
			list {
				email
				name
				lastname
			}
		}
	}
`
