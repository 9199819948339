import { Roles } from '@netcurio/frontend-common'
import {
	NetcurioTable,
	NetcurioTableBody,
	NetcurioTableCell,
	NetcurioTableContainer,
	NetcurioTableHead,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsumptionItem } from '../../../../types'
import { getUserRolesForCompany } from '../../../../utilities/getUserRolesForCompany'
import { ItemConsumption } from './ItemConsumption/ItemConsumption'

interface ItemsListProps {
	dataTable: ConsumptionItem[]
}
export const ItemsList: FC<ItemsListProps> = ({ dataTable }) => {
	const { t } = useTranslation()
	const userRoles = getUserRolesForCompany()
	return (
		<NetcurioTableContainer minHeight="calc(100vh - 36.5rem)">
			<NetcurioTable size="small">
				<colgroup>
					<col style={{ width: '10%' }} />
					{userRoles.includes(Roles.CUSTOMER) && <col style={{ width: '20%' }} />}
					<col style={{ width: '35%' }} />
					<col style={{ width: '15%' }} />
					<col style={{ width: '10%' }} />
					<col style={{ width: '10%' }} />
				</colgroup>
				<NetcurioTableHead>
					<NetcurioTableRow isDetailHeader>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('positionTooltip')} placement="top">
								<span>{t('positionText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						{userRoles.includes(Roles.CUSTOMER) && (
							<NetcurioTableCell align="center">
								<NetcurioTooltip title={t('supplierText')} placement="top">
									<span>{t('supplierText')}</span>
								</NetcurioTooltip>
							</NetcurioTableCell>
						)}
						<NetcurioTableCell>
							<NetcurioTooltip title={t('descriptionProductHeader')} placement="top">
								<span>{t('descriptionProductHeader')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('storageLocation')} placement="top">
								<span>{t('storageLocation')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('quantity')} placement="top">
								<span>{t('quantity')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
						<NetcurioTableCell align="center">
							<NetcurioTooltip title={t('currencyText')} placement="top">
								<span>{t('currencyText')}</span>
							</NetcurioTooltip>
						</NetcurioTableCell>
					</NetcurioTableRow>
				</NetcurioTableHead>
				<NetcurioTableBody>
					{dataTable.map((item: ConsumptionItem) => (
						<ItemConsumption key={item.position} informationTable={item} />
					))}
				</NetcurioTableBody>
			</NetcurioTable>
		</NetcurioTableContainer>
	)
}
