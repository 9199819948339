import { Header, NetcurioGrid } from '@netcurio/frontend-components'
import React, { ReactElement, useEffect } from 'react'
import { showModalExpiredSession } from '../../../components/expiredSessionMessage'
import { ReportListTable } from './reportListTable'

export const ReportList = (): ReactElement => {
	useEffect(() => {
		showModalExpiredSession()
	})

	return (
		<NetcurioGrid
			container
			maxWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<Header />
			<ReportListTable />
		</NetcurioGrid>
	)
}
