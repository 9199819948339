import { Company, getCompany, User } from '@netcurio/frontend-common'
import {
	NetcurioAlert,
	NetcurioButton,
	NetcurioDialog,
	NetcurioDivider,
	NetcurioGrid,
	NetcurioIcons,
	ReadOnlyInput,
	Severity
} from '@netcurio/frontend-components'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../../components/ConditionalRender/ConditionalRender'
import { UserContext } from '../../../../context/UserContext'
import useForm from '../../../../hooks/useForm'
import { MEXICO_STATES_LIST } from '../../../../utilities/constants/mexicoStates'
import styles from './MyCompany.module.scss'
import { useCompany } from './useCompany'

interface CompanyForm {
	name: string
	address_line_1: string
	postal_code: string
	state_code: string
}

export const MyCompanyTab = () => {
	const { t } = useTranslation()
	const { rfc } = getCompany()
	const { user, setUser } = useContext(UserContext)

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

	const [alert, setAlert] = useState<{ severity: Severity; message: string } | null>(null)

	const [isEditModeActive, setIsEditModeActive] = useState(false)

	const { company, patchCompany, setCompany } = useCompany({
		rfc: rfc!,
		onLoadCompanyCallback: (company) => {
			updateValues({
				name: company?.name ?? '',
				address_line_1: company?.address_line_1 ?? '',
				state_code: company?.state_code ?? '',
				postal_code: company?.postal_code ?? ''
			})
		}
	})

	const onSuccess = (values: CompanyForm) => {
		setCompany({
			...company,
			...values
		})
		setAlert({
			severity: Severity.Success,
			message: t('infoUpdatedSuccessfully')
		})
		if (user) {
			updateCompanyDataInLocalStorage(user, rfc!, values)
		}
	}

	const { values, updateValues, resetForm } = useForm<CompanyForm>({
		name: '',
		address_line_1: '',
		postal_code: '',
		state_code: ''
	})

	const handleSave = () => {
		setAlert(null)

		patchCompany(values)
			.then(() => {
				onSuccess(values)
			})
			.catch(() => {
				setAlert({
					severity: Severity.Error,
					message: t('errorSavingData')
				})
			})
			.finally(() => {
				setIsEditModeActive(false)
			})
	}

	const updateCompanyDataInLocalStorage = (user: User, rfc: string, updatedRecord: CompanyForm) => {
		const companies = user.companies.map((c: Company) => {
			if (c.rfc === rfc) {
				return { ...c, ...updatedRecord }
			}
			return c
		})

		setUser({
			...user,
			company: {
				...user.company,
				...updatedRecord
			},
			companies
		})
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.titleAndActionsContainer}>
					<h1 className={styles.tabTitle}>{t('companyData')}</h1>
					<div className={styles.actionButtonsContainer}>
						<ConditionalRender condition={!isEditModeActive}>
							<NetcurioButton
								size="small"
								variant="contained"
								onClick={() => {
									setAlert(null)
									setIsEditModeActive(true)
								}}
							>
								{t('modify')}
							</NetcurioButton>
						</ConditionalRender>
						<ConditionalRender condition={isEditModeActive}>
							<NetcurioButton
								size="small"
								variant="outlined"
								onClick={() => {
									resetForm({
										name: company?.name ?? '',
										address_line_1: company?.address_line_1 ?? '',
										state_code: company?.state_code ?? '',
										postal_code: company?.postal_code ?? ''
									})
									setIsEditModeActive(false)
								}}
							>
								{t('cancelText')}
							</NetcurioButton>
							<NetcurioButton
								size="small"
								variant="contained"
								onClick={() => {
									setAlert(null)
									if (
										values.name === '' ||
										values.address_line_1 === '' ||
										values.postal_code === '' ||
										values.state_code === ''
									) {
										setAlert({
											severity: Severity.Error,
											message: t('cannotSaveEmptyFields')
										})
										return
									}
									setIsConfirmationModalOpen(true)
								}}
								endIcon={<NetcurioIcons.SaveOutlined />}
							>
								{t('saveButton')}
							</NetcurioButton>
						</ConditionalRender>
					</div>
				</div>
				<ConditionalRender condition={alert !== null}>
					<div className={styles.alertContainer}>
						<div className={styles.alert}>
							<NetcurioAlert severity={alert?.severity}>{alert?.message}</NetcurioAlert>
						</div>
					</div>
				</ConditionalRender>
			</div>
			<NetcurioDivider />
			<NetcurioGrid
				columns={8}
				container
				rowSpacing={4}
				columnSpacing={6}
				className={styles.gridContainer}
			>
				<NetcurioGrid item xs={2}>
					<ReadOnlyInput value={company?.rfc ?? ''} label={t('rfc')} />
				</NetcurioGrid>
				<NetcurioGrid item xs={4}>
					<ReadOnlyInput
						value={values.name}
						label={t('companyNameText')}
						inputValue={values.name}
						isEditModeActive={isEditModeActive}
						onInputChange={(value) => updateValues({ name: value })}
					/>
				</NetcurioGrid>
				<NetcurioGrid item xs={2}>
					<ReadOnlyInput value={company?.created_at ?? ''} label={t('createdEnrollment')} />
				</NetcurioGrid>

				<NetcurioGrid item xs={6}>
					<ReadOnlyInput
						value={values.address_line_1}
						label={t('addressInputNewCompany')}
						inputValue={values.address_line_1}
						isEditModeActive={isEditModeActive}
						onInputChange={(value) => updateValues({ address_line_1: value })}
					/>
				</NetcurioGrid>
				<NetcurioGrid item xs={2}>
					<ReadOnlyInput value={company?.country_description ?? ''} label={t('country')} />
				</NetcurioGrid>
				<NetcurioGrid item xs={2}>
					<ReadOnlyInput
						value={values.state_code}
						label={t('state')}
						type="autocomplete"
						options={MEXICO_STATES_LIST.map((state) => ({
							label: state.name,
							value: state.code
						}))}
						isEditModeActive={isEditModeActive}
						onInputChange={(value) => updateValues({ state_code: value })}
					/>
				</NetcurioGrid>
				<NetcurioGrid item xs={1}>
					<ReadOnlyInput
						value={values.postal_code}
						label={t('zipcodeNewCompany')}
						isEditModeActive={isEditModeActive}
						onInputChange={(value) => updateValues({ postal_code: value })}
					/>
				</NetcurioGrid>
			</NetcurioGrid>

			<NetcurioDialog
				titleText={t('saveChanges')}
				open={isConfirmationModalOpen}
				actionButtons={
					<>
						<NetcurioButton
							onClick={() => {
								setIsConfirmationModalOpen(false)
								setIsEditModeActive(false)
								resetForm({
									name: company?.name ?? '',
									address_line_1: company?.address_line_1 ?? '',
									state_code: company?.state_code ?? '',
									postal_code: company?.postal_code ?? ''
								})
							}}
							variant="outlined"
						>
							{t('cancelButton')}
						</NetcurioButton>
						<NetcurioButton
							variant="contained"
							onClick={() => {
								setIsConfirmationModalOpen(false)
								handleSave()
							}}
						>
							{t('acceptTextButton')}
						</NetcurioButton>
					</>
				}
			>
				<Trans>
					<p>{t('modifyCompanyInfoConfirmation')}</p>
				</Trans>
			</NetcurioDialog>
		</div>
	)
}
