import { Filter, Roles, URLS } from '@netcurio/frontend-common'
import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Order } from '../../../../types'
import Constants from '../../../../utilities/constants'
import { getUserRolesForCompany } from '../../../../utilities/getUserRolesForCompany'
import UserInformation from '../../../../utilities/userInformation'
import EnrollmentDetailScreen from './enrollmentDetail/enrollmentDetail'
import { EnrollmentsList } from './enrollmentsList/enrollmentsList'

interface Props {
	chooseMainHeaderButton: (button: string) => void
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}

export default function EnrollmentSelectionScreen({ chooseMainHeaderButton, setDataFilterArray }: Props) {
	const { path } = useRouteMatch()

	useEffect(() => {
		if (
			window.location.pathname ===
			URLS.ADMINISTRATION + '/' + Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB
		) {
			if (getUserRolesForCompany().includes(Roles.SUPPLIER)) chooseMainHeaderButton('enrollmentsList')
			else {
				if (UserInformation.isEnrollmentRequestApprover()) chooseMainHeaderButton('inviteSupplier')
			}
			if (
				getUserRolesForCompany().includes(Roles.CUSTOMER) &&
				!UserInformation.isEnrollmentRequestApprover()
			) {
				chooseMainHeaderButton('enrollmentsListExport')
			}
		} else chooseMainHeaderButton('enrollmentsDetail')
	}, [window.location.pathname])

	return (
		<Switch>
			<Route exact path={path}>
				<EnrollmentsList setDataFilterArray={setDataFilterArray} />
			</Route>
			<Route path={`${path}/:invitation`}>
				<EnrollmentDetailScreen />
			</Route>
		</Switch>
	)
}
