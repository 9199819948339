import { currencyFormatter, getCurrentUser, URLS, User } from '@netcurio/frontend-common'
import {
	ApprovalFlowSection,
	Autocomplete,
	Comments,
	CustomInputLabel,
	CustomMenuItem,
	NetcurioButton,
	NetcurioDivider,
	NetcurioFormControl,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioSelectChangeEvent,
	OutlinedSelect,
	OutlinedTextField,
	Severity,
	StepProgressBar,
	StepProgressBarProps,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { TableContentItem } from '@netcurio/frontend-components/src'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { DocumentNode } from 'graphql'
import { TFunction } from 'i18next'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import buttonStyles from '../../../common/styles/button.module.scss'
import commonDetail from '../../../common/styles/detail.module.scss'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ConditionalRender } from '../../../components/ConditionalRender/ConditionalRender'
import { useAreas } from '../../../hooks/useAreas'
import { useBranches } from '../../../hooks/useBranches'
import { useClient } from '../../../hooks/useClient'
import { useCurrencies } from '../../../hooks/useCurrencies'
import { useMeasurementUnits } from '../../../hooks/useMeasurementUnits'
import { usePurchaseRequisitionTypes } from '../../../hooks/usePurchaseRequisitionTypes'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { useUsers } from '../../../hooks/useUsers'
import { Area, PurchaseRequisitionType } from '../../../types'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { useDebounce } from '../../../utilities/useDebounce'
import { ErrorModal } from '../../invoices/InvoiceEdit/Modals'
import { cancelPurchaseRequisition } from '../api/cancel-purchase-requisition'
import { getPurchaseRequisitionDetail } from '../api/get-purchase-requisition-detail'
import { getStatusSteps } from '../business-rules/get-status-steps'
import { PurchaseRequisitionStatusEnum } from '../enums/purchase-requisition-status.enum'
import {
	CREATE_PURCHASE_REQUISITION,
	SEND_PURCHASE_REQUISITION,
	UPDATE_PURCHASE_REQUISITION
} from '../graphql-ops/mutations'
import { ACTIVE_USERS_BY_COMPANY } from '../graphql-ops/queries'
import { getPurchaseRequisitionDtoHelper } from '../helpers/get-purchase-requisition-dto.helper'
import { useCurrentApprover } from '../hooks/useCurrentApprover'
import { useCurrentRequester } from '../hooks/useCurrentRequester'
import { usePurchaseRequisitionComments } from '../hooks/usePurchaseRequisitionComments'
import { PurchaseRequisitionApprovalInterface } from '../interfaces/purchase-requisition-approval.interface'
import { PurchaseRequisitionItemInterface } from '../interfaces/purchase-requisition-item.interface'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'
import { CancelPurchaseRequestModal } from '../modals/cancelConfirmationModal'
import {
	defaultValuesItemPr,
	defaultValuesPurchaseRequisition
} from './default-values/defaultValuesPurchaseRequisition'
import { Approve } from './PurchaseRequisitionActions/Approve'
import { Reject } from './PurchaseRequisitionActions/Reject/Reject'
import { ReturnToReview } from './PurchaseRequisitionActions/ReturnToReview/ReturnToReview'
import styles from './purchaseRequisitionDetail.module.scss'
import { PurchaseRequisitionPosition } from './PurchaseRequisitionPosition/PurchaseRequisitionPosition'
import { RequisitionAlert } from './requisitionAlert/requisitionAlert'

export interface FieldValidations {
	product_description: boolean
	quantity: boolean
	unit_measure: boolean
	unit_cost: boolean
	required_date: boolean
}

const getApprovalStatusLabel = (
	t: TFunction<'translation', undefined>,
	approver: PurchaseRequisitionApprovalInterface,
	previousActiveStatus: boolean
) => {
	if (approver.approvedAt) {
		return t('APPROVED')
	}
	if (approver.rejectedAt) {
		return t('REJECTED')
	}

	return previousActiveStatus ? '-' : t('pending')
}

export const PurchaseRequisitionDetail: FC = () => {
	const CHARACTERS_LIMIT = 120
	const client = useClient()
	const user = getCurrentUser()
	const totalContainerRef = useRef<HTMLDivElement>(null)
	const history: RouterChildContext['router']['history'] = useHistory()
	const purchaseRequisitionId = getUrlParameter('id') ? parseInt(getUrlParameter('id')) : undefined
	const recentlyCreated = getUrlParameter('recentlyCreated') ?? false
	const recentlySendToPending = getUrlParameter('recentlySendToPending') ?? false
	const queryParams = new URLSearchParams(history.location.search)

	const { t } = useTranslation()
	const { currencies, lastPrioritizedCode } = useCurrencies()
	const { users } = useUsers()
	const { isRequester, isApprover } = useUserRoles()
	const { measurementUnits } = useMeasurementUnits()
	const { areas } = useAreas(user?.company?.rfc ?? '')
	const { purchaseRequisitionTypes } = usePurchaseRequisitionTypes(user?.company?.rfc ?? '')
	const { branches } = useBranches(user?.company?.rfc)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const [suggestedUsers, setSuggestedUsers] = useState<Partial<User>[]>(users)
	const [userPreSavedObject, setUserPreSavedObject] = useState<Partial<User> | undefined>(user ?? undefined)
	const [userInputValue, setUserInputValue] = useState<string>('')
	const [userLoading, setUserLoading] = useState<boolean>(false)
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

	const [cancelModalVisibility, setCancelModalVisibility] = useState<boolean>(false)
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [totalErrorsInPositions, setTotalErrorsInPositions] = useState<Array<FieldValidations>>([])
	const [approvalFlow, setApprovalFlow] = useState<TableContentItem | undefined>(undefined)
	const [alert, setAlert] = useState<{
		message: string
		severity: Severity
	} | null>(null)
	const defaultValue = {
		...defaultValuesPurchaseRequisition,
		requester: user ?? {},
		requesterEmail: user?.email ?? '',
		createdBy: user ?? {},
		customerRfc: user?.company?.rfc ?? '',
		currency: 'MXN',
		items: [defaultValuesItemPr]
	}
	const [purchaseRequisition, setPurchaseRequisition] = useState<PurchaseRequisitionInterface>(defaultValue)
	const { isUserCurrentApprover } = useCurrentApprover({ purchaseRequisition })
	const { isUserCurrentRequester, isUserOwner, isInApprovalProcess } = useCurrentRequester({
		purchaseRequisition
	})

	const [initialValue, setInitialValue] = useState<PurchaseRequisitionInterface>(defaultValue)
	const [prTypeMissing, setPrTypeMissing] = useState<boolean>(false)
	const [descriptionIsMissing, setDescriptionIsMissing] = useState<boolean>(false)
	const [branchOfficeMissing, setBranchOfficeMissing] = useState<boolean>(false)

	const [requesterMissing, setRequesterMissing] = useState<boolean>(false)
	const [areaMissing, setAreaMissing] = useState<boolean>(false)
	const { comments, addComment, addPendingComments } = usePurchaseRequisitionComments({
		purchaseRequisitionId: purchaseRequisitionId,
		customerRfc: purchaseRequisition?.customerRfc ?? '',
		initialComments: purchaseRequisition.comments ?? [],
		onCommentAdded: () => {
			if (isApprover && checkFormHasUncommentChanges()) {
				const purchaseRequisitionDTO = getPurchaseRequisitionDtoHelper(
					purchaseRequisition,
					purchaseRequisition.items,
					purchaseRequisitionId
				)
				client
					.mutate({
						mutation: UPDATE_PURCHASE_REQUISITION,
						variables: { purchaseRequisition: purchaseRequisitionDTO }
					})
					.then(() => {
						setAlert(null)
						getPurchaseDetail(purchaseRequisitionId)
					})
			}
		}
	})
	const initialSteps = getStatusSteps(purchaseRequisition)
	const [stepArray, setStepArray] = useState<StepProgressBarProps[]>(initialSteps)
	const [userSearchString, setUserSearchString] = useState<string>(purchaseRequisition.requesterEmail)
	const debouncedUserInput = useDebounce<string>(userSearchString, 500)

	const handleAlertAndQueryParams = () => {
		const showAlert = (messageKey: string) => {
			setAlert({
				severity: Severity.Info,
				message: t(messageKey)
			})
		}

		const removeQueryParam = (param: string) => {
			if (queryParams.has(param)) {
				queryParams.delete(param)
				history.replace({ search: queryParams.toString() })
			}
		}

		if (recentlyCreated || recentlySendToPending) {
			const messageKey = recentlySendToPending
				? 'purchaseRequisitions.detail.successfullySent'
				: 'purchaseRequisitions.detail.informationSaved'
			showAlert(messageKey)

			removeQueryParam('recentlyCreated')
			removeQueryParam('recentlySendToPending')
		}
	}

	/* EFFECTS */
	useEffect(() => {
		handleAlertAndQueryParams()
	}, [recentlyCreated, recentlySendToPending])

	useEffect(() => {
		if (purchaseRequisitionId) {
			showLoadingSpinner()
			getPurchaseDetail(purchaseRequisitionId).then()
		}
	}, [purchaseRequisitionId])

	const { headerFormDisabled, positionsAndButtonsDisabled } = useMemo(() => {
		const isStatusIn = (statuses: PurchaseRequisitionStatusEnum[]) =>
			statuses.includes(purchaseRequisition.status)

		const isPendingStatus = isStatusIn([PurchaseRequisitionStatusEnum.PENDING])

		const isNewStatus = isStatusIn([PurchaseRequisitionStatusEnum.NEW])
		const isUnderReviewStatus = isStatusIn([PurchaseRequisitionStatusEnum.UNDER_REVIEW])

		if (isApprover && isRequester) {
			return {
				positionsAndButtonsDisabled: isPendingStatus
					? !isUserCurrentApprover
					: !((isUnderReviewStatus || isNewStatus) && isUserCurrentRequester),
				headerFormDisabled: !(isNewStatus && isUserCurrentRequester)
			}
		}

		if (isRequester) {
			return {
				positionsAndButtonsDisabled: !(
					(isNewStatus || isUnderReviewStatus) &&
					isUserCurrentRequester
				),
				headerFormDisabled: !(isNewStatus && isUserCurrentRequester)
			}
		}
		return {
			positionsAndButtonsDisabled: !isUserCurrentApprover || !isPendingStatus,
			headerFormDisabled: true
		}
	}, [purchaseRequisition, isApprover, isUserCurrentApprover])

	useEffect(() => {
		if (
			purchaseRequisition.status &&
			purchaseRequisition.approvals &&
			purchaseRequisition.approvals?.length > 0
		) {
			createApprovalsObject(purchaseRequisition.approvals, purchaseRequisition.status)
		}
	}, [purchaseRequisition.approvals])
	useEffect(() => {
		if (debouncedUserInput.length >= 3) {
			setUserLoading(true)
			suggestUsers(debouncedUserInput)
		}
	}, [debouncedUserInput])

	const checkFormHasUncommentChanges = () => {
		const hasChanges = JSON.stringify(purchaseRequisition.items) !== JSON.stringify(initialValue.items)
		const hasCommentsAdded = comments?.length > (initialValue.comments?.length ?? 0)
		return hasChanges && !hasCommentsAdded
	}

	/* HEADER BUTTONS LOGIC */
	const handleClose = () => {
		history.push(URLS.PURCHASE_REQUISITION_LIST)
	}
	/*const handleOpenAttachments = () => {
		console.log('Clicked OpenAttachments')
	}*/
	const handleSubmit = async () => {
		try {
			showLoadingSpinner()
			const isUnderReview = purchaseRequisition.status === PurchaseRequisitionStatusEnum.UNDER_REVIEW
			if (isUnderReview && isRequester && checkFormHasUncommentChanges()) {
				setAlert({
					severity: Severity.Warning,
					message: t('purchaseRequisitions.detail.changeDetected')
				})
				return
			}
			if (
				[PurchaseRequisitionStatusEnum.UNDER_REVIEW, PurchaseRequisitionStatusEnum.NEW].includes(
					purchaseRequisition.status
				)
			) {
				if (checkMissingFields()) {
					const purchaseRequisitionDTO = getPurchaseRequisitionDtoHelper(
						purchaseRequisition,
						purchaseRequisition.items,
						purchaseRequisitionId
					)
					await sendPurchaseRequisition(purchaseRequisitionDTO)
					return
				}
			}
		} catch {
			setShowErrorModal(true)
		} finally {
			hideLoadingSpinner()
		}
	}
	const handleSave = async () => {
		try {
			showLoadingSpinner()
			if (purchaseRequisition.areaId === '') {
				setAreaMissing(true)
			} else {
				setAreaMissing(false)
				const isUpdating = purchaseRequisitionId
				const mutationResultNode = isUpdating
					? 'updatePurchaseRequisition'
					: 'createPurchaseRequisition'
				const mutationPurchaseRequisition = isUpdating
					? UPDATE_PURCHASE_REQUISITION
					: CREATE_PURCHASE_REQUISITION

				const items = isUpdating
					? purchaseRequisition.items.map((item) => {
							return {
								...item,
								purchaseRequisitionId: purchaseRequisitionId
							}
						})
					: purchaseRequisition.items

				const purchaseRequisitionDTO = getPurchaseRequisitionDtoHelper(
					purchaseRequisition,
					items,
					purchaseRequisitionId
				)

				await createOrSavePurchaseRequisition(
					mutationPurchaseRequisition,
					mutationResultNode,
					purchaseRequisitionDTO
				)
			}
		} catch {
			setShowErrorModal(true)
		} finally {
			hideLoadingSpinner()
		}
	}

	/* OTHERS */
	const checkMissingFields = (): boolean => {
		let isValidItem = true
		if (purchaseRequisition.typeId === '') {
			setPrTypeMissing(true)
			isValidItem = false
		} else {
			setPrTypeMissing(false)
		}
		if (purchaseRequisition.description === '') {
			setDescriptionIsMissing(true)
			isValidItem = false
		} else {
			setDescriptionIsMissing(false)
		}
		if (purchaseRequisition.branchOffice === '') {
			setBranchOfficeMissing(true)
			isValidItem = false
		} else {
			setBranchOfficeMissing(false)
		}
		if (purchaseRequisition.areaId === '') {
			setAreaMissing(true)
			isValidItem = false
		} else {
			setAreaMissing(false)
		}
		if (purchaseRequisition.requesterEmail === '') {
			setRequesterMissing(true)
			isValidItem = false
		} else {
			setRequesterMissing(false)
		}
		const items = purchaseRequisition.items
		const errorsByItem: FieldValidations[] = []
		items.map((item: PurchaseRequisitionItemInterface) => {
			const itemErrors: FieldValidations = {
				product_description: !item.productDescription && !item.productDescription,
				quantity: !item.quantity || item.quantity < 1 || item.quantity.toString() === '',
				unit_measure: !item.unit,
				unit_cost: !item.unitPrice,
				required_date: !item.requiredDeliveryDate
			}
			if (Object.values(itemErrors).some((error) => error)) {
				isValidItem = false
			}
			errorsByItem.push(itemErrors)
		})
		setTotalErrorsInPositions(errorsByItem)
		return isValidItem
	}
	const createOrSavePurchaseRequisition = useCallback(
		async (mutation: DocumentNode, resultNode: string, dto: object) => {
			return client
				.mutate({ mutation: mutation, variables: { purchaseRequisition: dto } })
				.then(async (result) => {
					const id = result.data[resultNode].id
					if (id) {
						if (resultNode === 'updatePurchaseRequisition') {
							setAlert({
								severity: Severity.Info,
								message: t('purchaseRequisitions.detail.informationSaved')
							})
						} else {
							await addPendingComments({
								comments,
								purchaseRequisitionId: id,
								customerRfc: purchaseRequisition.customerRfc
							})
							const params = new URLSearchParams({
								customer: purchaseRequisition.customerRfc,
								id,
								recentlyCreated: 'true'
							})
							history.push(`${URLS.PURCHASE_REQUISITION_DETAIL}?${params.toString()}`)
						}
					}
				})
		},
		[purchaseRequisition, branches, history, comments]
	)
	const sendPurchaseRequisition = useCallback(
		async (dto: object) => {
			return client
				.mutate({
					mutation: SEND_PURCHASE_REQUISITION,
					variables: { purchaseRequisition: dto }
				})
				.then(async (result) => {
					const id = result.data.sendPurchaseRequisition.id
					if (id) {
						if (!purchaseRequisition?.id) {
							await addPendingComments({
								comments,
								purchaseRequisitionId: id,
								customerRfc: purchaseRequisition.customerRfc
							})
						}
						const params = new URLSearchParams({
							customer: purchaseRequisition.customerRfc,
							id,
							recentlySendToPending: 'true'
						})
						history.push(`${URLS.PURCHASE_REQUISITION_DETAIL}?${params.toString()}`)
						window.location.reload()
					}
				})
		},
		[purchaseRequisition, branches, history, comments]
	)

	const createApprovalsObject = (
		approvalsArr: PurchaseRequisitionApprovalInterface[],
		pRStatus: PurchaseRequisitionStatusEnum
	) => {
		const provisionalObject: TableContentItem = {
			titles: { title1: t('approvals.table.name'), title2: t('approvals.table.status') },
			rows: [{ name: '', status: '', activeRow: false }]
		}
		let tempPreviousActiveStatus: boolean = false
		const newRows = approvalsArr.map((approver) => {
			let activeStatus: boolean = false
			if (
				![
					PurchaseRequisitionStatusEnum.REJECTED,
					PurchaseRequisitionStatusEnum.APPROVED,
					PurchaseRequisitionStatusEnum.CANCELED
				].includes(pRStatus)
			) {
				if (!tempPreviousActiveStatus && !approver.approvedAt && !approver.rejectedAt) {
					activeStatus = true
				}
			} else {
				tempPreviousActiveStatus = true
			}
			const approvalStatusLabel = getApprovalStatusLabel(t, approver, tempPreviousActiveStatus)
			if (activeStatus) tempPreviousActiveStatus = activeStatus
			return {
				name: `${approver.approver.name} ${approver.approver.lastname}`,
				status: approvalStatusLabel,
				activeRow: activeStatus
			}
		})
		setApprovalFlow({ ...provisionalObject, rows: newRows })
	}

	const getPurchaseDetail = async (idPurchase: number | undefined): Promise<any> => {
		if (idPurchase) {
			const detailResult: PurchaseRequisitionInterface | 'Error' = await getPurchaseRequisitionDetail(
				client,
				idPurchase,
				user?.company?.rfc ?? ''
			)
			if (detailResult && detailResult !== 'Error') {
				const value = {
					...purchaseRequisition,
					id: detailResult.id,
					requester: detailResult.requester,
					typeId: detailResult.typeId,
					type: detailResult.type,
					status: detailResult.status,
					description: detailResult.description,
					branchOfficeId: detailResult.branchOfficeId,
					branchOffice: detailResult.branchOffice,
					deliveryAddressLine1: detailResult.deliveryAddressLine1,
					deliveryAddressLine2: detailResult.deliveryAddressLine2,
					deliveryAddressPostalCode: detailResult.deliveryAddressPostalCode,
					deliveryAddressStateCode: detailResult.deliveryAddressStateCode,
					deliveryAddressState: detailResult.deliveryAddressState,
					deliveryAddressCountryCode: detailResult.deliveryAddressCountryCode,
					deliveryAddressCountry: detailResult.deliveryAddressCountry,
					currency: detailResult.currency,
					areaId: detailResult?.areaId,
					area: detailResult?.area,
					items: detailResult.items,
					approvals: detailResult.approvals,
					comments: detailResult.comments,
					createdBy: detailResult.createdBy,
					customerRfc: detailResult.customerRfc,
					total: detailResult.total,
					createdAt: detailResult.createdAt,
					requesterEmail: detailResult.requesterEmail
				}
				setUserPreSavedObject(value.requester)
				setUserSearchString(value.requesterEmail)
				setPurchaseRequisition(value)
				setInitialValue(value)
				const currentSteps = getStatusSteps(detailResult)
				setStepArray(currentSteps)
			} else {
				setShowErrorModal(true)
			}
			setCancelModalVisibility(false)
			hideLoadingSpinner()
		}
	}
	const handleFieldChange = <T extends keyof PurchaseRequisitionInterface>(
		event: React.ChangeEvent<HTMLInputElement> | NetcurioSelectChangeEvent,
		field: T
	) => {
		if (field === 'branchOffice') {
			const chosenBranch = branches.find((branch) => branch.id === event.target.value)
			setPurchaseRequisition((prevState) => ({
				...prevState,
				[field]: event.target.value,
				deliveryAddressCountry: chosenBranch?.country_description,
				deliveryAddressCountryCode: chosenBranch?.country_code,
				deliveryAddressLine1: chosenBranch?.address_line_1,
				deliveryAddressLine2: chosenBranch?.address_line_2,
				deliveryAddressPostalCode: chosenBranch?.postal_code,
				deliveryAddressState: chosenBranch?.state_description,
				deliveryAddressStateCode: chosenBranch?.state_code
			}))
		} else {
			setPurchaseRequisition((prevState) => ({
				...prevState,
				[field]: event.target.value
			}))
		}
	}
	const handleCancelPRConfirmation = async () => {
		showLoadingSpinner()
		if (purchaseRequisitionId) {
			const cancelResult = await cancelPurchaseRequisition(client, purchaseRequisitionId)
			if (cancelResult === 'Success') {
				handleClose()
			} else {
				setShowErrorModal(true)
				setCancelModalVisibility(false)
			}
		} else {
			handleClose()
		}
		hideLoadingSpinner()
	}
	const getUserSuggestion = (searchText: string) => {
		setUserSearchString(searchText)
		setUserInputValue(searchText)
	}
	const suggestUsers = (debouncedSupplierInput: string) => {
		client
			.query({
				query: ACTIVE_USERS_BY_COMPANY,
				variables: {
					filter: [
						{ type: 'wildcard', column_name: 'name_lastname', value: debouncedSupplierInput }
					]
				}
			})
			.then((result) => {
				if (result.data.ActiveUserByCompany) {
					setSuggestedUsers(result.data.ActiveUserByCompany.list)
					if (purchaseRequisition.requesterEmail && purchaseRequisition && !userPreSavedObject) {
						setUserPreSavedObject(result.data.ActiveUserByCompany[0])
					}
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
			.finally(() => {
				setUserLoading(false)
			})
	}

	/* POSITIONS LOGIC */
	const addPosition = () => {
		const newItem: PurchaseRequisitionItemInterface = defaultValuesItemPr
		let items = purchaseRequisition.items
		items.push(newItem)
		items = items.map((item, index) => {
			return { ...item, position: index + 1 }
		})
		setPurchaseRequisition({
			...purchaseRequisition,
			items: items,
			total: getNetTotal(items)
		})
		setTotalErrorsInPositions([])
	}

	const deletePosition = (positionIndex: number) => {
		setPurchaseRequisition((prevPurchaseRequisition) => {
			const newItems = [...prevPurchaseRequisition.items]
			if (newItems.length <= 1) {
				return {
					...prevPurchaseRequisition,
					items: [defaultValuesItemPr],
					total: 0
				}
			} else {
				newItems.splice(positionIndex, 1)
				return {
					...prevPurchaseRequisition,
					items: [...newItems],
					total: getNetTotal(newItems)
				}
			}
		})
		setTotalErrorsInPositions([])
	}

	const handlePositionFieldChange = (evt: any, index: number, field: string) => {
		if (field === 'product') {
			const doCurrenciesMatch =
				evt.unit_price && evt.currency && evt.currency.trim() === purchaseRequisition.currency
			const purchaseRequisitionItems = purchaseRequisition.items
			const currentItem = purchaseRequisitionItems[index]
			const netAmount =
				doCurrenciesMatch && evt.unit_price && currentItem.quantity
					? evt.unit_price * currentItem.quantity
					: 0
			if (typeof evt === 'object') {
				setPurchaseRequisition((prev) => {
					const updatedItems = prev.items.map((item, i) => {
						if (i !== index) return item
						return {
							...item,
							position: index + 1,
							customerRfc: user?.company?.rfc ?? '',
							supplierRfc: item.supplierRfc,
							supplierDescription: item.supplierDescription,
							productCode: evt.id ? evt.id.trim() : '',
							productDescription: evt.description ? evt.description.trim() : '',
							quantity: currentItem.quantity,
							unit: evt.unit ? evt.unit.trim() : currentItem?.unit,
							unitPrice: doCurrenciesMatch ? parseFloat(evt.unit_price) : 0,
							netAmount: netAmount,
							requiredDeliveryDate: currentItem?.requiredDeliveryDate
						}
					})
					return {
						...prev,
						items: updatedItems,
						total: getNetTotal(updatedItems)
					}
				})
			} else {
				setPurchaseRequisition((prev) => {
					const updatedItems = prev.items.map((item, i) => {
						if (i !== index) return item
						return {
							...item,
							position: index + 1,
							customerRfc: user?.company?.rfc ?? '',
							supplierRfc: item.supplierRfc,
							supplierDescription: item.supplierDescription,
							productCode: '',
							productDescription: evt.trim(),
							quantity: item.quantity,
							unit: evt.unit ? evt.unit.trim() : item.unit,
							unitPrice: item.unitPrice,
							netAmount: item.quantity && item.unitPrice ? item?.quantity * item?.unitPrice : 0,
							requiredDeliveryDate: item.requiredDeliveryDate
						}
					})
					return {
						...prev,
						items: updatedItems,
						total: getNetTotal(updatedItems)
					}
				})
			}
		} else {
			setPurchaseRequisition((prev) => {
				const updatedItems = prev.items.map((item, i) => {
					if (i !== index) return item
					return {
						...item,
						position: index + 1,
						customerRfc: user?.company?.rfc ?? '',
						supplierRfc: field === 'supplier' ? evt.rfc : item.supplierRfc,
						supplierDescription: field === 'supplier' ? evt.name : item.supplierDescription,
						productCode: item.productCode,
						productDescription: item.productDescription,
						quantity: field === 'quantity' ? parseFloat(evt) : item.quantity,
						unit: field === 'unit' ? evt : item.unit,
						unitPrice: field === 'unitPrice' ? parseFloat(evt) : item.unitPrice,
						netAmount:
							field === 'quantity' || field === 'unitPrice'
								? (parseFloat(evt) || 0) *
									(field === 'quantity' ? (item.unitPrice ?? 0) : (item.quantity ?? 0))
								: item.netAmount,

						requiredDeliveryDate:
							field === 'requiredDate' ? dayjs(evt).toDate() : dayjs(item.requiredDeliveryDate)
					}
				})
				return {
					...prev,
					items: updatedItems,
					total: getNetTotal(updatedItems)
				}
			})
		}
	}

	const getNetTotal = (item: PurchaseRequisitionItemInterface[]) => {
		return item.reduce((total, num) => {
			if (typeof num.netAmount === 'number') {
				return total + num.netAmount
			} else {
				return total
			}
		}, 0)
	}

	const validateCommentChangesAndDisplayAlert = () => {
		setAlert(null)
		if (!checkMissingFields()) {
			return false
		}

		const hasUncommentChanges = checkFormHasUncommentChanges()
		if (hasUncommentChanges) {
			setAlert({
				severity: Severity.Warning,
				message: t('purchaseRequisitions.detail.changeDetected')
			})
			return false
		}
		return true
	}

	const getFullAddress = () => {
		if (purchaseRequisition.deliveryAddressLine1 !== '') {
			return `${purchaseRequisition.deliveryAddressLine1 ? purchaseRequisition.deliveryAddressLine1 + ', ' : ''}${purchaseRequisition.deliveryAddressPostalCode ? purchaseRequisition.deliveryAddressPostalCode + ', ' : ''}${purchaseRequisition.deliveryAddressState ? purchaseRequisition.deliveryAddressState + ', ' : ''}${purchaseRequisition.deliveryAddressCountry ? purchaseRequisition.deliveryAddressCountry + '.' : ''}`
		}
		return ''
	}

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<AuthenticatedHeader>
				<div>
					<ConditionalRender condition={isRequester}>
						<CancelPurchaseRequestModal
							onClose={() => setCancelModalVisibility(false)}
							onAccept={handleCancelPRConfirmation}
							open={cancelModalVisibility}
						/>
						<ConditionalRender
							condition={
								[
									PurchaseRequisitionStatusEnum.NEW,
									PurchaseRequisitionStatusEnum.PENDING
								].includes(purchaseRequisition.status) &&
								isUserCurrentRequester &&
								!isInApprovalProcess
							}
						>
							<NetcurioButton
								color="error"
								variant={'outlined'}
								className={buttonStyles.button}
								onClick={() => setCancelModalVisibility(true)}
								size="small"
								endIcon={<NetcurioIcons.Close />}
							>
								<span> {t('purchaseRequisitions.actions.cancel')} </span>
							</NetcurioButton>
						</ConditionalRender>

						<ConditionalRender
							condition={
								(purchaseRequisition.status == PurchaseRequisitionStatusEnum.NEW &&
									isUserCurrentRequester) ||
								(purchaseRequisition.status === PurchaseRequisitionStatusEnum.UNDER_REVIEW &&
									isUserOwner)
							}
						>
							<NetcurioButton
								color="primary"
								variant={'contained'}
								onClick={handleSubmit}
								size="small"
								endIcon={<NetcurioIcons.Check />}
							>
								<span> {t('purchaseRequisitions.actions.send')} </span>
							</NetcurioButton>
						</ConditionalRender>

						<ConditionalRender
							condition={purchaseRequisition.status === PurchaseRequisitionStatusEnum.NEW}
						>
							<NetcurioButton
								color="primary"
								variant={'outlined'}
								onClick={handleSave}
								size="small"
								endIcon={<NetcurioIcons.SaveOutlined />}
							>
								<span> {t('purchaseRequisitions.actions.save')} </span>
							</NetcurioButton>
						</ConditionalRender>
					</ConditionalRender>
					<ConditionalRender condition={isUserCurrentApprover}>
						<Reject
							purchaseRequisition={purchaseRequisition}
							validateBeforeReject={validateCommentChangesAndDisplayAlert}
							onReject={() => {
								getPurchaseDetail(purchaseRequisitionId).then()
							}}
						/>
						<ReturnToReview
							purchaseRequisition={purchaseRequisition}
							validateBeforeReturnToReview={validateCommentChangesAndDisplayAlert}
							onReturnToReview={() => {
								getPurchaseDetail(purchaseRequisitionId).then()
							}}
						/>
						<Approve
							purchaseRequisition={purchaseRequisition}
							validateBeforeApprove={validateCommentChangesAndDisplayAlert}
							onApprove={() => {
								getPurchaseDetail(purchaseRequisitionId).then()
								setAlert({
									severity: Severity.Info,
									message: t('purchaseRequisitions.detail.successApproved')
								})
							}}
						/>
					</ConditionalRender>
				</div>
				<div>
					{/*<NetcurioButton
						variant="outlined"
						className={commonDetail.btnHeader}
						onClick={handleOpenAttachments}
						endIcon={
							<NetcurioIcons.AttachFile
								className={classNames(commonDetail.icon, commonDetail.iconPDF)}
							/>
						}
					>
						<span> {t('purchaseRequisitions.detail.attachments')} </span>
					</NetcurioButton>*/}
				</div>
			</AuthenticatedHeader>
			<NetcurioGrid container alignItems="flex-start" height="100%">
				<NetcurioGrid item xs={9} height="100%" width="100%">
					<NetcurioGrid item container xs={12} height="auto">
						<NetcurioGrid item xs={12} className={commonDetail.cardContainer}>
							<NetcurioGrid className={commonDetail.cardTitle}>
								{t('purchaseRequisitions.detail.title')}
								{purchaseRequisition.id ? ' - ' + purchaseRequisition.id : ''}
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid item container xs={12} className={commonDetail.informationSection}>
							<NetcurioGrid
								item
								container
								xs={12}
								spacing={2}
								marginTop={'2rem'}
								marginBottom={'1.5rem'}
							>
								<NetcurioGrid item container xs={4}>
									<StepProgressBar stepProgressArray={stepArray} />
								</NetcurioGrid>
								<NetcurioGrid item container xs={8} className={styles.alertContainer}>
									<RequisitionAlert alert={alert} />
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid className={commonDetail.subtitleModule}>
								{t('purchaseRequisitions.detail.dataOf')}
							</NetcurioGrid>
							<div className={commonDetail.dividingLine}></div>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_type"
											label={t('purchaseRequisitions.detail.type')}
											size="small"
											disabled={headerFormDisabled}
											error={prTypeMissing}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.type')}
											labelId={'pr_type'}
											onChange={(e) => handleFieldChange(e, 'typeId')}
											value={purchaseRequisition.typeId}
											disabled={headerFormDisabled}
											error={prTypeMissing}
										>
											{purchaseRequisitionTypes?.map(
												(item: PurchaseRequisitionType) => (
													<CustomMenuItem key={item.id} value={item.id}>
														<span>{item.description}</span>
													</CustomMenuItem>
												)
											)}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('companyNameText')}
										value={user?.company?.name ?? ''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('textRFC')}
										value={user?.company?.rfc ?? ''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.createdAt')}
										value={dayjs(new Date()).format('DD/MM/YYYY')}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={2} paddingTop={'2rem'} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_currency"
											size="small"
											label={t('purchaseRequisitions.detail.currency')}
											disabled={headerFormDisabled}
										/>
										{currencies.length > 0 && (
											<OutlinedSelect
												label={t('purchaseRequisitions.detail.currency')}
												labelId={'pr_currency'}
												onChange={(e) => handleFieldChange(e, 'currency')}
												value={
													currencies.length > 0 && purchaseRequisition.currency
														? purchaseRequisition.currency
														: ''
												}
												disabled={headerFormDisabled}
											>
												{currencies.length > 0 &&
													currencies?.map((item) => {
														return [
															<CustomMenuItem
																value={item.code}
																key={item.code}
																style={{ marginBottom: -3 }}
															>
																<span>{`${item.code} - ${item.description}`}</span>
															</CustomMenuItem>,
															item.code === lastPrioritizedCode && (
																<NetcurioDivider sx={{ borderWidth: 2 }} />
															)
														]
													})}
											</OutlinedSelect>
										)}
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={10} paddingTop={'2rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.description')}
										value={purchaseRequisition.description}
										onChange={(e) => handleFieldChange(e, 'description')}
										fullWidth
										disabled={headerFormDisabled}
										maxLength={CHARACTERS_LIMIT}
										helperText={
											purchaseRequisition.description === ''
												? t('max120Char')
												: t('remainingCharacters', {
														chars:
															CHARACTERS_LIMIT -
															(purchaseRequisition?.description?.length ?? 0)
													})
										}
										error={descriptionIsMissing}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid item container xs={12} marginTop={'2rem'}>
								<NetcurioGrid item container xs={12}>
									<div className={commonDetail.subtitleModule}>
										{t('purchaseRequisitions.detail.requesterInformation')}
									</div>
									<div className={commonDetail.dividingLine}></div>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.createdBy')}
										value={
											purchaseRequisition.createdBy
												? `${purchaseRequisition.createdBy?.name} ${purchaseRequisition.createdBy?.lastname}`
												: ''
										}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_branchOffice"
											size="small"
											label={t('purchaseRequisitions.detail.branch')}
											disabled={headerFormDisabled}
											error={branchOfficeMissing}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.branch')}
											labelId={'pr_branchOffice'}
											onChange={(e) => {
												handleFieldChange(e, 'branchOffice')
												handleFieldChange(e, 'branchOfficeId')
											}}
											value={purchaseRequisition.branchOffice ?? undefined}
											disabled={headerFormDisabled}
											error={branchOfficeMissing}
										>
											{branches?.map((item, i) => (
												<CustomMenuItem key={i} value={item.id}>
													<span>{item.description}</span>
												</CustomMenuItem>
											))}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_requesterArea"
											size="small"
											label={t('purchaseRequisitions.detail.requesterArea')}
											disabled={headerFormDisabled}
											error={areaMissing}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.requesterArea')}
											labelId={'pr_requesterArea'}
											onChange={(e) => handleFieldChange(e, 'areaId')}
											value={purchaseRequisition.areaId}
											disabled={headerFormDisabled}
											error={areaMissing}
										>
											{areas?.map((item: Area, i: number) => (
												<CustomMenuItem key={i} value={item.id}>
													<span>{item.description}</span>
												</CustomMenuItem>
											))}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<NetcurioFormControl fullWidth>
										<Autocomplete
											label={t('purchaseRequisitions.detail.requester')}
											placeholder={t('purchaseRequisitions.detail.requester')}
											size="small"
											variant="outlined"
											options={suggestedUsers}
											value={userPreSavedObject}
											getOptionLabel={(option: User) =>
												`${option.name} ${option.lastname} - ${option.email}`
											}
											isOptionEqualToValue={(option: User, value: { email: string }) =>
												option?.email === value?.email
											}
											onSelectValue={(option: User) => {
												setPurchaseRequisition((prev) => ({
													...prev,
													requesterEmail: option?.email ?? ''
												}))
											}}
											inputValue={userInputValue}
											onInputValueChange={getUserSuggestion}
											loading={userLoading}
											minLength={3}
											freeSolo
											fullWidth
											disabled={headerFormDisabled}
											getOptionKey={(option: User) => option?.email ?? ''}
											error={requesterMissing}
										/>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={12} marginTop={'2rem'} marginBottom={'2rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.address')}
										value={getFullAddress()}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} height="auto" paddingTop={'1.5rem'}>
						<NetcurioGrid item container xs={12} className={commonDetail.cardContainer}>
							<div className={commonDetail.cardTitle}>
								{t('purchaseRequisitions.detail.positions')}
							</div>
						</NetcurioGrid>
						<NetcurioGrid item container xs={12} className={commonDetail.positionsSection}>
							{purchaseRequisition.items.map(
								(item: PurchaseRequisitionItemInterface, key: number) => (
									<div key={key} style={{ width: '100%' }}>
										<PurchaseRequisitionPosition
											index={key}
											item={item}
											deleteItem={deletePosition}
											totalErrors={totalErrorsInPositions}
											handlePositionFieldChange={handlePositionFieldChange}
											isFormDisabled={positionsAndButtonsDisabled}
											measurementUnits={measurementUnits}
											headerCurrency={purchaseRequisition.currency ?? ''}
											client={client}
										/>
										<div className={commonDetail.dividingLinePositions}></div>
									</div>
								)
							)}
							<NetcurioButton
								startIcon={<NetcurioIcons.Add />}
								onClick={addPosition}
								variant="outlined"
								size={'small'}
								style={{ display: positionsAndButtonsDisabled ? 'none' : 'inherit' }}
							>
								{t('purchaseRequisitions.actions.addPosition')}
							</NetcurioButton>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid item xs={3} paddingLeft={'1rem'}>
					<NetcurioGrid item paddingBottom={'1.5rem'} ref={totalContainerRef}>
						<TotalContainerBar
							title="total"
							fields={{
								currencyText: purchaseRequisition.currency
							}}
							total={{
								totalDotText: currencyFormatter.format(purchaseRequisition.total ?? 0)
							}}
						/>
					</NetcurioGrid>
					<NetcurioGrid
						item
						className={classNames(styles.commentSection)}
						paddingBottom={'1.5rem'}
						style={{
							minHeight: isCommentsSectionExtended
								? `calc(100vh - ${document.querySelector('header')?.offsetHeight ?? 0}px - ${totalContainerRef.current?.clientHeight ?? 0}px)`
								: 'auto'
						}}
					>
						<Comments
							setIsExpanded={setIsCommentsSectionExtended}
							comments={comments}
							addComment={addComment}
						/>
					</NetcurioGrid>
					{approvalFlow && (
						<ConditionalRender
							condition={purchaseRequisition.status !== PurchaseRequisitionStatusEnum.NEW}
						>
							<NetcurioGrid
								item
								className={classNames(styles.commentSection)}
								paddingBottom={'1.5rem'}
							>
								<ApprovalFlowSection
									title={t('purchaseRequisitions.approvals.title')}
									subtitle={t('purchaseRequisitions.approvals.subtitle')}
									tableContent={approvalFlow}
								/>
							</NetcurioGrid>
						</ConditionalRender>
					)}
				</NetcurioGrid>
			</NetcurioGrid>
			<ErrorModal
				open={showErrorModal}
				errorCode={''}
				redirect={false}
				callClose={() => setShowErrorModal(false)}
			/>
		</NetcurioGrid>
	)
}
