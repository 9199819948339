//TODO: Uncomment when needed. To avoid knip errors.

import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface CancelPurchaseRequestModalProps {
	onClose: () => void
	onAccept: () => void
	open: boolean
}

export const CancelPurchaseRequestModal: FC<CancelPurchaseRequestModalProps> = ({
	onClose,
	onAccept,
	open
}): ReactElement => {
	const { t } = useTranslation()
	return (
		<NetcurioDialog
			open={open}
			actionButtons={
				<>
					<NetcurioButton variant="outlined" onClick={onClose}>
						{t('comeBackTextModal')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('cancelButton')}
					</NetcurioButton>
				</>
			}
			maxWidth="xs"
			minWidth="xs"
		>
			<p>{t('purchaseRequisitions.cancelConfirmationText')}</p>
		</NetcurioDialog>
	)
}
