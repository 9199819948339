import { getCurrentUser } from '@netcurio/frontend-common'
import { useMemo } from 'react'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'

const user = getCurrentUser()

interface Props {
	purchaseRequisition: PurchaseRequisitionInterface
}

export const useCurrentApprover = ({ purchaseRequisition }: Props) => {
	const { approvals = [] } = purchaseRequisition
	const { isApprover } = useUserRoles()

	const isUserCurrentApprover = useMemo(() => {
		const currentApprover = approvals?.find(
			(approval) => approval.approvedAt === null && approval.rejectedAt === null
		)

		if (currentApprover) {
			return currentApprover.approver.email === user?.email && isApprover
		}
		return false
	}, [approvals, isApprover])

	return { isUserCurrentApprover }
}
