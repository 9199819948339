import { TypesDataTable, TableFillSelectionFilter } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	TagsFilter,
	TextInputFilter
} from '@netcurio/frontend-components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import '../../style.scss'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './paymentComplementList.module.scss'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { TypesMainTable, TypesStyleTable } from './types'

export const PaymentComplementTable = ({
	closeFilterContainerBar,
	dataComplement,
	dataFiltersArray,
	deleteFilterActive,
	deleteRange,
	filterContainerBar,
	filteringPaymentComplement,
	rowsActual,
	setActivePagination,
	filtersOfTypeStatus,
	totalRows,
	userRoles = [],
	showBarLoader,
	setShowBarLoader
}: TypesMainTable): ReactElement => {
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		initDate: undefined,
		finalDate: undefined,
		initCreatedDate: undefined,
		finalCreatedDate: undefined,
		filterValue: undefined,
		initRange: undefined,
		finalRange: undefined,
		initCreatedRange: undefined,
		finalCreatedRange: undefined,
		dateFilter: 'display-none',
		textFilter: 'display-none',
		createdDateFilter: 'display-none',
		actualField: '',
		initDateErrorText: '',
		finalDateErrorText: '',
		initCreatedDateErrorText: '',
		finalCreatedDateErrorText: '',
		textHeader: styles.textHeader,
		deleteRange: false,
		statusFilter: 'display-none',
		fillStatusInputFilter: objectStatusFilterArray()
	})
	const [styleTable, setStyleTable] = useState<TypesStyleTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		resultNotFound: false,
		activeHeight: true
	})

	useEffect(() => {
		setShowBarLoader(false)
		setStyleTable((prevState) => ({
			...prevState,
			mainTableHeight: window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT,
			mainContainerHeight: window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		}))
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRoles))
	}, [userRoles])

	useEffect(() => {
		if (totalRows && rowsActual) {
			window.document
				.getElementsByClassName(styles.infoPaymentComplement)[0]
				.addEventListener('scroll', handlePagination)
		}
		return () => {
			const element: Element = window.document.getElementsByClassName(styles.infoPaymentComplement)[0]
			if (element) element.removeEventListener('scroll', handlePagination)
		}
	}, [totalRows, rowsActual])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'date')
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initRange: undefined,
					finalRange: undefined
				}))
			else if (dataTable.actualField === 'created_at')
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initCreatedRange: undefined,
					finalCreatedRange: undefined
				}))
		}
		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (dataTable.fillStatusInputFilter.length !== objectStatusFilterArray().length)
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray()
				}))
		} else if (filtersOfTypeStatus.status.dataFilter !== '' && filtersOfTypeStatus.status.dataFilter) {
			// Condition explanation: only add dataFilter if dataTable.fillStatusInputFilter doesn't includes it already
			// This block is needed when status filters are closed and pushed again to SelectInputFilter
			if (
				!dataTable.fillStatusInputFilter.some((selectionFilter) => {
					return selectionFilter.selectedParameter === filtersOfTypeStatus.status.dataFilter
				})
			) {
				//add dataFilter to dataTable.fillStatusInputFilter
				setDataTable((prevState) => {
					const newFillStatusInputFilter = prevState.fillStatusInputFilter
					newFillStatusInputFilter.push(
						new TableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter)
					)

					return {
						...prevState,
						fillStatusInputFilter: newFillStatusInputFilter
					}
				})
			}
		}
		if (filterContainerBar && styleTable.activeHeight)
			setStyleTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight - 40,
				mainContainerHeight: prevState.mainContainerHeight - 40,
				activeHeight: false
			}))
		else if (!filterContainerBar && !styleTable.activeHeight)
			setStyleTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40,
				activeHeight: true
			}))
		setStyleTable((prevState) => ({
			...prevState,
			resultNotFound: totalRows < 1
		}))
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [totalRows, deleteRange, filterContainerBar, dataTable])

	const handlePagination = () => {
		setShowBarLoader(true)
		const scrollPosition = window.document.getElementsByClassName(
			styles.infoPaymentComplement
		)[0] as HTMLDivElement
		if (
			totalRows > rowsActual &&
			scrollPosition.scrollTop > 0 &&
			scrollPosition.offsetHeight + scrollPosition.scrollTop >= scrollPosition.scrollHeight - 1
		)
			setActivePagination(true)
		setShowBarLoader(false)
	}

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none',
			activeFilter = ''
		switch (filterActual) {
			case 'statusFilter':
				classActualField = `request-for-credit-memo-${fieldStyle}-filter`
				activeFilter = dataTable.statusFilter
				break
			case 'dateFilter':
				classActualField = `complement-${fieldStyle}-filter`
				activeFilter = dataTable.dateFilter
				break
			case 'createdDateFilter':
				classActualField = `complement-${fieldStyle}-filter`
				activeFilter = dataTable.createdDateFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}
		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer)
				setStyleTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - 40,
					mainContainerHeight: prevState.mainContainerHeight - 40
				}))
			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				initCreatedDate: undefined,
				finalCreatedDate: undefined,
				filterValue: '',
				initRange: undefined,
				finalRange: undefined,
				initCreatedRange: undefined,
				finalCreatedRange: undefined,
				statusFilter: 'display-none',
				dateFilter: 'display-none',
				textFilter: 'display-none',
				createdDateFilter: 'display-none',
				[filterActual]: classActualField,
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				initCreatedDateErrorText: '',
				finalCreatedDateErrorText: '',
				textHeader: styles.textHeaderOnClick
			}))
		} else if (dataTable.actualField === field) {
			setStyleTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
			setDataTable((prevState) => ({
				...prevState,
				[filterActual]: 'display-none',
				filterValue: '',
				textHeader: styles.textHeader,
				initRange: undefined,
				finalRange: undefined,
				initCreatedRange: undefined,
				finalCreatedRange: undefined
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		if (sortField !== '') {
			filteringPaymentComplement(
				0,
				sortField,
				order,
				fieldFilter,
				undefined,
				undefined,
				undefined,
				undefined
			)
		} else {
			let initRange: number | Dayjs = undefined,
				finalRange: number | Dayjs = undefined
			switch (fieldFilter) {
				case 'date':
					initRange = dataTable.initRange
					finalRange = dataTable.finalRange
					if (objectRange) {
						if (objectRange.initRange) initRange = objectRange.initRange
						if (objectRange.finalRange) finalRange = objectRange.finalRange
					}
					break
				case 'created_at':
					initRange = dataTable.initCreatedRange
					finalRange = dataTable.finalCreatedRange
					if (objectRange) {
						if (objectRange.initCreatedRange) initRange = objectRange.initCreatedRange
						if (objectRange.finalCreatedRange) finalRange = objectRange.finalCreatedRange
					}
					break
			}
			filteringPaymentComplement(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}
	const closeFilter = () => {
		setStyleTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + 40,
			mainContainerHeight: prevState.mainContainerHeight + 40
		}))
		setDataTable((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			statusFilter: 'display-none',
			createdDateFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.tableHeaderClicked,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined,
			initCreatedRange: undefined,
			finalCreatedRange: undefined
		}))
	}

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) =>
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			'',
			''
		)

	const emptyFilterField = () =>
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			finalDate: undefined,
			initDate: undefined,
			initCreatedDate: undefined,
			finalCreatedDate: undefined
		}))

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const selectionFilter = (status: string, position: number) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 3) {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillStatusInputFilter.splice(position, 1)
		}
	}

	return (
		<div className={styles.tablePaymentComplement}>
			<div className={styles.headerMainTablePC}>
				{fillFields.map((item: tableFillHeader) => (
					<HeaderTable
						key={item.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<TagsFilter
				showTagsFilter={dataTable.statusFilter}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={dataTable.fillStatusInputFilter}
			/>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<DateInputFilter
				showDateFilter={dataTable.createdDateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initCreatedDate}
				finalDate={dataTable.finalCreatedDate}
				callDataList={callDataList}
				initReferenceText={'initCreated'}
				finReferenceText={'finalCreated'}
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					objectType={Constants.LISTS.PC}
					dataFiltersArray={dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={deleteFilterActive}
				/>
			)}
			<div
				className={styles.tableInformationWhiteStyle}
				style={{ height: styleTable.mainContainerHeight }}
			>
				<div className={styles.infoPaymentComplement} style={{ height: styleTable.mainTableHeight }}>
					<ResultNotFound showNotFound={styleTable.resultNotFound} />
					{Object.keys(dataComplement).map((key) => (
						<TableRow
							key={key}
							informationTable={dataComplement[parseInt(key)]}
							userRoles={userRoles}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
