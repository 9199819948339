import { ApolloQueryResult } from 'apollo-boost'
import { useCallback, useEffect, useState } from 'react'
import GlobalQueries from '../components/queries'
import { Currency, MeasurementUnit } from '../types'
import { useClient } from './useClient'

export const useMeasurementUnits = () => {
	const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[]>([])
	const client = useClient()

	const fetchCurrencies = useCallback(() => {
		client
			.query({
				query: GlobalQueries.MEASUREMENT_UNITS
			})
			.then((result: ApolloQueryResult<{ MeasurementUnits: Array<Currency> }>) => {
				if (result.data) {
					setMeasurementUnits(result.data.MeasurementUnits)
				}
			})
			.catch((error) => {
				console.error('Error fetching measurement units:', error)
			})
	}, [client])

	useEffect(() => {
		fetchCurrencies()
	}, [fetchCurrencies])

	return { measurementUnits }
}
