import { Roles, TableFillSelectionFilter } from '@netcurio/frontend-common'
import { t } from 'i18next'
import Constants from '../../../../utilities/constants'
import { tableFillHeader } from '../../../../utilities/tableFillHeader'

export function objectHeaderArray(userRoles: Roles[]): Array<tableFillHeader> {
	const serieFolioColumn = new tableFillHeader(
		t('registerIdentifier'),
		undefined,
		'',
		'serie' + 'folio',
		'text'
	)
	const statusColumn = new tableFillHeader(t('actualState'), undefined, '', 'status', 'status')
	const proposedDateColumn = new tableFillHeader(
		t('proposedPaymentDate'),
		undefined,
		'',
		'proposed_payment_date',
		'proposedDate'
	)
	const customerColumn = new tableFillHeader(t('buyer'), undefined, '', 'receiver', 'text')
	const supplierColumn = new tableFillHeader(t('seller'), undefined, '', 'sender', 'text')
	const statusDetailColumn = new tableFillHeader(
		t('invoices.statusDetail'),
		undefined,
		'',
		'statusDetail',
		'text',
		false
	)
	const currencyColumn = new tableFillHeader(t('currencyText'), undefined, '', 'currency', 'text')
	const quantityColumn = new tableFillHeader(t('totalToPay'), undefined, '', 'total', 'numeric')
	const relatedDocumentColumn = new tableFillHeader(
		t('relatedSaleIdentifier'),
		undefined,
		'',
		'reference',
		'text'
	)
	const paymentComplementColumn = new tableFillHeader(
		t('relatedPaymentComplement'),
		undefined,
		'',
		'payment_complement',
		'hasRelation'
	)
	const objectHeaderArraySupplier = [
		serieFolioColumn,
		statusColumn,
		statusDetailColumn,
		customerColumn,
		proposedDateColumn,
		relatedDocumentColumn,
		paymentComplementColumn,
		quantityColumn,
		currencyColumn
	]
	const objectHeaderArrayCustomer = [
		serieFolioColumn,
		statusColumn,
		statusDetailColumn,
		supplierColumn,
		proposedDateColumn,
		relatedDocumentColumn,
		paymentComplementColumn,
		quantityColumn,
		currencyColumn
	]
	if (userRoles.includes(Roles.CUSTOMER)) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}
export function objectStatusFilterArray(userRoles: Roles[]): Array<TableFillSelectionFilter> {
	const missingStatusOption = new TableFillSelectionFilter(
		Constants.LINKS.MISSING_LINK,
		'payment-status-option'
	)
	const paymentScheduledStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.PAYMENT_SCHEDULED,
		'payment-scheduled-status-option'
	)
	const paidStatusOption = new TableFillSelectionFilter(Constants.STATUS.PAID, 'paid-status-option')
	const partiallyPaidStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.PARTIALLY_PAID,
		'partially-paid-status-option'
	)
	const errorStatusOption = new TableFillSelectionFilter(Constants.ERROR.ERROR, 'error-status-option')
	const canceledStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.CANCELED,
		'canceled-status-option'
	)
	const voidedStatusOption = new TableFillSelectionFilter(Constants.STATUS.VOIDED, 'voided-status-option')
	const inProcessStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.IN_PROCESS,
		'payment-status-option'
	)
	const inApprovalStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.IN_APPROVAL,
		'in_approval-status-option'
	)
	const inExceptionStatusOption = new TableFillSelectionFilter(
		Constants.STATUS.EXCEPTION,
		'exception-status-option'
	)
	const objectStatusSupplierOptions = [
		missingStatusOption,
		paidStatusOption,
		partiallyPaidStatusOption,
		paymentScheduledStatusOption,
		errorStatusOption,
		canceledStatusOption,
		voidedStatusOption,
		inProcessStatusOption,
		inApprovalStatusOption,
		inExceptionStatusOption
	]
	const objectStatusCustomerOptions = [
		missingStatusOption,
		paidStatusOption,
		partiallyPaidStatusOption,
		paymentScheduledStatusOption,
		errorStatusOption,
		canceledStatusOption,
		voidedStatusOption,
		inProcessStatusOption,
		inApprovalStatusOption,
		inExceptionStatusOption
	]
	if (userRoles.includes(Roles.CUSTOMER)) {
		return objectStatusCustomerOptions
	} else {
		return objectStatusSupplierOptions
	}
}

export function objectPcFilterArray(): Array<TableFillSelectionFilter> {
	const hasRelation = new TableFillSelectionFilter(true, '') // t('invoices.true')
	const doesntHaveRelation = new TableFillSelectionFilter(false, '')
	return [hasRelation, doesntHaveRelation]
}
