import { Filter, getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React, { useRef, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { NavLink } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import '../../style.scss'
import { Order } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import listHelper from '../../utilities/listHelper'
import styles from './adminConsole.module.scss'
import { HeaderButtons } from './HeaderButtons/HeaderButtons'
import { AdministrationConsoleErrorModal } from './Modals/AdministrationConsoleErrorModal'
import { UploadProductsModal } from './Modals/UploadProductsModal/UploadProductsModal'
import CompanyDocuments from './tabs/companyDocuments/companyDocuments'
import EnrollmentSelectionScreen from './tabs/enrollments/enrollmentSelectionScreen'
import { InviteNewSupplierModal } from './tabs/enrollments/inviteSupplierModal/InviteNewSupplierModal'
import * as queries from './tabs/enrollments/inviteSupplierModal/queries'
import { MyCompanyTab } from './tabs/MyCompanyTab/MyCompanyTab'
import ProductSelectionScreen from './tabs/products/productSelectionScreen'
import RelatedCompaniesSelectionScreen from './tabs/relatedCompanies/relatedCompaniesSelectionScreen'
import { NewUserModal } from './tabs/users/NewUserModal/NewUserModal'
import UserSelectionScreen from './tabs/users/userSelectionScreen'

export const AdministratorConsole = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false)
	const [massiveUploadModal, setMassiveUploadModal] = useState(false)
	const [isInviteSupplierModalOpen, setIsInviteSupplierModalOpen] = useState(false)
	const [selectedTab, setSelectedTab] = useState('')
	const [errorCode, setErrorCode] = useState<string>('')
	const [dataFiltersArray, setDataFiltersArray] = useState<Filter[]>([])
	const [orderDataFilters, setOrderDataFilters] = useState<Order>()
	const [valueEmail, setValueEmail] = useState<string>('')
	const [emailError, setEmailError] = useState<boolean>(false)
	const adminConsoleContainer = useRef<HTMLDivElement>(null)
	const userRoles = getUserRolesForCompany()
	const company = getCurrentUser()?.company
	const adminURL = `${URLS.ADMINISTRATION}/`
	const client = connection()

	const sendInvitation = () => {
		const error = !valueEmail.match(Constants.REGEX.EMAIL)

		setEmailError(error)
		if (!error) {
			showLoadingSpinner()
			setIsInviteSupplierModalOpen(false)
			client
				.mutate({
					mutation: queries.INVITE_SUPPLIER,
					variables: {
						email: valueEmail
					}
				})
				.then(() => {
					closeInviteSupplierModal()
					hideLoadingSpinner()
				})
				.catch(handleError)
				.finally(() => hideLoadingSpinner())
		}
	}

	const setDataFilterArray = (dataFilter: Filter[], orderFilter: Order) => {
		if (Array.isArray(dataFilter)) {
			setDataFiltersArray(dataFilter)
		}
		if (orderFilter) {
			setOrderDataFilters(orderFilter)
		}
	}

	const downloadReportExcel = (reportURL: string) => {
		const currentUser = getCurrentUser()
		showLoadingSpinner()
		const copyFilterToApply = JSON.parse(JSON.stringify(dataFiltersArray))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		const body = JSON.stringify({
			companyFilter: currentUser ? currentUser?.company?.rfc : undefined,
			filters: copyFilterToApply,
			sort_field: orderDataFilters?.sortField,
			sort_order: orderDataFilters?.sortOrder
		})
		listHelper
			.generateReportList(body, reportURL)
			.then(async (res) => {
				if (res.ok) {
					res.json().then((responseJson: FileDescriptor) => {
						downloadExcelFile(responseJson)
					})
				} else {
					hideLoadingSpinner()
					const error: Error = await res.json()
					handleError(error)
				}
			})
			.catch(handleError)
			.finally(() => hideLoadingSpinner())
	}

	const handleError = (error: Error) => {
		console.error(error)
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) setErrorCode(newErrorCode)
	}

	function closeInviteSupplierModal() {
		setIsInviteSupplierModalOpen(false)
		setEmailError(false)
		setValueEmail('')
	}
	function actionHeaderButton(button: string) {
		switch (button) {
			case 'newUser':
				setIsOpenNewUserModal(true)
				break
			case 'newProduct':
				location.href = URLS.NEW_PRODUCT
				break
			case 'massiveUpload':
				setMassiveUploadModal(true)
				break
			case 'newEnrollment':
				location.href = URLS.NEW_ENROLLMENT
				break
			case 'comeBackProductList':
				location.href = adminURL + Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB
				break
			case 'comeBackUserList':
				location.href = adminURL + Constants.ADMIN.ADMIN_TABS.USER_TAB
				break
			case 'comeBackEnrollmentList':
				location.href = adminURL + Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB
				break
			case 'inviteSupplier':
				setIsInviteSupplierModalOpen(true)
				break
			case 'exportListUsers':
				downloadReportExcel('users')
				break
			case 'exportListProduct':
				downloadReportExcel('products')
				break
			case 'exportListRelatedCompanies':
				downloadReportExcel('relationships')
				break
			case 'exportListEnrollmentsList':
				downloadReportExcel('sent-invitations')
				break
			case 'relatedCompanyDetail':
				location.href = adminURL + Constants.ADMIN.ADMIN_TABS.RELATED_COMPANIES_TAB
				break
			default:
				break
		}
	}

	return (
		<div ref={adminConsoleContainer}>
			<AuthenticatedHeader>
				<HeaderButtons selectedTab={selectedTab} actionHeaderButton={actionHeaderButton} />
			</AuthenticatedHeader>
			{/*<NavBar />*/}
			<div className={styles.tableMarginsContainer}>
				<div className={styles.administratorConsoleHeader}>
					<p className={styles.textAdminHeader}>{t('administratorConsole')}</p>
				</div>

				<div className={styles.tabsAdminisConsole}>
					<NavLink
						to={adminURL + Constants.ADMIN.ADMIN_TABS.USER_TAB}
						activeClassName={styles.tabSelected}
						className={styles.tabGeneric}
					>
						{t('users')}
					</NavLink>
					{userRoles.includes(Roles.SUPPLIER) && (
						<NavLink
							to={adminURL + Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB}
							activeClassName={styles.tabSelected}
							className={styles.tabGeneric}
						>
							{t('productsText')}
						</NavLink>
					)}
					<NavLink
						to={adminURL + Constants.ADMIN.ADMIN_TABS.RELATED_COMPANIES_TAB}
						activeClassName={styles.tabSelected}
						className={styles.tabGeneric}
					>
						{t(userRoles.includes(Roles.SUPPLIER) ? 'customerTab' : 'supplierTab')}
					</NavLink>
					<NavLink
						to={adminURL + Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB}
						activeClassName={styles.tabSelected}
						className={`${styles.tabGeneric} ${styles.tabDoubleLine}`}
					>
						{t('enrollmentText')}
					</NavLink>
					{userRoles.includes(Roles.SUPPLIER) && (
						<NavLink
							to={adminURL + Constants.ADMIN.ADMIN_TABS.DOCUMENTS_TAB}
							activeClassName={styles.tabSelected}
							className={styles.tabGeneric}
						>
							{t('documentsText')}
						</NavLink>
					)}
					<NavLink
						to={adminURL + Constants.ADMIN.ADMIN_TABS.MY_COMPANY_TAB}
						activeClassName={styles.tabSelected}
						className={styles.tabGeneric}
					>
						{t('myEnterprise')}
					</NavLink>
				</div>
			</div>
			<Switch>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.USER_TAB}>
					<UserSelectionScreen
						companyName={`${company?.name}`}
						chooseMainHeaderButton={setSelectedTab}
						setDataFilterArray={setDataFilterArray}
					/>
				</Route>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.PRODUCTS_TAB}>
					<ProductSelectionScreen
						chooseMainHeaderButton={setSelectedTab}
						setDataFilterArray={setDataFilterArray}
					/>
				</Route>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.RELATED_COMPANIES_TAB}>
					<RelatedCompaniesSelectionScreen
						chooseMainHeaderButton={setSelectedTab}
						setDataFilterArray={setDataFilterArray}
					/>
				</Route>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.ENROLLMENTS_TAB}>
					<EnrollmentSelectionScreen
						chooseMainHeaderButton={setSelectedTab}
						setDataFilterArray={setDataFilterArray}
					/>
				</Route>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.DOCUMENTS_TAB}>
					<CompanyDocuments chooseMainHeaderButton={setSelectedTab} />
				</Route>
				<Route path={adminURL + Constants.ADMIN.ADMIN_TABS.MY_COMPANY_TAB}>
					<MyCompanyTab />
				</Route>
				<Redirect from={'*'} to={adminURL + Constants.ADMIN.ADMIN_TABS.USER_TAB} />
			</Switch>
			{/*Modals*/}
			<NewUserModal open={isOpenNewUserModal} close={() => setIsOpenNewUserModal(false)} />
			<InviteNewSupplierModal
				open={isInviteSupplierModalOpen}
				title={'newSupplier'}
				close={() => closeInviteSupplierModal()}
				accept={() => sendInvitation()}
				bodyText={['newSuplierText', 'informationSendInviteText']}
				textButton="inviteText"
				email={valueEmail}
				setEmail={setValueEmail}
				isEmailError={emailError}
			/>
			<AdministrationConsoleErrorModal open={!!errorCode} errorCode={errorCode} />
			<UploadProductsModal open={massiveUploadModal} onClose={() => setMassiveUploadModal(false)} />
		</div>
	)
}
