export const URLS: Record<string, string> = {
	SITE: '/',
	HOME: '/home',
	LOGIN: '/login',
	REGISTER: '/signup',
	RESET_PASSWORD: '/resetpassword',
	PASSWORD_RECOVERY: '/pwdrecovery',
	PASS_RESET_SUCCESSFUL: '/resetsuccess',
	REGISTER_COMPANY: '/registercompany',
	SELECT_COMPANY_AT_LOGIN: '/selectcompany',
	PO_NEW: '/newpurchaseorder',
	PO_LIST: '/purchaseorderlist',
	INVOICE_LIST: '/invoicelist',
	INVOICE_LIST_ARCHIVED: '/invoicelist/archived',
	NEW_INVOICE: '/new-invoice',
	PO_DETAIL: '/detailpurchaseorder',
	INVOICE_DETAIL: '/detailinvoice',
	PO_EDIT: '/editpurchaseorder',
	INVOICE_EDIT: '/editinvoice',
	GR_LIST: '/goodsreceiptlist',
	GR_NEW: '/newgoodsreceipt',
	GR_DETAIL: '/detailgoodsreceipt',
	COMPLEMENT_LIST: '/paymentcomplementlist',
	COMPLEMENT_DETAIL: '/detailpaymentcomplement',
	REPORT_LIST: '/reports',
	DASHBOARDS: '/dashboard',
	RFQ: '/requestforquotationlist',
	RFQ_NEW: '/newrequestforquotation',
	RFQ_DETAIL: '/detailrequestforquotation',
	ADMINISTRATION: '/administration',
	WITHOUT_ACCESS: '/withoutaccess',
	USER_SETTINGS: '/usersettings',
	NOT_FOUND: '/notfound',
	NEW_PRODUCT: '/newproduct',
	NEW_ENROLLMENT: '/newenrollment',
	CONSUMPTION_LIST: '/consumptionlist',
	NEW_CONSUMPTION: '/newconsumption',
	CONSUMPTION_DETAIL: '/detailconsumption',
	REQUEST_FOR_CREDIT_MEMO_LIST: '/requestforcreditmemolist',
	NEW_REQUEST_FOR_CREDIT_MEMO: '/newrequestforcreditmemo',
	RFCM_DETAIL: '/detailrequestforcreditmemo',
	CREDIT_MEMO_LIST: '/creditmemolist',
	CREDIT_MEMO_DETAIL: '/creditmemodetail',
	NEW_CREDIT_MEMO: '/new-credit-memo',
	NEW_TICKET: '/newticket',
	TICKET_DETAIL: '/ticketdetail',
	TICKET_LIST: '/ticket',
	HELP: 'https://netcurio.atlassian.net/wiki/spaces/ADN/overview',
	PURCHASE_REQUISITION_LIST: '/purchase-requisitions',
	PURCHASE_REQUISITION_DETAIL: '/purchase-requisition'
}
