import { AUTH } from '../constants'
import { User } from '../types'

export const getCurrentUser = (): User | null => {
	const currentUser = localStorage.getItem(AUTH.NETCURIO_USER)
	if (currentUser) {
		try {
			return JSON.parse(currentUser)
		} catch (error) {
			console.warn(error)
			return null
		}
	}
	return null
}
