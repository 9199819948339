import { Filter, getCurrentUser, Roles, URLS, User } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioIcons,
	NetcurioTooltip,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { downloadExcelFile } from '../../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../../utilities/file-handling/file-descriptor'
import { getUserRolesForCompany } from '../../../../utilities/getUserRolesForCompany'
import listHelper from '../../../../utilities/listHelper'
import styles from '../invoicesList.module.scss'
import { ErrorInvoiceArchivedModal } from '../modals/ErrorInvoiceArchivedModal'
import { TableInvoices } from '../TableInvoices'
import { DataInvoice, DataInvoiceList, FilterToApply, Invoice, UpdateFilter } from '../types'
import { invoicesListValues, themeButtonHeader } from '../utilities/defaultValues'
import {
	ARCHIVE_INVOICES,
	INVOICES_CUSTOMER,
	INVOICES_SUPPLIER,
	UNARCHIVE_INVOICES
} from '../utilities/queries'

const userRoles: Roles[] = getUserRolesForCompany()
const initialSort = 'created_at'

export const ArchivedInvoicesList: FC = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const history = useHistory()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const [invoiceList, setInvoiceList] = useState<DataInvoiceList>(invoicesListValues)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [currentUser, setCurrentUser] = useState<User>(undefined)
	const [errorCodeModal, setErrorCodeModal] = useState<string>()
	const [uuidInvoices, setUuidInvoices] = useState<Set<string>>(new Set())
	const [undoUnarchivedCache, setUndoUnarchivedCache] = useState<Set<string>>(new Set())
	const disabledButtonArchived = !(uuidInvoices.size > 0)
	const lastTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

	useEffect(() => {
		setCurrentUser(getCurrentUser())
		const isMediumWindow = 600
		const containerHeightCompensation = 95
		const containerHeightSmall = 500
		const heightRow = 45
		const additionalRow = 2
		let finalHeight
		if (window.innerHeight > isMediumWindow) {
			finalHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = containerHeightSmall
		}
		const numberRows: number = Math.round(finalHeight / heightRow) + additionalRow
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (invoiceList.limitRows) {
			filteringInvoices(0, initialSort, '', initialSort)
		}
	}, [invoiceList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			filteringInvoices(invoiceList.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	const filteringInvoices = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange = '',
		finalRange = '',
		filterRemove = ''
	) => {
		let fieldListData = invoiceList.fieldList
		let orderListData = invoiceList.orderList
		let columnFilterActual: string = invoiceList.actualFilterData
		let currentList = { ...invoiceList.dataInvoice } as DataInvoice

		if (sortOrder !== '') {
			orderListData = sortOrder
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}

		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}

		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		let valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'seriefolio':
			case 'receiver':
			case 'sender':
			case 'reference':
			case 'payment_complement':
				typeFilterActual = 'hasRelation'
				valueFilterActual = valueFilter === 'Sí' ? 'true' : 'false'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'statusDetail':
				typeFilterActual = 'wildcard'
				break
			case 'currency':
				typeFilterActual = 'wildcard'
				break
			case 'proposed_payment_date':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'total':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
		}

		if (skip > 0) {
			const rowsActual: number = invoiceList.rowsActual + invoiceList.limitRows
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: invoiceList.limitRows,
				dataInvoice: {}
			}))
			currentList = {}
		}

		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)
		queryFilteringInvoice(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | string,
		finalRangeActual: number | string,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFiltersToApply: FilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			invoiceList.dataFilters,
			invoiceList.filtersOfTypeStatus
		) as FilterToApply

		const obj: UpdateFilter = resultFiltersToApply.objectForStateUpdate

		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			dataFilters: obj.dataFilters,
			dataFiltersArray: obj.dataFiltersArray,
			deleteRange: obj.deleteRange,
			filterContainerBar: obj.filterContainerBar
		}))

		if (obj.filtersOfTypeStatus) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				filtersOfTypeStatus: obj.filtersOfTypeStatus
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const queryFilteringInvoice = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataInvoice
	) => {
		const copyFilterToApply = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: userRoles.includes(Roles.CUSTOMER) ? INVOICES_CUSTOMER : INVOICES_SUPPLIER,
				variables: {
					limit: invoiceList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					archived: true,
					filter: copyFilterToApply
				},
				fetchPolicy: 'no-cache'
			})
			.then(
				(
					result: ApolloQueryResult<{
						Invoices: {
							list: Array<Invoice>
							total: number
						}
					}>
				) => {
					let interCount = skip
					const data: DataInvoice = { ...currentList }
					const obj: Array<Invoice> = result.data.Invoices.list
					for (const keyName in obj) {
						interCount++
						data[interCount] = obj[keyName]
					}
					setInvoiceList((prevState: DataInvoiceList) => ({
						...prevState,
						totalRows: result.data.Invoices.total,
						filtersOfTypeStatus: {
							...prevState.filtersOfTypeStatus,
							status: {
								...prevState.filtersOfTypeStatus.status,
								dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
							}
						},
						dataInvoice: data
					}))
					setStopPagination(true)
					hideLoadingSpinner()
					setShowBarLoader(false)
				}
			)
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				dataInvoice: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				}
			}))
			setStopPagination(true)
			setErrorCodeModal(errorCode)
			setShowBarLoader(false)
		}
		hideLoadingSpinner()
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringInvoices, initialSort)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			invoiceList.dataFilters,
			closeFilterContainerBar,
			filteringInvoices
		)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const downloadReportExcel = () => {
		showLoadingSpinner()
		const copyFilterToApply = JSON.parse(JSON.stringify(invoiceList.dataFiltersArray))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)

		listHelper
			.generateReportList(
				JSON.stringify({
					companyFilter: currentUser ? currentUser?.company?.rfc : undefined,
					filters: copyFilterToApply,
					archived: true,
					sort_field: invoiceList.fieldList,
					sort_order: invoiceList.orderList
				}),
				'invoices'
			)
			.then(async (res) => {
				if (res.ok) {
					res.json().then((responseJson: FileDescriptor) => {
						downloadExcelFile(responseJson)
					})
					hideLoadingSpinner()
				} else {
					const error = await res.json()
					handleError(error)
					hideLoadingSpinner()
				}
			})
			.catch((error) => {
				handleError(error)
			})
	}

	const saveUnarchivedInvoices = (uuid?: string) => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: UNARCHIVE_INVOICES,
				variables: {
					invoicesIds: uuid ? [uuid] : Array.from(uuidInvoices)
				}
			})
			.then(() => {
				setUndoUnarchivedCache((prevState) => (uuid ? new Set([...prevState, uuid]) : uuidInvoices))
				if (lastTimeoutRef.current) {
					clearTimeout(lastTimeoutRef.current)
				}
				lastTimeoutRef.current = setTimeout(() => {
					setUndoUnarchivedCache(new Set())
				}, 6000)
				setUuidInvoices(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handleUndoUnarchivedInvoices = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: ARCHIVE_INVOICES,
				variables: {
					invoicesIds: Array.from(undoUnarchivedCache)
				}
			})
			.then(() => {
				setUndoUnarchivedCache(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	return (
		<main className={styles.containerMainViewList}>
			<div className={styles.containerViewList}>
				<AuthenticatedHeader>
					<h1 className={styles.tittleHeader}>{t('archived')}</h1>
					<div>
						<NetcurioButton
							variant="outlined"
							className={classNames(
								styles.btnHeader,
								disabledButtonArchived && styles.btnArchivedDisabledIcon
							)}
							size="small"
							disabled={disabledButtonArchived}
							onClick={() => saveUnarchivedInvoices()}
							endIcon={
								<NetcurioIcons.Unarchive
									className={classNames(disabledButtonArchived && styles.disabledIcon)}
								/>
							}
						>
							<span> {t('unarchived')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							size="small"
							color="error"
							onClick={() => history.push(URLS.INVOICE_LIST)}
							endIcon={<i className={classNames(styles.icon, styles.iconGoToList)}></i>}
						>
							<span> {t('comebackListText')} </span>
						</NetcurioButton>
						<NetcurioTooltip title={t('buttonExportTooltip')}>
							<div>
								<NetcurioButton
									variant="outlined"
									sx={themeButtonHeader}
									disabled={true}
									onClick={downloadReportExcel}
									endIcon={<NetcurioIcons.Download />}
								>
									<span> {t('exportTextButton')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
					</div>
				</AuthenticatedHeader>
				<TableInvoices
					dataInvoice={invoiceList.dataInvoice}
					filteringInvoices={filteringInvoices}
					rowsActual={invoiceList.rowsActual}
					totalRows={invoiceList.totalRows}
					userRoles={userRoles}
					filterContainerBar={invoiceList.filterContainerBar}
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={invoiceList.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					deleteRange={invoiceList.deleteRange}
					filtersOfTypeStatus={invoiceList.filtersOfTypeStatus}
					filtersOfTypePaymentComp={invoiceList.filtersOfTypePaymentComp}
					setActivePagination={setActivePagination}
					uuidInvoices={uuidInvoices}
					handleUndoUnarchivedInvoices={handleUndoUnarchivedInvoices}
					undoUnarchivedCount={undoUnarchivedCache.size}
					setUuidInvoices={setUuidInvoices}
					toggleArchiveInvoices={saveUnarchivedInvoices}
					showBarLoader={showBarLoader}
					setShowBarLoader={setShowBarLoader}
				/>
			</div>
			<ErrorInvoiceArchivedModal open={!!errorCodeModal} errorCode={errorCodeModal} />
		</main>
	)
}
