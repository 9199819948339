import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * This hook is similar to useEffect, but it skips
 * the effect on the first render,
 */

export const useUpdateEffect: (effect: EffectCallback, deps?: DependencyList) => void = (effect, deps) => {
	const isInitialMount = useRef(true)

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			return effect()
		}
	}, deps)
}
