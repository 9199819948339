import { AUTH, Company, getCurrentUser, getDefaultAccessPage, URLS, User } from '@netcurio/frontend-common'
import Tracker from '@openreplay/tracker'
import { Auth } from 'aws-amplify'
import { useEffect, useMemo } from 'react'
import { doesRequireAuthentication } from '../auth/does-require-authentication'
import ERROR from '../utilities/constants/error'
import { validateMultiCompany } from '../utilities/redirectHelper'
import UserInformation from '../utilities/userInformation'
import { useEnvironment } from './useEnvironment'

interface AuthProps {
	onTokenExpired?: () => void
}

export const useAuth = ({ onTokenExpired }: AuthProps = {}) => {
	const { environment, isSuccess } = useEnvironment()

	let trackerId = undefined

	if (isSuccess) {
		trackerId = environment.OPENREPLAY_KEY
	}
	const tracker = useMemo(() => {
		return trackerId
			? new Tracker({
					projectKey: trackerId,
					network: {
						sessionTokenHeader: false,
						failuresOnly: false,
						ignoreHeaders: true,
						capturePayload: true, //start capturing the payload of every request
						captureInIframes: true,
						sanitizer: (data) => {
							// ignore payloads for calls that start with /secure
							if (data.url.startsWith('/secure')) {
								data.request.body = null
								data.response.body = null
							}
							if (data.request.headers['Authorization']) {
								// can also use ignoreHeaders option instead
								data.request.headers['Authorization'] = 'SANITISED'
							}
							return data
						}
					}
				})
			: undefined
	}, [trackerId])

	const tokenHasExpired = (idToken?: { payload: { iat: number } }) => {
		if (!idToken) {
			return true
		} else {
			const now = new Date()
			const issuedAt = idToken.payload.iat
			const expiresOn = ((issuedAt ?? 0) + AUTH.TOKEN_VALIDITY_MINUTES * 60) * 1000
			return expiresOn < now.getTime()
		}
	}

	useEffect(() => {
		const currentUser = getCurrentUser()
		if (tracker && currentUser?.email) {
			tracker.start().then((result) => {
				if (result.success) console.log('OpenReplay tracker started with session: ', result.sessionID)
			})
		}
	}, [tracker])

	const signOut = () => {
		const pathname = window.location.pathname
		if (doesRequireAuthentication(pathname)) {
			Auth.signOut()
				.then(() => {
					window.localStorage.clear()
					setTimeout(() => {
						location.href = URLS.LOGIN
					}, 100)
				})
				.catch((err) => console.log(err))
		} else {
			window.localStorage.clear()
		}
	}

	const handleMultiCompany = (
		currentUser: User,
		companiesUnblocked: Company[],
		defaultCompany?: string
	) => {
		if (defaultCompany) {
			const companySelected = UserInformation.getCompanySelected(
				companiesUnblocked,
				defaultCompany ?? ''
			)
			if (companySelected !== undefined) {
				location.href = getDefaultAccessPage(currentUser)
			} else {
				validateMultiCompany(companiesUnblocked)
			}
		} else {
			validateMultiCompany(companiesUnblocked)
		}
	}

	const validateKnownUrl = (pathname: string) => {
		const appURLS: { [key: string]: string } = URLS
		let urlValid = false
		for (const key in appURLS) {
			if (appURLS[key] === pathname) {
				urlValid = true
				break
			}
		}
		if (
			!urlValid &&
			(pathname.includes(appURLS.ADMINISTRATION + '/') ||
				pathname.includes(appURLS.REPORT_LIST + '/') ||
				pathname.includes(appURLS.SAMPLE + '/'))
		) {
			urlValid = true
		}
		if (!urlValid) {
			location.href = appURLS.NOT_FOUND
		}
	}

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				if (tokenHasExpired(user.signInUserSession.idToken)) {
					if (doesRequireAuthentication(window.location.pathname)) {
						signOut()
					}
					onTokenExpired?.()
					return
				}
				const currentUser = getCurrentUser()
				if (!currentUser) {
					signOut()
					return
				}
				const companySelected = currentUser.company
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()
				const defaultCompany = UserInformation.getDefaultCompany()
				if (tracker && currentUser?.email) {
					tracker.setUserID(currentUser.email)
				}
				const pathname = window.location.pathname
				validateKnownUrl(pathname)
				if (!doesRequireAuthentication(pathname)) {
					if (pathname === URLS.SITE) {
						if (currentUser && (companySelected || defaultCompany)) {
							location.href = getDefaultAccessPage(currentUser)
						}
					} else if (companySelected) {
						location.href = getDefaultAccessPage(currentUser)
					} else {
						handleMultiCompany(currentUser, companiesUnblocked, defaultCompany)
					}
				}
			})
			.catch((err) => {
				if (err !== ERROR.AWS_NOT_AUTHENTICATED_ERROR) {
					console.error(err)
				}
				if (doesRequireAuthentication(window.location.pathname)) {
					sessionStorage.setItem('internalUrl', window.location.href)
					location.href = URLS.LOGIN
				}
			})
	}, [tracker])
}
