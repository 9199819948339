import { NetcurioTooltip } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './HeaderTable.module.scss'

interface MainHeaderTableProps {
	textHeader: string
	showFilters?: (field: string, fieldStyle: string) => void
	dataMainHeaderTable: tableFillHeader
	isForGridArea?: boolean
}

/**
 * MainHeaderTable
 * @param textHeader <string> class to be used in the main div of each header
 * @param showFilters <function> function called to show or hide the table's filters
 * @param dataMainHeaderTable <object> group of classes used to give custom style to each table's headers
 * @param isForGridArea <boolean>
 */
export const HeaderTable = ({
	textHeader,
	showFilters,
	dataMainHeaderTable,
	isForGridArea
}: MainHeaderTableProps) => {
	const { t } = useTranslation()
	const isClickable = dataMainHeaderTable.getIsClickable()
	return (
		<div
			className={classNames(
				isForGridArea && styles.headerForGridArea,
				isClickable && styles.headerForGridAreaClickable
			)}
		>
			<NetcurioTooltip title={t(`tooltips.${dataMainHeaderTable.getParameterField()}`)}>
				<div
					className={classNames(
						textHeader,
						dataMainHeaderTable.getSecondaryClass(),
						dataMainHeaderTable.getSelectedFieldClass(),
						styles.textHeaderOrderList,
						isClickable && styles.clickable,
						isForGridArea && styles.textHeaderOrderListFoGridArea
					)}
					onClick={() => {
						if (showFilters) {
							showFilters(
								dataMainHeaderTable.getParameterField(),
								dataMainHeaderTable.getParameterFieldClass()
							)
						}
					}}
					role="button"
				>
					<div>{t(dataMainHeaderTable.getName())}</div>
				</div>
			</NetcurioTooltip>
		</div>
	)
}
