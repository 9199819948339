export default {
	REJECTED: 'REJECTED',
	CONFIRMED: 'CONFIRMED',
	NEW: 'NEW',
	ACCOUNTED: 'ACCOUNTED',
	DELIVERED: 'DELIVERED',
	IN_PROCESS: 'IN_PROCESS',
	ACCEPTED: 'ACCEPTED',
	HALF_CONFIRMED: 'HALF_CONFIRMED',
	HALF_DELIVERED: 'HALF_DELIVERED',
	CANCELED: 'CANCELED',
	ERROR: 'ERROR',
	IN_APPROVAL: 'IN_APPROVAL',
	MISSING_LINK: 'MISSING_LINK',
	VOIDED: 'VOIDED',
	PAYMENT_SCHEDULED: 'PAYMENT_SCHEDULED',
	PARTIALLY_PAID: 'PARTIALLY_PAID',
	PAID: 'PAID',
	EXCEPTION: 'EXCEPTION'
}
