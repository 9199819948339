import {
	NetcurioButton,
	NetcurioDialog,
	NetcurioIcons,
	TextArea,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { useUpdateEffect } from '@netcurio/frontend-components/src/NetcurioList/hooks/useUpdateEffect'
import { useMutation } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../../../components/ConditionalRender/ConditionalRender'
import { useClient } from '../../../../../hooks/useClient'
import { useUserRoles } from '../../../../../hooks/useUserRoles'
import { PurchaseRequisitionStatusEnum } from '../../../enums/purchase-requisition-status.enum'
import { SEND_PURCHASE_REQUISITION_TO_REVISION } from '../../../graphql-ops/mutations'
import { useCurrentApprover } from '../../../hooks/useCurrentApprover'
import { PurchaseRequisitionInterface } from '../../../interfaces/purchase-requisition.interface'
import styles from './ReturnToReview.module.scss'

interface Props {
	purchaseRequisition: PurchaseRequisitionInterface
	onReturnToReview?: () => void
	validateBeforeReturnToReview: () => boolean
}

const CHARACTERS_LIMIT = 150

export const ReturnToReview = ({
	purchaseRequisition,
	onReturnToReview,
	validateBeforeReturnToReview
}: Props) => {
	const { isApprover } = useUserRoles()
	const { t } = useTranslation()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const client = useClient()
	const [hasError, setHasError] = useState<boolean>(false)

	const [comment, setComment] = useState<string>('')
	const { id, customerRfc, items } = purchaseRequisition

	const { isUserCurrentApprover } = useCurrentApprover({ purchaseRequisition })
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const canReview = useMemo(() => {
		const { status } = purchaseRequisition
		if (status !== PurchaseRequisitionStatusEnum.PENDING) {
			return false
		}

		return isUserCurrentApprover
	}, [purchaseRequisition, isUserCurrentApprover])

	const { mutate: sendToRevision } = useMutation({
		mutationFn: async () => {
			setIsModalOpen(false)
			showLoadingSpinner()
			const { data } = await client.mutate({
				mutation: SEND_PURCHASE_REQUISITION_TO_REVISION,
				variables: {
					purchaseRequisition: {
						id,
						customerRfc: customerRfc,
						items: items
					},
					comment
				}
			})
			return data
		},
		onSuccess: () => {
			onReturnToReview?.()
			hideLoadingSpinner()
		},
		onError: (error) => {
			console.error('Error sending to review a purchase requisition:', error)
			hideLoadingSpinner()
		}
	})

	const handleOnSubmit = () => {
		if (comment.trim().length === 0) {
			setHasError(true)
		} else {
			setHasError(false)
			sendToRevision()
		}
	}
	useUpdateEffect(() => {
		if (isModalOpen) {
			setComment('')
			setHasError(false)
		}
	}, [isModalOpen])

	const remainingChars = () => {
		return CHARACTERS_LIMIT - comment.length === 1
			? t('invoice.remainingSingleCharacter')
			: t('invoice.remainingCharacters', {
					chars: CHARACTERS_LIMIT - comment.length
				})
	}

	if (!isApprover || PurchaseRequisitionStatusEnum.PENDING !== purchaseRequisition.status || !canReview) {
		return null
	}

	return (
		<>
			<NetcurioButton
				color="primary"
				variant={'outlined'}
				onClick={() => {
					if (validateBeforeReturnToReview()) {
						setIsModalOpen(true)
					}
				}}
				size="small"
				endIcon={<NetcurioIcons.UndoOutlined />}
			>
				<span> {t('purchaseRequisitions.actions.returnToReview')} </span>
			</NetcurioButton>

			<NetcurioDialog
				open={isModalOpen}
				actionButtons={
					<>
						<NetcurioButton onClick={() => setIsModalOpen(false)} variant="outlined">
							{t('comeBackTextModal')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={handleOnSubmit}>
							{t('buttonSendText')}
						</NetcurioButton>
					</>
				}
			>
				<p>{t('purchaseRequisitions.detail.orderRequestReview')}</p>
				<div className={styles.textAreaContainer}>
					<TextArea
						rows={3}
						placeholder={`${t('purchaseRequisitions.detail.reviewReason')}*`}
						onChangeText={(response: string) => setComment(response)}
						readValue={comment}
						maxLength={CHARACTERS_LIMIT}
						errorMessage={hasError ? t('requiredField') : ''}
					/>
					<ConditionalRender condition={!hasError}>
						<span className={styles.textMaxChars}>{remainingChars()}</span>
					</ConditionalRender>
				</div>
			</NetcurioDialog>
		</>
	)
}
