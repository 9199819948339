import { TableFillSelectionFilter } from '@netcurio/frontend-common'
import Constants from '../../../../../utilities/constants'
import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import styles from '../../../adminConsole.module.scss'

export function objectHeaderArray(isCustomer: boolean) {
	const idColumn = new tableFillHeader('idEnrollment', styles.enrollmentRowStandard, '', 'id', 'text')
	const stateColumn = new tableFillHeader(
		'stateEnrollment',
		styles.enrollmentRowStandard,
		'',
		'status',
		'status'
	)
	const creationDateColumn = new tableFillHeader(
		'createdEnrollment',
		styles.enrollmentRowStandard,
		'',
		'created_at',
		'date'
	)
	const customerNameColumn = new tableFillHeader(
		'customerNameEnrollment',
		styles.enrollmentRowLarge,
		'',
		'customer',
		'text'
	)
	const customerRfcColumn = new tableFillHeader(
		'customerRfcEnrollment',
		styles.enrollmentRowStandard,
		'',
		'customer_rfc',
		'text'
	)
	const supplierNameColumn = new tableFillHeader(
		'supplierNameEnrollment',
		styles.enrollmentRowLarge,
		'',
		'supplier',
		'text'
	)
	const supplierRfcColumn = new tableFillHeader(
		'supplierRfcEnrollment',
		styles.enrollmentRowStandard,
		'',
		'supplier_rfc',
		'text'
	)
	if (isCustomer) {
		return [idColumn, stateColumn, creationDateColumn, supplierNameColumn, supplierRfcColumn]
	} else {
		return [idColumn, stateColumn, creationDateColumn, customerNameColumn, customerRfcColumn]
	}
}

export function objectStatusFilterArray(): Array<TableFillSelectionFilter> {
	const newStatusOption = new TableFillSelectionFilter(Constants.ENROLLMENT_STATUS.NEW, 'new-status-button')
	const reviewStatusOption = new TableFillSelectionFilter(
		Constants.ENROLLMENT_STATUS.REVIEW,
		'review-status-button'
	)
	const approvedStatusOption = new TableFillSelectionFilter(
		Constants.ENROLLMENT_STATUS.APPROVED,
		'approved-rfc-status-button'
	)
	const rejectedStatusOption = new TableFillSelectionFilter(
		Constants.ENROLLMENT_STATUS.REJECTED,
		'rejected-status-button'
	)

	return [newStatusOption, reviewStatusOption, approvedStatusOption, rejectedStatusOption]
}
