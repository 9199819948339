import gql from 'graphql-tag'

export const USER = gql`
	query User($email: ID!) {
		User(email: $email) {
			email
			name
			lastname
			status {
				key
				value
			}
			created_at
			created_by {
				email
				name
				lastname
			}
			phone_number
			updated_at
			modified_by {
				email
				name
				lastname
			}
			time_zone
			roles {
				id
				description
			}
			is_admin
			enrollment_request_approver
		}
	}
`

export const CHANGE_USER_STATUS = gql`
	mutation changeUserStatus($email: ID!, $action: String!) {
		changeUserStatus(email: $email, action: $action) {
			code
			message
		}
	}
`

export const UPDATE_USER_TYPE = gql`
	mutation updateUserType($email: ID!, $is_admin: Boolean!, $enrollment_request_approver: Boolean!) {
		updateUserType(
			email: $email
			is_admin: $is_admin
			enrollment_request_approver: $enrollment_request_approver
		) {
			code
			message
		}
	}
`
