import { Comment, dateFormatter, parseTimestampToTimeZone, Roles, URLS } from '@netcurio/frontend-common'
import {
	acceptedRequestCreditMemo,
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	DetailTable,
	NetcurioButton,
	NetcurioIcons,
	newRequestCreditMemo,
	rejectedRequestCreditMemo,
	statusLoading,
	TrackInformation,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import classnames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ErrorModal } from '../../components/dialogModal/errorModal'
import { CreditMemo, ITrackInformationCreditMemo, RequestForCreditMemoItem } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import { InformationContainer } from '../creditMemoDetail/InformationContainer/InformationContainer'
import { AcceptRequestCreditMemoModal } from './Modals/AcceptRequestCreditMemo/AcceptRequestCreditMemoModal'
import { RejectPendingMsgRequestCreditMemoDetail } from './Modals/RejectPendingMsgRequestCreditMemo/RejectPendingMsgRequestCreditMemoDetail'
import { RejectRequestCreditMemoModal } from './Modals/RejectRequestCreditMemo/RejectRequestCreditMemoModal'
import { ContainerAssociationDocument } from './containerAssociationDocument'
import { CreditMemoRelatedColumn } from './creditMemoDetail/creditMemoRelatedColumn'
import * as queries from './queries'
import styles from './requestForCreditMemoDetail.module.scss'

interface InformationRFCM {
	id: string
	created_by?: {
		name?: string
		lastname?: string
	}
	modified_by_customer?: {
		name?: string
		lastname?: string
	}
	modified_by_supplier?: {
		name?: string
		lastname?: string
	}
	customer: {
		name: string
		rfc: string
	}
	supplier: {
		name: string
		rfc: string
	}
	request_reason: string
	invoice: {
		uuid: string
		serie: string
		folio: number
		currency: string
		total: number
	}
	status?: {
		key: string
		value: string
	}
	user_reference?: string
	customer_reference?: string
	rejected_reason?: string
	created_at: Date
	date_accepted?: Date
	date_rejected?: Date
}

interface InformationTable {
	amount: number
	concept_description: string
	net_amount: number
	position: number
	tax: number
}

export const RequestForCreditMemoDetail = () => {
	const client = connection()
	const { t } = useTranslation()
	const history = useHistory()
	const userRoles: Roles[] = useMemo((): Roles[] => getUserRolesForCompany(), [])
	const id = getUrlParameter('requestforcreditmemo')
	const customer = getUrlParameter('customer')
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [informationRFCM, setInformationRFCM] = useState<InformationRFCM>()
	const [informationTable, setInformationTable] = useState<InformationTable[]>([])
	const [informationCreditMemos, setInformationCreditMemos] = useState<CreditMemo[]>([])
	const [creditMemoSelected, setCreditMemoSelected] = useState<CreditMemo>(undefined)
	const [rfcmItems, setRfcmItems] = useState<RequestForCreditMemoItem[]>([])
	const [showCreditMemoRelated, setShowCreditMemoRelated] = useState<boolean>(false)
	const [hideMainView, setHideMainView] = useState<boolean>(false)
	const [comments, setComments] = useState<Comment[]>([])
	const [hideButtons, setHideButtons] = useState(false)
	const [existMessagePending, setExistMessagePending] = useState<boolean>(false)
	const [isEditable, setIsEditable] = useState<boolean>(false)
	const [statusRFCM, setStatusRFCM] = useState<string>('')

	const [openAcceptRequestCreditMemoModal, setOpenAcceptRequestCreditMemoModal] = useState<boolean>(false)
	const [openRejectRequestCreditMemoModal, setOpenRejectRequestCreditMemoModal] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>('')
	const [openPendingMessageModal, setOpenPendingMessageModal] = useState<boolean>(false)

	const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

	useEffect(() => {
		queryInformation()
	}, [])

	useEffect(() => {
		if (errorCode) {
			setModal(
				<ErrorModal
					fillModal={errorModal(errorCode)}
					hideModal={() => {
						setErrorCode('')
						setModal(undefined)
					}}
					errorCode={errorCode}
				/>
			)
		} else if (showCreditMemoRelated) {
			setHideMainView(true)
			const associateItems = (rfcmItemPosition: string | null, cmItemIndex: number) => {
				const cmItems = [...creditMemoSelected.items]
				cmItems[cmItemIndex].reference_position = rfcmItemPosition
				setCreditMemoSelected({ ...creditMemoSelected, items: cmItems })
			}
			setModal(
				<div className={styles.trackTotalContainer}>
					<InformationContainer
						creditMemoDetail={creditMemoSelected}
						showHeaderMessage={false}
						rfcmItems={rfcmItems}
						onRfcmItemAssociation={associateItems}
						closeWindow={closeWindow}
					/>
					<CreditMemoRelatedColumn
						creditMemoDetail={creditMemoSelected}
						creditMemos={informationCreditMemos}
						showCreditMemoDetail={showCreditMemoDetail}
						statusRFCM={statusRFCM}
						readonly={true}
						buttonAction={goToCreditMemo}
					/>
				</div>
			)
		}
	}, [
		errorCode,
		isEditable,
		openAcceptRequestCreditMemoModal,
		openRejectRequestCreditMemoModal,
		showCreditMemoRelated,
		creditMemoSelected
	])

	function goToCreditMemo() {
		history.push(URLS.CREDIT_MEMO_DETAIL + '?creditmemo=' + creditMemoSelected.uuid)
	}

	function queryInformation() {
		showLoadingSpinner()
		client
			.query({
				query: queries.REQUEST_FOR_CREDIT_MEMO,
				variables: {
					id,
					customer
				}
			})
			.then((result) => {
				setInformationRFCM(result.data.RequestForCreditMemo)
				setInformationTable(result.data.RequestForCreditMemo.items)
				setInformationCreditMemos(result.data.RequestForCreditMemo.credit_memos)
				setStatusRFCM(result.data.RequestForCreditMemo.status.key)
				setComments(result.data.RequestForCreditMemo.comments)
				if (result.data.RequestForCreditMemo.status.key === Constants.STATUS.NEW) setIsEditable(true)
				else setIsEditable(false)
				hideLoadingSpinner()
			})
			.catch((error) => {
				const newErrorCode = showErrorComponent(error)
				expiredToken(newErrorCode)
				if (!expiredToken(newErrorCode)) {
					setErrorCode(newErrorCode)
				}
				hideLoadingSpinner()
			})
	}

	function cancelButtonAction() {
		if (existMessagePending) {
			setOpenPendingMessageModal(true)
		} else {
			history.push(URLS.REQUEST_FOR_CREDIT_MEMO_LIST)
		}
	}

	const commentsSectionOpen = (open: CommentSectionSize) =>
		setHideButtons(open === CommentSectionSize.Extended)

	function addNewComment(newCommentData: { text: string }) {
		const dataNewComment = {
			requestForCreditMemo: id,
			customer,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: queries.CREATE_RFCM_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				client
					.query({
						query: queries.RFCM_COMMENTS,
						variables: {
							id,
							customer
						},
						fetchPolicy: 'no-cache'
					})
					.then((result) => {
						setComments(result.data.RequestForCreditMemo.comments)
					})
					.catch((error) => {
						const newErrorCode = showErrorComponent(error)
						expiredToken(newErrorCode)
						if (!expiredToken(newErrorCode)) {
							setErrorCode(newErrorCode)
						}
					})
			})
			.catch((error) => {
				const newErrorCode = showErrorComponent(error)
				expiredToken(newErrorCode)
				if (!expiredToken(newErrorCode)) {
					setErrorCode(newErrorCode)
				}
			})
			.finally(() => {
				setExistMessagePending(false)
			})
	}

	function pendingMessageHandler(pendingMsg: string) {
		setExistMessagePending(!!pendingMsg)
	}

	function showCreditMemoDetail(creditMemo: CreditMemo) {
		setCreditMemoSelected(creditMemo)
		setRfcmItems(creditMemo.request_for_credit_memo.items || [])
		setShowCreditMemoRelated(true)
	}

	function closeWindow() {
		setModal(undefined)
		setShowCreditMemoRelated(false)
		setHideMainView(false)
	}
	const mapStatuses = useCallback(() => {
		const createdDate: string = dateFormatter.format(
			parseTimestampToTimeZone(informationRFCM?.created_at)
		)
		const acceptedDate: string = dateFormatter.format(
			parseTimestampToTimeZone(informationRFCM?.date_accepted)
		)
		const rejectedDate: string = dateFormatter.format(
			parseTimestampToTimeZone(informationRFCM?.date_rejected)
		)

		const statuses = {
			[Constants.STATUS.NEW]: [newRequestCreditMemo(createdDate)],
			[Constants.STATUS.ACCEPTED]: [
				newRequestCreditMemo(createdDate),
				acceptedRequestCreditMemo(acceptedDate)
			],
			[Constants.STATUS.REJECTED]: [
				newRequestCreditMemo(createdDate),
				rejectedRequestCreditMemo(rejectedDate)
			],
			DEFAULT: [statusLoading(createdDate)]
		}
		return statuses[informationRFCM?.status.key] || statuses['DEFAULT']
	}, [informationRFCM])

	const mapTrackInformationData = useCallback((): ITrackInformationCreditMemo => {
		const trackInformationData = {
			created_by_text:
				(informationRFCM?.created_by?.name || '') +
				' ' +
				(informationRFCM?.created_by?.lastname || ''),
			supplier: informationRFCM?.supplier?.name || '',
			supplier_rfc: informationRFCM?.supplier?.rfc || '',

			editBy: informationRFCM?.modified_by_supplier
				? (informationRFCM?.modified_by_supplier.name || '') +
					' ' +
					(informationRFCM?.modified_by_supplier.lastname || '')
				: '-',
			customer: informationRFCM?.customer?.name || '',
			customer_rfc: informationRFCM?.customer?.rfc || '',

			creationDate: dateFormatter.format(parseTimestampToTimeZone(informationRFCM?.created_at)),
			request_customer_reference: informationRFCM?.customer_reference || '-',
			userReference: informationRFCM?.user_reference || '-',
			reason: informationRFCM?.request_reason || '',
			associatedInvoice: informationRFCM
				? informationRFCM.invoice
					? informationRFCM.invoice.serie +
						'-' +
						informationRFCM.invoice.folio +
						'/' +
						informationRFCM.invoice.uuid
					: ''
				: '',
			invoiceCurrency: informationRFCM?.invoice?.currency || '',
			invoiceAmount: informationRFCM?.invoice?.total
				? Formatter.currency.format(informationRFCM.invoice.total)
				: '',
			reason_selector_item: informationRFCM?.rejected_reason
		}
		if (userRoles.includes(Roles.CUSTOMER)) {
			delete trackInformationData.editBy
			delete trackInformationData.customer
			delete trackInformationData.customer_rfc
		} else {
			delete trackInformationData.created_by_text
			delete trackInformationData.supplier
			delete trackInformationData.supplier_rfc
		}
		if (!informationRFCM?.rejected_reason) {
			delete trackInformationData.reason_selector_item
		}
		return trackInformationData
	}, [informationRFCM])

	const mapMessageStatus = useCallback((): any => {
		const rejected = {
			error: { key: informationRFCM?.status.key, value: 'rejectRFQText' }
		}
		const newStatus = 'createNewCreditNoteText'
		const acceptedStatus = 'rfcmAccepted'
		return informationRFCM?.status.key === Constants.STATUS.REJECTED
			? rejected
			: informationRFCM?.status.key === Constants.STATUS.NEW
				? newStatus
				: informationRFCM?.status.key === Constants.STATUS.ACCEPTED
					? acceptedStatus
					: ''
	}, [informationRFCM])

	const mapItemsTable = useCallback(() => {
		return (
			informationTable?.map((item) => {
				return {
					positionRFQ: Formatter.id(item.position),
					conceptRFQ: item.concept_description,
					amountRFQ: item.amount,
					netamountRFQ: Formatter.currency.format(item.net_amount),
					indicatorTaxes: Formatter.percent.format(item.tax)
				}
			}) || []
		)
	}, [informationTable])

	const closePendingMessageModal = () => {
		setOpenPendingMessageModal(false)
	}

	return (
		<div className={styles.containerRFCMDetail}>
			<AuthenticatedHeader>
				<div>
					<NetcurioButton
						variant="outlined"
						color="error"
						size={'small'}
						onClick={cancelButtonAction}
						endIcon={<NetcurioIcons.ArrowBack />}
					>
						<span> {t('comebackListText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</AuthenticatedHeader>
			{modal ?? modal}
			{!hideMainView && (
				<div className={styles.trackTotalContainer}>
					<div className={styles.trackAndTableRFCMDetailContainer}>
						<TrackInformation
							id={Formatter.id(informationRFCM?.id || '')}
							title={'requestCreditMemo'}
							information={mapTrackInformationData()}
							gridTemplateAreas={`'${
								userRoles.includes(Roles.CUSTOMER)
									? 'created_by_text supplier supplier_rfc'
									: 'editBy customer customer_rfc'
							} creationDate'
										 'request_customer_reference userReference . .'
										 'reason reason reason reason'
										 'associatedInvoice associatedInvoice invoiceCurrency invoiceAmount'
										${
											informationRFCM?.status?.key == Constants.STATUS.REJECTED
												? `'reason_selector_item reason_selector_item reason_selector_item reason_selector_item'`
												: ``
										}`}
							statusMessage={mapMessageStatus()}
							headerMessage={mapMessageStatus()}
							showHeaderMessage={informationRFCM?.status?.key !== Constants.STATUS.REJECTED}
							statuses={mapStatuses()}
						/>
						<div className={styles.separatorGray}></div>
						<DetailTable
							items={mapItemsTable()}
							errorItems={[]}
							section={'requestForCreditMemoDetail'}
						/>
					</div>

					<div className={styles.lateralInfoRFCMDetailContainer}>
						<CommentsSection
							variant={CommentSectionVariant.WithBody}
							comments={comments}
							onAddComment={addNewComment}
							onSizeChange={commentsSectionOpen}
							onChange={pendingMessageHandler}
						/>

						{isEditable ? (
							userRoles.includes(Roles.SUPPLIER) && (
								<div
									className={classnames(styles.buttonsRequestCreditMemo, {
										'display-none': hideButtons
									})}
								>
									<NetcurioButton
										color="primary"
										endIcon={<NetcurioIcons.CheckCircleOutlineOutlined />}
										onClick={() => {
											setOpenAcceptRequestCreditMemoModal(true)
										}}
										variant="outlined"
										className={styles.btnAccept}
									>
										{t('acceptRequest')}
									</NetcurioButton>
									<NetcurioButton
										color="secondary"
										endIcon={<NetcurioIcons.CancelOutlined />}
										onClick={() => {
											setOpenRejectRequestCreditMemoModal(true)
										}}
										variant="text"
										className={styles.btnReject}
									>
										{t('rejectRequestText')}
									</NetcurioButton>
								</div>
							)
						) : (
							<div className={classnames({ 'display-none': hideButtons })}>
								<ContainerAssociationDocument
									creditMemos={informationCreditMemos}
									showCreditMemoDetail={showCreditMemoDetail}
									statusRFCM={statusRFCM}
									readOnly={userRoles.includes(Roles.CUSTOMER)}
								/>
							</div>
						)}
					</div>
				</div>
			)}
			<AcceptRequestCreditMemoModal
				openModal={openAcceptRequestCreditMemoModal}
				closeModal={() => setOpenAcceptRequestCreditMemoModal(false)}
			/>
			<RejectRequestCreditMemoModal
				closeModal={() => setOpenRejectRequestCreditMemoModal(false)}
				openModal={openRejectRequestCreditMemoModal}
			/>
			<RejectPendingMsgRequestCreditMemoDetail
				open={openPendingMessageModal}
				close={() => closePendingMessageModal()}
				onAccept={() => history.push(URLS.REQUEST_FOR_CREDIT_MEMO_LIST)}
			/>
		</div>
	)
}
