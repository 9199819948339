import { Filter, Roles, URLS, User, getCurrentUser } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioIcons,
	NetcurioTooltip,
	Severity,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { useCompanySettings } from '../../../hooks/useCompanySettings'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { downloadExcelFile } from '../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../utilities/file-handling/file-descriptor'
import { getUserRolesForCompany } from '../../../utilities/getUserRolesForCompany'
import listHelper from '../../../utilities/listHelper'
import { ModifyInvoicesModal } from '../ModifyInvoicesModal/ModifyInvoicesModal'
import { NewInvoiceModal } from '../NewInvoiceModal/NewInvoiceModal'
import { TableInvoices } from './TableInvoices'
import styles from './invoicesList.module.scss'
import { ErrorInvoiceArchivedModal } from './modals/ErrorInvoiceArchivedModal'
import { DataInvoice, DataInvoiceList, FilterToApply, Invoice, UpdateFilter } from './types'
import { invoicesListValues } from './utilities/defaultValues'
import {
	ARCHIVE_INVOICES,
	INVOICES_CUSTOMER,
	INVOICES_SUPPLIER,
	PIN_INVOICES,
	REPROCESSING_INVOICES,
	UNARCHIVE_INVOICES,
	UNPIN_INVOICES
} from './utilities/queries'

export const InvoicesList: FC = () => {
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const initialSort = 'created_at'
	const history = useHistory()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const pathname = window.location.pathname
	const [showBarLoader, setShowBarLoader] = useState<boolean>(false)
	const [userRoles, setUserRoles] = useState<Roles[]>([])
	const [invoiceList, setInvoiceList] = useState<DataInvoiceList>(invoicesListValues)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [currentUser, setCurrentUser] = useState<User | null>(null)
	const [errorCodeModal, setErrorCodeModal] = useState<string>()
	const [uuidInvoices, setUuidInvoices] = useState<Set<string>>(new Set())
	const [undoArchivedCache, setUndoArchivedCache] = useState<Set<string>>(new Set())
	const [openUploadInvoiceModal, setOpenUploadInvoiceModal] = useState(pathname === URLS.NEW_INVOICE)
	const [openInformationAlert, setOpenInformationAlert] = useState<boolean>(false)
	const [openModifyInvoiceModal, setOpenModifyInvoiceModal] = useState(false)
	const [invoicesHasBeenModified, setInvoicesHasBeenModified] = useState<boolean>(false)
	const [alertType, setAlertType] = useState<Severity>()
	const [alertMessage, setAlertMessage] = useState<string>('')
	const disableHeaderActionBtn = !(uuidInvoices.size > 0)
	const lastTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
	const tableHasInvoices = Object.keys(invoiceList.dataInvoice).length > 0
	const isSupplier = userRoles.includes(Roles.SUPPLIER)
	const isCustomer = userRoles.includes(Roles.CUSTOMER)
	const { companySettings } = useCompanySettings({ rfc: currentUser?.company?.rfc ?? null })
	const companyHasStandAloneEnable = companySettings?.standalone_invoice_workflow_enabled ?? false
	const showInvoiceBtn = isSupplier || (isCustomer && companyHasStandAloneEnable)
	const isAdmin = currentUser?.company?.is_admin

	useEffect(() => {
		setCurrentUser(getCurrentUser())
		const isMediumWindow = 600
		const containerHeightCompensation = 95
		const containerHeightSmall = 500
		const heightRow = 45
		const additionalRow = 2
		let finalHeight: number
		if (window.innerHeight > isMediumWindow) {
			finalHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = containerHeightSmall
		}
		const numberRows: number = Math.round(finalHeight / heightRow) + additionalRow
		setUserRoles(getUserRolesForCompany())
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (invoiceList.limitRows) {
			filteringInvoices(0, initialSort, '', initialSort)
		}
	}, [invoiceList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			filteringInvoices(invoiceList.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	const filteringInvoices = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange = '',
		finalRange: Dayjs,
		filterRemove: string = ''
	) => {
		let fieldListData: string = invoiceList.fieldList
		let orderListData: string = invoiceList.orderList
		let columnFilterActual: string = invoiceList.actualFilterData
		let currentList: DataInvoice = { ...invoiceList.dataInvoice }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		const valueFilterActual: string = valueFilter
		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs

		switch (columnFilterActual) {
			case 'seriefolio':
			case 'receiver':
			case 'sender':
			case 'reference':
			case 'statusDetail':
			case 'currency':
				typeFilterActual = 'wildcard'
				break
			case 'payment_complement':
				typeFilterActual = 'hasRelation'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'proposed_payment_date':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'total':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
		}

		if (skip > 0) {
			const rowsActual: number = invoiceList?.rowsActual + invoiceList?.limitRows
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: invoiceList.limitRows,
				dataInvoice: {}
			}))
			currentList = {}
		}

		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		queryFilteringInvoice(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFiltersToApply: FilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			invoiceList.dataFilters,
			invoiceList.actualFilterData === 'payment_complement'
				? invoiceList.filtersOfTypePaymentComp
				: invoiceList.filtersOfTypeStatus
		) as FilterToApply
		const obj: UpdateFilter = resultFiltersToApply.objectForStateUpdate
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			dataFilters: obj.dataFilters,
			dataFiltersArray: obj.dataFiltersArray,
			deleteRange: obj.deleteRange,
			filterContainerBar: obj.filterContainerBar
		}))
		if (obj.filtersOfTypeStatus) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				filtersOfTypeStatus: obj.filtersOfTypeStatus
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const queryFilteringInvoice = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataInvoice
	) => {
		const copyFilterToApply = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: isCustomer ? INVOICES_CUSTOMER : INVOICES_SUPPLIER,
				variables: {
					limit: invoiceList.limitRows,
					skip: skip,
					archived: false,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				},
				fetchPolicy: 'no-cache'
			})
			.then(
				(
					result: ApolloQueryResult<{
						Invoices: {
							list: Array<Invoice>
							total: number
						}
					}>
				) => {
					let interCount = skip
					const data: DataInvoice = { ...currentList }
					const obj: Array<Invoice> = result.data.Invoices.list
					for (const keyName in obj) {
						interCount++
						data[interCount] = obj[keyName]
					}
					setInvoiceList((prevState: DataInvoiceList) => ({
						...prevState,
						totalRows: result.data.Invoices.total,
						filtersOfTypeStatus: {
							...prevState.filtersOfTypeStatus,
							status: {
								...prevState?.filtersOfTypeStatus?.status,
								dataFilter: prevState?.filtersOfTypeStatus?.status?.dataFilter || ''
							}
						},
						dataInvoice: data
					}))
					setStopPagination(true)
					hideLoadingSpinner()
					setShowBarLoader(false)
				}
			)
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				dataInvoice: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus?.status,
						dataFilter: ''
					}
				}
			}))
			setStopPagination(true)
			setErrorCodeModal(errorCode)
			setShowBarLoader(false)
		}
		hideLoadingSpinner()
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringInvoices, initialSort)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			invoiceList.dataFilters,
			closeFilterContainerBar,
			filteringInvoices
		)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const downloadReportExcel = () => {
		showLoadingSpinner()
		const copyFilterToApply = JSON.parse(JSON.stringify(invoiceList.dataFiltersArray))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		const body: string = JSON.stringify({
			companyFilter: currentUser ? currentUser.company?.rfc : undefined,
			filters: copyFilterToApply,
			archived: false,
			sort_field: invoiceList.fieldList,
			sort_order: invoiceList.orderList
		})
		listHelper
			.generateReportList(body, 'invoices')
			.then(async (res) => {
				if (res.ok) {
					res.json().then((responseJson: FileDescriptor) => {
						downloadExcelFile(responseJson)
					})
					hideLoadingSpinner()
				} else {
					const error = await res.json()
					handleError(error)
					hideLoadingSpinner()
				}
			})
			.catch((error: Error) => {
				handleError(error)
			})
	}

	const redirectToDetail = (UUID: string) => {
		sessionStorage.setItem('invoiceRegistered', 'true')
		if (isSupplier) {
			history.push(`${URLS.INVOICE_EDIT}?invoice=${UUID}`)
		} else if (isCustomer && companyHasStandAloneEnable) {
			history.push(`${URLS.INVOICE_DETAIL}?invoice=${UUID}`)
		}
	}

	const handleReprocessInvoices = () => {
		const arrayOfInvoices = Array.from(uuidInvoices)
		showLoadingSpinner()
		client
			.mutate({
				mutation: REPROCESSING_INVOICES,
				variables: {
					invoicesIds: arrayOfInvoices
				}
			})
			.then((res) => {
				const reprocessingResponse = res.data.reprocessingInvoices
				setAlertType(Severity.Info)
				if (lastTimeoutRef.current) {
					clearTimeout(lastTimeoutRef.current)
				}
				setAlertMessage(
					t('reprocessInvoicesSuccessMessage', {
						processed: reprocessingResponse?.counter ?? 0,
						sent: arrayOfInvoices.length
					})
				)
				setOpenInformationAlert(true)
				lastTimeoutRef.current = setTimeout(() => {
					setOpenInformationAlert(false)
					hideLoadingSpinner()
				}, 6000)
			})
			.catch(() => {
				setAlertType(Severity.Error)
				setAlertMessage(t('reprocessInvoicesErrorMessage'))
				if (lastTimeoutRef.current) {
					clearTimeout(lastTimeoutRef.current)
					hideLoadingSpinner()
				}
				setOpenInformationAlert(true)
				lastTimeoutRef.current = setTimeout(() => {
					setOpenInformationAlert(false)
				}, 6000)
			})
			.finally(() => {
				filteringInvoices(0, initialSort, '', initialSort)
				setUuidInvoices(new Set())
			})
	}

	const saveArchivedInvoices = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: ARCHIVE_INVOICES,
				variables: {
					invoicesIds: Array.from(uuidInvoices)
				}
			})
			.then(() => {
				setUndoArchivedCache(uuidInvoices)
				if (lastTimeoutRef.current) {
					clearTimeout(lastTimeoutRef.current)
				}
				lastTimeoutRef.current = setTimeout(() => {
					setUndoArchivedCache(new Set())
				}, 6000)
				setUuidInvoices(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handleUndoArchivedInvoices = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: UNARCHIVE_INVOICES,
				variables: {
					invoicesIds: Array.from(undoArchivedCache)
				}
			})
			.then(() => {
				setUndoArchivedCache(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handlePinInvoicesFeature = (invoices) => {
		showLoadingSpinner()
		/*
		 * Rules:
		 * - If any item is unpinned, pin all
		 * - If all items are pinned, unpin all
		 * */
		const hasUnpinnedInvoices = invoices.some((invoiceUUID) => {
			const invoiceKey = Object.keys(invoiceList.dataInvoice).find((key) => {
				return invoiceList.dataInvoice[key].uuid === invoiceUUID
			})
			return !invoiceList.dataInvoice[invoiceKey].pinned
		})
		const neededMethod = hasUnpinnedInvoices ? PIN_INVOICES : UNPIN_INVOICES
		client
			.mutate({
				mutation: neededMethod,
				variables: {
					invoicesIds: Array.from(invoices)
				}
			})
			.then(() => {
				setUuidInvoices(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handleModifiedInvoices = () => {
		if (alertType === Severity.Info) {
			if (lastTimeoutRef.current) clearTimeout(lastTimeoutRef.current)
			filteringInvoices(0, initialSort, '', initialSort)
			setUuidInvoices(new Set())
			setOpenInformationAlert(true)
			lastTimeoutRef.current = setTimeout(() => setOpenInformationAlert(false), 6000)
			setInvoicesHasBeenModified(false)
		}
		if (alertType === Severity.Error) {
			if (lastTimeoutRef.current) clearTimeout(lastTimeoutRef.current)
			setOpenInformationAlert(true)
			lastTimeoutRef.current = setTimeout(() => setOpenInformationAlert(false), 6000)
			setInvoicesHasBeenModified(false)
		}
	}

	useEffect(() => {
		if (invoicesHasBeenModified) {
			handleModifiedInvoices()
		}
	}, [invoicesHasBeenModified])

	return (
		<main className={styles.containerMainViewList}>
			<div className={styles.containerViewList}>
				<AuthenticatedHeader>
					<div>
						{showInvoiceBtn && (
							<NetcurioButton
								variant="outlined"
								className={styles.btnHeader}
								onClick={() => setOpenUploadInvoiceModal(true)}
								endIcon={<NetcurioIcons.Add />}
							>
								<span> {t('newInvoice')} </span>
							</NetcurioButton>
						)}
					</div>
					<div>
						<NetcurioTooltip title={t('pin')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={classNames(
										styles.btnHeader,
										disableHeaderActionBtn && styles.btnPinDisabledIcon
									)}
									size="small"
									disabled={disableHeaderActionBtn}
									onClick={() => {
										handlePinInvoicesFeature(Array.from(uuidInvoices))
									}}
									endIcon={
										<NetcurioIcons.PushPin
											className={classNames(
												disableHeaderActionBtn && styles.disabledIcon
											)}
										/>
									}
								>
									<span> {t('pin')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioTooltip title={t('archive')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={classNames(
										styles.btnHeader,
										disableHeaderActionBtn && styles.btnArchivedDisabledIcon
									)}
									size="small"
									disabled={disableHeaderActionBtn}
									onClick={() => saveArchivedInvoices()}
									endIcon={
										<NetcurioIcons.Archive
											className={classNames(
												disableHeaderActionBtn && styles.disabledIcon
											)}
										/>
									}
								>
									<span> {t('archive')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							disabled={disableHeaderActionBtn}
							onClick={() => handleReprocessInvoices()}
							endIcon={
								<NetcurioIcons.Refresh
									color="info"
									className={disableHeaderActionBtn ? styles.disabledIcon : ''}
								/>
							}
						>
							<span> {isSupplier ? t('reprocessInvoices') : t('resendInvoices')} </span>
						</NetcurioButton>
						{isCustomer && isAdmin && (
							<NetcurioButton
								variant="outlined"
								className={styles.btnHeader}
								onClick={() => setOpenModifyInvoiceModal(true)}
								endIcon={
									<NetcurioIcons.Edit
										color="info"
										className={!disableHeaderActionBtn ? '' : styles.disabledIcon}
									/>
								}
								size="small"
								disabled={disableHeaderActionBtn}
							>
								<span> {t('modify')} </span>
							</NetcurioButton>
						)}
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={() => history.push(URLS.INVOICE_LIST_ARCHIVED)}
							endIcon={<NetcurioIcons.TableRowsOutlined color="info" />}
							size="small"
						>
							<span> {t('seeArchived')} </span>
						</NetcurioButton>
						<NetcurioTooltip title={t('buttonExportTooltip')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={styles.btnHeader}
									disabled={!tableHasInvoices}
									onClick={downloadReportExcel}
									endIcon={
										<NetcurioIcons.Download
											color="info"
											className={tableHasInvoices ? '' : styles.disabledIcon}
										/>
									}
								>
									<span> {t('exportTextButton')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
					</div>
				</AuthenticatedHeader>
				<TableInvoices
					dataInvoice={invoiceList.dataInvoice}
					filteringInvoices={filteringInvoices}
					rowsActual={invoiceList.rowsActual}
					totalRows={invoiceList.totalRows}
					userRoles={userRoles}
					filterContainerBar={invoiceList?.filterContainerBar ?? false}
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={invoiceList.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					deleteRange={invoiceList.deleteRange ?? false}
					filtersOfTypeStatus={invoiceList.filtersOfTypeStatus}
					filtersOfTypePaymentComp={invoiceList.filtersOfTypePaymentComp}
					setActivePagination={setActivePagination}
					uuidInvoices={uuidInvoices}
					handleUndoArchivedInvoices={handleUndoArchivedInvoices}
					undoArchivedCount={undoArchivedCache.size}
					setUuidInvoices={setUuidInvoices}
					showBarLoader={showBarLoader}
					setShowBarLoader={setShowBarLoader}
					alertType={alertType}
					alertText={alertMessage}
					openAlert={openInformationAlert}
				/>
			</div>
			<ErrorInvoiceArchivedModal open={!!errorCodeModal} errorCode={errorCodeModal ?? ''} />
			<NewInvoiceModal
				open={openUploadInvoiceModal}
				onClose={() => setOpenUploadInvoiceModal(false)}
				redirectToDetail={redirectToDetail}
				isCustomer={isCustomer}
				companyHasStandAloneEnable={companyHasStandAloneEnable}
			/>
			<ModifyInvoicesModal
				invoicesData={Array.from(uuidInvoices)}
				open={openModifyInvoiceModal}
				onClose={() => setOpenModifyInvoiceModal(false)}
				handleInvoicesHasBeenModified={setInvoicesHasBeenModified}
				handleModifyInvoicesResult={setAlertType}
				handleModifyInvoicesResultText={setAlertMessage}
			/>
		</main>
	)
}
