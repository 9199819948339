import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
	isOpen: boolean
	onCancel: () => void
	onConfirm: () => void
}

export const ChangeSupplierConfirmationModal = ({ isOpen, onConfirm, onCancel }: ConfirmationModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={isOpen}
			titleText={t('deleteProducts')}
			actionButtons={
				<>
					<NetcurioButton variant="text" onClick={onCancel}>
						{t('goBackText')}
					</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onConfirm}>
						{t('deleteText')}
					</NetcurioButton>
				</>
			}
		>
			<div>
				<p>{t('deleteProductsQuestion')}</p>
			</div>
		</NetcurioDialog>
	)
}
