import { PurchaseRequisitionStatusEnum } from '../../enums/purchase-requisition-status.enum'
import { PurchaseRequisitionItemInterface } from '../../interfaces/purchase-requisition-item.interface'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

export const defaultValuesItemPr: PurchaseRequisitionItemInterface = {
	position: 1,
	customerRfc: '',
	supplierRfc: '',
	supplierDescription: '',
	productCode: '',
	productDescription: '',
	quantity: 1,
	unitPrice: 0,
	unit: '',
	netAmount: 0,
	requiredDeliveryDate: null
}

export const defaultValuesPurchaseRequisition: PurchaseRequisitionInterface = {
	customerRfc: '',
	requester: { email: '' },
	requesterEmail: '',
	createdBy: { email: '' },
	typeId: '',
	type: {
		id: '',
		description: ''
	},
	status: PurchaseRequisitionStatusEnum.NEW,
	description: '',
	branchOfficeId: '',
	branchOffice: '',
	deliveryAddressLine1: '',
	deliveryAddressLine2: '',
	deliveryAddressPostalCode: '',
	deliveryAddressStateCode: '',
	deliveryAddressState: '',
	deliveryAddressCountryCode: '',
	deliveryAddressCountry: '',
	currency: '',
	areaId: '',
	area: {
		id: '',
		description: ''
	},
	items: [defaultValuesItemPr]
}
