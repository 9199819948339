import { ApolloQueryResult } from 'apollo-boost'
import { useCallback, useEffect, useState } from 'react'
import { Currency } from '../types'
import { CURRENCIES_CATALOGUE } from '../views/requestForQuotationDetail/utilities/queries'
import { useClient } from './useClient'

const priorityOrder = ['MXN', 'USD', 'EUR']

const sortCurrenciesByPriority = (a: Currency, b: Currency) => {
	const indexA = priorityOrder.indexOf(a.code)
	const indexB = priorityOrder.indexOf(b.code)

	if (indexA === -1) return 1
	if (indexB === -1) return -1

	return indexA - indexB
}

export const useCurrencies = () => {
	const [currencies, setCurrencies] = useState<Currency[]>([])
	const client = useClient()
	const fetchCurrencies = useCallback(() => {
		client
			.query({
				query: CURRENCIES_CATALOGUE
			})
			.then((result: ApolloQueryResult<{ Currencies: Array<Currency> }>) => {
				if (result.data.Currencies) {
					result.data.Currencies.sort(sortCurrenciesByPriority)
					setCurrencies(result.data.Currencies)
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
	}, [client])

	useEffect(() => {
		fetchCurrencies()
	}, [fetchCurrencies])

	return { currencies, lastPrioritizedCode: priorityOrder.at(-1) }
}
