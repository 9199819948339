import { URLS } from '@netcurio/frontend-common'

export const authenticationFreeUrls = [
	URLS.LOGIN,
	URLS.REGISTER,
	URLS.RESET_PASSWORD,
	URLS.PASSWORD_RECOVERY,
	URLS.PASS_RESET_SUCCESSFUL,
	URLS.REGISTER_COMPANY,
	URLS.SITE
]
