import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const PURCHASE_ORDER_DETAIL_SUPPLIER: DocumentNode = gql`
	query PurchaseOrder($id: ID!, $customer: ID!) {
		PurchaseOrder(id: $id, customer: $customer) {
			goodsReceipts {
				id
				customer {
					rfc
				}
			}
			id
			customer {
				rfc
				name
			}
			status {
				key
				value
			}
			type {
				value
				key
			}
			branch_office
			modified_by {
				email
			}
			invoices {
				uuid
				serie
				folio
			}
			status {
				key
				value
			}
			created_at
			delivery_address_line_1
			delivery_address_line_2
			delivery_address_country
			delivery_address_state
			delivery_address_postal_code
			currency
			total
			updated_at
			created_at
			created_by {
				email
				name
				lastname
			}
			supplier {
				rfc
				name
			}
			items {
				position
				name
				code
				net_value
				unit_price
				reference_price
				price_change_reason
				requested_amount
				required_delivery_date
				tax
				unit
				status {
					key
					value
				}
				storage_location
				requires_confirmation
			}
			customer_reference
			user_reference
			attachment
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const PO_REASONS_REJECT: DocumentNode = gql`
	query PurchaseOrderRejectedReasons($language: ID) {
		PurchaseOrderRejectedReasons(language: $language) {
			value
			key
		}
	}
`

export const REJECT_ORDER: DocumentNode = gql`
	mutation rejectPurchaseOrder($id: ID!, $customer: ID!, $rejected_reason: String!) {
		rejectPurchaseOrder(id: $id, customer: $customer, rejected_reason: $rejected_reason) {
			code
			message
		}
	}
`

export const CONFIRM_ORDER: DocumentNode = gql`
	mutation confirmPurchaseOrder($id: ID!, $customer: ID!, $items: [PurchaseOrderItemInput!]!) {
		confirmPurchaseOrder(id: $id, customer: $customer, items: $items) {
			code
			message
		}
	}
`

export const REJECT_ORDER_SPECIFIC_REASON: DocumentNode = gql`
	mutation rejectPurchaseOrderBySpecificReasons(
		$id: ID!
		$customer: ID!
		$items: [PurchaseOrderItemInput!]!
	) {
		rejectPurchaseOrderBySpecificReasons(id: $id, customer: $customer, items: $items) {
			code
			message
		}
	}
`

export const CREATE_PURCHASE_ORDER_COMMENT: DocumentNode = gql`
	mutation createPurchaseOrderComment($purchase_order: ID!, $customer: ID!, $text: String!) {
		createPurchaseOrderComment(purchase_order: $purchase_order, customer: $customer, text: $text) {
			code
			message
		}
	}
`

export const PURCHASE_ORDER_COMMENTS: DocumentNode = gql`
	query PurchaseOrder($id: ID!, $customer: ID!) {
		PurchaseOrder(id: $id, customer: $customer) {
			id
			customer {
				rfc
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
