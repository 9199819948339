import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

export const REQUEST_FOR_QUOTATION_DETAIL: DocumentNode = gql`
	query RequestForQuotation($id: ID!, $customer: ID!) {
		RequestForQuotation(id: $id, customer: $customer) {
			id
			status {
				key
				value
			}
			created_at
			updated_at
			expiration_date
			date_rejected
			date_closed
			date_quoted
			created_by {
				email
				name
				lastname
			}
			supplier {
				rfc
				name
			}
			customer {
				rfc
				name
			}
			branch_office
			delivery_address_line_1
			delivery_address_line_2
			delivery_address_postal_code
			delivery_address_state
			delivery_address_country
			items {
				position
				product_description
				unit
				quantity
				required_delivery_date
				product_code
			}
			quotations {
				id
				total
				expiration_date
				hasExpired
				supplier {
					rfc
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			modified_by_supplier {
				email
				name
				lastname
			}
			customer_reference
			setting_validate_catalogue
			incoterm {
				code
				description
			}
			payment_term {
				code
				description
			}
			user_reference
			attachment
		}
	}
`
