import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const SUPPLIERS_BY_NAME_OR_CODE: DocumentNode = gql`
	query SuppliersByNameOrCode($search_text: String!, $sort_field: String, $sort_order: String) {
		SuppliersByNameOrCode(search_text: $search_text, sort_field: $sort_field, sort_order: $sort_order) {
			rfc
			name
		}
	}
`
