import { Company, getCompany, Roles, URLS } from '@netcurio/frontend-common'
import { Severity, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ErrorModal } from '../../components/dialogModal/errorModal'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import fileToBase64 from '../../utilities/fileToBase64'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import DocumentComments from './documentAndCommets'
import HeaderTicket from './headerTicket'
import { ConfirmNewTicketModal } from './NewTicketModal/ConfirmNewTicketModal'
import * as queries from './queries'
import styles from './styles.module.scss'
import { DataHeader, ModalData, ModalTypes } from './types'

const userRoles: Roles[] = getUserRolesForCompany()

export const NewTicket = (): ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const history = useHistory()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [modalType, setModalType] = useState<ModalTypes>()
	const [errorCode, setErrorCode] = useState<string>()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [showModal, setShowModal] = useState<boolean>()
	const [showModalCancelNewTicket, setShowModalCancelNewTicket] = useState<boolean>(false)
	const [showModalConfirmSaveNewTicket, setShowModalConfirmSaveNewTicket] = useState<boolean>(false)
	const [showModalChangeReceiver, setShowModalChangeReceiver] = useState<boolean>(false)
	const [dataHeader, setDataHeader] = useState<DataHeader>({
		receiver: {
			name: undefined,
			rfc: undefined
		},
		category: {
			code: undefined,
			description: undefined
		},
		subject: undefined
	})
	const [description, setDescription] = useState<string>()
	const [file, setFile] = useState<File | undefined>()
	const [comment, setComment] = useState<string>()
	const [disabledButtonSend, setDisabledButtonSend] = useState<boolean>(true)
	const [verifyReceiverReset, setVerifyReceiverReset] = useState<boolean>(false)
	const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

	useEffect(() => {
		if (showModal && modalType !== undefined) {
			const errorModalShow = errorCode !== undefined
			const { fillModal, acceptActionModal } = getModalData()
			if (errorModalShow) {
				const modal: ReactElement = (
					<ErrorModal
						fillModal={fillModal}
						acceptActionModal={acceptActionModal}
						hideModal={() => {
							setShowModal(false)
							setModal(undefined)
							setModalType(undefined)
							setErrorCode(undefined)
							setErrorMessage(undefined)
						}}
						errorCode={errorCode}
					/>
				)
				setModal(modal)
			}
		}
	}, [showModal])

	useEffect(() => {
		activateSendButton()
	}, [dataHeader, description])

	//modal render
	const getModalData = (): ModalData => {
		switch (modalType) {
			case ModalTypes.error:
				return { fillModal: errorModal(errorCode, errorMessage) }
			default:
				break
		}
	}

	//error capture of the request
	const handleError = (error: Error) => {
		console.error(error)
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setModalType(ModalTypes.error)
			setErrorCode(newErrorCode)
			if (error.message) setErrorMessage(error.message)
			setShowModal(true)
		}
		hideLoadingSpinner()
	}

	const resetDataTicket = () => {
		setDataHeader({
			receiver: {
				name: undefined,
				rfc: undefined
			},
			category: {
				code: undefined,
				description: undefined
			},
			subject: undefined
		})
		setDescription(undefined)
		setComment(undefined)
		setFile(undefined)
		setVerifyReceiverReset(true)
		setShowModalChangeReceiver(false)
	}

	const changeReceiver = () => {
		setShowModalChangeReceiver(true)
	}

	const activateSendButton = (): void => {
		if (dataHeader.receiver.rfc && dataHeader.category.code && dataHeader.subject && description)
			setDisabledButtonSend(false)
		else setDisabledButtonSend(true)
	}

	const actionButtonSend = () => {
		setShowModalConfirmSaveNewTicket(true)
	}

	const cancelNewTicket = () => {
		setShowModalCancelNewTicket(false)
		history.push(URLS.TICKET_LIST)
	}

	const saveNewTicket = async () => {
		showLoadingSpinner()
		let document_creation: string
		if (file) {
			await fileToBase64(file)
				.then((res: string) => {
					document_creation = res.split('base64')[1]
				})
				.catch(handleError)
		}

		const dataTicket = {
			receiver: dataHeader.receiver.rfc,
			category: dataHeader.category.code,
			subject: dataHeader.subject,
			description: description,
			document_creation,
			comment: { text: comment || '' }
		}
		client
			.mutate({
				mutation: queries.CREATE_NEW_TICKET,
				variables: dataTicket
			})
			.then((result) => {
				if (result.data) {
					const idTicket: string = result.data.createTicket
					const sender: Company = getCompany()
					history.push(`${URLS.TICKET_DETAIL}?ticket=${idTicket}&sender=${sender.rfc}`)
				}
			})
			.catch(handleError)
			.finally(() => {
				setShowModalConfirmSaveNewTicket(false)
				hideLoadingSpinner()
			})
	}
	return (
		<div className={styles.newTicketContainer}>
			{modal ?? modal}
			<AuthenticatedHeader>
				<div>
					<CancelButton onClick={() => setShowModalCancelNewTicket(true)} />
				</div>
				<div />
			</AuthenticatedHeader>
			<HeaderTicket
				dataHeader={dataHeader}
				setDataHeader={setDataHeader}
				handleError={handleError}
				description={description}
				setDescription={setDescription}
				changeReceiver={changeReceiver}
				verifyReceiverReset={verifyReceiverReset}
				setVerifyReceiverReset={setVerifyReceiverReset}
			/>
			<DocumentComments
				nameFile={file?.name}
				setFile={setFile}
				setComment={setComment}
				disabledButtonSend={disabledButtonSend}
				actionButtonSend={actionButtonSend}
			/>
			<ConfirmNewTicketModal
				open={showModalCancelNewTicket}
				close={() => setShowModalCancelNewTicket(false)}
				accept={cancelNewTicket}
				bodyText="cancelTicketText"
				textButton="acceptTextButton"
			/>
			<ConfirmNewTicketModal
				open={showModalConfirmSaveNewTicket}
				close={() => setShowModalConfirmSaveNewTicket(false)}
				accept={saveNewTicket}
				title="sendTicketTitle"
				headerTitleSeverity={Severity.Warning}
				bodyText="sendTicketText"
				textButton="buttonSendTextHco"
			/>
			<ConfirmNewTicketModal
				open={showModalChangeReceiver}
				close={() => setShowModalChangeReceiver(false)}
				accept={resetDataTicket}
				bodyText={
					userRoles.includes(Roles.SUPPLIER) ? 'deleteCustomerQuestion' : 'deleteSupplierQuestion'
				}
				textButton="acceptTextButton"
			/>
		</div>
	)
}
